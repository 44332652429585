import moment from "moment/moment";
import tickets from "../Portals/DataStore/AdminStore/Tickets";

export class TimeInterval{
    constructor() {
    }
    getTimeInterval(startDate,interval) {
        const firstDate = moment(startDate);
        const datesInterval = [];
        datesInterval.push(firstDate.format('l').toString());
        for (let i = 0; i < interval; i++) {
            datesInterval.push(firstDate.subtract(1, 'days').format('l').toString()
            )
        }
        return datesInterval
    }
    TimeIntervalHours(startTime,interval){
        const startDate= moment(startTime)
        const hourInterval = []
        hourInterval.push(startTime.format('LT').toString())
        for(let i=0;i<interval;i++){
            hourInterval.push(startDate.subtract(1,'hours').format('LT').toString())
        }
        return hourInterval
    }
    DateSet(tickets,startDate,interval,label,status,borderWidth,borderColor,backgroundColor,tension,fill){
        const dateInterval = this.getTimeInterval(startDate,interval)
        let dataset =[]
        console.log("filtering tiket ...",tickets)

        for (let i=0;i<dateInterval.length;i++){
            const filteredTickets=tickets.filter(ticket=> {
                console.log("Ticket Status",ticket.status)
                console.log("Ticket label",label)
                console.log("Ticket date",ticket.createdAt)
                console.log("Ticket moment",moment(ticket.createdAt).format('l'))
                console.log("Ticket dateInterval ",dateInterval[i])
                console.log("Ticket dateInterval ",dateInterval[i])
                return ticket.status === status&&moment(ticket.createdAt).format('l').toString()===dateInterval[i].toString()
            })
            console.log("Filtered tickets",filteredTickets)
            dataset.push(filteredTickets.length)
        }
        console.log("Stop filtering Data ...")

        const Dataset= {label:label,data:dataset,borderWidth,borderColor,backgroundColor,tension,fill}
        console.log("dataset ",Dataset)
        return Dataset
    }

}



