import { Box, Flex } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { server_URL } from "../../serverURL";
import { AdminclientsActions } from "../DataStore/AdminStore/Clients";
import { AdminTicketsActions } from "../DataStore/AdminStore/Tickets";
import AdminNavBar from "./components/AdminNavBar";
import AdminSideBar from "./components/AdminSideBar";
import { userActions } from "../DataStore/UserData";
import { io } from "socket.io-client";
import { Store } from "react-notifications-component";
import { useLayoutEffect } from "react";
import {ClientCommunicationActions} from "../DataStore/Client/ClientCommunication";
import {Howler} from "howler";
import * as signalR from "@microsoft/signalr";


function AdminInterface() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user);
  const token = sessionStorage.getItem("Session-token");
  const email = sessionStorage.getItem("Session-email");
  // const [adminSocket, setadminSocket] = useState(() =>
  //   io(server_URL, {
  //     query: {
  //       email: userData.email,
  //       role: "ADMIN",
  //     },
  //     extraHeaders: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   })
  // );
  const [connection, setConnection] = useState(null);
  useEffect(() => {
    getSummary();
    const newConnection= new signalR.HubConnectionBuilder().withUrl(`${server_URL}/techChat`)
        .withAutomaticReconnect()
        .build();
    newConnection.start();
    console.log()
    newConnection.on("ReceiveMessage", (user, message) => {
      console.log(user, message)
    });
    setConnection(newConnection);
    // adminSocket.on("connect", () => {
    //   dispatch(userActions.setActive(true));
    //   adminSocket.emit("admin:getData");
    // });
    // adminSocket.on("activeusers", (data) => {
    //   dispatch(AdminclientsActions.addActiveUsers(data));
    // });
    // adminSocket.on("$techresq:ticket:added", (data) => {
    //   dispatch(AdminTicketsActions.addTicket(data));
    //   //Message({
    //   //  message: `New Ticket added by ${data.user}`,
    //   //  status: "success",
    //   //});
    // });
    // adminSocket.on("$techresq:message:added", (data) => {
    //     dispatch(AdminTicketsActions.addMessage(data.message));
    //     // Message({
    //     //   message: `New Ticket added by ${data.user}`,
    //     //   status: "success",
    //     // });
    // });
    // adminSocket.on("$techresq:ticket:agent:assigned", (data) => {
    //   dispatch(AdminTicketsActions.updateAgent(data.agent));
    //   // Message({
    //   //   message: `Agent Assigned ${data.agent}`,
    //   //   status: "success",
    //   // });
    // });
    // adminSocket.on("$techresq:tickets:status:updated", (data) => {
    //   dispatch(AdminTicketsActions.updateStatus(data.status));
    //   dispatch(AdminTicketsActions.addNote(data.noteData))
    //   // //Message({
    //   //   message: `Status Changed ${data.email}`,
    //   //   status: "success",
    //   // });
    // });
    // adminSocket.on("$techresq:consultation:updated", (data) => {
    //   console.log('Consultation Updated',data)
    //   dispatch(AdminTicketsActions.updateConsultationStatus(data.consultation));
    //   dispatch(AdminTicketsActions.addNote(data.note))
    // });
    // adminSocket.on("disconnect", (reason, details) => {
    //   dispatch(userActions.setActive(false));
    //   //Message({ message: "User disconnected", status: "danger" }); // '{"code":1,"message":"Session ID unknown"}'
    // });
    return () => {
      // adminSocket.disconnect();
      // adminSocket.off("connect");
      // adminSocket.off("disconnect");
      // adminSocket.off("new user");
      // adminSocket.off("$techresq:ticket:added");
      // adminSocket.off("$techresq:message:added");
      dispatch(AdminclientsActions.removeClients());
      dispatch(AdminclientsActions.removeActiveUsers());
      dispatch(AdminTicketsActions.removeTickets());
    };
  }, []);
  const getSummary = async () => {

    const config = {
      url: `${server_URL}/Admins/admins/summary?listSize=10`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Get Summary",res);
        dispatch(AdminclientsActions.addClients(res.data.users))
        dispatch(AdminTicketsActions.addTickets(res.data.tickets));
      })
      .catch((err) => {
        console.log("Data admin error", err);
      });
  };
  const Message = ({ message, status }) => {
    Store.addNotification({
      message: message,
      type: status,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
      onRemoval: () => {
        // setloading(false);
        // navigate("/login");
      },
    });
  };
  console.log("Entering Admin interface ")
  return (
    <Box h="100%" overflow={"hidden"} className={'text-base'}>
      <AdminNavBar />
      <Flex
        className="p-0 m-0 no-gutters overflow"
        flex={"auto"}
        flexDirection={"row"}
        h="calc(100vh - 3em)"
        w={"100%"}
        bgColor="red"
      >
        <AdminSideBar />
        <Box w="100%" h={"100%"} bgColor={'whitesmoke'} overflow="hidden" p={3}>
          {/*<Outlet context={[adminSocket]}/>*/}
           <Outlet />
        </Box>
      </Flex>
    </Box>
  );
}
export default AdminInterface;
