import { Box, Button, Flex, Text ,Icon, IconButton, Tag} from '@chakra-ui/react'
import React, {useEffect, useRef, useState} from 'react'
import { BiUpload } from 'react-icons/bi'
import { MdClose, MdUpload } from 'react-icons/md'

function FileInput(props) {
  const [filename, setFileName] = useState('')
  const [fileSize, setFileSize] = useState('')
  const [file, setFile] = useState([])
  const fileRef = useRef(null)
  const handleChanges =(e)=>{
    props.fileChange(e.current.files[0])
    console.log(e.current.files)
    setFile(e.current.files[0])
  }
;
 function humanFileSize(size) {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

  const resetFile =()=>{
    setFile([])
    props.fileChange([])
      setFileName("")
      setFileSize('')
  }
  return (
    <Box pos={'relative'} w='100%'  m={1} borderRadius={'5px'} bgColor='gray.200' className="file-upload">
    <Box p={1}>
        <input ref={fileRef} onChange={()=>handleChanges(fileRef)} accept={'.pdf,.jpeg,.png'}  multiple={props.multiple} style={{display:'none'}} type="file" name="file-upload-input" id={`file-upload-input-${props.id}`} />

        {file.name===undefined?<label style={{backgroundColor:'',width:'100%'}} className="file-select-name" htmlFor={`file-upload-input-${props.id}`}>
           <Flex flexDir={'row'} justifyContent='center' alignItems={'center'} w='100%' p={1}>
               <Icon as={MdUpload}/>
               {props.children}
           </Flex>
        </label>:<>
        <Flex flexDir={'row'} justifyContent='space-between' alignItems={'center'}>

          <Flex flexDir={'row'}  w='100%' justifyContent='space-between'  alignItems={'center'}>
                <Text m={1} fontSize='.8em'>{file.name.length<30?file.name.slice(0,30):(file.name.slice(0,30)+"...")}</Text>
                <Tag  color={file.size<=2097152?"green":"red"} m={1} fontSize='.8em'>{humanFileSize(file.size)}</Tag>
            </Flex>
            <IconButton icon={<MdClose/>} onClick={() => resetFile()}  aria-label={'icon button'}/>
        </Flex>

        </>}
    </Box>
    {file.size>=2097152?<Text bgColor={'red.300'} borderBottomEndRadius={'5px'} borderBottomLeftRadius={'5px'} fontSize={'.7em'} p={1}>File too Big!!! (max: 2MB)</Text>:null}
    </Box>
  )
}
export default FileInput