import { createSlice } from "@reduxjs/toolkit";
const AdminClientSlice = createSlice({
  name: "adminclients",
  initialState: {
    clients: [],
    activeUsers:[],
    selectedClient:[]
  },
  reducers: {
    addClients(state, action) {
      const clients = action.payload;
      state.clients = clients;
    },
    addActiveUsers(state, action) {
      const clients = action.payload;
      state.activeUsers = clients;
    },
    addClient(state, action) {
      const clients = action.payload;
      Object.keys(clients).forEach((key) => {
        if (clients[key] !== state.clients[key]) {
          state.clients = [...state.clients, clients[key]];
        }
      });
    },
    removeClients(state, action) {
      state.clients = [];
    },
    removeActiveUsers(state, action) {
      state.activeUsers = [];
    },
    selectClient(state,action){
      state.selectedClient=action.payload
    },
    removeSelectedClient(state,action){
      state.selectClient= []
    }
  },
});
export const AdminclientsActions = AdminClientSlice.actions;
export default AdminClientSlice;
