import { Box, Button, Flex, List, ListItem, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { BiHome, BiMoney } from 'react-icons/bi'
import { BsPeople } from 'react-icons/bs'
import {TbSettings2, TbTicket} from 'react-icons/tb'
import { Link } from 'react-router-dom'
import {IoSettings} from "react-icons/io5";
import {IoIosCart} from "react-icons/io";

function AdminSideBar() {
    const { isOpen, onToggle, onClose } = useDisclosure()
    const today= new Date();
  return (
    <Box className={'h-full flex flex-col justify-between'} bgColor='#033053'  color='white' >
        <List  className={'m-0 p-0 ww-full'} fontSize='2xl'>
            <ListItem _hover={{backgroundColor:'white',color:'blue.800'}}  textDecor={'none'} as={Link} to='/admin/analytics'  className={'flex flex-row align-items-center my-2 justify-start px-2 w-full'}>
                <BiHome style={{margin:'0'}}/>
               <Text fontSize='md' className={'mx-2 my-3 p-0'}>Home</Text>
            </ListItem>
            <ListItem _hover={{backgroundColor:'white',color:'blue.800'}} w='100%' textDecor={'none'} as={Link} to='/admin/ticket/all' className={'flex flex-row align-items-center justify-start px-2 w-full'}>
                <TbTicket style={{margin:'0'}}/>
              <Text fontSize='md' className={'mx-2 my-3 p-0'}>Tickets</Text>
            </ListItem>
            <ListItem _hover={{backgroundColor:'white',color:'blue.800'}} className={'flex flex-row align-items-center my-2 justify-start w-full'} >
                <Popover onClose={onClose} trigger='hover' placement='right' >
                <PopoverTrigger>
                <Flex className={'flex flex-row align-items-center  justify-start px-2 w-full'} >
                   <Box>
                       <BsPeople style={{margin:'0'}}/>
                   </Box>
                    <Text fontSize='md'className={'mx-2 my-3 p-0'}>Users</Text>
                </Flex>
                </PopoverTrigger>
                 <PopoverContent>
    <PopoverArrow />
    
    <PopoverBody w={'100%'}>
        <Button w='100%' as={Link} to="/admin/agent/all" onClick={()=>onClose()}>Agents</Button>
        <Button w='100%' as={Link} to="/admin/user/all">Clients</Button>
        <Button w='100%' as={Link} to="/admin/admin/all">Admin</Button>
    </PopoverBody>
  </PopoverContent>
</Popover>
            </ListItem>
            <ListItem _hover={{backgroundColor:'white',color:'blue.800'}}  textDecor={'none'} as={Link} to='/admin/orders'  className={'flex flex-row align-items-center my-2 justify-start px-2 w-full'}>
                <IoIosCart style={{margin:'0em'}}/>
                <Text fontSize='md' className={'mx-2 my-3 p-0'}>Orders </Text>
            </ListItem>
            <ListItem _hover={{backgroundColor:'white',color:'blue.800'}}  textDecor={'none'} as={Link} to='/admin/ticket/all'  className={'flex flex-row align-items-center my-2 justify-start px-2 w-full'}>
                <BiMoney style={{margin:'0em'}}/>
              <Text fontSize='md' className={'mx-2 my-3 p-0'}>Billings </Text>
            </ListItem>

            <ListItem _hover={{backgroundColor:'white',color:'blue.800'}}  as={Link} to='/admin/settings/'  className={'flex flex-row align-items-center my-2 justify-start px-2 w-full'}>
                <TbSettings2 style={{margin:'0'}}/>
                <Text fontSize='md' className={'mx-2 my-3 p-0'}>Settings</Text>
            </ListItem>
        </List>
        <Text className={'text-sm my-3'}>TechResQ<span className={'font-thin font-sans'} > @{today.getFullYear()}</span></Text>
    </Box>
  )
}

export default AdminSideBar
