import {Box,Center,Text} from "@chakra-ui/react";
import NavBar from "../../components/navBar";
import Footer from "../../pages/Footer";
import {useLocation} from "react-router-dom";
import {server_URL} from "../../serverURL";
import axios from "axios";
function AgentEmailVerified(){
    const location=useLocation()
    const verifyingEmail = async () => {
        const data = {
            token: location.search.slice(1).split('=')[1]
        }
        const config = {
            url: server_URL + "/agents/verifyagentemail",
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            data: data
        }
        await axios(config).then(response=>{
            if(response){
                console.log(response)
            }
        }).catch(error=>console.log(error))
    }

    return (
        <Box>
            <NavBar/>
            <Center m={5} p={5} w={''} height={'80vh'}>
                <Box width={'50%'}>
                    <Box height={'20px'} p={3} bgColor={'green'}>
                    </Box>
                    <Box>
                        Email verified token:{location.search.slice(1).split('=')[1]}
                    </Box>
                </Box>
                <button onClick={()=>verifyingEmail()}>Verify </button>
            </Center>
            <Footer/>
        </Box>
    )
}

export default AgentEmailVerified