import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import ndalaict from "../Assets/ndalaict.png";
import hardware from "../Assets/hardware.jpg";
import security from "../Assets/security.jpg";
import {Text} from "@chakra-ui/react";

function SecuritySystems() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="sales_page">
            <NavBar />
            <div className="body">
                <div className="heading">
                    <div className="">
                        <h3>Security Systems</h3>
                        <img src={security} alt="" />
                        <Text className={'text-xs italic text-gray-400 fw-lighter text-end'}>Photo by <a href="https://unsplash.com/@imedianamibia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Alan J. Hendry</a> on <a href="https://unsplash.com/s/photos/security?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                        </Text>
                    </div>
                    <hr />
                    <div className="list">
                        <div className="Card">
                            <svg
                                aria-hidden="true"
                                role="img"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    stroke="none"
                                />
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m8 4l8 8l-8 8"
                                />
                            </svg>
                            <h5>CCTV SYSTEMS</h5>
                        </div>
                        <div className="Card">
                            <svg
                                aria-hidden="true"
                                role="img"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    stroke="none"
                                />
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m8 4l8 8l-8 8"
                                />
                            </svg>
                            <h5>ACCESS CONTROL</h5>
                        </div>
                        <div className="Card">
                            <svg
                                aria-hidden="true"
                                role="img"
                                width="1em"
                                height="1em"
                                preserveAspectRatio="xMidYMid meet"
                                viewBox="0 0 24 24"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    stroke="none"
                                />
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m8 4l8 8l-8 8"
                                />
                            </svg>
                            <h5>ELECTRIC FENCE</h5>
                        </div>

                    </div>
                    <div className="_power">
                        <div className="text">
                            <p>
                                To learn more about our solutions visit our company website{" "}
                            </p>
                        </div>
                        <div className="ndalaict">
                            <div className="logo">
                                <img src={ndalaict} alt="" />
                            </div>
                            <div className="link">
                                <div className="site">
                                    <a
                                        target="_blank"
                                        href="https://www.ndalaict.com"
                                        rel="noreferrer"
                                        style={{ color: "silver" }}
                                    >
                                        NdalaICT Solutions{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SecuritySystems;
