import { createSlice } from "@reduxjs/toolkit";

const NotificationSlice = createSlice({
  name: "clientTickets",
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotifications(state, action) {
      const notifications = action.payload;
      Object.keys(notifications).forEach((key) => {
        if (notifications[key] !== state.notifications[key]) {
          state.notifications = [...state.notifications, notifications[key]];
        }
      });
    },
    addNotification(state, action) {

      const notification = action.payload;
      console.log("Adding notification ", notification);
      state.notifications = [...state.notifications, notification];
    },
    clearNotifications(state, action) {
      state.notifications = [];
    },
  },
});
export const NotificationActions = NotificationSlice.actions;
export default NotificationSlice;
