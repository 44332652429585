import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Container,
  Divider,
  Flex,
  GridItem,
  Img,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  ButtonGroup,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Wrap, IconButton,
} from "@chakra-ui/react";
import { Scale } from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import creditcard from "../../Assets/creditcard.gif";
import { userActions } from "../DataStore/UserData";
import { Store } from "react-notifications-component";
import {server_URL} from "../../serverURL";
import axios from "axios";
import {MdError} from "react-icons/md";
import VerifyUserEmail from "./components/VerifyUserEmail";
import ErrorsHandler from "../../components/ErrorsHandler";

const statusColor = [
  { status: "1", bgColor: "#532300" ,color:"white"},
  { status: "2", bgColor: "#e5707d" ,color:"black"},
  { status: "3", bgColor: "#6cf15d" ,color:"black"},
  { status: "4", bgColor: "#a7c759" ,color:"black"},
  { status: "5", bgColor: "#415300" ,color:"white"},
];
function ClientHome(props) {
  const toast = useToast();
  const [isLoading, setisLoading] = useState(false);
  const [invoiceExist, setinvoiceExist] = useState(false);
  const [verifiedUserValue, setverifiedUserValue] = useState(0);
  const userData = useSelector((state) => state.user);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isOpen:isOpenEmailSuccess, onOpen:onOpenEmailSuccess, onClose:onCloseEmailSuccess } = useDisclosure();
  const { isOpen:isOpenEmailError, onOpen:onOpenEmailError, onClose:onCloseEmailError } = useDisclosure();
  const { isOpen:isOpenFunds, onToggle:onToggleFunds, onClose:onCloseFunds } = useDisclosure();

  const [totalAmount,setTotalAmount] =useState(userData.payments.map(payment=>parseFloat(payment.amount).toFixed(2)/100.00).reduce((total,num)=>(total+num),0).toFixed(2))
  const [temporaryEmailVerification ,settemporaryEmailVerification]=useState(userData.email_verified)
  const bankDetails = useSelector((state) => state.banking);
  const date = new Date();
  const tickets = useSelector((state) => state.clientTickets).tickets;
  const currentTicket = useSelector((state) => state.clientTickets).currentTicket;
  const userVerified = useSelector((state) => state.userVerified);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const statusDescrptions = {
    1: "Open",
    2: "Closed",
    3: "Pending",
    4: "Resolved",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let count = 0;
    setisLoading(false);
    if (userData.emailConfirmed!==false) {
      console.log("email valid  ");

      count += 30;
    } else {
      onToggle();
      count += 0;
    }
    // if (bankDetails.holder !== undefined) {
    //   console.log("bank details valid  ", bankDetails.cardHolder);
    //   count += 30;
    //   onClose();
    // } else {
    //   console.log("bank details invalid  ");
    //   onOpen();
    // }
    if (userData?.job_title !== null && userData.address !== null) {
      console.log("job tittle exist  ");
      count += 40;
    }
    setverifiedUserValue(count);

  }, []);
  const sendEmail = async () => {
    onToggle();
    const config = {
      url: server_URL + '/users/sendemail',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        email: userData.email
      }
    }
    await axios(config).then(response => {
        if(response){
          //console.log(response.data)
          dispatch(userActions.userVerified(true))
          //Message(`Email sent to ${userData.email}`, "success");
          onOpenEmailSuccess();
        }else {
          //console.log('No response')
          //Message("Email not sent ", "danger");
          onOpenEmailError();
        }
    }).catch(error=>{
      //console.log(error)
      //Message("We could not verify the user at the moment  ", "danger");
      onOpenEmailError();
    })

  };
  const Message = (message, type) => {
    Store.addNotification({
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: () => {},
    });
  };
  console.log(tickets.length===0)
  return (
    <div className="" style={{ position: "relative" }}>
      <Box className="md:grid md:grid-cols-12 grid-rows-1">
        <div
          className={`${
            verifiedUserValue === 100
              ? "col-lg-12"
              : "flex flex-col grid-cols-12 md:col-span-9  w-full lg:flex-row"
          }`}
        >
          {props.isLoading ? (
            <LoadTickets/>
          ) : (
            <Box
              bg="gray.200"
              h={"100%"}
              m={1}
              borderRadius={"md"}
              width={"100%"}
            >
              <ErrorsHandler isOpen={isOpenEmailSuccess} onClose={onCloseEmailSuccess} onOpen={onOpenEmailSuccess} type={'Success'} name={"emailSucessful"} message={"Sorry ,we could not send the mail at the moment"}/>
              <ErrorsHandler isOpen={isOpenEmailError} onClose={onCloseEmailError} onOpen={onOpenEmailError} type={"Error"} name={"emailError"} message={"Sorry ,we could not send the mail at the moment"}/>
              <Box className={'flex flex-row w-full'}>
                <Text fontSize={"xl"} className={'w-full my-auto'} color={"blue.700"} mt={4}>
                  Recent Tickets{" "}
                </Text>
               <Box className={'block md:hidden lg:hidden'}>
                 <Popover  >
                   <PopoverTrigger className={'p-2'}>
                     <IconButton className={'w-fit m-4'} bgColor='red' color={'white'} icon={<MdError/>} aria-label={'user not verified'}/>
                   </PopoverTrigger>
                   <PopoverContent>
                     <PopoverBody>
                       <Box
                           h={"100%"}
                           borderRadius={"md"}
                           p={4}
                           width={"100%"}
                           boxShadow={"sm"}
                           display={"block"}

                       >
                         <Flex p={0}>
                           <Spacer />
                           <Skeleton isLoaded={!props.isLoadingProfile}>
                             {verifiedUserValue >= 100 ? (
                                 <Badge variant="solid" colorScheme="green">
                                   Verified
                                 </Badge>
                             ) : (
                                 <Badge variant="solid" colorScheme="red">
                                   Not Verified
                                 </Badge>
                             )}
                           </Skeleton>
                         </Flex>
                         <Skeleton isLoaded={!props.isLoadingProfile}>
                           <Avatar
                               size="2xl"
                               name="User avatar"
                               src={userData.avatarDestination}
                               bgColor="white"
                               id={"avatar-tour"}
                               className="avatar-data"
                               m={0}
                           ></Avatar>
                         </Skeleton>
                         <Center width={"100%"} m={"1rem"}>
                           <List spacing={1} mx="auto" textAlign="start">
                             <ListItem>
                               <Popover
                                   returnFocusOnClose={false}
                                   isOpen={isOpen}
                                   onClose={onClose}
                                   placement="right"
                                   closeOnBlur={false}
                               >
                                 <PopoverTrigger>
                                   <Box>
                                     <ListIcon
                                         as={
                                           userData.email_verified
                                               ? CheckCircleIcon
                                               : WarningIcon
                                         }
                                         cursor={userData.email_verified ? "none" : "pointer"}
                                         color={`${
                                             userData.email_verified ? "green.500" : "red.400"
                                         }`}
                                     />
                                     Email verification
                                   </Box>
                                 </PopoverTrigger>
                                 <PopoverContent boxShadow="lg" bgColor="red.400">
                                   <PopoverHeader
                                       fontWeight="semibold"
                                       className="w-full flex justify-content-center"
                                       bgColor="red.500"
                                   >
                                     <Text
                                         fontWeight="600"
                                         fontFamily={"Roboto"}
                                         className="text-xl"
                                         color="gray.200"
                                     >
                                       {" "}
                                       Email not verified
                                     </Text>
                                   </PopoverHeader>
                                   <PopoverArrow bgColor="whitesmoke" color="whitesmoke" />
                                   <PopoverCloseButton color="white" />
                                   <PopoverBody className="p-2" bgColor="whitesmoke">
                                     <Box className="flex flex-col ">
                                       <Text className="py-3">
                                         {" "}
                                         Click the button below to verify the email{" "}
                                       </Text>
                                      <VerifyUserEmail email={userData.email} fullName={userData.fullName} phoneNumber={userData.phoneNumber}/>
                                     </Box>
                                   </PopoverBody>
                                 </PopoverContent>
                               </Popover>
                             </ListItem>
                             <ListItem>
                               <ListIcon
                                   as={
                                     bankDetails
                                         ? bankDetails?.cardHolder !== ""
                                             ? CheckCircleIcon
                                             : WarningIcon
                                         : WarningIcon
                                   }
                                   color={`${
                                       bankDetails?.cardHolder !== "" ? "green.500" : "red.400"
                                   }`}
                               />
                               Payment Card Verification
                             </ListItem>
                             <ListItem as={Link} to="/mydashboard/profile">
                               <ListIcon
                                   as={
                                     userData?.job_title !== null && userData.address !== null
                                         ? CheckCircleIcon
                                         : WarningIcon
                                   }
                                   color={`${
                                       userData?.job_title !== null && userData.address !== null
                                           ? "green.500"
                                           : "red.500"
                                   }`}
                               />
                               Personal Details
                             </ListItem>
                           </List>
                         </Center>
                         <Flex>
                           <Center m="auto">
                             <CircularProgress
                                 textAlign={"start"}
                                 value={verifiedUserValue}
                                 className={'m-0 my-2 md:m-4'}
                                 size="3em"
                                 thickness={'2px'}
                                 color={
                                   verifiedUserValue < 40
                                       ? "red.400"
                                       : verifiedUserValue < 80
                                           ? "orange.400"
                                           : "green.400"
                                 }
                             >
                               <CircularProgressLabel >
                                 <Text>{verifiedUserValue}%</Text>
                                 <Text className={'m-0 my-2 md:m-4'}>Profile Completion </Text>
                               </CircularProgressLabel>
                             </CircularProgress>
                           </Center>
                         </Flex>
                       </Box>
                     </PopoverBody>
                   </PopoverContent>
                 </Popover>
               </Box>
              </Box>
              <div className="grid grid-cols-2 h-fit grid-rows-2 md:grid md:grid-rows-1 md:grid-cols-4">
                <Box
                  boxShadow={"sm"}
                  bgColor={"gray.100"}
                  _hover={{ bgColor: "whiteAlpha.800" }}
                  className="m-1 py-3 px-1 flex justify-center align-middle flex-row  md:flex-col lg:flex-row"
                >
                  <Text className={'my-auto mx-1 p-0'}>Open</Text>
                  <CircularProgress
                    value={tickets.length!==0?Math.floor(
                      (tickets?.tickets.filter((i) => i.status === "1").length /
                        tickets.tickets.length) *
                        100
                    ):0}
                    className={'my-4'}
                    size="50px"
                    color="orange.400"
                  >
                    <CircularProgressLabel>
                      {tickets.legth!==0?tickets.filter((i) => i.status === "1").length:0}
                    </CircularProgressLabel>
                  </CircularProgress>
                </Box>
                <Box
                  className="m-1 py-3 px-1 flex justify-center align-middle flex-row  md:flex-col lg:flex-row"
                  bgColor={"gray.100"}
                  _hover={{ bgColor: "whiteAlpha.800" }}
                  boxShadow={"md"}
                >
                  <Text  className={'my-auto mx-1 p-0'}>Closed</Text>
                  <CircularProgress
                    value={tickets.length!==0?Math.floor(
                      (tickets?.filter((i) => i.status === "2").length /
                        tickets.tickets.length) *
                        100
                    ):0}
                    className={'my-4'}
                    size="50px"
                    color="orange.400"
                  >
                    <CircularProgressLabel>
                      {tickets.length!==0?tickets?.tickets.filter((i) => i.status === "2").length:0}
                    </CircularProgressLabel>
                  </CircularProgress>
                </Box>
                <Box
                  boxShadow={"md"}
                  className="m-1 py-3 px-1 flex justify-center align-middle flex-row  md:flex-col lg:flex-row"
                  bgColor={"gray.100"}
                  _hover={{ bgColor: "whiteAlpha.800" }}
                  p={3}
                >
                  <Text  className={'my-auto mx-1 p-0'}>Pending</Text>
                  <CircularProgress
                    value={tickets.length!==0?Math.floor(
                      (tickets?.tickets.filter((i) => i.status === "3").length /
                        tickets.tickets.length) *
                        100
                    ):0}
                    className={'my-4'}
                    size="50px"
                    color="orange.400"
                  >
                    <CircularProgressLabel>
                      {tickets.length!==0?tickets?.tickets.filter((i) => i.status === "3").length:0}
                    </CircularProgressLabel>
                  </CircularProgress>
                </Box>
                <Box
                  boxShadow={"md"}
                  className="m-1 py-3 px-1 flex justify-center align-middle flex-row  md:flex-col lg:flex-row"
                  bgColor={"gray.100"}
                  _hover={{ bgColor: "whiteAlpha.800" }}
                >
                  <Text  className={'my-auto mx-1 p-0'}>Resolved</Text>
                  <CircularProgress
                    value={tickets.length!==0?Math.floor(
                      (tickets?.tickets.filter((i) => i.status === "4").length /
                        tickets.tickets.length) *
                        100
                    ):0}
                    className={'my-4'}
                    size="50px"
                    color="orange.400"
                  >
                    <CircularProgressLabel>
                      {tickets.length!==0?tickets?.tickets.filter((i) => i.status === "4").length:0}
                    </CircularProgressLabel>
                  </CircularProgress>
                </Box>
              </div>
              <Box
                m={2}
                boxshadow={"lg"}
                bgColor="white"
                overflow={"auto"}
                height={"13em"}
                pos="relative"
              >
                <TableContainer
                  boxShadow="md"
                  borderColor={"black.500"}
                  bgColor={"white"}
                  p={1}
                >
                  <Table size="sm">
                    <Thead>
                      <Tr>
                        <Th>Ticket ID </Th>
                        <Th>Status</Th>
                        <Th>Date </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tickets.length!==0 ? (
                        tickets?.tickets.slice(0, 5).map((item) => (
                          <Tr
                            key={item.id}
                            _hover={{ bgColor: "gray.100" }}
                            cursor={"pointer"}
                            onClick={() =>
                              navigate(`/mydashboard/tickets/${item.id}`)
                            }
                          >
                            <Td>{item.id}</Td>
                            <Td>
                              <Tag bgColor={statusColor[item.status].bgColor} color={statusColor[item.status].color}>
                                {statusDescrptions[item.status]}
                              </Tag>
                            </Td>
                            <Td>
                              {moment(item.createdAt).fromNow().toString()}
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td>
                            <Flex
                              p={4}
                              flexDir={"row"}
                              justifyContent="center"
                              alignItems={"center"}
                            >
                              <Text>No tickets </Text>
                              <Button
                                border={"none"}
                                bgColor="white"
                                color={"gray.400"}
                                size={"sm"}
                              >
                                New ticket +
                              </Button>
                            </Flex>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
        </div>
        <div className="hidden md:block md:col-span-3">
          <Box
            h={"100%"}
            borderRadius={"md"}
            className={'p-3'}
            width={"100%"}
            boxShadow={"sm"}
            display={"block"}
          >
            <Flex p={0}>
              <Spacer />
              <Skeleton isLoaded={!props.isLoadingProfile}>
                {verifiedUserValue >= 100 ? (
                  <Badge variant="solid" colorScheme="green">
                    Verified
                  </Badge>
                ) : (
                  <Badge variant="solid" colorScheme="red">
                    Not Verified
                  </Badge>
                )}
              </Skeleton>
            </Flex>
            <Skeleton isLoaded={!props.isLoadingProfile}>
              <Avatar
                size="2xl"
                name="User avatar"
                src={userData.avatarDestination}
                bgColor="white"
                id={"avatar-tour"}
                className="avatar-data"
                m={0}
              ></Avatar>
            </Skeleton>
            <Box className={'flex'} width={"100%"} m={".5rem"}>
              <List className={'m-0 p-0'} >
                <ListItem>
                  <Popover
                    returnFocusOnClose={false}
                    isOpen={isOpen}
                    onClose={onClose}
                    placement="right"
                    closeOnBlur={false}
                  >
                    <PopoverTrigger>
                      <Box className={'flex flex-row align-middle text-start'}>
                        <ListIcon className={'my-auto '}
                          as={
                            userData.emailConfirmed
                              ? CheckCircleIcon
                              : WarningIcon
                          }
                          cursor={userData.emailConfirmed? "none" : "pointer"}
                          color={`${
                            userData.emailConfirmed ? "green.500" : "red.400"
                          }`}
                                  onClick={onToggle}
                        />
                       <Text  > Email verification</Text>
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent boxShadow="lg" bgColor="red.400">
                      <PopoverHeader
                        fontWeight="semibold"
                        className="w-full flex justify-content-center"
                        bgColor="red.500"
                      >
                        <Text
                          fontWeight="600"
                          fontFamily={"Roboto"}
                          className="text-xl"
                          color="gray.200"
                        >
                          {" "}
                          Email not verified
                        </Text>
                      </PopoverHeader>
                      <PopoverArrow bgColor="whitesmoke" color="whitesmoke" />
                      <PopoverCloseButton color="white" />
                      <PopoverBody className="p-2" bgColor="whitesmoke">
                        <Box className="flex flex-col ">
                          <Text className="py-3">
                            {" "}
                            Click the link below to resend verification email{" "}
                          </Text>
                          <Button
                            bgColor={"gray.400"}
                            onClick={() => sendEmail()}
                            size="sm"
                          >
                            Send Email
                          </Button>
                        </Box>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </ListItem>
                <ListItem className={'flex flex-row align-middle text-start'}>
                  <Popover
                      returnFocusOnClose={false}
                      isOpen={isOpenFunds}
                      onClose={onCloseFunds}
                      placement="right"
                      closeOnBlur={false}
                  >
                    <PopoverTrigger>
                      <Box className={'flex flex-row align-middle text-start'}>
                        <ListIcon className={'my-auto '}
                                  as={
                                    userData.emailConfirmed
                                        ? CheckCircleIcon
                                        : WarningIcon
                                  }
                                  cursor={userData.emailConfirmed? "none" : "pointer"}
                                  color={`${
                                      userData.emailConfirmed ? "green.500" : "red.400"
                                  }`}
                                  onClick={onToggleFunds}
                        />
                        <Text  > Available Funds</Text>
                      </Box>
                    </PopoverTrigger>
                    <PopoverContent boxShadow="lg" bgColor="red.400">
                      <PopoverHeader
                          fontWeight="semibold"
                          className="w-full flex justify-content-center"
                          bgColor="red.500"
                      >
                        <Text
                            fontWeight="600"
                            fontFamily={"Roboto"}
                            className="text-xl"
                            color="gray.200"
                        >
                          {" "}
                          Low Funds
                        </Text>
                      </PopoverHeader>
                      <PopoverArrow bgColor="whitesmoke" color="whitesmoke" />
                      <PopoverCloseButton color="white" />
                      <PopoverBody className="p-2" bgColor="whitesmoke">
                        <Box className="flex flex-col ">
                          <Text className="py-3">
                            You do not have funds, visit billing section to add funds
                          </Text>

                        </Box>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </ListItem>
                <ListItem className={'flex flex-row align-middle text-start'} as={Link} to="/mydashboard/profile">
                  <ListIcon className={'my-auto'}
                    as={
                      userData?.job_title !== null && userData.address !== null
                        ? CheckCircleIcon
                        : WarningIcon
                    }
                    color={`${
                      userData?.job_title !== null && userData.address !== null
                        ? "green.500"
                        : "red.500"
                    }`}
                  />
                  Personal Details
                </ListItem>
              </List>

            </Box>
            <Flex>
              <Center m="auto">

                <CircularProgress
                  textAlign={"start"}
                  value={verifiedUserValue}
                  className={'m-0 my-2 md:m-4'}
                  size="3em"
                  thickness={'2px'}
                  color={
                    verifiedUserValue < 40
                      ? "red.400"
                      : verifiedUserValue < 80
                      ? "orange.400"
                      : "green.400"
                  }
                >
                  <CircularProgressLabel>
                    <Text>{verifiedUserValue}%</Text>
                    <Text className={'m-0 my-2 md:m-4'}>Profile Completion</Text>
                  </CircularProgressLabel>
                </CircularProgress>
              </Center>
            </Flex>
          </Box>
        </div>
        <Box className={'w-full md:col-span-12'}>
          <Box
            bg="white"
            borderRadius={"md"}
            m={1}
            h={"100%"}
            p={4}
            boxShadow={"sm"}

          >
            <Text fontSize={"2xl"} m={2}>
              Recent Transactions
            </Text>
            <Divider />
            <Wrap m={3}>
              <Stat textAlign={"start"} my={5} mx={4}>
                <StatLabel>Collected Fees</StatLabel>
                <StatNumber>R460.00</StatNumber>
                <StatHelpText>
                  Tickets:{0}
                  {/* {Math.floor(
                    props.tickets.filter((i) => i.status === 1).length
                  )} */}
                </StatHelpText>
              </Stat>

              <Stat textAlign={"start"} my={5} mx={4}>
                <StatLabel>Current Balance</StatLabel>
                <StatNumber>R {totalAmount}</StatNumber>
                <StatHelpText>
                  {moment(date).format("ll").toString()}-
                  {moment(date).add(30, "days").format("ll").toString()}
                </StatHelpText>
              </Stat>
            </Wrap>
            <Box m={3} display="flex" flexDir={"column"}>
              <Divider />
              <Flex m={2}>
                <Text fontSize={"2xl"} mx={0} my={1} textAlign={"start"}>
                  Invoice{" "}
                </Text>
                <Spacer />
                <Button
                  size={"sm"}
                  disabled={invoiceExist ? false : true}
                  onClick={() =>
                    toast({
                      title: "Invoice Sent !!!",
                      description: ` to ${props.email}`,
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                    })
                  }
                >
                  Email Invoice!
                </Button>
              </Flex>
              <Wrap display={"flex"} m={2} flexDir={"column"}>
                <Text color={"gray.700"}>Invoice Number: </Text>
                <Text color={"gray.400"}>#8988989</Text>
              </Wrap>
            </Box>
            <TableContainer
              m={2}
              className={'m-2 mb-2'}
              boxShadow="sm"
              borderColor={"black"}
              bgColor={"silver.700"}
            >
              <Table size="sm" borderColor={"black.400"} borderWidth={"1px"}>
                <Thead>
                  <Tr>
                    <Th p={2} m={2}>
                      Description
                    </Th>
                    <Th p={2} m={2}>
                      QTY
                    </Th>
                    <Th isNumeric p={2} m={2}>
                      UNIT PRICE (Rands)
                    </Th>
                    <Th isNumeric p={2} m={2}>
                      AMOUNT
                    </Th>
                  </Tr>
                </Thead>
                <Tbody></Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Total</Th>
                    <Th></Th>
                    <Th isNumeric></Th>
                    <Th isNumeric></Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function LoadTickets() {
  return (
    <Skeleton w={"100%"}>
      <div>contents wrapped</div>
      <div>won't be visible</div>
    </Skeleton>
  );
}
export default ClientHome;
