import { faker } from "@faker-js/faker";
function createRandomUser() {
  return {
    _id: faker.datatype.uuid(),
    fullName: faker.person.fullName(),
    email: faker.internet.email(),
    category: faker.helpers.arrayElements(["tickets", "service", "hardware"]),
    balance: faker.number.float({ min: 100, max: 100000, precision: 0.01 }),
    contactNumber: faker.phone.number("+27# ### ####"),
    address: {
      streetAddress: faker.location.streetAddress(),
      city: faker.location.city(),
      province: faker.location.state(),
      country: faker.location.country(),
      zipCode: faker.location.zipCode(),
    },
  };
}
function createRandomItem() {
  return {
    code: faker.commerce.product(),
    productName: faker.commerce.productName(),
    description: faker.commerce.productDescription(),
    category: faker.helpers.arrayElements(["tickets", "service", "hardware"]),
    balance: faker.commerce.price(),
    active: faker.datatype.boolean(),
    price: faker.commerce.price(),
  };
}

function createRandomQuotes() {
  return {
    customer: faker.person.fullName(),
    VAT_Reference: faker.commerce.product(),
    Balance: faker.commerce.price(),
    DeliveryAddress: faker.location.streetAddress(),
    Credit_Limit: faker.commerce.price(),
    DocumentNumber: faker.location.buildingNumber(),
    active: faker.datatype.boolean(),
  };
}
function createRandomInvoices() {
  return {
    customer: faker.person.fullName(),
    VAT_Reference: faker.commerce.product(),
    Balance: faker.commerce.price(),
    DeliveryAddress: faker.location.streetAddress(),
    Credit_Limit: faker.commerce.price(),
    DocumentNumber: faker.location.buildingNumber(),
    active: faker.datatype.boolean(),
  };
}
function createRandomOrders() {
  return {
    customerName: faker.person.fullName(),
    customerPhone: faker.phone.number("### ### ####"),
    customerEmail: faker.internet.email(),
    customerTel: faker.phone.number("(+##)## ### ####"),
    itemList: {
      itemName: faker.commerce.productName(),
      itemPrice: faker.commerce.price(),
      quantity: faker.number.int({ min: 1, max: 7 }),
    },
    active: faker.datatype.boolean(),
  };
}

const generateUsers = (number) => {
  let users = [];
  for (let i = 0; i < number; i++) {
    const newItem = createRandomUser(number);
    users.push(newItem);
  }
  return users;
};
const generateItems = (number) => {
  let items = [];
  for (let i = 0; i < number; i++) {
    const newItem = createRandomItem(number);
    items.push(newItem);
  }
  return items;
};
const generateOrders = (number) => {
  const users = [];
  for (let i = 0; i < number; i++) {
    users.push(createRandomOrders(number));
  }
  return users;
};
export { generateUsers, generateItems, generateOrders };
