import {Box,Text} from "@chakra-ui/react";

function ViewItem (){
    return (
        <Box>
            <Box>
                <Text>View Item</Text>
            </Box>
            <Box>

            </Box>
        </Box>
    )
}

export default ViewItem