import {Box, Text} from "@chakra-ui/react";
import {Button} from "rsuite";
import {Link} from "react-router-dom";

export default function AdminSettings (){
    return (
        <Box>
         <Text>Settings </Text>
            <Box className={'grid grid-cols-2'} >
                <Box boxShadow={'sm'} _hover={{boxShadow:'md',bgColor:'white'}} borderRadius={'md'} className={'m-2 p-2'} bgColor={'gray.100'}>
                    <Text className={'text-xl'}>SLA Policies</Text>
                    <Box>
                        <Text className={'text-md'} fontFamily={'Roboto'} fontWeight={'light'}  color={'gray.600'} > Setup your SLA policy for efficient ticket management</Text>
                    </Box>
                   <Link to={'/admin/settings/sla'}>
                        <Button>View policies </Button>
                   </Link>
                </Box>
                <Box boxShadow={'sm'} _hover={{boxShadow:'md',bgColor:'white'}} borderRadius={'md'} className={'m-2 p-2'} bgColor={'gray.100'}>
                   <Text className={'text-xl'}> Email Notifications</Text>
                    <Box className={'m-2'}>
                        <Text className={'text-md'} fontFamily={'Roboto'} fontWeight={'light'}  color={'gray.600'}>Setup your email templates for notifications</Text>
                    </Box>
                    <Link to={'/admin/settings/email_notification'}>
                        <Button>View templates</Button>
                    </Link>
                </Box>
                <Box boxShadow={'sm'} _hover={{boxShadow:'md',bgColor:'white'}} borderRadius={'md'} className={'m-2 p-2'} bgColor={'gray.100'}>
                    <Text className={'text-xl'}>Access Control</Text>
                    <Box className={'m-2'}>
                        <Text className={'text-md'} fontFamily={'Roboto'} fontWeight={'light'} color={'gray.600'}>Grant privileges to administrators and agents </Text>
                    </Box>
                    <Link to={'/admin/settings/accesscontrol'}>
                        <Button>Settings</Button>
                    </Link>
                </Box>

            </Box>

        </Box>
    )
}