import {Box} from "@chakra-ui/react";
import {useEffect} from "react";

export default function AllAgentTickets(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box>
            All Agent Tickets
        </Box>
    )
}