import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Input,
  Menu,
  MenuButton,
  Text,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { generateItems, generateUsers } from "../FakeData/Items";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { MobileSpinner } from "../AdminSharedComponents/MobileSpinner";
import SelectPicker from "rsuite/SelectPicker";
import items from "../../../DataStore/AdminStore/Items";

function NewOrder() {
  const [selectCustomer, setSelectCustomer] = useState("");
  
  const newOrderForm = useFormik({
    initialValues: {
      customerId: "",
      customerName: "",
      customerTel: "",
      customerPhone: "",
      customerEmail: "",
      creator: "",
      items: [],
      checked: true,
      quotationId: "",
      fromQuotation: false,
      total: 0.0,
      subtotal: 0.0,
      discount: 0.0,
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Required"),
    }),
  });
  return (
    <Box className="w-full">
      <Box>
        <Text className={"text-lg m-3"} fontFamily={"RobotoCondensedBold"}>
          New Order
        </Text>
      </Box>
      <Box className={"flex flex-row w-full gap-5 p-3"}>
        <Box
          className={"shadow-md bg-neutral-50 shadow-gray-300 m- w-full p-2"}
        >
          <Text className={"text-xl"} fontFamily={"RobotoCondensedBold"}>
            Customer
          </Text>
          <Box className={"flex flex-col"}>
            <FormControl className={"flex flex-col"}>
              <Box className={"flex flex-row justify-start m-1 w-full"}>
                <SelectPicker
                  className={"w-2/3"}
                  data={generateUsers(50).map((item) => ({
                    label: item.fullName,
                    value: item.fullName,
                  }))}
                />
              </Box>

              <Box>
                <Box className={"flex flex-row"}>
                  <FormControl className={"mx-1 my-2"}>
                    <Text className={"text-start"}>Customer Name: </Text>
                    <Input></Input>
                  </FormControl>
                  <FormControl className={"mx-1 my-2"}>
                    <Text className={"text-start"}>Customer Phone:</Text>
                    <Input></Input>
                  </FormControl>
                </Box>
                <Box className={"flex flex-row"}>
                  <FormControl className={"mx-1 my-2"}>
                    <Text className={"text-start"}>Customer Tel:</Text>
                    <Input></Input>
                  </FormControl>
                  <FormControl className={"mx-1 my-2"}>
                    <Text className={"text-start"}>Customer Email:</Text>
                    <Input></Input>
                  </FormControl>
                </Box>
              </Box>
            </FormControl>
          </Box>
        </Box>
        <Box className={"flex flex-column p-2 w-full"}>
          <Text className={"text-start"} fontFamily={"RobotoCondensedBold"}>
            Item List{" "}
          </Text>
          <Box className={"flex flex-row m-2 p-1 justify-between align-middle"}>
            <SelectPicker
              className={"w-2/3"}
              data={generateItems(40).map((item) => ({
                label: item.code,
                value: item.code,
              }))}
            />
            <Button bgColor={"#003053"} color={"white"} className={"w-fit"}>
              Add
            </Button>
          </Box>
          {generateItems(50).map((item) => (
            <Box className={"border-1 border-solid border-gray-300 mx-1 p-1"}>
              <Box className={"flex flex-row"}>
                <FormControl className={"flex w-full"}>
                  <FormLabel className={"flex"}>Item Code:</FormLabel>
                  <Text
                    className={"h-fit border-1 border-solid border-gray-300"}
                    isDisabled={true}
                    type={"text"}
                  >
                    {item.code}
                  </Text>
                </FormControl>
                <FormControl
                  className={
                    "flex w-full h-full p-1 align-middle justify-items-center"
                  }
                >
                  <FormLabel className={"flex my-auto"}>Quantity:</FormLabel>
                  <MobileSpinner value={item.quantity} />
                </FormControl>
              </Box>
              <Box className={"flex flex-row"}>
                <FormControl className={"flex w-full"}>
                  <FormLabel className={"flex"}>Item Name:</FormLabel>
                  <Text
                    className={"h-fit border-1 border-solid border-gray-300"}
                    isDisabled={true}
                    type={"text"}
                  >
                    {item.productName}
                  </Text>
                </FormControl>
                <FormControl className={"flex w-full"}>
                  <FormLabel className={"flex"}>Price:</FormLabel>
                  <Text>R{item.price}</Text>
                </FormControl>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
const OrderList = generateItems(50).map((items) => items);
export default NewOrder;
