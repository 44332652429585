import { Avatar, Box, Flex, IconButton, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { useSelector } from "react-redux";
import gsap from "gsap";
import { AttachmentIcon } from "@chakra-ui/icons";
function Message({ me, message, time, tags, avatarURL, type }) {
  const messageRef = useRef(null);
  const userData = useSelector((state) => state.user);
  useEffect(() => {
    gsap.from(messageRef.current, {
      width: "200%",
    });
  }, []);
  if (type === "file") {
    return (
      <div transition={{ ease: "easeOut", duration: 1 }}>
        <Flex
          w={"100%"}
          p={1}
          ref={messageRef}
          as="div"
          flexDir={me ? "row-reverse" : "row"}
          justifyContent={me ? "flex-start" : "flex-start"}
          alignItems="center"
        >
          <Avatar
            src={me ? avatarURL : null}
            m={2}
            boxShadow="lg"
            size={"sm"}
          />
          <Flex
            p={2}
            my={2}
            alignItems="center"
            boxShadow="md"
            position={"relative"}
            w="50%"
            right={0}
            bgColor={me ? "blue.800" : "gray.300"}
            borderRadius="5px"
          >
            <Box
              flexDir={"column"}
              height="fit-content"
              width="100%"
              mx={1}
              my={0}
              alignItems="flex-start"
              wordwrap="break-word"
              color={me ? "white" : "black"}
            >
              <Text>Attachements </Text>
              <Flex alignItems={"center"}>
                <IconButton w="100%" color={"black"} icon={<AttachmentIcon />}>
                  File NAm{" "}
                </IconButton>
              </Flex>
              <Text
                fontSize={"1em"}
                fontFamily="RobotoCondensedLight"
                flexWrap="wrap"
                m={0}
                textAlign="start"
                wordWrap="break-word"
              >
                {message}
              </Text>
            </Box>
            <Text
              w={"10em"}
              textAlign="end"
              bottom={"-1.5em"}
              color={"gray.700"}
              borderRadius={"5px"}
              right={"0"}
              bgColor={"#0000000"}
              position="absolute"
              marginTop="auto"
              fontSize=".7em"
              fontFamily={"RobotoCondensedRegular"}
            >
              {moment(time).fromNow().toString()}{" -"}
            </Text>
            <Text
              pos="absolute"
              left={me ? "auto" : 0}
              right={me ? 0 : "auto"}
              top={"-2em"}
            >
              {me ? "You" : " Vuyani Daweti"}
            </Text>
            {me ? (
              <Text
                w={"10em"}
                textAlign="start"
                bottom={"-1.5em"}
                color={"gray.600"}
                borderRadius={"5px"}
                left={"0"}
                bgColor={"#0000000"}
                position="absolute"
                fontSize=".7em"
                fontFamily={"RobotoCondensedRegular"}
              >
                <BiCheckDouble fontSize={"1.5em"} p={0} m={0} />
              </Text>
            ) : null}
          </Flex>
        </Flex>
      </div>
    );
  }
  return (
    <div transition={{ ease: "easeOut", duration: 1 }}>
      <Flex
        w={"100%"}
        p={1}
        my={5}
        ref={messageRef}
        as="div"
        flexDir={me ? "row-reverse" : "row"}
        justifyContent={me ? "flex-start" : "flex-start"}
        alignItems="center"
      >
        <Avatar src={me ? avatarURL : null} m={2} boxShadow="lg" size={"sm"} />
        <Flex
          p={2}
          my={2}
          alignItems="center"
          boxShadow="md"
          position={"relative"}
          w="50%"
          right={0}
          bgColor={me ? "blue.800" : "gray.300"}
          borderRadius="5px"
        >
          <Box
            flexDir={"column"}
            height="fit-content"
            width="100%"
            mx={1}
            my={0}
            alignItems="flex-start"
            wordWrap="break-word"
            color={me ? "white" : "black"}
          >
            <Text
              fontSize={"1em"}
              fontFamily="RobotoCondensedLight"
              flexWrap="wrap"
              m={0}
              textAlign="start"
              
            >
              {message}
            </Text>
          </Box>
          <Text
            w={"10em"}
            textAlign="end"
            top={"-1.5em"}
            textAlign={"start"}
            color={"gray.700"}
            borderRadius={"5px"}
            left={!me ? "auto" : 0}
            right={!me ? 0 : "auto"}
            bgColor={"#0000000"}
            position="absolute"
            marginTop="auto"
            fontSize=".7em"
            fontFamily={"RobotoCondensedRegular"}
          >
            {moment(time).fromNow().toString()}{" "}
          </Text>
          <Text
            pos="absolute"
            left={me ? "auto" : 0}
            right={me ? 0 : "auto"}
            top={"-2em"}
          >
            {me ? "You" : 'AGENT'}
          </Text>
          {me ? (
            <Text
              w={"10em"}
              textAlign="start"
              bottom={"-1.5em"}
              color={"gray.600"}
              borderRadius={"5px"}
              left={"0"}
              bgColor={"#0000000"}
              position="absolute"
              fontSize=".7em"
              fontFamily={"RobotoCondensedRegular"}
            >
              <BiCheckDouble fontSize={"1.5em"} p={0} m={0} />
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </div>
  );
}

export default Message;
