import { CloseIcon } from '@chakra-ui/icons'
import { Avatar, Box,Button,Flex,Icon, IconButton, Tag, Text } from '@chakra-ui/react'
import React,{useState} from 'react'
import { TbCricket, TbTicket } from 'react-icons/tb'
import "./Notification.scss"

export const Notification = (notification) => {
  const {text,type,opened,id,ticketID} = notification.notification
const materialSlides = document.querySelectorAll('.notification-slide');

  materialSlides.forEach(slide => {
    const state = {
      isActive: false,
      isOpen: false,
      isOpenLast: false,
      startPos: null,
      currentPos: null,
    }
  
    const infoPanel = slide.querySelector('.notification-slide__info');
    const buttonPanel = slide.querySelector('.notification-slide__options > span');
    const buttons = buttonPanel.querySelectorAll('button');
  
    const onMouseDown = (e) => {
      state.isActive = true;
      state.startPos = e.clientX;
      infoPanel.classList.add('material-slide__options--user-control');
    }
  
   
    const onMouseMove = (e) => {
      const {
        isActive,
        isOpenLast,
        startPos
      } = state;
  
      if (!isActive) return;
  
      const buttonsWidth = buttonPanel.offsetWidth;
  
      const offset = isOpenLast ? e.clientX - startPos - buttonsWidth : e.clientX - startPos;
  
      if (offset > 0) return;
  
      if (buttonsWidth < Math.abs(offset)) {
        state.isOpen = true;
      } else {
        state.isOpen = false;
      }
  
      state.currentPos = offset;
  
      infoPanel.style.transform = `translatex(${offset}px)`
    }
  
    const updateState = () => {
      const {
        isOpen
      } = state;
  
      state.isActive = false;
      state.startPos = null;
      state.currentPos = null;
      state.isOpenLast = isOpen;
      infoPanel.classList.remove('notification-slide__options--user-control');
      infoPanel.style.transform = isOpen ? `translatex(-${buttonPanel.offsetWidth}px)` : '';
      infoPanel.setAttribute('aria-expanded', isOpen);
      buttonPanel.setAttribute('aria-hidden', !isOpen);
  
      buttons.forEach(button => {
        if (isOpen) {
          button.removeAttribute('disabled');
        } else {
          button.setAttribute('disabled', '');
        }
      });
    }
  
    const onKeyUp = (e) => {
      if ([32, 13].indexOf(e.which) < 0 || e.target !== infoPanel) return;
      state.isOpen = !state.isOpen;
      updateState();
    }
  
    const onDoubleClick = (e) => {
      state.isOpen = !state.isOpen;
      updateState();
    }
  
    infoPanel.addEventListener('keyup', onKeyUp);
  
    infoPanel.addEventListener('dblclick', onDoubleClick);
    infoPanel.addEventListener('mousedown', onMouseDown);
    document.body.addEventListener('mouseup', updateState);
    document.body.addEventListener('mouseleave', updateState);
    document.body.addEventListener('mousemove', onMouseMove);
  
    infoPanel.addEventListener("touchstart", (e) => onMouseDown(e.touches[0]));
    document.body.addEventListener("touchend", updateState);
    document.body.addEventListener("touchcancel", updateState);
    document.body.addEventListener("touchmove", (e) => onMouseMove(e.touches[0]));
  
    updateState();
  })
  
   
    if(type==="sms"){
        return (
            <div className={`notification ${opened? "opened" : ""}`} id={id}>
                <div className="notification-content">
                    <p>{text}</p>
                </div>
            </div>
        )
    }
    if(type==="ticket"){
        return (
          <Box margin="0 auto" padding={'.1em .1em'}>
          <div className="notification-slide notification-slide--service">
               <button style={{display:'grid',width:'100%'}} className="notification-slide__info" aria-expanded="false">
                         <Text as={'span'} float='left' display='inline' className="notification-slide__heading">{`Ticket `}</Text>
                         <Text as={'span'} className='notification_message' >{text} </Text>
                     </button>
            <div className="notification-slide__options">
              <span>
                <Button title="delete" width={'100%'} aria-label="delete" height={'100%'} onClick={()=>console.log("Clear me ")}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{width:'1.4em'}}>
                    <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                  </svg>
                </Button>
              </span>
            </div>
            <div className="notification-slide__outline"></div>
          </div>
        </Box>
        )
    }
  return (
    <Box margin="0 auto" padding={'.2em .1em'}>
  <div className="notification-slide notification-slide--service">
       <button style={{display:'grid'}} className="notification-slide__info" aria-expanded="false">
                 <Text as={'span'} float='left' display='inline' className="notification-slide__heading">Delete notification</Text>
                 <Text as={'span'} className='notification_message' >This color implies there is something bad that could happen, in this case it's a delete function.</Text>
             </button>
    <div className="notification-slide__options">
      <span>
        <Button title="delete" width={'100%'} aria-label="delete" height={'100%'} onClick={()=>console.log("Clear me ")}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{width:'1.4em'}}>
            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
          </svg>
        </Button>
      </span>
    </div>
    <div className="notification-slide__outline"></div>
  </div>
</Box>
  )
}

