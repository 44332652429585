import React, { useState } from "react";
import NavBar from "../components/navBar";
import { Avatar, Box, Center, Flex, Text, Button } from "@chakra-ui/react";
import Footer from "./Footer";
import { IoIosArrowForward } from "react-icons/io";
import { useFormik } from "formik";
import * as Yup from "yup";
import SignupAsAnIndividual from "../components/SignupAsAnIndividual";
import SignupAsanOrganization from "../components/SignupAsanOrganization";

function SignUpv2() {
  const [signUpAs, setsignUpAs] = useState(0);
  
 
  return (
    <Box className="">
      <NavBar />
      <Box className="p-24 w-full">
        <Box
          className={
            "w-full flex flex-col md:flex-row md:w-100 lg:w-50 p-4 md:flex"
          }
        >
          <Box className={"flex w-full h-fit flex-col justify-between"}>
            <Center className={""}>
              <Text
                className={"text-3xl md:text-xl text-start mr-auto"}
                color={"#033053"}
                textOverflow={"figure"}
                fontFamily={"RobotoCondensedBold"}
                fontSize={"1.5em"}
              >
                3 Step Sign Up Process{" "}
              </Text>
            </Center>
            <Flex flexDir={"column"} m={4} p={3}>
              <Flex alignItems={"center"} className={"m-4"}>
                <Avatar bg={"green.500"} size={"md"} name="1" m={1} />
                <Text m={1}>Complete the registration form </Text>
              </Flex>
              <Flex alignItems={"center"} className={"m-4"}>
                <Avatar bg={"blue.800"} size={"md"} name="2" />
                <Text m={2} textAlign={"start"}>
                  You will receive a One Time Pin on your cellphone.{" "}
                </Text>
              </Flex>
              <Flex alignItems={"center"} className={"m-4"}>
                <Avatar bg={"blue.800"} size={"md"} name="3" />
                <Text m={2}>Confirm your email address </Text>
              </Flex>
            </Flex>
          </Box>
          {signUpAs === 0 && (
            <Box className="m-3 p-2 flex w-full flex-col justify-center align-middle">
              <Text className={"text-start text-md text-bold"}>
                Sign Up as:{" "}
              </Text>
              <Button
                _hover={{ bgColor: "#4262b7" }}
                borderRadius={"0"}
                className="m-3 mx-5 p-10 text-xl-center"
                bgColor={"#033053"}
                color={"white"}
                onClick={() => setsignUpAs(1)}
                rightIcon={<IoIosArrowForward />}
              >
                An Individual
              </Button>
              <Button
                _hover={{ bgColor: "#4262b7" }}
                onClick={() => setsignUpAs(2)}
                borderRadius={"0"}
                className="m-3 mx-5 p-10 text-xl-center"
                bgColor={"#033053"}
                color={"white"}
                rightIcon={<IoIosArrowForward />}
              >
                An Organization
              </Button>
            </Box>
          )}
          {signUpAs === 1 && (
            <SignupAsAnIndividual
              setsignUpas={setsignUpAs}
            />
          )}
          {signUpAs === 2 && (
            <SignupAsanOrganization
              setsignUpas={setsignUpAs}
            />
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default SignUpv2;
