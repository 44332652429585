import { Box, Text } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export function QoutesList() {
    const [rowData, setRowData] = useState([
        { make: "Toyota", model: "Celica", price: 35000 },
        { make: "Ford", model: "Mondeo", price: 32000 },
        { make: "Porsche", model: "Boxter", price: 72000 },
      ]);
      const [columnDefs, setColumnDefs] = useState([
        { field: "athlete", rowDrag: true },
        { field: "country" },
        { field: "year", width: 100 },
        { field: "date" },
        { field: "sport" },
        { field: "gold" },
        { field: "silver" },
        { field: "bronze" },
      ]);
      const defaultColDef = useMemo(() => {
        return {
          width: 170,
          sortable: true,
          filter: true,
          rowDrag:true,
        };
      }, []);
    
      const onGridReady = useCallback((params) => {
        fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
          .then((resp) => resp.json())
          .then((data) => setRowData(data));
      }, []);
  return (
    <Box className="h-full w-full mb-5 overflow-y-auto overflow-x-hidden">
      <Box bgColor="#033053">
        <Text className="text-xl p-4" color="white">
          List of Qoutes 
        </Text>
      </Box>
      <Box className="w-full h-2/3 m-1 p-1 overflow-auto">
      <AgGridReact
                      rowData={rowData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowDragManaged={true}
                      animateRows={true}
                      onGridReady={onGridReady}
                    />
      </Box>
    </Box>
  );
}
