import { createSlice } from "@reduxjs/toolkit";

const BankingSlice = createSlice({
  name: "banking",
  initialState: {
    cardHolder: "",
    cvv: "",
    accountNumber: "",
    bankName: "",
    expiry: "",
    holder: "",
    funds :[]
  },
  reducers: {
    addBank(state, action) {
      const newBank = action.payload;
      state.bankName = newBank.bankName||'';
      state.accountNumber = newBank.accountNumber;
      state.cvv = newBank.cvv;
      state.cardHolder = newBank.userID;
      state.expiry = newBank.expiry;
      state.holder = newBank.holder;
    },
    removeBank(state, action) {
      const existingBank = state.cardHolder !== "";
      if (existingBank) {
        state.bankName = "";
        state.accountNumber = "";
        state.cvv = "";
        state.cardHolder = "";
      } else {
      }
    },
    addFunds(state,action){
      state.funds=action.payload
    },
    removeFunds(state,action){
      state.funds=[]
    }
  },
});
export const bankingActions = BankingSlice.actions;
export default BankingSlice;
