import { CheckIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button, CloseButton,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import { BiUpArrowCircle } from "react-icons/bi";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router-dom";
import {CheckPicker, Col, FlexboxGrid, SelectPicker, TagPicker} from "rsuite";
import TicketsList from "./TicketsList";
import { Dropdown } from "primereact/dropdown";
import {TreeSelect} from "primereact/treeselect";
import './adminalltickets.scss'
import moment from "moment";
import axios from "axios";
import {AdminTicketsActions} from "../../../DataStore/AdminStore/Tickets";
import {server_URL} from "../../../../serverURL";

const today = new Date()
function AdminAllTickets() {
  const tickets = useSelector((state) => state.admintickets).tickets;
  const currentTicket= useSelector((state) => state.admintickets).currentTicket;
  const agents = useSelector((state) => state.adminclients).clients.filter(
    (client) => client.role === "AGENT"
  );
  const dispatch = useDispatch();
  const [status_filter, setstatus_filter] = useState(null);
  const [listSize, setlistSize] = useState(10);
  const [agent_filter, setagent_filter] = useState(null);
  const [sender_filter, setsender_filter] = useState(null);
  const [priority_filter, setpriority_filter] = useState(null);
  const [ticketSearch, setTicketSearch] = useState("");
  const [ticketTimeFilter, setTicketTimeFilter] = useState("");
  // console.log("Status Filter: ", status_filter);
  // console.log("Agent Filter: " + agent_filter);
  // console.log("Priority Filter: " + priority_filter);
  console.log(tickets);

  console.log(priority_filter);
  const getTickets=async () => {
    const data = {
      listSize
    }
    const config = {
      method: "POST",
      url: server_URL + "/tickets",
      headers: {
        Accept: "application/json"
      },
      data:data
    }
    await axios(config).then(response => {
      if (response) {
        console.log("response:",response.data)
        console.log("tickets:",tickets)
        if(tickets.length===0){
          console.log("add tickets:",response.data)
          dispatch(AdminTicketsActions.addTickets([response.data]) )
        }else{
          console.log("Tickets are not empty",tickets)
        }
      }else {
        console.log("no response ")
      }
    }).catch(e=>{
      console.log("Error:",e)
    })
  }
  useEffect(()=>{
    if(tickets.length===0){
      //getTickets();
  }
  },[])
  return (
    <Box h="100%" as="div" className="row no-gutter p-0 " overflow={"hidden"}>
      <Box
        display={"flex"}
        h="fit-content !important"
        justifyContent="space-between"
        bgColor={"white"}
        alignItems={"center"}
        p={1}
        className="col-lg-12 col-md-12 col-sm-12"
      >
        <InputGroup w="40%" m={1}>
          <InputRightElement>
            <SearchIcon />
          </InputRightElement>
          <Input
            placeholder="Search ticket"
            value={ticketSearch}
            onChange={(e) => setTicketSearch(e.target.value)}
            fontSize="sm"
          />
        </InputGroup>
      </Box>
      <Box
        className="col-lg-8 col-md-8 col-sm-8 "
        overflow={"hidden"}
        h="fit-content"
        boxShadow={"md"}
      >

        <Box h="100%" overflow={"auto"}>
          <TicketsList
            agentFilter={agent_filter}
            statusFilter={status_filter}
            priorityFilter={priority_filter}

            ticketSearch={ticketSearch}
          />
        </Box>
      </Box>
      <Box
        className="col-lg-4 col-md-4 col-sm-4"
        h="95%"
        bgColor={"whiteAlpha.700"}
      >
        <Text fontFamily={"RobotoCondensedBold"}>Ticket Filters </Text>
        <Flex flexDir={"column"} m={1}>
          <FormLabel  fontSize="lg" className={"font-bold m-1"} htmlFor="status">
            Status
          </FormLabel>

         <Box className={'flex flex-row h-fit'}>
           <Dropdown showFilterClear
                     placeholder={"Filter by Status"}
                     filter
                     showClear
                     className="w-full h-fit md:w-14rem"
                     value={status_filter}
                     onEmptied={()=>setstatus_filter(null)}
                     optionLabel="label"
                     onChange={(e) =>e.value? setstatus_filter(e.value):setstatus_filter(null)}
                     options={statuses}>
           </Dropdown>
         </Box>

        </Flex>
        <Flex flexDir={"column"} m={1}>
          <FormLabel m={0} fontSize="lg" className={"font-bold m-1"} htmlFor="status">
            Agent{" "}
          </FormLabel>
         <Box className={'flex flex-row'}>
           <Dropdown className={''}
                     placeholder={"Filter by Agent "}
                     filter
                     showClear
                     inputId={"agentID"}
                     value={agent_filter}
                     onEmptied={()=>setagent_filter(null)}
                     optionLabel="fullName"
                     onChange={(e) => setagent_filter(e.value)}
                     options={agents}>
           </Dropdown>
         </Box>
         <Box className={'flex flex-col  w-full'}>
           <Text className={'text-start'}>Date:</Text>
           <Box className={'flex flex-row w-full'}>
             <Dropdown className={'my-0 py-1 w-full text-sm'}
                       placeholder={"Filter by Time "}
                       filter
                       defaultValue={moment(today).subtract(
                           1,'year')}
                       value={ticketTimeFilter}
                       optionLabel="label"
                       showClear
                       onEmptied={()=>setstatus_filter(null)}
                       onChange={(e) => setTicketTimeFilter(e.value)}
                       options={ticketDates}></Dropdown>
           </Box>
         </Box>
        </Flex>
        <Flex flexDir={"column"} m={1}>
          <FormLabel m={0} fontSize="md" htmlFor="status">
            Priority
          </FormLabel>

          <Box className={'flex flex-row'}>
            <Dropdown className={''}
                      placeholder={"Filter by Priority"}
                      filter
                      display="chip"
                      value={priority_filter}
                      showClear
                      onEmptied={()=>setstatus_filter(null)}
                      optionLabel="label"
                      onChange={(e) => setpriority_filter(e.value)}
                      options={priorities}>
            </Dropdown>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

const statuses = [
  { label: "Open", value: 1 },
  { label: "Closed", value: 3 },
  { label: "Pending", value: 2 },
  { label: "Resolved", value: 4 },
];
const priorities = [
  { label: "Low", key: 1 },
  { label: "Medium", key: 2 },
  { label: "High", key: 3 },
  { label: "Urgent", key: 4 },
];
const ticketDates = [
  {label:'An hour  ago',value: moment(today).subtract(1,'hours')},
  {label:'A day  ago',value: moment(today).subtract(1,'days')},
  {label:'A week ago',value: moment(today).subtract(1,'weeks')},
  {label:'A month ago',value: moment(today).subtract(1,'months')},
]
export default AdminAllTickets;
