import {
  Box,
  Center, Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  SimpleGrid,
  Switch,
  Text,
} from "@chakra-ui/react";
import React from "react";

function ClientSettings(props) {
  return (
    <div className="row no-gutters m-0 w-100">
      <Flex className={'flex-col justify-center'}>
        <Text m={2} className={'text-2xl'} fontFamily={"RobotoCondensedBold"}>
          Settings
        </Text>
        <Divider/>
      </Flex>

      <div className="col-lg-12 col-md-12 col-sm-12 p-3 z-0">
        <Text fontFamily={"RobotoCondensedBold"} className={''} align={"start"}>Notification Settings </Text>
        <FormControl
          as={SimpleGrid}
          m={3}
          display="flex"
          justifyContent={"space-between"}
          flexDir="column"
          alignItems="flex-start"
          columns={{ base: 2, lg: 4 }}
        >
          <FormLabel htmlFor="emailNotification">
            Receive notifications as email{" "}
          </FormLabel>
          <Switch mb={3} id="emailNotification" />
          <FormLabel htmlFor="smsUpdates">Receive updates via sms? </FormLabel>
          <Switch mb={3} id="smsUpdates" />
          <FormLabel htmlFor="isFocusable">
            Order Tracker Notification 
          </FormLabel>
          <Switch mb={2} id="isFocusable" isChecked />
        </FormControl>
      </div>
     
    </div>
  );
}

export default ClientSettings;
