import {Box} from "@chakra-ui/react";
import {useEffect} from "react";

export default function AgentSettings (){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Box>Agent Settings </Box>
    )
}