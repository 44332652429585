import React from "react";
import { Link } from "react-router-dom";
import POPIAct from "../Assets/POPIAct.png";
import ndalaict from "../Assets/ndalaict.png";
import logo from "../Assets/logo2.svg";
import {Spacer, Text} from "@chakra-ui/react";
export default function Footer() {
  const today = new Date()
  return (
    <div className="footer">
      <div className="top row  g-0">
      <div className="about col-sm-12 col-md-3 col-lg-3 row g-0 no-gutters m-0">
        <div className="">
            <img src={logo} alt="" />
        </div>
        <div className="">
            <p>
              An IT solutions service platform providing remote and onsite IT
              support services anytime and anywhere in SA
            </p>
          </div>
      </div>
        <div className="sitemap">
          <h3>SITE MAP</h3>
          <hr />
          <div className="link_list">
            <Link to="/aboutus">About Us</Link>
            <Link to="/faqs">FAQs</Link>
            <div className="services">
              Our services:
              <div className="servicelist">
                <svg
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 4l8 8l-8 8"
                  />
                </svg>
                <Link to="/enduserservices">End-user Support</Link>
              </div>
              <div className="servicelist">
                <svg
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 4l8 8l-8 8"
                  />
                </svg>
                <Link to="/infrastructureservices">
                  IT Infrastructure Support
                </Link>
              </div>
              <div className="servicelist">
                <svg
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <rect
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m8 4l8 8l-8 8"
                  />
                </svg>
                <Link to="/salesservices">IT Hardware & Software Sales</Link>
              </div>
            </div>
            <a href="https://popia.co.za/" target="_blank" rel="noreferrer">
              POPI Act
            </a>
            <Link to="/contacts">Contact Us</Link>
            <Link to="/downloads">Downloads</Link>
          </div>
        </div>
      </div>
      <hr/>
      <div className="bottom">
        <Text className={'text-sm m-0 p-0'}>Copyright © {today.getFullYear()} All Right Reserved.</Text>
        <div className="t_c m-0 p-0">
          <Link to="/privacy"><Text className={'text-sm m-0 p-0'}>Terms and Conditions</Text></Link>
        </div>
        <div className="pop" style={{display:'flex',justifyContent:'space-between'}}>
          <div className="popi_act">
            <img src={POPIAct} alt="POPI Act " />
          </div>
          <div>
          </div>

          <div className="ndalaictlogo" >
            <img src={ndalaict} alt="ndalaict Logo" style={{width:'40%'}}/>
          </div>
        </div>

      </div>
    </div>
  );
}
