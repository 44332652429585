import React, { useState } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import LoginComponent from "../components/LoginComponent";
//const SITE_KEY = "6LncKWmIhAAAAAL_tk2CX7SLsx1LJAbieKXKyVBj9K";

function Login() {
  const [isConnected, setIsConnected] = useState(false);
  const [lastPong, setLastPong] = useState(null);
  const [token, settoken] = useState("");
  return (
      <div className="login_page">
      <NavBar />
      <LoginComponent showLink={true} closeModal={() => {}} />
      <Footer />
    </div>
  );
}
export default Login;
