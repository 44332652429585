import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import ndalaict from "../Assets/ndalaict.png";
import hardware from "../Assets/hardware.jpg";

function Sales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sales_page">
      <NavBar />
      <div className="body">
        <div className="heading">
          <div className="">
            <h3>IT HARDWARE AND SOFTWARE SALES</h3>
            <img src={hardware} alt="" />
          </div>
          <hr />
          <div className="list">
            <div className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 4l8 8l-8 8"
                />
              </svg>
              <h5>LAPTOP/DESKTOP/MOBILE DEVICES</h5>
            </div>
            <div className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 4l8 8l-8 8"
                />
              </svg>
              <h5>NETWORKING EQUIPMENT</h5>
            </div>
            <div className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 4l8 8l-8 8"
                />
              </svg>
              <h5>AUDIO AND VISUAL</h5>
            </div>
            <div className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 4l8 8l-8 8"
                />
              </svg>
              <h5>SOFTWARE PRODUCTS</h5>
            </div>
          </div>
          <div className="_power">
            <div className="text">
              <p>
                To learn more about our solutions visit our company website{" "}
              </p>
            </div>
            <div className="ndalaict">
              <div className="logo">
                <img src={ndalaict} alt="" />
              </div>
              <div className="link">
                <div className="site">
                  <a
                    target="_blank"
                    href="https://www.ndalaict.com"
                    rel="noreferrer"
                    style={{ color: "silver" }}
                  >
                    NdalaICT Solutions{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Sales;
