import React, { useEffect } from "react";
import NavBar from "../components/navBar.js";
import Footer from "./Footer.js";
import logolight from "../Assets/TechResQ_Logo_fixed.svg";
import { Text } from "@chakra-ui/react";

export default function Aboutus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="AboutUs_page">
      <NavBar />
      <div className="body">
        <div className="image">
          <img src={logolight} alt="" style={{ width: "25rem" }} />
        </div>
        <div className="text">
          <Text w={"100%"}>
            TechResQ is a platform that bridges the gap between clients and IT
            professionals. By validating hundreds of IT Professionals nationally
            that must meet our high standards and passing rigorous assessments,
            we not only offer our clients with quality skills, but we also
            connect our engineers to vetted clients, encouraging a productive
            ecosystem that has yielded remarkable results. We recruit
            experienced IT technicians across the country. Our IT Technicians
            undergo a comprehensive background screening and vetting process,
            including technical skills assessments.
            <br />
            <br />
            Our clients are individual IT users and small to medium enterprises
            who do not require permanent IT personnel, but a pay-as-you-go IT
            support service. Our clients only pay for time spent to support
            their requirements through fixed remote and onsite hourly rates. Our
            clients are not bound to monthly IT support contracts.
          </Text>
        </div>
      </div>
      <Footer />
    </div>
  );
}
