import React from "react";
import Error_404 from "../Assets/404error.jpg";
import NavBar from "../components/navBar";
import Footer from "./Footer";
function Error404() {
  return (
    <div className="Error404">
      <NavBar />
      <div className="body">
        <img src={Error_404} alt="" />
        <div className="text">
          <h3>Page not Found !!!</h3>
          <p>Check if you have entered a correct url </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Error404;
