import React, {useState} from 'react'
import {IconButton, TagInput} from 'rsuite'
import './ClientNavigationBar.scss'
import {FiMenu, FiX} from 'react-icons/fi'
import {
    Box, Button,
    Divider, Flex, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Text, Textarea, useDisclosure, Wrap
} from '@chakra-ui/react'
import Logo from '../../../Assets/TechResQ_Logo_fixed.svg'
import Notifications from './Notifications'
import ClientProfileBar from './ClientProfileBar'
import {ChevronDownIcon} from "@chakra-ui/icons";
import ticketDataServices from "../../../utils/serviceList";
import {useSelector} from "react-redux";
import {Plus} from "@rsuite/icons";

function ClientNavigationBar(props) {

    const userData = useSelector((state) => state.user);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ticketChoice, setticketChoice] = useState("");
    const [urgency, seturgency] = useState(1);
    const [description, setDescription] = useState("");
    const [descriptionError, setdescriptionError] = useState("");
    const [subject, setSubject] = useState("");
    const [cc_employees, setcc_employees] = useState([]);

    const submitTicket = () => {
        const newTicketData = {
            description: description,
            subject: subject,
            requester: userData.email,
            priority: urgency,
            status: 1,
            fwd_cc: cc_employees,
            createdAt: new Date(),
            service_name: ticketChoice,
        };
        props.socket.emit("$techresq:ticket:new", newTicketData);

        //ticketSubmitted();
        onClose();
    };
    return (
      <Box className="client_navbar">
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            p={1}
            fontFamily={"RobotoCondensedRegular"}
            bgColor={"gray.200"}
          >
            <ModalHeader>
              <Box>
                <Text textAlign={"center"} fontFamily="RobotoCondensedBold">
                  New Ticket
                </Text>
              </Box>
              <Divider />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody w={"100%"}>
              <Wrap mx={2}>
                <Text fontSize={"md"} m={1} size={"sm"} f>
                  From: <strong>{userData.fullName}</strong>
                </Text>
              </Wrap>
              <Flex
                display={"flex"}
                alignItems="center"
                flexDir={"row"}
                justifyContent={"space-between"}
                mx={2}
                my={3}
              >
                <Text fontSize={"md"} my={1} w="25%">
                  Subject:
                </Text>
                <Input
                  value={subject}
                  fontFamily={"RobotoCondensedRegular"}
                  fontSize={"sm"}
                  variant="unstyled"
                  m={1}
                  p={2}
                  w={"75%"}
                  borderBottom={"solid .1px silver"}
                  color={"black"}
                  bgColor={"white"}
                  _placeholder={{
                    color: "gray.500",
                  }}
                  placeholder={"Enter subject"}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Flex>
              <Flex
                display={"flex"}
                alignItems="center"
                flexDir={"row"}
                justifyContent={"space-between"}
                mx={2}
                my={3}
              >
                <Text fontSize={"md"} my={1} w="25%">
                  CC:
                </Text>
                <Input
                  as={TagInput}
                  trigger={["Enter", "Space", "Comma"]}
                  value={cc_employees}
                  onChange={(e) => setcc_employees(e)}
                  placeholder="e.g john@gmail.com, lerato@gmail.com"
                  h={"auto"}
                  mx={2}
                  p={0}
                  bgColor={"gray"}
                  style={{
                    width: "75%",
                    borderBottom: "solid .1px silver",
                    fontFamily: "RobotoCondensedBold",
                    fontSize: ".7em",
                    background: "white",
                  }}
                />
              </Flex>

              <Flex flexDir={"row"} w="100%" justifyContent={"flex-start"}>
                <Text
                  fontSize={"sm"}
                  p={".6em"}
                  textAlign={"start"}
                  my={1}
                  width="25%"
                >
                  Service Type:
                </Text>
                <Menu w="auto">
                  <MenuButton
                    as={Button}
                    leftIcon={<ChevronDownIcon />}
                    bgColor={"white"}
                    borderColor="gray.200"
                    borderBottomWidth={".1em"}
                    mx={2}
                  >
                    <Text fontSize={"sm"}>{ticketChoice}</Text>
                  </MenuButton>
                  <MenuList h={200} w={"100%"} overflow={"auto"}>
                    {ticketDataServices
                      ? ticketDataServices.map((service,index) => (
                          <div key={index}>
                            <MenuGroup
                              key={service.services}
                              title={service.category}
                            >
                              {service.services.map((item) => (
                                <MenuItem
                                  key={item}
                                  fontSize={"1rem"}
                                  onClick={() => setticketChoice(item)}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              ))}
                            </MenuGroup>
                            <MenuDivider />
                          </div>
                        ))
                      : "No tickets"}
                  </MenuList>
                </Menu>
              </Flex>

              <Flex
                as={Flex}
                flexDir={"row"}
                w="100%"
                justifyContent={"flex-start"}
              >
                <Text
                  fontSize={"sm"}
                  p={".6em"}
                  textAlign={"start"}
                  my={1}
                  width="25%"
                >
                  Description:{" "}
                </Text>
                <Textarea
                  variant="unstyled"
                  p={2}
                  mx={2}
                  w={"75%"}
                  color={"gray.500"}
                  backgroundColor="white"
                  borderColor={descriptionError !== " " ? "red" : "gray.100"}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Textarea>
              </Flex>
              <Text mt={1} fontSize={"sm"} fontStyle="italic" color={"gray"}>
                You can attach your files after creating a ticket
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme="blue" onClick={() => submitTicket()}>
                Submit Ticket
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box className="nav_bar">
          <IconButton
            onClick={() => props.setShowBar(!props.showBar)}
            icon={props.showBar ? <FiMenu /> : <FiX />}
          />
          <Box
            className={"flex p-0 justify-center h-full  flex-row align-middle"}
          >
            <img className="client_logo" src={Logo} size={"100"} alt="logo" />
          </Box>
          <Box className="client_navigation_bar_right">
            <Notifications open={onOpen} />
            <ClientProfileBar socket={props.socket} />
          </Box>
        </Box>
      </Box>
    );
}

export default ClientNavigationBar
