

export function ItemCategoryList() {
    return (
        <div>
            <div>List of Categories </div>
            <div>Table of categories  </div>
        </div>
    );
}
