import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  Spacer,
  Spinner,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { Store } from "react-notifications-component";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../DataStore/UserData";
import styled from "@emotion/styled";
import { server_URL } from "../../serverURL";
import { Progress } from "rsuite";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

const FormData = require("form-data");
//const server_URL = "https://d24susgzy6zbdw.cloudfront.net";
function ClientProfile() {
  const [profile_image, setprofile_image] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [editName, seteditName] = useState(false);
  const [editContact, seteditContact] = useState(false);
  const [editDescription, seteditDescription] = useState(false);
  const [editEmail, seteditEmail] = useState(false);
  const [editJobTitle, seteditJobTitle] = useState(false);
  const [editAddress, seteditAddress] = useState(false);
  const [preview, setpreview] = useState("");
  const [ProgressValue, setProgressValue] = useState(0)
  const imgref = useRef(null);
  //const [avatarData, setavatarData] = useState(props.avatarURL);
  const [updating, setUpdating] = useState(false);
  const user = useSelector((state) => state.user);
  const [avatarData, setavatarData] = useState(user.avatarDestination||'');
  const token = sessionStorage.getItem("Session-token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadReady, setUploadReady] = useState(false);
  const [uploadFinished, setuploadFinished] = useState(false)
  const [uploading, setuploading] = useState(false)
  //Label style
  const Label = styled.label`
    background-color: rgba(91, 91, 91, 0.7);

    &:hover {
      background-color: rgba(61, 61, 61, 0.94);
    }
  `;
  const profileForm = useFormik({
    initialValues: {
      name: user.fullName,
      phoneNumber: user.phoneNumber,
      job_title: user.job_title,
      email: user.email,
      address: user.address,
      id: user.email,
    },
    onSubmit: (values) => {
      setUpdating(true);
      console.log("submit");
      updateUserData(values);
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Cant be left blank"),
      email: Yup.string().required("Cant be left blank"),
      phoneNumber: Yup.number().required("Cant be left blank"),
      job_title: Yup.string().required("Cant be left blank"),
      address: Yup.string().required("Cant be left blank"),
    }),
  });
  const updateAvatar = () => {
    const data = new FormData();
    data.append("avatar", avatarData);
    data.append("email", user.email);
    const config = {
      method: "post",
      url: `${server_URL}/users/avatar`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
      onUploadProgress: (progressEvent) => setProgressValue(progressEvent.loaded / progressEvent.total),

    };
    axios(config)
      .then(function (response) {
      dispatch(userActions.addAvatarDestination(response.data));
      setuploading(false)
       setuploadFinished(true)
      })
      .catch(function (error) {
        console.log(error);
        setUpdating(false)
      });
  };

  const updateUserData = async (values) => {
    const data = values;
    const config = {
      method: "post",
      url: `${server_URL}/update`,
      Headers: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log("Edit profile response",response)
        //const user = response.data.user
        seteditName(false);
        setUploadReady(false);
        //dispatch(userActions.updateUser(user))
        sendingMessage("USER UPDATED");
      })
      .catch((e) => {
        errorMessage(e);
        seteditName(false);
      });
  };

  const errorMessage = (e) => {
    Store.addNotification({
      title: "Sorry...,Something went wrong ",
      message: e,
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: () => {
        setUpdating(false);
      },
    });
  };
  const captureCanvas = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setProgressValue(0)
    if (file) {
      setUploadReady(true);
      setavatarData(file);
      const reader = new FileReader();
      reader.addEventListener("load", function () {
        imgref.current.setAttribute("src", reader.result);
      });
      reader.readAsDataURL(file);
    }
  };
  const sendingMessage = (message) => {
    Store.addNotification({
      title: "Your details have been updated successfully ",
      message: message,
      type: "success",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: () => {
        setUpdating(false);
      },
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setUpdating(false);
  }, []);
  return (
    <Box
      className="row  no-gutters"
      style={{ height: "100%",width:'100%', padding: "1rem", overflowX: "hidden"}}
    >
      <div
        className="col-sm-12 col-md-6 col-lg-6 p-1 m-auto h-100"
        style={{ display: "flex", justifyContent: "flex-start",alignItems:'center'}}
      ><Button onClick={() => navigate(-1)} marginBottom={'auto'} leftIcon={<ArrowBackIcon/>}>Back</Button>
        <Center
          w={"100%"}
          mx={6}
          position="relative "
          display={"flex"}
          flexDir="column"

        >
          <Box
            objectFit={"contain"}
            position="relative"
            width={"17em"}
            height={"17em"}
            margin='0 auto'
          borderRadius={'50%'}
            overflow="hidden"
          >
            <img
              ref={imgref}
              alt="User profile"
              src={user.avatarDestination}
              className='rounded-circle cover'
              style={{ objectFit: "cover",height:'100%',width:'100%'}}
            />
            <input
              type="file"
              id="avatarInput"
              multiple
              accept="image/png,image/jpeg"
              onChange={(e) => {
                captureCanvas(e);
              }}
              style={{ display: "none",height:0 }}
            />
            <Label
              htmlFor="avatarInput"
              id="avatar_label"
              style={{
                color: "white",
                bottom: "0%",
                position: "absolute",
                padding: "0rem .8rem .8rem .8rem",
                right: "0%",
                left: "0",
                zIndex: 1,
              }}
            >
              <Text mt={0}>Edit </Text>
            </Label>
          </Box>
         {uploadReady?
         <Tooltip isDisabled={uploadReady?true:false} label='Edit image to upload'>
           <Button
              disabled={!uploadReady}
              my={"1em"}
              onClick={() => updateAvatar()}
              bgColor="#033053"
              color={"white"}
              boxShadow="md"
              w={"100%"}
            >
             upload
            </Button>
         </Tooltip>:""}
          <Progress.Line style={{display:uploadReady?'':'none'}} percent={Math.floor(ProgressValue*100)} status={uploadFinished?'success':'active'}/>
          {uploadFinished?<Text color={'whatsapp.300'}>Uploaded Successfully!</Text>:""}
        </Center>
      </div>
      <Box bgColor={'center'} className="col-sm-12 col-md-6 col-lg-6 h-100 m-auto">
        <Center as={'form'} h={'100%'} my='auto' onSubmit={profileForm.handleSubmit}>
          <Box h='auto'  justifyContent="flex-start" className="row no-gutters" textAlign={"flex-start"} mx={"1rem"} >
          <Text fontFamily={'RobotoCondensedRegular'} fontSize='2xl'>User Profile </Text>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <Box display={"flex"} flexDir='column' justifyContent='space-between' alignItems={"flex-start"} w='100%'>
                <Text
                  textAlign={"start"}
                  fontFamily={"RobotoCondensedRegular"}

                  as={"label"}
                  fontSize='md'
                >
                  Name :
                </Text>
                <Input
                  onBlur={profileForm.handleBlur}
                  isDisabled={true}
                  isInvalid={profileForm.errors.name ? true : false}
                  isRequired
                  bgColor={"gray.100"}
                  name={"name"}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="flex-start"
                  w={"100%"}
                  boxShadow='md'
                  border={'.1px solid black'}
                  value={profileForm.values.name}
                  onChange={profileForm.handleChange}
                />
                {profileForm.errors.name?<span>{profileForm.errors.name}</span>:""}
              </Box>
            </div>
            <Box
              display={"flex"}
              className="col-lg-6 col-md-12 col-sm-12"
              flexDir="column"
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Text textAlign={"start"} fontSize='md' w={"100%"} mx={2} as={"p"}>
                Contact Number:{" "}
              </Text>
              <Tooltip label='Contact support to change your name '>
                <Input
                  isRequired
                  isDisabled={!editName}
                  onBlur={profileForm.handleBlur}
                  type="text"
                  bgColor={"white"}
                  isInvalid={profileForm.errors.phoneNumber ? true : false}
                  alignItems="flex-start"
                  display={"flex"}
                  justifyContent="flex-start"
                  boxShadow='md'
                  border={'.1px solid black'}
                  w={"100%"}
                  name={"phoneNumber"}
                  value={profileForm.values.phoneNumber}
                  onChange={profileForm.handleChange}
                />
              </Tooltip>
              {profileForm.errors.phoneNumber?<span>{profileForm.errors.phoneNumber}</span>:""}

            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              flexDir="column"
              className="col-lg-6 col-md-12 col-sm-12"
              alignItems="flex-start"
              py={2}
            >
              <Text textAlign={"start"} fontSize='md'  w={"100%"} as={"p"}>
                Job Description :
              </Text>
              <Tooltip label='Click Edit Button  '>
                <Input
                boxShadow='md'
                border={'.1px solid black'}
                  isRequired
                  isDisabled={!editName}
                  onBlur={profileForm.handleBlur}
                  isInvalid={profileForm.errors.job_title ? true : false}
                  alignItems="center"
                  display={"flex"}
                  bgColor={"white"}
                  justifyContent="flex-start"
                  w={'100%'}
                  name="job_title"
                  value={profileForm.values.job_title}
                  onChange={profileForm.handleChange}
                />
              </Tooltip>
              {profileForm.errors.job_title?<span>{profileForm.errors.job_title}</span>:""}

            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              flexDir="column"
              py={2}
              className="col-lg-6 col-md-12 col-sm-12"
              alignItems={'flex-start'}
            >
              <Text textAlign={"start"} w={"100%"} fontSize='md' as={"p"}>
                Email:{" "}
              </Text>
              <Tooltip label='Contact support to change your email'>
                <Input
                boxShadow='md'
                border={'.1px solid black'}
                  isRequired
                  isDisabled={true}
                  onBlur={profileForm.handleBlur}
                  type={"email"}
                  isInvalid={profileForm.errors.email ? true : false}
                  bgColor={"white"}
                  alignItems="flex-start"
                  display={"flex"}
                  justifyContent="flex-start"
                  w={"100%"}
                  fontSize='md'
                  name="email"
                  value={profileForm.values.email}
                  onChange={profileForm.handleChange}
                />
              </Tooltip>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              flexDir="column"
              alignItems={"center"}
              my={2}
            >
              <Text textAlign={"start"} w={"100%"} as={"p"}>
                Address:{" "}
              </Text>
              <Tooltip label='Click Edit Button'>
                <Textarea
                boxShadow='md'
                border={'.1px solid black'}
                  isRequired
                  isDisabled={!editName}
                  onBlur={profileForm.handleBlur}
                  isInvalid={profileForm.errors.address ? true : false}
                  bgColor={"white"}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="flex-start"
                  w={"100%"}
                  type="text"
                  name="address"
                  value={`${profileForm.values.address}`.toString() || " "}
                  onChange={profileForm.handleChange}
                />
              </Tooltip>
            </Box>
            <HStack>
              <Spacer />
              <Button
                onClick={
                  !editName
                    ? () => seteditName(true)
                    : () => profileForm.resetForm()
                }
                bgColor={"#033050"}
                color={"white"}
                _hover={{ backgroundColor: "#033053" }}
              >
                {editName ? "Reset" : "Edit"}
              </Button>
              <Button
                isDisabled={!editName}
                type="submit"
                bgColor={"#033050"}
                color={"white"}
                _hover={{ backgroundColor: "#033053" }}
              >
                {updating ? <Spinner /> : <Text>Update</Text>}
              </Button>
              <Spacer />
            </HStack>
          </Box>
        </Center>
      </Box>
    </Box>
  );
}
export default ClientProfile;
