import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import contactus from "../Assets/contactus.jpg";

export default function Contactus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contactus_page">
      <NavBar />
      <div className="body">
        <div className="contacts_and_form">
          <div className="contacts">
            <div className="heading">
              <h3 data-aos="zoom-in">CONTACT US </h3>
            </div>
            <div data-aos="fade-right" className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1200 1200"
              >
                <rect
                  x="0"
                  y="0"
                  width="1200"
                  height="1200"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="currentColor"
                  d="m1183.326 997.842l-169.187 167.83c-24.974 25.612-58.077 34.289-90.316 34.328c-142.571-4.271-277.333-74.304-387.981-146.215C354.22 921.655 187.574 757.82 82.984 559.832C42.87 476.809-4.198 370.878.299 278.209c.401-34.86 9.795-69.073 34.346-91.543L203.831 17.565c35.132-29.883 69.107-19.551 91.589 15.257l136.111 258.102c14.326 30.577 6.108 63.339-15.266 85.188l-62.332 62.3c-3.848 5.271-6.298 11.271-6.36 17.801c23.902 92.522 96.313 177.799 160.281 236.486c63.967 58.688 132.725 138.198 221.977 157.021c11.032 3.077 24.545 4.158 32.438-3.179l72.51-73.743c24.996-18.945 61.086-28.205 87.771-12.714h1.272l245.51 144.943c36.041 22.592 39.799 66.252 13.994 92.815z"
                />
              </svg>
              <a href="Tel:0861237377">
                <p>086 123 7377</p>
              </a>
            </div>
            <div data-aos="fade-right" className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M17.415 14.382c-.298-.149-1.759-.867-2.031-.967c-.272-.099-.47-.148-.669.15c-.198.296-.767.966-.94 1.164c-.174.199-.347.223-.644.075c-.297-.15-1.255-.463-2.39-1.475c-.883-.788-1.48-1.761-1.653-2.059c-.173-.297-.019-.458.13-.606c.134-.133.297-.347.446-.52c.149-.174.198-.298.297-.497c.1-.198.05-.371-.025-.52c-.074-.149-.668-1.612-.916-2.207c-.241-.579-.486-.5-.668-.51c-.174-.008-.372-.01-.57-.01c-.198 0-.52.074-.792.372c-.273.297-1.04 1.016-1.04 2.479c0 1.462 1.064 2.875 1.213 3.074c.149.198 2.095 3.2 5.076 4.487c.71.306 1.263.489 1.694.625c.712.227 1.36.195 1.872.118c.57-.085 1.758-.719 2.006-1.413c.247-.694.247-1.289.173-1.413c-.074-.124-.272-.198-.57-.347Zm-5.422 7.403h-.004a9.87 9.87 0 0 1-5.032-1.378l-.36-.214l-3.742.982l.999-3.648l-.235-.374a9.861 9.861 0 0 1-1.511-5.26c.002-5.45 4.436-9.884 9.889-9.884c2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 0 1 2.892 6.993c-.002 5.45-4.436 9.885-9.884 9.885Zm8.412-18.297A11.815 11.815 0 0 0 11.992 0C5.438 0 .102 5.335.1 11.892a11.864 11.864 0 0 0 1.587 5.945L0 24l6.304-1.654a11.881 11.881 0 0 0 5.684 1.448h.005c6.554 0 11.89-5.335 11.892-11.893a11.821 11.821 0 0 0-3.48-8.413"
                  clipRule="evenodd"
                />
              </svg>
              <a href="whatsapp://send?text=Hello TechresQ Agent!&phone=+2786 123 7377">
                <p>083 789 1733</p>
              </a>
            </div>
            <div data-aos="fade-right" className="Card">
              <svg
                aria-hidden="true"
                role="img"
                width="1em"
                height="1em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 32 32"
              >
                <rect
                  x="0"
                  y="0"
                  width="32"
                  height="32"
                  fill="none"
                  stroke="none"
                />
                <path
                  fill="currentColor"
                  d="M28 6H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm-2.2 2L16 14.78L6.2 8ZM4 24V8.91l11.43 7.91a1 1 0 0 0 1.14 0L28 8.91V24Z"
                />
              </svg>
              <a
                href="mailTo:support@ndalaict.com"
                target={"_blank"}
                rel="noreferrer"
              ><p>support@techresq.co.za</p>
              </a>
            </div>
          </div>
          <div className="form">
            <img src={contactus} alt="" />
            {/* <Formik
      initialValues={{
        fullname: '',
        contactnumber: '',
        email: '',
        message:'',
      }}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
      }}
    >
      <Form>
        <div className="form_entry">
          <label htmlFor="fullname">First Name</label>
          <Field id="fullname" name="fullnae" placeholder="Jane" />
        </div>

        <div className="form_entry">
          <label htmlFor="contactnumber">Contact Number</label>
          <Field id="contactnumber" name="contactnumber" placeholder="e.g 0831234567" />
        </div>

        <div className="form_entry">
          <label htmlFor="email">Email</label>
          <Field
            id="email"
            name="email"
            placeholder="e.g jane@acme.com"
            type="email"
          />
        </div>
        <div className="form_entry">
          <label htmlFor="message">Message</label>
          <Field as='textarea'
            id="message"
            name="message"
            placeholder="e.g jane@acme.com"
            type="message"
          />
        </div>
        <button type="submit">Submit</button>
      </Form>
    </Formik> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
