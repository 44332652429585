import {createSlice} from "@reduxjs/toolkit";
const ItemSlice = createSlice({
    name: "ItemSlice",
    initialState: {
        Items: [],
        currentItem:null,
        categories:[],
        currentCategory: null
    },
    reducers:{
        addItems(state, action) {
            const tickets = action.payload;
            console.log("Adding  admin tickets ", tickets);
            Object.keys(tickets).forEach((key) => {
                if (tickets[key] !== state.tickets[key]) {
                    state.tickets = [...state.tickets, tickets[key]];
                }
            });
        },
        removeItem(state, action) {
            state.Items = [];
        },
        addCurrentItem(state,action){
            state.currentItem = action.payload
        },
        removeCurrentItem(state,action){
            state.currentItem =[]
        },
        addCategory(state,action){
            const new_category = action.payload
             if (!state.categories.includes(new_category)){
                 state.categories = {...state.categories, new_category}
             }
        },
        updateCategory(state, action) {
            const tempCategories = [];
            const updatedCategory = action.payload;
            for (var i=0; i<state.category.length;i++){
                if (state.categories[i].Id ===updatedCategory.Id) {
                    state.category[i]=updatedCategory
                }
            }
        },
        removeCategory(state, action) {
           const removedCategory = action.payload
            state.categories = state.category.filter(category=>category.Id !==removedCategory.Id)
        }
    },
});
export const ItemActions = ItemSlice.actions;
export default ItemSlice;
