import React, {useState} from 'react'
import {
    Box,
    Divider,
    Input,
    InputGroup,
    InputLeftElement,
    Stat,
    StatLabel,
    StatNumber,
    Button,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Tag,
    Flex,
    Text,
    TableContainer,
    Skeleton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody
} from "@chakra-ui/react";
import {DownloadIcon, Search2Icon} from "@chakra-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import {AdminclientsActions} from "../../../DataStore/AdminStore/Clients";

const today = new Date()
function AllAgents() {
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const [status_filter,setStatusFilter] =useState('')
    const [searchfilter,setSearchFilter] =useState('')
    const [dateFilter,setDateFilter] =useState(today)
    const [verifiedFilter,setVerifiedFilter] =useState(null)
    const clients  = useSelector(state=>state.adminclients).clients
    const activeClients= useSelector(state=>state.adminclients).activeUsers
    const agents= clients.filter(client=>client.role==='AGENT')

    const selectAgent= (agent)=>{
        dispatch(AdminclientsActions.selectClient(agent))
        navigate(`/admin/agent/${agent.email}`)
    }
    console.log(verifiedFilter,dateFilter,status_filter)
  return (
    <Box h={'100%'} className={'w-full h-full flex-col'} >
        <Box className={'flex flex-row'}>
        <Stat className={'m-1'} boxShadow={'md'} bgColor={'white'}>
            <StatLabel className={'m-1'}>All Agents</StatLabel>
            <Divider className={'m-0 p-0'}/>
            <StatNumber>{agents.length}</StatNumber>
        </Stat>
        <Stat className={'m-1'} boxShadow={'md'} bgColor={'white'}>
            <StatLabel className={'m-1'}>Verified Agents</StatLabel>
            <Divider className={'m-0 p-0'}/>
            <StatNumber>{agents.filter(agent=>agent.email_verified!==false).length}</StatNumber>
        </Stat>
        <Stat className={'m-1'} boxShadow={'md'} bgColor={'white'}>
            <StatLabel className={'m-1'}>Not verified</StatLabel>
            <Divider className={'m-0 p-0'}/>
            <StatNumber>{agents.filter(agent=>agent.email_verified===false).length}</StatNumber>
        </Stat>
        <Stat className={'m-1'} boxShadow={'md'} bgColor={'white'}>
            <StatLabel className={'m-1'}>Active Agents</StatLabel>
            <Divider className={'m-0 p-0'}/>
            <StatNumber>{activeClients.filter(client=>client.role==='AGENT').length}</StatNumber>
        </Stat>
      </Box>
        <Box className={'flex flex-col flex-1 h-full justify-stretch'}>
            <Box className={'flex flex-row justify-between p-1 m-1'} boxShadow={'md'}>
                <InputGroup className={'w-auto'}>
                    <InputLeftElement>
                        <Search2Icon color={'gray.500'} size={'sm'}/>
                    </InputLeftElement>
                    <Input value={searchfilter} onChange={(e)=>setSearchFilter(e.target.value)} size={'md'} bgColor='white' _focusVisible={{border:'1px solid',borderColor:'gray.200',color:'gray.500'}} type={'search'} placeholder={'Search'}></Input>
                </InputGroup>
                <Box>
                    <Button size={'sm'} leftIcon={<DownloadIcon/>}>CSV</Button>
                </Box>
            </Box>
            <Box className={'grid grid-cols-12 h-full w-full'} >
                <Box className={'col-span-9 w-full h-full'} >
                    <TableContainer
                        boxShadow="md"
                        borderColor={"black.500"}
                        bgColor={"white"}
                        p={3}
                        className={'h-2/3'}
                    >
                        <Table size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Agent Name </Th>
                                    <Th>Agent Email </Th>
                                    <Th>Joined on  </Th>
                                    <Th>Verified</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {agents.filter(agent=>agent.fullName.includes(searchfilter)).map((agent,index) => (
                                            <Tr
                                                key={index}
                                                _hover={{ bgColor: "gray.100" }}
                                                cursor={"pointer"}
                                                onClick={() =>{selectAgent(agent)}}
                                            >
                                                <Td>{agent.fullName}</Td>
                                                <Td>
                                                    {agent.email}
                                                </Td>
                                                <Td>
                                                    {moment(agent.createdAt).format("HH:MM DD/MMMM/YYYY")}
                                                </Td>
                                                <Td>
                                                    {agent.email_verified?<Tag>Verified</Tag>:<Tag>Not verified</Tag>}
                                                </Td>
                                            </Tr>
                                  ))
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={'col-span-3 p-2'} >

                    <Box>
                        <Text className={'text-start text-md'}>Verified:</Text>
                        <Dropdown className={'my-0 py-1 text-sm'}
                                  placeholder={"All"}
                                  filter
                                  value={verifiedFilter}
                                  optionLabel="label"
                                  onChange={(e) => setVerifiedFilter(e.value)}
                                  options={verifiedData}>
                        </Dropdown>
                    </Box>
                    <Box>
                        <Text className={'text-start'}>Level:</Text>
                        <Dropdown className={'my-0 py-1 text-sm'}
                                  placeholder={"All"}
                                  filter
                                  defaultValue={moment(today).subtract(1,'year')}
                                  value={dateFilter}
                                  optionLabel="label"
                                  onChange={(e) => setDateFilter(e.value)}
                                  options={agentDates}></Dropdown>
                    </Box>
                    <Box
                        className={"flex relative  mt-10 mx-4 rounded-md"}
                    >
                        <Box
                            className={"absolute -translate-y-1/2 mx-auto left-0 right-0  p-1 w-3/4"}
                            zIndex={2}
                            border={"1px solid"}
                            borderColor={'gray.200'}
                            borderRadius={"5px"}
                            bgColor={"white"}
                        >
                            <Text className={"text-md"}>Active Agents </Text>
                        </Box>
                        <Box
                            h={"26em"}
                            className={"relative overflow-y-auto py-4 w-full px-1 rounded-md"}
                            bgColor={'white'}
                            boxShadow={'lg'}
                            borderColor={'gray.200'}
                        >
                                <Box  className={'flex flex-col w-full justify-center align-middle'}>
                                    {activeClients.filter(clients=>clients.role==='AGENT').map((item, index) => (
                                        <Box key={index} className={"w-3/4 mx-auto p-1 my-2"}>
                                            <Box className={'flex flex-row relative w-full align-middle justify-start'} >
                                                <Text bgColor={'whitesmoke'}  className={"py-3 p-1 relative m-0 my-1 w-full  text-start"} boxShadow={'md'} >{item.name}</Text>
                                            </Box>
                                        </Box>
                                    ))}</Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}
const agentStatuses = [
    {label:'All',value:0},
    {label:'Open',value:1},
    {label:'Pending',value:2},
    {label:'Closed',value:3},
    {label:'Resolved',value:4},
    {label:'Escalated',value:5},

]
const verifiedData = [
    {label:'All',value:"All"},
    {label:'Verified',value:true},
    {label:'Not Verified',value:null},
]
const agentDates = [
    {label:'Level 1',value:1},
    {label:'Level 2',value:2},
    {label:'Level 3',value:3},
]
export default AllAgents