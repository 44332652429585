import { BellIcon, DeleteIcon } from '@chakra-ui/icons'
import { Avatar, AvatarBadge, Box, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Text } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IconButton } from 'rsuite'
import { NotificationActions } from '../../DataStore/Client/notifications'
import { Notification } from './Notification'
import {Plus} from "@rsuite/icons";
function Notifications({socket,open}) {
  const mysocket =socket
  const dispatch =useDispatch()
  const notifications = useSelector((state) => state.notification.notifications)
  const user_email = useSelector((state)=>state.user.email)
  console.log("notifications:-",notifications)
  const clearNotifications =async (e) => {
    e.preventDefault();
   
    mysocket.emit('$techresq:notifications:cleared:all',JSON.stringify({user_email}))
    
  }
  return (
    <Box className={'flex flex-row justify-center align-middle px-2'}>
      <Button className={'m-2 text-sm'} borderRadius={'0'} size={'sm'} onClick={open} leftIcon={<Plus/>}>New Ticket</Button>
      <Popover placement='auto-start' className={'mx-2 my-auto'} >
      <PopoverTrigger m={'auto !important'}  className={'m-auto'}>
        <Avatar icon={<BellIcon/>} size={'sm'}>
          {notifications.length>0?<AvatarBadge boxSize='1.25em' bg='green.500'/>:""}
        </Avatar>
        </PopoverTrigger>
        <PopoverContent bgColor='#033053'>
          <PopoverArrow  bgColor='#033053'/>
          <PopoverHeader pos={'relative'}>
          <Text color={'gray.200'} fontSize={'md'}>Notifications </Text></PopoverHeader>
          <PopoverBody m={0} p={1} bg='gray.100' position='relative' height={'fit-content'} overflow={'auto'}>
           {
            notifications?.map((notification) => {
              return (
                <Notification key={notification.id} notification={notification} />
              )
            })
           }
            {
            notifications.length===0?<Text p={5} fontSize={'sm'}>"No new notifications available"</Text>:null
           }
          </PopoverBody>
          <PopoverFooter p={0} position={'relative'}>
          {notifications.length!==0?
          <Link to="" style={{marginLeft:'auto',position:'absolute',top:'-90%',zIndex:'50',right:'1%',textDecoration:'none'}}>
            <Button fontSize={'.6em'} leftIcon={<DeleteIcon/>} px={2} bgColor={'blue.700'} color="whiteAlpha.800" onClick={(e) => clearNotifications(e)} size='sm' m={0} _hover={{transform:'scale(1.1)',bgColor:'blue.600',color:'whiteAlpha.900'}}>
              All</Button>
          </Link>
           :null} 
           {notifications.length!==0?<Button w="100%" borderRadius={'none'}color="blue.800" _hover={{bgColor:'gray.400'}}>More...</Button>:null}</PopoverFooter>
        </PopoverContent>
    </Popover>
  </Box>
  )
}

export default Notifications