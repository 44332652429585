import {
    Box,
    Checkbox,
    Flex,
    Text,
    Button,
    Input,
    IconButton,
    Tag,
    TagLabel,
    TagLeftIcon,
    Center, CloseButton
} from '@chakra-ui/react'
import {ErrorMessage, Field, FieldArray, Form, Formik, useFormik} from 'formik'
import React, {useRef, useState} from 'react'
import {  SelectPicker, Uploader } from 'rsuite'
import NavBar from '../../components/navBar'
import Footer from '../../pages/Footer'
import * as Yup from 'yup'
import FileInput from './components/FileInput'
import ReactSignatureCanvas from 'react-signature-canvas'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import moment from 'moment/moment'
import {server_URL} from "../../serverURL";
import axios from "axios";
import Signature from './components/Signature'
import CertificateInputs from "./components/CertificatesInputs";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { Progress } from 'rsuite';
import {Store} from "react-notifications-component";

function AgentOnboarding(){
    const navigate = useNavigate()
    const userData= useSelector(state=>state.user)
    const [progressValue, setProgressValue] = useState(0)
    const [numberOfCertificates,setNumberOfCertificates]=useState(0)
    const [signatureFile,  setSignatureFile] = React.useState([]);
    const [uploading,  setUploading] = React.useState(false);
    const [doneSigning,setDoneSigning] = React.useState(false);
    const [signedDate,setSignedDate] = React.useState('');
    const refSignature= useRef();
    let refcvFile= useRef();
    const refgrade12File= useRef();
    const refcertificateFiles= useRef();
    const refidFile= useRef();
    const [errors,setErrors]= React.useState([])
    const newFile = new File([""], "", {type:"text/plain", lastModified: new Date().getTime()})
    const validationSchema = Yup.object().shape({
        role:Yup.string().required('Required'),
        experience:Yup.string().required('Required'),
        grade12:Yup.string().required('Required'),
        eligibility:Yup.string().required('Required'),
        idNumber:Yup.string().required('Required'),
        grade12File:Yup.mixed().nullable().when('grade12', {
            is: 'Yes',
            then:Yup.mixed().required('Required').test("FILE NAME ","File required",(value)=>value?.name),
    }),
        idFile:Yup.mixed().nullable().required('Required').test("FILE NAME ","File required",(value)=>value?.name),
        cvFile:Yup.mixed().nullable().required('Required').test("FILE NAME ","File required",(value)=>value?.name),
        signatureFile:Yup.mixed().nullable().required("Required").test("Signature","Signature Required",(value)=>value?.image!=={})
    })
    const uploadData=async (values)=>{
        setUploading(true)
        const fileNames=["cvFile","certificatesFiles","idFile","grade12File","signatureFile"]
        const form = new FormData()
        form.append("fileNames",fileNames)
        form.append("role",values.role)
        form.append("email",sessionStorage.getItem('Session-email'))
        form.append("idNumber",values.idNumber)
        console.log(values)

        form.append("cvFile",values.cvFile)
        console.log(values.certificatesFiles.length)
        for (let i = 0 ; i < values.certificatesFiles.length ; i++) {
            console.log(values.certificatesFiles[i])
            form.append(`certificatesFiles`,values.certificatesFiles[i])
        }
        form.append("eligibility",values.eligibility)
        form.append("experience",values.experience)
        form.append("grade12",values.grade12)
        form.append("signature",values.signatureFile)
        form.append("grade12File",values.grade12File)
        form.append("idFile",values.idFile)
        for(let [name, value] of form) {
            console.log(`${name} = ${value}`);
        }
        const config = {
            method: "post",
            url: `${server_URL}/agents/onboarding`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: form,
            onUploadProgress: (progressEvent) => setProgressValue(Math.floor((progressEvent.loaded / progressEvent.total)*100)),
        };
        await axios(config).then(response=>{
            if(response){

                navigate(`/applicationstatus?email=${userData.email}`)
            }
        }).catch(error=>{
            console.log(error)
            Message({message:'We could not upload you documents at the moment please try again later',type:'danger'})
        })
    }
    const onClearSignature=(e)=>{
        refSignature.current.clear();
    }
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const finishedSigning =(errors)=>{
        console.log(refSignature.current)
        if(!refSignature.current.isEmpty()) {

            refSignature.current.off();
            setDoneSigning(true)
            setSignedDate(moment(Date.now()).format('MM/DD/YY, hh:mm:ss'))
        }else {
            errors.signature='Required'
        }
    }
    const validation =(e)=>{
        console.log(e)
        if(refSignature.current.isEmpty()){
            errors.signature='Required'
        }else {
            console.log("Validating signature")
            setSignatureFile({image:refSignature.current.toDataURL()})
        }
    }
    const Message = ({message,type}) => {
        Store.addNotification({
            message: message,
            type: type,
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },

            onRemoval: () => {
               setProgressValue(0)
                setUploading(false)
            },
        });
    };
    console.log(progressValue)
    return (
        <Box>
            <NavBar/>
            <Formik  initialValues={{
                role:'',
                experience:'',
                grade12:'',
                eligibility:'',
                idNumber:'',
                cvFile:null,
                certificatesFiles:[],
                idFile:null,
                grade12File:null,
                signatureFile:null,
                isFieldAgent:false,
            }}  validationSchema={validationSchema}
                    onSubmit={(values,{setSubmitting})=>{
                        uploadData(values)

            }
            }>
                {({values,isSubmitting,setFieldValue})=>(
                    <Box as={Form} className={'my-10'}>
                        <Flex className="row g-0 mb-5" h='auto'  w={'100%'}>
                            <Box  className="col-sm-12 col-md-12 col-lg-12" marginTop={'3em'}>
                                {isSubmitting?<Box>
                                   uploading
                                </Box>:<Text fontSize={'2.0em'} fontFamily={'RobotoCondensedBold'} className="text-center">Onboarding Process</Text>
                                }
                            </Box>
                            <Box className="col-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center py-1 m-0 px-1">
                                <Flex flexDir={'column'} justifyContent='center' w={'50%'} m={1}>
                                    <Text color='gray.600' fontSize={'.9em'} textAlign='start'> <span className={'text-red-400'}>*</span> Choose Role :</Text>
                                    <Box p={1} outlineColor={'blue.700'} bgColor={'gray.100'} borderRadius={'md'} as={Field} component={'select'} name={'role'}>
                                        <option value={''}>Select....</option>
                                        <option value={'Help Desk'}>Help Desk</option>
                                        <option value={'Field Agent '}>Field Agent </option>
                                        <option value={'Enduse Support'}>Enduser Support </option>
                                        <option value={'Cyber Security'}>Cyber Security</option>
                                    </Box>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'role'}  component={'div'}/>
                                </Flex>
                                <Flex flexDir={'column'} justifyContent='center' w={'50%'} m={1}>
                                    <Field as={Checkbox} name={'isFieldAgent'} >As a Field Agent </Field>
                                </Flex>
                                <Flex flexDir={'column'} justifyContent='center' w={'50%'} m={1}>
                                    <Text color='gray.600' fontSize={'.9em'} textAlign='start'><span className={'text-red-400'}>*</span> Years  of experience  :</Text>
                                    <Box p={1} outlineColor={'blue.700'} bgColor={'gray.100'} borderRadius={'md'} as={Field} component={'select'}  name={'experience'}>
                                        <option value={''}>Select....</option>
                                        <option value={'less than a year'}>less than a year</option>
                                        <option value={'1+ years'}>1+ years</option>
                                        <option value={'2+ years'}>2+ years</option>
                                        <option value={'3+ years'}>3+ years</option>
                                        <option value={'5+ years'}>5+ years</option>
                                    </Box>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'experience'}  component={'div'}/>

                                </Flex>
                                <Flex flexDir={'column'} justifyContent='center' w={'50%'} m={1}>
                                    <Text color='gray.600' fontSize={'.9em'} textAlign='start'><span className={'text-red-400'}>*</span> Do you have grade 12 or NQF 4 ?</Text>
                                    <Box p={1} outlineColor={'blue.700'} bgColor={'gray.100'} borderRadius={'md'} as={Field} component={'select'} name={'grade12'}>
                                        <option value={''}>Select....</option>
                                        <option value={'No'}>No</option>
                                        <option value={'Yes'}>Yes</option>
                                    </Box>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'grade12'}  component={'div'}/>
                                </Flex>
                                <Flex flexDir={'column'} justifyContent='center' w={'50%'} m={2}>
                                    <Text color='gray.600' fontSize={'.9em'} textAlign='start'> <span className={'text-red-400'}>*</span> Are you eligible to work in South Africa :</Text>
                                    <Box p={1} outlineColor={'blue.700'} bgColor={'gray.100'} borderRadius={'md'} as={Field} component={'select'} name={'eligibility'}>
                                        <option value={''}>Select....</option>
                                        <option value={'No'}>No</option>
                                        <option value={'Yes'}>Yes</option>
                                    </Box>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'eligibility'} component={'div'}/>
                                </Flex>
                                <Flex flexDir={'column'} w={'50%'}  m={2}>
                                    <Text color='gray.600' fontSize={'.9em'} textAlign='start'><span className={'text-red-400'}>*</span> ID / Passport Number  :</Text>
                                    <Box p={2} outlineColor={'blue.700'} bgColor={'gray.100'} max borderRadius={'sm'} as={Field} name="idNumber" placeholder='Enter ID Number...' data-style="btn-info"/>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'idNumber'} component={'div'}/>
                                    <Box className={' my-6 w-full'} >
                                        <Text  className={'text-sm text-gray-400 text-start'} my={0}>( <span className={'text-red-400 '}>*</span> )Are required fields </Text>
                                    </Box>
                                </Flex>


                            </Box>
                            <Box paddingY={0}  px={[4,0,0]}  className="col-sm-12 col-md-6 h-100 col-lg-6 d-flex flex-column justify-content-center align-items-center" >
                                <Box height={'100%'} display='flex' flexDir={'column'} justifyContent='flex-start' w={'60%'}  p={1}  alignItems={'flex-start'}>
                                    <Text fontSize='.8em' mx={2} mt={3}><span className={'text-red-400'}>*</span> CV /Resume <span style={{fontSize:'.9em',fontFamily:"RobotoCondensedLight"}}>(pdf,png and jpeg only)</span></Text>
                                    <Field id={'cvFileID'} ref={ref=>refcvFile=ref} onChange={(e) => setFieldValue('cvFile',e.target.file[0])} as={FileInput} name={'cvFile'} multiple={false} file={values.cvFile} fileChange={(value)=>setFieldValue("cvFile",value)} id={1} >
                                        <Text fontSize={'.9em'} color='blue.800' fontFamily={'RobotoCondensedRegular'}>Upload your CV /Resume</Text>
                                    </Field>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'cvFile'} component={'div'}/>
                                </Box>
                                <Box height={'100%'} display='flex' flexDir={'column'} justifyContent='flex-start' w={'60%'}  p={1}  alignItems={'flex-start'}>
                                    <Text fontSize='.8em' mx={2} mt={3}><span className={'text-red-400'}>*</span> Grade 12 : <span style={{fontSize:'.9em',fontFamily:"RobotoCondensedLight"}}>(pdf,png and jpeg only)</span></Text>
                                    <Field as={FileInput} name={'grade12File'} multiple={false} file={values.grade12File} fileChange={(value)=>setFieldValue("grade12File",value)} id={2} >
                                        <Text fontSize={'.9em'} color='blue.800' fontFamily={'RobotoCondensedRegular'}>Upload grade 12/NQF 4 Document</Text>
                                    </Field>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'grade12File'} component={'div'}/>
                                </Box>
                                <Box height={'100%'} display='flex' flexDir={'column'} justifyContent='flex-start' w={'60%'}  p={1}  alignItems={'flex-start'}>
                                    <Text fontSize='.8em' mx={2} mt={3}><span className={'text-red-400'}>*</span> ID/Licence :<span style={{fontSize:'.9em',fontFamily:"RobotoCondensedLight"}}>(pdf,png and jpeg only)</span></Text>
                                    <Field as={FileInput} name={'idFile'} multiple={false} file={values.cvFile} fileChange={(value)=>setFieldValue("idFile",value)} id={3} >
                                        <Text fontSize={'.9em'} color='blue.800' fontFamily={'RobotoCondensedRegular'}>Upload your ID /License</Text>
                                    </Field>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'idFile'} component={'div'}/>
                                </Box>
                                {/*<Box height={'100%'} display='flex' flexDir={'column'} justifyContent='flex-start' w={'60%'}  p={1}  alignItems={'flex-start'}>*/}
                                {/*    <Text fontSize='.8em' mx={2} mt={3}>* Certificates :<span style={{fontSize:'.9em',fontFamily:"RobotoCondensedLight"}}>(pdf,png and jpeg only)</span></Text>*/}
                                {/*    <Field as={FileInput} name={'certificatesFiles'} multiple={true} file={values.certificatesFiles} fileChange={(value)=>setFieldValue("certificatesFiles",values.certificatesFiles.push(value))} id={4} >*/}
                                {/*        <Text fontSize={'.9em'} color='blue.800' fontFamily={'RobotoCondensedRegular'}>Upload Certificates</Text>*/}
                                {/*    </Field>*/}
                                {/*    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'certificatesFiles'} component={'div'}/>*/}
                                {/*    <Button>Add Certificate</Button>*/}
                                {/*</Box>*/}
                                <FieldArray name={'certificatesFiles'}>
                                    {({insert,remove,push})=>(<>
                                        { values.certificatesFiles.length > 0&&values.certificatesFiles.map((certificate,index)=>(
                                            <Center key={index} className={'w-100'}>
                                                    <Box height={'100%'} display='flex' flexDir={'column'} justifyContent='flex-start' w={'60%'}  p={1}  alignItems={'flex-start'}>
                                                        <Text fontSize='.8em' mx={2} mt={3}>Certificate {index+1} :<span style={{fontSize:'.9em',fontFamily:"RobotoCondensedLight"}}>(pdf,png and jpeg only)</span></Text>
                                                        <Field as={CertificateInputs} file={certificate} name={`certificatesFiles-${index}`}  setFieldValue={setFieldValue} certificates={values.certificatesFiles} id={4+index} >
                                                        </Field>
                                                    </Box>
                                                <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={`certificatesFiles-${index}`} component={'div'}/>
                                            </Center>
                                            ))}
                                            {/*{values.certificatesFiles.length===0?<Button onClick={()=>push({lastModified: 0, lastModifiedDate:'', name: "oofufufi-", size:'', type: "",webkitRelativePath: ""})} >Add eCertificate</Button>:''}*/}
                                            {/*{values.certificatesFiles}*/}
                                        </>)}
                                </FieldArray>
                                <Button onClick={()=>setFieldValue("certificatesFiles",[...values.certificatesFiles,newFile])} >Add eCertificate</Button>

                                </Box>
                            <Flex m={3} mb='0em' flexDir={'column'} alignItems='center' justifyContent={'center'} className="col-sm-12 col-md-12 w-100 m-auto col-lg-12">
                                <Box pos={'relative'} w={'auto'} >
                                    <Text textAlign={'start'}>Signature:</Text>
                                    <Box pos='relative'>
                                        <ReactSignatureCanvas onChange={(value)=>console.log("Change",value)} ref={refSignature} name='signatureFile' onEnd={(e)=>setFieldValue('signatureFile',{image:refSignature.current.toDataURL()})} penColor={'black'} backgroundColor='silver' canvasProps={{width: 250, height: 150, className: 'sigCanvas'}} />
                                        {doneSigning?<Box p={2} borderTopWidth={1} display='flex' bgColor={'white'} flexDir='row'>
                                            <Tag size='lg' color='green'>
                                                <TagLeftIcon as={CheckIcon}/>
                                                <TagLabel >Signed</TagLabel>
                                            </Tag><Text p={2} fontSize='.7em'>{signedDate}</Text></Box>:<Box className="d-flex justify-content-between"  bgColor='gray.300' >

                                            <Button size='sm' borderRadius={'5%'} m={1} leftIcon={<CheckIcon size='sm'/>}    onClick={()=>finishedSigning()}>Done </Button>
                                            <Button size='sm' borderRadius={'5%'} m={1} leftIcon={<CloseIcon size='sm'/>}   onClick={()=>{onClearSignature();setFieldValue("signatureFile",'')}}>Clear</Button>
                                        </Box>}
                                    </Box>
                                    <Box as={ErrorMessage} className={'mx-1 text-sm text-left text-red-600'} name={'signatureFile'} component={'div'}/>
                                </Box>
                                <Text fontSize={'.9em'} my={0}> By providing your signature you agree to have provided accurate and complete information</Text>

                                {/*{errors.signature?<Text textAlign={'start'} color='red'>{errors.signature}</Text>:``}*/}
                                {/*<Text fontSize={'.7em'} my={4}> By providing your signature you agree to have provided accurate and complete information</Text>*/}
                                {/*<Button onClick={e=>Submit(e)} className="btn btn-primary w-50" bgColor='blue.700'>Submit</Button>*/}
                            </Flex>
                            {uploading?<Box border={'.5px solid'} bgColor={'gray.400'} borderColor={'gray.100'} boxShadow={'lg'} w={'50%'} className={'w-1/2 mt-5 mb-2 mx-auto p-2'} borderRadius={'5px'} bgColor={'white'}>
                                <Text className={'text-2xl'}> Get ready to work !!!  </Text>
                                <Progress.Line className={''} percent={progressValue} status={progressValue<100?'active':'success'}/>
                                <Text className={'italic text-sm'} color={'gray.500'}>Uploading files , please wait .....</Text>
                            </Box>:''}
                        </Flex>
                        {uploading?'':<Button  className={'m-1'} w={'25%'} disabled={uploading?true:false} type={'submit'}>Submit </Button>
                        }
                    </Box>
                )
                }
            </Formik>
            <Footer/>
        </Box>
)

}

export default AgentOnboarding