import { Outlet, useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import { Menubar } from "primereact/menubar";

function AdminOrdersInterface() {
  const navigate = useNavigate();
  const redirectMe = (url) => {
    navigate(url);
  };

  const items = [
    {
      label: "Orders",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "new Order",
          icon: "pi pi-fw pi-plus",
          command: () => {
            navigate("/admin/orders/new");
          },
        },
        {
          label: "List of orders",
          icon: "pi pi-fw pi-list",
          command: () => {
            navigate("/admin/orders");
          },
        },
      ],
    },
    {
      label: "Items",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "New Item",
          icon: "pi pi-plus pi-align-left",
          command: () => {
            navigate("/admin/orders/items/new");
          },
        },
        {
          label: "List of items",
          icon: "pi pi-fw pi-list",
          command: () => {
            navigate("/admin/orders/items/all");
          },
        },
        {
          label: "Item Categories",
          icon: "pi pi-fw pi-list",
          command: () => {
            navigate("/admin/orders/items/all");
          },
        },

      ],
    },
    {
      label: "Qoutations",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "New Quote",
          icon: "pi pi-fw pi-file-edit",
          command: () => {
            navigate("/admin/orders/quotes/new");
          },
        },
        {
          label: "List of Qoutations",
          icon: "pi pi-fw pi-list",
          command: () => {
            navigate("/admin/orders/quotes/all");
          },
        },
      ],
    },
    {
      label: "Invoices",
      icon: "pi pi-fw pi-calendar",
      items: [
        {
          label: "New Invoice",
          icon: "pi pi-fw pi-pencil",
          command: () => {
            navigate("/admin/orders/invoices/new");
          },
        },
        {
          label: "List of Invoices",
          icon: "pi pi-fw pi-list",
          command: () => {
            navigate("/admin/orders/invoices/all");
          },
        },

      ],
    },
  ];
  return (
    <Box h={"100%"} className={"flex flex-col h-full m-0 p-0 w-full relative"} >
      <Box className={"flex h-fit justify-start w-full p-2"}>
        <Menubar
          pt={{
            action: ({ props, state, context }) => ({}),
          }}
          onChange={(e) => console.log(e)}
          model={items}
          fontFamily={"RobotoCondensedRegular"}
          className={"w-full h-fit"}
        />
      </Box>
    <Box className='h-full w-full flex'>
    <Outlet />
    </Box>
    </Box>
  );
}

export default AdminOrdersInterface;
