import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import faqs from "../Assets/faqs.jpg";
import { Link } from "react-router-dom";
export default function FAQs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="faqs_page">
      <NavBar />

      <div className="body">
        <div className="heading">
          <div className="">
            <h3>Frequently Asked Questions </h3>
            <img src={faqs} alt="" />
          </div>
          <hr />
        </div>
        <div className="list">
          <div className="Card">
            <h3>What does PayasyouGo mean? </h3>
            <p>You only pay for services you requested </p>
          </div>
          <div className="Card">
            <h3>Do you offer consultation? </h3>
            <p>Yes,all services include consultation</p>
          </div>
          <div className="Card">
            <h3>How do i make payments ? </h3>
            <p>We currently accept all major South Africa Banks cards</p>
          </div>

          <div className="">
            <p>You still can not find a solution ? </p>{" "}
            <Link to="/contacts">Contact Us</Link>{" "}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
