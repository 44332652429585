import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Steps } from "primereact/steps";

// import { ErrorMessage, Formik } from "formik";
// import { Dropdown } from "primereact/dropdown";
// import { FileUpload } from "primereact/fileupload";
// import { EyeIcon } from "primereact/icons/eye";
// import Lock from "@rsuite/icons/legacy/Lock";
// import * as Yup from "yup";

function AdminNewClient() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };
  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);

  const [showPassword, setShowPassword] = useState(false);
  const items = [
    {
      label: "Personal Details",
    },
    {
      label: "Contract Agreement",
    },
    {
      label: "Payments",
    },
    {
      label: "Confirmation",
    },
  ];
  return (
    <Box className={"h-full grid m-0 mt-4 p-0"}>
      <Box
        className={"flex flex-col h-full"}
        bgColor={"white"}
        boxShadow={"md"}
      >
        <Box className={"w-full p-2"}>
          <Text className="text-2xl font-medium"> New User </Text>
        </Box>
        <Box className={"flex-grow-1 h-full"}>
          <Box className="m-1 p-3">
            <Steps current={step} model={items} />
          </Box>
          <Box>
            <Button _disabled={true}>Prev</Button>
            <Button>Next</Button>
          </Box>
        </Box>
        {/*<Box className={'flex-grow-1 h-full'}>
            <Formik initialValues={{
              fullName:'',
              password:'',
              confirmPassword:'',
              address:"",
              phone:'',
              job_title:'',
              companyId:'',
            }
            } onSubmit={(values)=>{
              console.log(values)
            }
            }
                    validationSchema={ Yup.object().shape({
                      fullName:Yup.string().required('Required'),
                      email:Yup.string().email('Email format required').required('Required'),
                      password: Yup.string()
                          .matches(
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                              'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
                          )
                          .required("Required"),
                      confirmPassword: Yup.string()
                          .oneOf([Yup.ref("password"), null], "Password do not match")
                          .required("Required"),

                    })}
            >
              {({values,isSubmitting,touched,errors,handleSubmit,handleChange,handleBlur})=>(
                  <Box as={"form"} onSubmit={handleSubmit} className={'p-0 relative w-full h-full flex flex-col '} boxShadow={'md'} >
                    <Box className={' w-1/2 relative m-auto  h-full flex flex-col overflow-y-hidden '}>
                      <Box className={'m-1 p-3 flex flex-col  mx-0 w-full h-full'} borderRadius={'5px'} bgColor={'white'}>
                        <Box className={' flex flex-col flex-grow-1'} >
                          <FormControl className={'flex flex-col w-full justify-start'} isRequired >
                            <FormLabel className={'m-1 p-0 text-start w-auto'}>Full Name:</FormLabel>
                            <Input type={'text'} name={'fullName'} onChange={handleChange} onBlur={handleBlur} size={'md'} className={'m-0 p-2 w-1/2'}/>
                            {errors.email&&touched.fullName?<Text className={'text-sm text-start'} color={'red.500'} >{errors.fullName}</Text>:''}

                          </FormControl>
                          <Box className={'flex flex-row w-full justify-between'}>
                            <FormControl isRequired className={'flex my-1 w-full flex-col'}>
                              <FormLabel  className={'m-1 p-0 text-start w-auto'}> Email: </FormLabel>
                              <Input type={'email'} name={'email'}  onChange={handleChange} onBlur={handleBlur}></Input>
                              {errors.email&&touched.email?<Text className={'text-sm text-start'} color={'red.500'} name={'confirmPassword'}>{errors.email}</Text>:''}

                            </FormControl>
                            <FormControl className={'flex m-1 w-full flex-col'}>
                              <FormLabel className={'m-1 p-0 text-start w-auto'}> Job Title: </FormLabel>
                              <Input type={'text'} name={'job_title'} onChange={handleChange} onBlur={handleBlur}></Input>

                            </FormControl>
                          </Box>
                          <Box className={'flex flex-row '}>
                            <FormControl isRequired className={'flex  m-1 w-full flex-col'}>
                              <FormLabel  className={'m-1 p-0 text-start w-auto'}> Password: </FormLabel>
                              <InputGroup>
                                <InputRightElement>
                                  <IconButton onClick={()=>setShowPassword(!showPassword)} icon={showPassword?<EyeIcon/>:<Lock/>} aria-label={'show or hide password '}></IconButton>
                                </InputRightElement>
                               <Box className={'flex flex-col w-full'}>
                                 <Input name={'password'}  onChange={handleChange} onBlur={handleBlur} type={showPassword?'password':'text'}></Input>
                                 {errors.password&&touched.password?<Text className={'text-sm text-start'} color={'red.500'} name={'confirmPassword'}>{errors.password}</Text>:''}
                               </Box>
                              </InputGroup>
                            </FormControl>
                            <FormControl isRequired className={'flex  m-1 w-full flex-col'}>
                              <FormLabel  className={'m-1 p-0 text-start w-auto'}>Confirm Password: </FormLabel>
                              <InputGroup>
                                <InputRightElement>
                                  <IconButton onClick={()=>setShowPassword(!showPassword)} icon={showPassword?<EyeIcon/>:<Lock/>} aria-label={'show or hide password '}></IconButton>
                                </InputRightElement>
                                <Box className={'flex flex-col w-full'}>
                                  <Input name={'confirmPassword'}  onChange={handleChange} onBlur={handleBlur} type={showPassword?'password':'text'}></Input>
                                  {errors.confirmPassword&&touched.confirmPassword?<Text className={'text-sm text-start'} color={'red.500'} name={'confirmPassword'}>{errors.confirmPassword}</Text>:''}
                                </Box>
                              </InputGroup>
                            </FormControl>
                          </Box>
                          <FormControl className={'mx-1 p-2 col-span-3 flex w-1/2 flex-col my-2 h-auto'}  >
                            <FormLabel className={'p-1'} bgColor={'white'} >Company </FormLabel>
                              <Dropdown>
                              </Dropdown>
                          </FormControl>
                          <FormControl className={'h-1/3 flex flex-col py-2 '} >
                            <FormLabel  className={'m-1 p-0 text-start w-auto'}> Address: </FormLabel>
                            <Textarea name={'address'} onChange={handleChange} onBlur={handleBlur} className={'flex-grow-1'}/>
                          </FormControl>
                        </Box>
                        <Box className={'m-1 flex flex-row justify-end'}>
                          <Button size={'md'} className={'mx-2'}>Close</Button>
                          <Button type={'submit'} size={'md'} bgColor={'blue.800'} color={'white'} className={'mx-2'}>Create</Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
              )}
            </Formik>
          </Box> */}
      </Box>
    </Box>
  );
}

const files = [
  { filename: "INage.pg", url: "eetwrtwrwter.vom" },
  { filename: "INaplpge.pg", url: "eetwrtwrwter.vom" },
];
export default AdminNewClient;
