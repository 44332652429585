import React, { useState, useEffect } from 'react';
import {Box, Button, Text} from "@chakra-ui/react";
import moment from "moment";

const CountdownTimer = ({targetDate,finished,data,finishMethod,startMethod}) => {
    const [days,setDays]=useState(0)
    const [hours,setHours]=useState(0)
    const [minutes,setMinutes]=useState(0)
    const [seconds,setSeconds]=useState(0)
    const [targetDateTime,setTargetDate]=useState(new Date(targetDate).getTime())
    const [stopped,setStop]=useState(moment(finished).fromNow().toString()||null)

    useEffect(()=>{
        console.log(targetDateTime)
        const interval = setInterval(()=>{
            const now= new Date().getTime()
            const gap = now -targetDateTime
            const second = 1000;
            const minute = second * 60
            const hour = minute * 60 ;
            const day = hour * 24
            const textDay = Math.floor(gap/day)
            const textHour = Math.floor((gap%day)/hour)
            const textMinute = Math.floor((gap%hour)/minute)
            const textSecond = Math.floor((gap%minute)/second)
            setDays(textDay)
            setHours(textHour)
            setMinutes(textMinute)
            setSeconds(textSecond)
        },1000)
        return ()=>{
            clearInterval(interval)
        }
    },[])
    console.log("Finished",finished)
    console.log("Stopped",stopped)
    return (
        <div className="h-fit flex justify-center items-center">
            {stopped&&finished?<Box>
                <Box className={'flex flex-col'}>
                   <Text> consultation Finished {stopped}</Text>
                </Box>
            </Box>:<div className="text-center">
                <div className=" flex flex-row mb-4">
                    <Box className={'flex flex-col m-1'}>
                        <Text className={'text-bold text-3xl'}>{days}</Text>
                        <Text className={'day'}>Days</Text>
                    </Box>
                    <Box className={'flex flex-col m-1'}>
                        <Text className={'text-bold text-3xl'}>{hours}</Text>
                        <Text className={'day'}>Hours</Text>
                    </Box>
                    <Box className={'flex flex-col m-1'}>
                        <Text className={'text-bold text-3xl'}>{minutes}</Text>
                        <Text className={'day'}>Minutes</Text>
                    </Box>
                    <Box className={'flex flex-col m-1'}>
                        <Text className={'font-bold text-3xl'}>{seconds}</Text>
                        <Text className={'text-md'}>Seconds</Text>
                    </Box>
                </div>
                {data?<Button size={'sm'} boxShadow={'md'} bgColor={'red.100'} color={'red.500'} border={'1px solid'} borderColor={'red.300'} onClick={()=>finishMethod()}>{"End Consultation"}</Button>:""}
                {!data?<Button size={'sm'} boxShadow={'md'} bgColor={'green.100'} color={'green.500'} border={'1px solid'} borderColor={'green.300'} onClick={()=>startMethod()}>{"Start Consultation"}</Button>:""}
            </div>
            }
        </div>
    );
};

export default CountdownTimer;
