import React from 'react'
import { Outlet } from 'react-router-dom'
import {Box} from "@chakra-ui/react";

function AdminCompanyInterface() {
  return (
    <Box  className={'flex flex-col m-0 p-0 h-full relative'}>
        <Outlet/>
    </Box>
  )
}
export default AdminCompanyInterface