import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

function SignupAsanOrganization({ setsignUpas }) {
  const [isLoading, setIsLoading] = useState(false);
  const SignUpForm = useFormik({
    initialValues: {
      companyName: "",
      registrationNumber: "",
      confirmEmail: "",
      email: "",
      password: "",
      domain: "",
      confirmPassword: "",
      toggled: false,
      checked: false,
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Required"),
      domain: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      registrationNumber: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
        )
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password do not match")
        .required("Required"),
      toggled: Yup.string().oneOf(["true", "false"], "Required"),
      company: Yup.string().required("Required"),
      checked: Yup.boolean()
        .oneOf([true], "Field must be checked")
        .required("Required"),
      recaptchaValue: Yup.string().required("Required"),
    }),
  });
  return (
    <form
      onSubmit={SignUpForm.handleSubmit}
      className={"p-4 w-full shadow-md bg-gray-200 rounded-md"}
    >
      <Button
        className={"w-fit shadow-md"}
        onClick={() => setsignUpas(0)}
        leftIcon={<IoIosArrowBack />}
      >
        Back
      </Button>
      <Text
        className={"text-2xl block"}
        color={"#033053"}
        fontFamily={"RobotoCondensedBold"}
      >
        {" "}
        Signing Up as an Organization{" "}
      </Text>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="company_Name"
        >
          Company Name
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.companyName}
            type="text"
            id="company_Name"
            name="companyName"
            borderColor={SignUpForm.errors.companyName ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.companyName && SignUpForm.errors.companyName ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.companyName}
            </Box>
          ) : null}
        </InputGroup>
      </Box>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="registration_number"
        >
          Registration Number :
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.registrationNumber}
            type="text"
            id="registration_number"
            name="registrationNumber"
            borderColor={SignUpForm.errors.registrationNumber ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.registrationNumber &&
          SignUpForm.errors.registrationNumber ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.registrationNumber}
            </Box>
          ) : null}
        </InputGroup>
      </Box>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="Indusrty"
        >
          Industry:
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.indusrty}
            type="text"
            id="Indusrty"
            name="email"
            borderColor={SignUpForm.errors.email ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.email && SignUpForm.errors.email ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.email}
            </Box>
          ) : null}
        </InputGroup>
      </Box>

      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="email"
        >
          Email:{" "}
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.email}
            type="text"
            id="email"
            name="email"
            borderColor={SignUpForm.errors.email ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.email && SignUpForm.errors.email ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.email}
            </Box>
          ) : null}
        </InputGroup>
      </Box>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="password"
        >
          Password:{" "}
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.password}
            type="text"
            id="password"
            name="password"
            borderColor={SignUpForm.errors.password ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.password && SignUpForm.errors.password ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.password}
            </Box>
          ) : null}
        </InputGroup>
      </Box>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="confirm_password"
        >
          Confirm Password:{" "}
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.confirmPassword}
            type="text"
            id="confirm_password"
            name="confirmPassword"
            borderColor={SignUpForm.errors.confirmPassword ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.confirmPassword &&
          SignUpForm.errors.confirmPassword ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.confirmPassword}
            </Box>
          ) : null}
        </InputGroup>
      </Box>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="_domain"
        >
          Email Domain:{" "}
          <span style={{ color: "gray" }}>e.g companyX.co.za</span>
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Input
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.domain}
            type="text"
            id="_domain"
            name="domain"
            borderColor={SignUpForm.errors.domain ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.domain && SignUpForm.errors.domain ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.domain}
            </Box>
          ) : null}
        </InputGroup>
      </Box>
      <Box className={"pt-2"}>
        <Text
          as={"label"}
          className={"text-start w-full text-sm"}
          htmlFor="company_description"
        >
          Company Description:
        </Text>
        <InputGroup m={0} className={"flex flex-col"}>
          <Textarea
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            value={SignUpForm.values.companyDescription}
            type="text"
            id="company_description"
            name="companyDescription"
            borderColor={SignUpForm.errors.companyDescription ? "red" : "white"}
            bgColor={"white"}
            color={"gray.600"}
            _focusVisible={{ borderColor: "gray.300" }}
          />
          {SignUpForm.touched.companyDescription &&
          SignUpForm.errors.companyDescription ? (
            <Box className={"w-full text-start text-sm text-red-600 m-1"}>
              {SignUpForm.errors.companyDescription}
            </Box>
          ) : null}
        </InputGroup>
      </Box>

      <Flex display={"flex"} w={"100%"} px={2} flexDirection={"column"}>
        <Flex className={"flex flex-row justify-start align-middle"}>
          <Checkbox
            onBlur={SignUpForm.handleBlur}
            onChange={SignUpForm.handleChange}
            type={"checkbox"}
            textAlign={"start"}
            name="checked"
            size="lg"
            className={"mx-1 my-auto p-0 h-fit w-fit"}
            spacing="1rem"
            isChecked={SignUpForm.values.checked}
            colorScheme="blue"
            bgColor={"whitesmoke"}
          ></Checkbox>
          <Box
            className={
              "block my-auto justify-start align-items-start text-start"
            }
          >
            <Text className={"text-black text-start inline  text-sm m-0 p-0"}>
              {" "}
              Check this box if you have read and agree to our{" "}
            </Text>
            <Link to="/privacy" className={"mx-1 inline-block"}>
              <Text
                className={"text-sm text-bold m-0 p-0 underline  text-gray-500"}
              >
                Agreement Terms
              </Text>
            </Link>
          </Box>
        </Flex>

        {SignUpForm.touched.checked && SignUpForm.errors.checked ? (
          <Box className={"w-full text-start text-sm text-red-600 m-1"}>
            *{SignUpForm.errors.checked}
          </Box>
        ) : null}
      </Flex>
      <Button isDisabled={isLoading ? true : false} onClick={()=>SignUpForm.submitForm} >
        <Text>Submit</Text>
      </Button>
      <Link to="/login" className={"text-md"}>
        <Text className={"text-md"}>I have an account</Text>
      </Link>
    </form>
  );
}

export default SignupAsanOrganization;
