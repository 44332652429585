import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Field, useFormik } from "formik";
import { Fieldset } from "primereact/fieldset";
import * as Yup from "yup";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import { SelectPicker } from "rsuite";
import { generateUsers } from "../../FakeData/Items";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { PlusSquareIcon } from "@chakra-ui/icons";

export function NewInvoice() {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "30vh", width: "100%" }), []);
  const [rowData, setRowData] = useState([
    { make: "Toyota", model: "Celica", price: 35000 },
    { make: "Ford", model: "Mondeo", price: 32000 },
    { make: "Porsche", model: "Boxter", price: 72000 },
  ]);
  const [columnDefs, setColumnDefs] = useState([
    { field: "Id", rowDrag: true },
    { field: "" },
    { field: "year", width: 100 },
    { field: "date" },
    { field: "sport" },
    { field: "gold" },
    { field: "silver" },
    { field: "bronze" },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
      filter: true,
      editable: true,
      rowDrag: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => setRowData(data));
  }, []);

  const newQouteForm = useFormik({
    initialValues: {
      customerID: "",
      itemList: [],
      notes: "",
      expired: true,
      subtotal: 0.0,
      discounts: 0.0,
      taxes: 0.0,
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required("Required"),
    }),
  });

  return (
    <Box
      as={"form"}
      onSubmit={newQouteForm.handleSubmit}
      className="overflow-y-auto mb-5 w-full"
    >
      <Box bgColor="#033053">
        <Text className="text-xl p-4" color="white">
          New Invoice
        </Text>
      </Box>
      <Box>
        <Box className="flex flex-row">
          <Box className="m-2 flex flex-col w-full shadow-md">
            <Box bgColor="#033053" className="shadow p-2 w-full">
              <Text color={"white"}>Customer Details</Text>
            </Box>
            <Box
              className={
                "flex flex-row justify-between align-middle h-fit m-1 p-1 w-full"
              }
            >
              <SelectPicker
                className={"w-full m-1"}
                data={generateUsers(50).map((item) => ({
                  label: item.fullName,
                  value: item.fullName,
                }))}
              />
              <Button
                leftIcon={<PlusSquareIcon />}
                size="md"
                className="m-1 shadow-md"
                bgColor="gray-700"
              >
                Add Customer
              </Button>
            </Box>
            <Box className="flex flex-col m-1 p-1">
              <Box className="flex w-full"></Box>
              <Box className="flex flex-row m-1 p-1">
                <FormControl className="m-1 p-1">
                  <FormLabel>Customer Name:</FormLabel>
                  <Input
                    bgColor={"white"}
                    placeholder={"Customer Name:"}
                    name={"Customer Name"}
                    value={"Customer Name"}
                  />
                </FormControl>
                <FormControl className="m-1 p-1">
                  <FormLabel>Customer Id:</FormLabel>
                  <Input
                    bgColor={"white"}
                    placeholder={"Customer Name:"}
                    name={"Customer Name"}
                    value={"CustomerId"}
                  />
                </FormControl>
              </Box>
              <Box className="flex flex-row m-1 p-1">
                <FormControl className="m-1 p-1">
                  <FormLabel>Customer Phone:</FormLabel>
                  <Input
                    bgColor={"white"}
                    placeholder={"Customer Name:"}
                    name={"Customer Name"}
                    value={"CustomerId"}
                  />
                </FormControl>
                <FormControl className="m-1 p-1">
                  <FormLabel>Customer Email:</FormLabel>
                  <Input
                    bgColor={"white"}
                    placeholder={"Customer Name:"}
                    name={"Customer Name"}
                    value={"CustomerId"}
                  />
                </FormControl>
              </Box>

              <Box className="flex">
                <FormControl className="m-1 p-1">
                  <FormLabel>Address:</FormLabel>
                  <Textarea
                    bgColor={"white"}
                    placeholder={"Customer Address:"}
                    name={"Customer Name"}
                    value={"Customer Address"}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box className="flex flex-col w-full m-2 shadow-md">
            <Box bgColor="#033053" className="shadow p-2 w-full">
              <Text color={"white"}>Qoute Details</Text>
            </Box>
            <Box className="flex flex-col m-1 p-1">
              <Box className="flex flex-row">
                <FormControl className="m-1 p-1">
                  <FormLabel>Document No: </FormLabel>
                  <Input bgColor={"white"} type="text"></Input>
                </FormControl>
                <FormControl className="m-1 p-1">
                  <FormLabel>Customer Ref: </FormLabel>
                  <Input bgColor={"white"} type="text"></Input>
                </FormControl>
              </Box>
              <Box className="flex flex-row">
                <FormControl className="m-1 p-1">
                  <FormLabel>Date </FormLabel>
                  <Input bgColor={"white"} type="date"></Input>
                </FormControl>
                <FormControl className="m-1 p-1">
                  <FormLabel>Expiry Date: </FormLabel>
                  <Input bgColor={"white"} type="date"></Input>
                </FormControl>
              </Box>
              <Box className="flex flex-row">
                <FormControl className="m-1 p-1">
                  <FormLabel>Discount %: </FormLabel>
                  <InputGroup className="w-full">
                    <Input bgColor={"white"} type="number" />
                    <InputRightAddon children="%" />
                  </InputGroup>
                </FormControl>
                <FormControl className="m-1 p-1">
                  <FormLabel>Customer Ref: </FormLabel>
                  <Input bgColor={"white"} type="text"></Input>
                </FormControl>
              </Box>
              <Box className="flex flex-row">
                <FormControl className="m-1 p-1 flex align-middle">
                  <FormLabel>Status: </FormLabel>
                  <SelectPicker
                    className="w-2/3"
                    searchable="false"
                    defaultValue="Pending"
                    data={["Invoiced", "Pending", "Cancelled"].map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="flex flex-col">
          <Box className="flex w-full flex-col p-2">
            <Box bgColor="#033053" className="shadow p-2 w-full">
              <Text color={"white"}>Items Details</Text>
            </Box>
            <Box className="w-full">
              <Box className="flex my-2 flex-row justify-end w-full">
                <Box>
                  <SelectPicker
                    data={generateUsers(2).split("[]").map((item) => ({
                      label: item.code,
                      value: item.code,
                    }))}
                  />
                </Box>
                <Box>
                  <Button size="sm">Add Item</Button>
                </Box>
              </Box>
              <Box className="w-full h-full">
                <Box className="w-full h-full">
                  <Box style={gridStyle} className="ag-theme-alpine">
                    <AgGridReact
                      rowData={rowData}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowDragManaged={true}
                      animateRows={true}
                      onGridReady={onGridReady}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="flex flex-col shadow-md m-2">
          <Box bgColor="#033053" className="shadow p-2 w-full">
            <Text color={"white"}>Total</Text>
          </Box>
          <Box className="flex w-full justify-between">
            <Box className="flex w-1/2 my-auto">
              <FormControl className="m-1 p-1">
                <FormLabel>Message: </FormLabel>
                <Textarea bgColor={"white"} type="text"></Textarea>
              </FormControl>
            </Box>
            <Box className="flex flex-col">
              <Box className="flex flex-row m-1 p-1 justify-end">
                <Box className="flex flex-row">
                  <Box className="flex flex-row">
                    <FormControl className="m-1">
                      <FormLabel>Total Discount</FormLabel>
                      <Input
                        bgColor={"white"}
                        type="number"
                        placeholder="0.00"
                        value="0.00"
                      />
                    </FormControl>
                  </Box>
                  <Box className="flex flex-row">
                    <FormControl className="m-1">
                      <FormLabel>Total Exclusive</FormLabel>
                      <Input
                        bgColor={"white"}
                        type="number"
                        placeholder="0.00"
                        value="0.00"
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box className="flex flex-row m-1 p-1 justify-end">
                <Box className="flex flex-row">
                  <FormControl className="m-1">
                    <FormLabel>Total VAT</FormLabel>
                    <Input
                      bgColor={"white"}
                      type="number"
                      placeholder="0.00"
                      value="0.00"
                    />
                  </FormControl>
                </Box>
                <Box className="flex flex-row">
                  <FormControl className="m-1">
                    <FormLabel>Total</FormLabel>
                    <Input
                      bgColor={"white"}
                      type="number"
                      placeholder="0.00"
                      value="0.00"
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="m-1 p-1 flex flex-row justify-center">
          <Box className="flex">
            <Button className="m-1" color="white" bgColor="#003053">
              Save
            </Button>
            <Button className="m-1">Save and New </Button>
            <Button className="m-1">Print </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
