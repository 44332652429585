import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
} from "chart.js";

import {
  Box,
  Button, List, ListItem,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber, Td,
  Text,
  useDisclosure,

} from "@chakra-ui/react";
import React, {  useEffect, useState } from "react";
import "./client.scss";
import {Table,Dropdown, Popover} from 'rsuite';
import {
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import CardComponent from "./components/BilllingComponents/CardComponent";
import {Link, useHref, useNavigate, useOutletContext} from "react-router-dom";
import moment from "moment";
import { PDFViewer } from '@react-pdf/renderer';

import MoreIcon from '@rsuite/icons/legacy/More';

import {IconButton, Whisper} from "rsuite";
import {DownloadIcon} from "@chakra-ui/icons";
import {EyeIcon} from "primereact/icons/eye";
import HtmlToPdfViewer from "../../components/PDFGenerator";
import PDFGenerator from "../../components/PDFGenerator";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const labels = ["January", "February", "March", "April", "May", "June", "July"];
const { Column, HeaderCell, Cell } = Table;

let delayed;
export const options = {
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default" && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Expenditure",
    },
  },
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        text: "Amount(Rands)",
        color: "rgba(8,28,44,0.94)",
        font: {
          family: "Roboto",
          size: 14,

          lineHeight: 1.2,
        },
        padding: { top: 30, left: 0, right: 0, bottom: 0 },
      },
    },
    x: {
      display: true,
      title: {
        display: true,
        text: "Months",
        color: "rgba(8,28,44,0.94)",
        font: {
          family: "Roboto",
          size: 14,
          lineHeight: 1.2,
        },
        padding: { top: 30, left: 0, right: 0, bottom: 0 },
      },
    },
  },
};

const today = new Date();
function ClientBilling(props) {
  const navigate = useNavigate();
  const [currentPayment,setcurrentPayment]=useState({})
  const {
    isOpen: transactionisOpen,
    onOpen: transactiononOpen,
    onClose: transactiononClose,
  } = useDisclosure();
  const user = useSelector((state) => state.user);
  const wallet = useSelector((state) => state.wallet);
  const getSum =(total,num)=>{
    return total+num
  }
  const [totalAmount,setTotalAmount] =useState(user.payments.map(payment=>parseFloat(payment.amount).toFixed(2)/100.00).reduce(getSum,0).toFixed(2))
  const [currentTransaction,setCurrentTransaction] = useState({})
  const bankDetails = useSelector((state) => state.banking);
  const [currentKPIFilter, setcurrentKPIFilter] = useState("")
  const { isOpen:isFundsOpen, onOpen:onFundsOpen, onClose:onFundsClose } = useDisclosure()
  const viewTransaction= (transaction)=>{
    setcurrentPayment(transaction)
    transactiononOpen()

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 const changeToMomentDate = (date)=> {
   return  moment(new Date(date)).fromNow()
  }
  const showTransaction =(transaction)=>{
    setCurrentTransaction(transaction)
    if(transaction){
      transactiononOpen()
    }
  }
  const DateCell = ({ rowData, dataKey, ...props }) => (
      <Cell {...props}>
        <Text>{changeToMomentDate(rowData[dataKey])}</Text>
      </Cell>
  );
  const AmountCell=({rowData,dataKey,...props})=>(
    <Cell {...props}>
      <Text>{`R ${parseFloat(rowData[dataKey]/100).toFixed(2)}`}</Text>
    </Cell>
  );
  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
      onClose();
      console.log(eventKey);
    };
    return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
         <List className={'m-0 p-0'}>
           <ListItem className={'flex flex-col m-1'}>
           </ListItem>
         </List>
        </Popover>
    );
  };
  const ActionCell = ({ rowData, dataKey, ...props }) => {
    return (
        <Cell {...props} className="m-0 p-0">
            <Box className={'m-0 p-0 '}  lineHeight={'20px'}>
              <Button className={'m-0 p-2'} onClick={()=>viewTransaction(rowData)} bgColor={'blue.200'} leftIcon={<EyeIcon/>}>View </Button>
            </Box>
        </Cell>
    );
  };
  console.log(currentPayment)
  return (
    <Box className={"h-full flex flex-col"} >
      <Modal isOpen={transactionisOpen}  scrollBehavior={'inside'} motionPreset='slideInBottom' onClose={transactiononClose}>
        <ModalOverlay />
        <ModalContent fontFamily={'Roboto'} borderRadius={'0'}>
          <ModalHeader >Transaction </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Box className={'h-full w-full'}>
                <PDFViewer style={{height:'100vh'}} className={'w-full'}>
                  <PDFGenerator currentTransaction={currentPayment}/>
                </PDFViewer>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={transactiononClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box bgColor={"whitesmoke"} className={"grid grid-cols-1 md:grid-cols-2"}>
        <Box className={"h-auto my-1 md:mx-0 p-2 w-full md:p-0 lg:p-0"}>
          <CardComponent transactiononOpen={transactiononOpen} transactiononClose={transactiononClose} />
        </Box>
        <Box className={"h-auto m-4"}>
          {/*<Box className={'p-2'}>*/}
          {/*  <Dropdown options={BillKPIDateFilter} value={currentKPIFilter} onChange={event => setcurrentKPIFilter(event.value)} optionLabel={'label'} placeholder={'Bill duration'}> </Dropdown>*/}
          {/*</Box>*/}
          <StatGroup
            className={"h-auto  m-auto"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stat bgColor={"white"} boxShadow={"lg"} className={" p-2 m-2"}>
              <StatLabel>
                <Text fontWeight={"700"}>Tickets Amount</Text>{" "}
              </StatLabel>
              <StatNumber>R 0.00</StatNumber>
              <StatHelpText>
                from {moment(today).subtract(1, "month").from(moment(today))}
              </StatHelpText>
            </Stat>
            <Stat bgColor={"white"} boxShadow={"lg"} className={"p-2 m-2"}>
              <StatLabel>Balance</StatLabel>
              <StatNumber>
                R {totalAmount}
              </StatNumber>
              <StatHelpText>
                from {moment(today).subtract(1, "month").from(moment(today))}
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Box>
      </Box>
      <Box bgColor={"white"} className={"md:pr-3 pb-20 flex w-full flex-grow"}>
        <Box className={"w-full h-fit p-0"}>
          <Box m={2}>
            <Text className={"text-2xl"} fontWeight={"600"}>
              Transactions{" "}
            </Text>
          </Box>
          <Box className={'w-full'}>
            <Table height={400} className={'w-full'} data={user.payments}>
              <Column flexGrow={1}  align="left" fixed>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="id"/>
              </Column>
              <Column flexGrow={1} align="left" fixed>
                <HeaderCell>Amount</HeaderCell>
                <AmountCell dataKey='amount'/>
              </Column>
              <Column flexGrow={1}  align="center" fixed>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="status" />
              </Column>
              <Column flexGrow={1}  align="center" fixed>
                <HeaderCell>Date</HeaderCell>
                <DateCell dataKey="createdAt" />
              </Column>
              <Column flexGrow={2} className={'m-0 p-0'} id={'transactionViewColumn'} align={'center'} fixed>
                <HeaderCell>
                 <Text>More...</Text>
                </HeaderCell>
                <ActionCell dataKey="id" className={'m-0 p-0'} />
              </Column>
            </Table>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
const BillingReport = [
  {
    invoiceNumber: "#23345345",
    service: "Hardware Sales",
    amount: 2344.0,
    payment: "Completed",
  },
];

const BillKPIDateFilter = [
  { label: "Ah hour ago", date: moment(today).subtract(1, "hour") },
  { label: "A day ago", date: moment(today).subtract(1, "day") },
  { label: "A week ago", date: moment(today).subtract(1, "week") },
  { label: "A month ago", date: moment(today).subtract(1, "month") },
];
export default ClientBilling;
