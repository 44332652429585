import {
  Box,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  CheckboxGroup,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";

import { Fieldset } from "primereact/fieldset";
import { IoChevronDownSharp } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";

function NewItem() {
  const newItemForm = useFormik({
    initialValues: {
      code: "",
      description: "",
      category: "",
      checked:"true",
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Required"),
    }),
  });
  return (
    <Box
      as={"form"}
      fontFamily={"RobotoCondensedRegular"}
      onSubmit={newItemForm.handleSubmit}
      className="m-2 shadow-md w-full"
    >
      <Box bgColor="#033053">
        <Text className="text-xl p-4" color="white">
          New Item
        </Text>
      </Box>
      <Box className="flex flex-row w-full m-1">
        <Fieldset legend="Item details" className="w-full p-1 m-1">
          <FormControl className="">
            <FormLabel>Code</FormLabel>
            <Input
              errorBorderColor="red.200"
              isInvalid={!!newItemForm.errors.code}
              name={"code"}
              value={newItemForm.values.code}
              onChange={newItemForm.handleChange}
              type="text"
            />
            {newItemForm.errors.code ? (
              <FormHelperText className={"text-start w-full"} color={"red.300"}>
                {newItemForm.errors.code}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
          <FormControl className="">
            <FormLabel>Description </FormLabel>
            <Input
              errorBorderColor="red.200"
              isInvalid={!!newItemForm.errors.description}
              name={"description"}
              value={newItemForm.values.description}
              onChange={newItemForm.handleChange}
              type="text"
            />
            {newItemForm.errors.description ? (
              <FormHelperText className={"text-start w-full"} color={"red.300"}>
                {newItemForm.errors.description}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
          <FormControl className="">
            <FormLabel>Category</FormLabel>
            <Menu className="w-full">
              <MenuButton
                className="w-full"
                as={Button}
                rightIcon={<IoChevronDownSharp />}
              >
                {newItemForm.values.category}
              </MenuButton>
              <MenuList onChange={(e) => console.log(e)} className="w-full">
                <MenuItem
                  onClick={() =>
                    newItemForm.setFieldValue("category", "Tickets")
                  }
                >
                  Tickets
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    newItemForm.setFieldValue("category", "Services")
                  }
                >
                  Service
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    newItemForm.setFieldValue("category", "Hardware")
                  }
                >
                  Hardware
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    newItemForm.setFieldValue("category", "Hardware")
                  }
                >
                  Hardware
                </MenuItem>
              </MenuList>
            </Menu>
          </FormControl>
          <FormControl className={"my-3"}>
            <FormLabel className={""}>Active</FormLabel>
            <RadioGroup name={'checked'}
              defaultValue={'1'}
              onChange={newItemForm.handleChange}
              value={newItemForm.values.checked}
               onBlur={newItemForm.handleBlur}
            >
              <Stack spacing={5} direction="row">
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </Fieldset>
        <Fieldset legend="Item Costs" className="w-full p-1 m-1">
          <FormControl className="">
            <FormLabel>Price</FormLabel>
            <Input
              errorBorderColor="red.200"
              isInvalid={!!newItemForm.errors.price}
              name={"price"}
              value={newItemForm.values.price}
              onChange={newItemForm.handleChange}
              type="text"
            />
            {newItemForm.errors.price ? (
              <FormHelperText className={"text-start w-full"} color={"red.300"}>
                {newItemForm.errors.price}
              </FormHelperText>
            ) : " "
            }
          </FormControl>
          <FormControl className="">
            <FormLabel>Quantity</FormLabel>
            <Input
              type={"number"}
              errorBorderColor="red.200"
              isInvalid={!!newItemForm.errors.quantity}
              name={"code"}
              value={newItemForm.values.quantity}
              onChange={newItemForm.handleChange}
            />
            {newItemForm.errors.quantity ? (
              <FormHelperText className={"text-start w-full"} color={"red.300"}>
                {newItemForm.errors.quantity}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
          <FormControl className="">
            <FormLabel>Code</FormLabel>
            <Input
              errorBorderColor="red.200"
              isInvalid={!!newItemForm.errors.code}
              name={"code"}
              value={newItemForm.values.code}
              onChange={newItemForm.handleChange}
              type="text"
            />
            {newItemForm.errors.code ? (
              <FormHelperText className={"text-start w-full"} color={"red.300"}>
                {newItemForm.errors.code}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        </Fieldset>
      </Box>
      <Box className={"m-1 p-1"}>
        <Button type={"submit"} className={"m-1"}>
          Add Item{" "}
        </Button>
        <Button className={"m-1"}>Cancel</Button>
      </Box>
    </Box>
  );
}

export default NewItem;
