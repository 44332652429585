import React from "react";
import "./index.scss";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom/dist";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HashRouter>
    <App />
  </HashRouter>
);

reportWebVitals();