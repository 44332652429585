import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import anydesk from "./../Assets/anydesk.png";
import downloads from "./../Assets/downloads.jpg";

function Downloads() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="downloads" style={{ width: "100%" }}>
      <NavBar />
      <div className="body">
        <div className="heading">
          <div className="">
            <h3>DOWNLOADS</h3>
            <img src={downloads} alt="" />
          </div>
          <hr />
          <Box w={"100%"} my={"5em"}>
            <Flex flexDir={"row"} m={4} p={4} alignItems={"center"}>
              <div>
                <img src={anydesk} width={"250px"} alt="" />
              </div>
              <a
                style={{ margin: "0 2em" }}
                href="https://anydesk.com/en"
                target={"_blank"}
                rel="noreferrer"
              >
                Download AnyDesk
              </a>
            </Flex>
          </Box>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Downloads;
