import {Button, useDisclosure} from "@chakra-ui/react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {Link} from "react-router-dom";
export default  function ErrorsHandler({message,isOpen,onOpen,onClose,type}) {

    return (
        <>
           <Modal onOpen={onOpen} isOpen={isOpen} onClose={onClose} closeOnEsc={true} closeOnOverlayClick={true}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{type}</ModalHeader>
                        <ModalCloseButton color={'black'} />
                        <ModalBody>
                            {message}
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={()=>onClose()}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
        </>
    )
}
