import {
  Box,
  Button,
  Center,
  CloseButton,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SkeletonText,
  Spacer,
  StatHelpText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import {
  AddIcon,
  ChevronDownIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { SelectPicker, TagInput, Timeline,} from "rsuite";
import { ImTicket } from "react-icons/im";
import moment from "moment";
import { Store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import { ClientTicketsActions } from "../DataStore/Client/ClientTickets";
import TicketView from "./components/TicketView";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

function ClientTickets() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ticketChoice, setticketChoice] = useState("");
  const [urgency, seturgency] = useState(1);
  const [description, setDescription] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setsubjectError] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [cc_email, setCC_Email] = useState([]);
  const [currentTicket, setcurrentTicket] = useState(0);
  const [fileToUpload, setfileToUpload] = useState([]);
  const [isbillingCardset, setisbillingCardset] = useState(false);
  const [statusFilterBy, setstatusFilterBy] = useState("All");
  const [ticketSearchItem, setticketSearchItem] = useState("");
  const [cc_employees, setcc_employees] = useState([]);
  const [ticketChoiceError, setticketChoiceError] = useState("");
  const [showTicket, setshowTicket] = useState(false);
  const [ticket_details, setticket_details] = useState([]);
  const [loadingTicket, setloadingTicket] = useState(false);
  const userData = useSelector((state) => state.user);
  const tickets = useSelector((state) => state.clientTickets).tickets;
  const banking = useSelector((state) => state.banking);
  const dispatch = useDispatch();
  const time = new Date();
  const bankDetials = useSelector((state) => state.banking);
  const [totalAmount,setTotalAmount] =useState(userData.payments.map(payment=>parseFloat(payment.amount).toFixed(2)/100.00).reduce((total,num)=>{return total+num},0).toFixed(2))
  // const submitTicket = () => {
  //   const newTicketData = {
  //     description: description,
  //     subject: subject,
  //     requester: userData.email,
  //     priority: urgency,
  //     status: 1,
  //     fwd_cc: cc_employees,
  //     createdAt: new Date(),
  //     service_name: ticketChoice,
  //   };
  //   clientSocket.emit("$techresq:ticket:new", newTicketData);
  //   //dispatch(ClientTicketsActions.addTickets([newTicketData]));
  //   ticketSubmitted();
  //   onClose();
  // };

  // const getTickeInfo = (id) => {
  //   setloadingTicket(true);
  //   const config = {
  //     method: "post",
  //     url: `${server_URL}/tickets/${id}`,
  //     headers: {},
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       setticket_details([response.data]);
  //       console.log(response.data);
  //       setloadingTicket(false);
  //       setshowTicket(true);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setticket_details([]);
  //     });
  // };
  // const replyMessage = async (id) => {
  //   const data = {
  //     id: id,
  //     email: email,
  //     body: replyMessage,
  //   };
  //   const config = {
  //     method: "post",
  //     url: `${server_URL}/tickets/reply`,
  //     header: {},
  //     data: data,
  //   };
  //   await axios(config)
  //     .then((response) => console.log(response))
  //     .catch((e) => console.log(e));
  // };




  const navigate= useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);

    // clientSocket.on("$techresq:message:retrieved:all",(data)=>{
    //   console.log("Messages reveiced.......")
    // })
    return ()=>{
      //clientSocket.off("$techresq:message:retrieve:all")
    }
  }, []);
  const viewTicket = (id) => {
    setcurrentTicket(id);
    // getTickeInfo(id);
    setshowTicket(true);
  };
  const sendAttachments = () => {
    console.log(fileToUpload);
    setfileToUpload([]);
    //sendingAttachment()
  };


  const validator = () => {};
  return (
    <div
      className="client_tickets"
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: `${totalAmount>0? "none" : "block"}`,
          zIndex: 10,
          backgroundColor: "rgba(229,229,229,0.77)",
          backdropFilter: "blur(2px)",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Box className={'flex flex-col justify-center align-middle'} h={"100%"}>
          <Box classsName={'w-fit m-auto'}>
            <Text>Add a minimum of R100 to your account to log in a ticket</Text>
          </Box>
          <Button  onClick={() =>navigate('/mydashboard/billing')}>
           Add Funds
          </Button>
        </Box>
      </div>
      <Box h="100%" overflow={""} pos={"relative"}>
        <Skeleton isLoaded={true} h="100%">
           <Outlet  />
        </Skeleton>
      </Box>
    </div>
  );
}

export default ClientTickets;
