const ticketDataServices = [
  {
    category: "IT Desktop Support",
    services: [
      "Software Installation",
      "Hardware Installation",
      "Software Upgrades",
      "Virus removals",
      "Others..",
    ],
  },
  {
    category: "IT Infrastructure Support",
    services: [
      "Network Connectivity installations",
      "Network Connectivity Support",
      "WIFI Troubleshooting",
      "WIFI Performance Improvement",
      "Audio Visual Installations",
      "Audio Visual Support",
      "Video Conferencing system installations",
      "Video Conferencing system Support",
    ],
  },
  {
    category: "IT Hardware and Software Sales",
    services: ["New Laptop", "New Hardware Part", "New Desktop", "Software"],
  },
  {
    category: "Security Systems ",
    services: ["CCTV System", "Access Control", "New Desktop", "Software"],
  },
];

export default ticketDataServices