import {createSlice} from "@reduxjs/toolkit";

const AdminQuotationsSlice = createSlice({
    name: "adminQuotations",
    initialState: {
        quotations: [],
        currentQuotation:null
    },
    reducers: {
        addQuotations(state, action) {
            const quotations = action.payload;
            console.log("Adding  admin quotations ", quotations);
            Object.keys(quotations).forEach((key) => {
                if (quotations[key] !== state.quotations[key]) {
                    state.quotations = [...state.quotations, quotations[key]];
                }
            });
        },
        removeQuotations(state, action) {
            state.quotations = [];
        },
        addQuotation(state, action) {
            console.log("Add ticket to redux soter")
            const quotation = action.payload;
            state.quotations = [...state.quotations, quotation];
        },
        addCurrentQuotation(state,action){
            console.log(action.payload)
            state.currentQuotation = action.payload
        },
        removeCurrentQuotation(state,action){
            state.currentQuotation =[]
        },

        updateAgent(state, action) {
            state.currentQuotation.agent = action.payload
        },
        updateStatus (state, action) {
            console.log("Update status ")
            state.currentQuotation.status = action.payload
        },
        addNote(state, action) {
            console.log("Add Note ")
            const note = action.payload
            state.currentQuotation.ticketNotes =[...state.currentQuotation.ticketNotes,note]
        },
        updateQuotation(state,action){
            const ticket = action.payload
            console.log(ticket)
        },
        updateConsultationStatus(state, action) {
            const consultation= action.payload
            if(consultation.consultationId===state.currentQuotation.consultation.consultationId){
                state.currentQuotation.consultation.finishedAt=consultation.finishedAt
            }
        },
    },
});
export const AdminQuotationsActions = AdminQuotationsSlice.actions;
export default AdminQuotationsSlice;
