import {
  ArrowBackIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import CloseIcon from "@rsuite/icons/Close";
import QRCode from "../../../components/QRCodeGenerator";
import "./TicketViewer.scss";
import {
  Center,
  Input,
  Text,
  Wrap,
  Menu,
  MenuButton,
  Textarea,
  Button,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  TabPanel,
  Flex,
  Tag,
  Box,
  WrapItem,
  List,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Heading,
  VStack,
  ListItem,
  Img,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Timeline, Uploader } from "rsuite";

import { FileUpload } from "primereact/fileupload";

// import * as Yup from "yup";
import Conversation from "./Conversation";
import { ClientCommunicationActions } from "../../DataStore/Client/ClientCommunication";
import TimeLine from "./TimeLine";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import scanme from "../../../Assets/scanme.png";
import { TbFileFunction } from "react-icons/tb";
import axios from "axios";
import { server_URL } from "../../../serverURL";

function TicketView(props) {
  const [fileToUpload, setfileToUpload] = useState([]);
  const dispatch = useDispatch();
  const uploaderRef = useRef(null);
  const tickets = useSelector((state) => state.clientTickets).tickets;
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const statusDescrptions = {
    1: "Open",
    2: "Pending",
    4: "Resolved",
    3: "Closed",
  };
  const priorityIdentifier = { 1: "Low", 2: "Medium", 3: "High", 4: "Urgent" };
  const priorityIdentifierColor = {
    1: "green.300",
    2: "yellow.300",
    3: "orange.300",
    4: "red.300",
  };
  const statusIdentifierColor = {
    1: ["#532300","white"],
    2: ["#e5707d","white"],
    3: ["#6cf15d","black"],
    4: ["#a7c759","black"],
    5: ["#415300","white"],
  };
  const filterData = ["Open", "Closed", "Pending", "Escalated"].map((item) => ({
    label: item,
    value: item,
  }));
  const downloadFile = async (attachment) => {
    const configuration = {
      method: "POST",
      url: server_URL + "ticket/attachment",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        attachment,
      },
    };

    await axios(configuration)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const removeAttachment =()=>{

  }
  return (
    <Box w={"100%"} h="100%" p={0}>
      {tickets
        .filter((ticket) => ticket.id.toString() === id)
        .map((item) => (
          <Box
            className="flex flex-column h-full"
            key={item.ticketId}
            p={0}
            m={0}
          >
            <VStack flexDir="row" alignItems="center" p={2} m={2} height="5vh">
              <Button
                w={"fit-content"}
                onClick={() => navigate(-1)}
                leftIcon={<ArrowBackIcon/>}
                mx={1}
              >
                Back
              </Button>
              <Heading
                fontFamily={"RobotoCondensedBold"}
                fontSize={"lg"}
                textAlign="center"
                p={0}
                m={0}
              >
                Ticket :#{item.id}
              </Heading>
            </VStack>
            <Tabs overflow="hidden" className="flex-grow-1 h-full">
              <TabList className="">
                <Tab className="h-full">Ticket Description</Tab>
                <Tab>Conversations</Tab>
                <Tab>Ticket Tracker</Tab>
              </TabList>
              <TabPanels overflow="auto" className="h-full">
                <TabPanel p={0} mb={"2em"} w={"100%"} className="h-full">
                  <div className="row p-0  ">
                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center h-100">
                      <Text
                        fontFamily={"RobotoCondensedBold"}
                        my={3}
                        mx={1}
                        p={2}
                        w={"fit-content"}
                        fontSize="md"
                      >
                        Subject:
                      </Text>
                      <Tag
                        fontFamily={"RobotoCondensedRegular"}
                        p={1}
                        m={0}
                        w={"fit-content"}
                        textAlign="start"
                        fontSize="md"
                      >
                        {item.subject}
                      </Tag>
                    </div>
                    <Box
                      as={"div"}
                      className="col-lg-8 col-md-12 col-sm-12"
                      h="70%"
                    >
                      <Text
                        textAlign={"start"}
                        mx={2}
                        h="15em"
                        bgColor={"gray.100"}
                        boxShadow={"md"}
                        p={4}
                        fontSize="md"
                      >
                        {item.description}
                      </Text>
                      <Box
                        flexDirection={"column"}
                        m={3}
                        display={"flex"}
                        justifyContent={"flex-start"}
                      >
                        <Flex flexDir="column">
                          <WrapItem>
                            <Text fontSize={["sm", "md", "1rem"]}>
                              Attachment(s):
                            </Text>

                          </WrapItem>
                          <WrapItem>
                            <List className="w-full m-0 p-0">

                              {item.attachments.map((attachment) => (
                                <ListItem
                                  key={attachment.id}
                                  className="w-full m-1 p-1 flex flex-row"
                                  bgColor={"whitesmoke"}
                                >
                                  <Box
                                    className={
                                      "flex flex-row w-full m-0 p-0 align-items-center"
                                    }
                                  >
                                    <TbFileFunction className="text-lg" />
                                    <Text className="m-0 p-0">
                                      {attachment.name}
                                    </Text>
                                  </Box>
                                  <Button
                                    size={"sm"}
                                    leftIcon={<DownloadIcon />}
                                    onClick={() => downloadFile(attachment)}
                                  >
                                    Download
                                  </Button>
                                </ListItem>
                              ))}
                            </List>
                          </WrapItem>
                        </Flex>
                        <Flex
                          flexDir="column"
                          w={"auto"}
                          p={2}
                          mt={3}
                          bgColor="gray.200"
                          boxShadow="lg"
                        >
                          {/* <Uploader
                            defaultFileList={[]}
                            ref={uploaderRef}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "start",
                              color: "blue",
                              width: "100%",
                              overflow: "auto",
                              fontSize: "1em",
                            }}
                            action="http://localhost:3000/users/upload"
                            fileList={fileToUpload}
                            name="attachments"
                            autoUpload={false}
                            onChange={setfileToUpload}
                          />
                          {fileToUpload.length !== 0 ? (
                            <Button
                              onClick={() => {
                                uploaderRef.current.start();
                              }}
                              w={"fit-content"}
                            >
                              {"Send-File(s)"}
                            </Button>
                          ) : null} */}
                          <FileUpload
                            name="attachments"
                            url={`${server_URL}/users/upload/${user.email}/${item.id}`}
                            multiple
                            accept="image/*,pdf"
                            onRemove={()=>removeAttachment()}
                            maxFileSize={1000000}
                            chooseOptions={{
                              icon: <CloseIcon />,
                              className: "custom-choose-btn ",
                            }}
                            renderFileInfo={(file, fileElement) => {
                              return (
                                <>
                                  <span>File Name: {file.name}</span>
                                  <p>File URL: {file.url}</p>
                                </>
                              );
                            }}
                            emptyTemplate={
                              <p className="m-0">
                                Drag and drop files to here to upload.
                              </p>
                            }
                          />
                        </Flex>
                      </Box>
                    </Box>
                    <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                      <Box
                        m={1}
                        width={"100%"}
                        display="flex"
                        flexDir={"row"}
                        justifyContent="flex-start"
                        className={'my-3'}
                      >
                        <Text m={1} fontSize={"sm"} w="25%" textAlign="start">
                          Request date:{" "}
                        </Text>
                        <Tag size={"sm"}>
                          {new moment(item.createdAt).fromNow()}
                        </Tag>
                      </Box>
                      <Box
                        m={1}
                        width={"100%"}
                        display="flex"
                        flexDir={"row"}
                        justifyContent="flex-start"
                        className={'my-3'}
                      >
                        <Text m={1} fontSize={"sm"} w="25%" textAlign="start">
                          Priority:
                        </Text>
                        <Tag bgColor={priorityIdentifierColor[item.priority]}>
                          {priorityIdentifier[`${item.priority}`]}
                        </Tag>
                      </Box>

                      <Box
                        m={1}
                        width={"100%"}
                        display="flex"
                        flexDir={"row"}
                        justifyContent="flex-start"
                        className={'my-3'}
                      >
                        <Text fontSize={"sm"} w="25%" m={1} textAlign="start">
                          Status:
                        </Text>
                        <Tag bgColor={statusIdentifierColor[item.status][0]} color={statusIdentifierColor[item.status][1]}>
                          {statusDescrptions[item.status]}
                        </Tag>
                      </Box>
                      <Box
                          m={1}
                          width={"100%"}
                          display="flex"
                          flexDir={"row"}
                          justifyContent="flex-start"
                          className={'my-3'}
                      >
                        <Text fontSize={"sm"} w="25%" m={1} textAlign="start">
                          Agent:
                        </Text>
                        <Tag>{item.agent}</Tag>
                      </Box>
                      <Box
                          m={1}
                          width={"100%"}
                          display="flex"
                          flexDir={"row"}
                          justifyContent="flex-start"
                      >
                        <Text fontSize={"sm"} w="25%" m={1} textAlign="start">
                          Last update:
                        </Text>
                        <Tag>{new moment(item.updatedAt).fromNow()}</Tag>
                      </Box>
                      <Center p={5}>
                        {/*<Box>*/}
                        {/*  <Img src={scanme} width={"150"} height={"150"} />*/}
                        {/*  <Text>Scan to pay </Text>*/}
                        {/*</Box>*/}
                        {/*<QRCode />*/}
                      </Center>
                      {/* <Box m={0} p={1} w={'100%'}>
                        <Text  m={2} fontSize={"lg"} textAlign="start">
                          {" "}
                          Ticket Tracker{" "}
                        </Text>
                        <Box m={2} w='100%' pos={'relative'}>
                          <Timeline style={{position:'relative'}}>
                            <Timeline.Item>
                             Ticket Logged
                            </Timeline.Item>
                            <Timeline.Item
                              dot={
                                item.responder ? (
                                  <FiCheckCircle
                                    style={{
                                      background: "white",
                                      color: "green",
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <FiXCircle
                                    style={{
                                      background: "white",
                                      color: "red",
                                      borderRadius: "50%",
                                    }}
                                  />
                                )
                              }
                            >
                              <Text p={2}
                                color={item.responder ? "green" : "red"}
                                fontSize={"sm"}
                              >
                                Technician Assigned
                              </Text>
                            </Timeline.Item>
                            <Timeline.Item
                              dot={
                                statusDescrptions[item.status] !== "Closed" ? (
                                  <FiCheckCircle
                                    style={{
                                      background: "white",
                                      color: "orange",
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <FiCheckCircle
                                    style={{
                                      background: "white",
                                      color: "green",
                                      borderRadius: "50%",
                                    }}
                                  />
                                )
                              }
                            >
                              <Text p={2}
                                color={
                                  statusDescrptions[item.status] !== "Closed"
                                    ? "orange"
                                    : "green"
                                }
                                fontSize={"sm"}
                              >
                                Status :{statusDescrptions[item.status]}
                              </Text>
                            </Timeline.Item>
                          </Timeline>
                        </Box>
                      </Box> */}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel p={0} className="h-full m-0">
                  <Conversation
                    avatarURL={user.avatarDestination}
                    selectedTicket={id}
                  />
                  {/* <Button onClick={joinRoom}>Join Room </Button> */}
                </TabPanel>
                <TabPanel p={0} mb={"2em"} w={"100%"} className="h-full">
                  <Box m={2} w="100%" pos={"relative"}>
                    <TimeLine item={item} />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        ))}
    </Box>
  );
}
const ticketDataServices = [
  {
    category: "IT Desktop Support",
    services: [
      "Software Installations",
      "Hardware installations",
      "Software Upgrades",
      "Virus removals",
    ],
  },
  {
    category: "IT Infrastructure Support",
    services: [
      "Network Connectivity installations",
      "Network Connectivity Support",
      "WIFI Troubleshooting",
      "WIFI Performance Improvement",
      "Audio Visual Installations",
      "Audio Visual Support",
      "Video Conferencing system installations",
      "Video Conferencing system Support",
    ],
  },
  {
    category: "IT Hardware and Software Sales",
    services: ["New Laptop", "New Hardware Part", "New Desktop", "Software"],
  },
];
export default TicketView;
