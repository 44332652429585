import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import './TimeLine.scss'
import { MdSupportAgent } from "react-icons/md";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import moment from 'moment/moment';
import { ImTicket } from 'react-icons/im';
function TimeLine(props) {
  return (
    <Box className='timeline'>
        <Box height={'--chakra-fontSizes-lg'} bgColor='#033053' p={2} color={'gray.100'}>
            TICKET TRACKER
        </Box>
        <VerticalTimeline lineColor='#033053'>
  <VerticalTimelineElement
    className="vertical-timeline-element--work ticket h-auto"
    contentStyle={{ background: 'rgb(121 140 226)', color: 'black',fontFamily:'RobotoCondensedLight',fontSize:'.5em' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(121 140 226)' }}
    date={`${moment(props.item.createdAt).fromNow()}`}
    dateClassName='date'
    iconStyle={{ background: 'rgb(121 140 226)' }}
    icon={<ImTicket color='blue.900'/>}
  > <Text as={'h5'} fontSize='lg'> Ticket #{props.item.id} Logged</Text>
    
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(121 140 226)', color: 'black',fontFamily:'RobotoCondensedLight',fontSize:'.5em' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(121 140 226)' }}
    date={props.item.responder?`${moment(props.item.updateAt).fromNow()}`:'not assigned yet'}
    iconStyle={{ background: 'rgb(121 140 226)'}}
    icon={<MdSupportAgent color='blue.900'/>}
    dateClassName='date'
  >
    
     <Text as={'h5'} fontSize='lg'>Agent Assigned</Text>
  </VerticalTimelineElement>
 
 </VerticalTimeline>
    </Box>

  )
}

export default TimeLine