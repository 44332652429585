import {Box, Text, Button, Spacer, useDisclosure, Table, IconButton} from "@chakra-ui/react";
import {Modal, ModalOverlay, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton} from '@chakra-ui/react'
import {DeleteIcon, EditIcon} from "@chakra-ui/icons";
function SLA (){
    const { isOpen:newPolicyisOpen, onOpen:newPolicyonOpen, onClose:newPolicyonClose } = useDisclosure()
    return (
        <Box>
            <Modal isOpen={newPolicyisOpen}  onClose={newPolicyonOpen}>
                <ModalOverlay />
                <ModalContent borderRadius={'none'}>
                    <ModalHeader className={'w-full flex flex-'}>
                        <Text className={'w-full'}>New Policy </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody boxShadow={'md'} className={'m-2'}>
                       <Box>New policy </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={newPolicyonClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Text>SLA Policies</Text>
            <Box>
                <Box boxShadow={'md'} className={'m-2 flex flex-row justify-items-end w-full'} border={'solid 1px gray'} borderRadius={'none'}>
                    <Spacer/>
                    <Button onClick={newPolicyonOpen} colorScheme='teal' borderRadius={'none'} size={'sm'} className={'m-2'}>Add Policies</Button>
                    <Button colorScheme='blue' borderRadius={'none'} size={'sm'} className={'m-2'} variant='outline'>Edit</Button>
                </Box>
                <Box>
                   <TableContainer>
                       <Table>
                           <Thead>
                               <Tr>
                                   <Th>Policy name </Th>
                                   <Th>Policy </Th>
                                   <Th>Active </Th>
                                   <Th></Th>
                               </Tr>
                           </Thead>
                           <Tbody>
                               <Tr className={'h-full m-0 p-1'} boxShadow={'md'} border={'solid gray 1px'} >
                                   <Td  className={'m-0 p-0'}>
                                       <Text border={'solid gray 1px'} className={'m-0 p-1'}>SLA 1</Text>
                                   </Td>
                                   <Td   className={'m-0 p-0'}>
                                       <Text border={'solid gray 1px'} className={'m-0 p-1'}>SLA 1</Text>
                                   </Td>
                                   <Td   className={'m-0 p-0'}>
                                       <Text border={'solid gray 1px'} className={'m-0 p-1'}>SLA 1</Text>
                                   </Td>
                                   <Td    className={'m-0 p-0'}>
                                       <Box border={'solid gray 1px'} className={'m-0 p-1'}>
                                           <IconButton icon={<EditIcon/>}/>
                                           <IconButton icon={<DeleteIcon/>}/>
                                       </Box>
                                   </Td>



                               </Tr>
                           </Tbody>
                       </Table>
                   </TableContainer>
                </Box>
            </Box>

        </Box>
    )
}

export default SLA