import React, { useEffect, useState } from "react";
import logolight from "../Assets/TechResQ_Logo_fixedWhite.svg";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import {CloseIcon,} from "@chakra-ui/icons";
import {Flex, Spacer, Text} from "@chakra-ui/react";
function NavBar(props) {
  const [toggled, settoggled] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 768) settoggled(false);
    else settoggled(true);

  }, [props]);

  return (
    <div className="navBar">
      <div className="logo">
        <Link to="/">
          <img src={logolight} alt="TechResQ logo" color={'red'} />
        </Link>
        <div className="menubar">
          {toggled ? (
            <Icon onClick={() => settoggled(false)} icon="mdi:menu-open" />
          ) : (
            <Icon onClick={() => settoggled(true)} icon="healthicons:ui-menu" />
          )}
        </div>
      </div>
      <div className="contact">
        <ul  >
          <li>
            <a href="mailto: support@techresq.co.za">
              <Text style={{ fontSize: "lg", width: "100%" }}>
                support@techresq.co.za
              </Text>
            </a>
          </li>
          <li>
            <a href="tel: 086 123 7377 ">
              <Text style={{ fontSize: "lg", width: "fit-content" }}>
                086 123 7377
              </Text>
            </a>
          </li>
          <li>
            <a href="tel 086 123 7377">
              <Text style={{ fontSize: "lg", width: "fit-content" }}>
                086 123 ResQ
              </Text>
            </a>
          </li>
        </ul>
      </div>

      <div className={`navigation ${toggled ? "" : "closed"}`} >
        <div className="navbarlist" >
          <Flex w={"100%"} p={4} className="mobile-close-menu">
            <Spacer />
            {toggled ? (
              <CloseIcon
                onClick={() => settoggled(false)}
                margin-left="auto"
                fontSize={"2em"}
              />
            ) : (
              <Icon
                onClick={() => settoggled(true)}
                icon="healthicons:ui-menu"
              />
            )}
          </Flex>
          <ul className="list" >
            <li className="listitem">
              <Link to="/">Home</Link>
            </li>
            <li className="listitem">
              <Link to="/aboutus">About Us</Link>
            </li>
            <li className="listitem service">
              <p>Our Services</p>
              <ul className="servicelist">
                <li>
                  <Link to="/enduserservices">End-user Support</Link>
                </li>
                <li>
                  <Link to="/infrastructureservices">
                    IT Infrastructure Support
                  </Link>
                </li>
                <li>
                  <Link to="/salesservices">
                    IT Software and Hardware Sales
                  </Link>
                </li>
                <li>
                  <Link to="/securitysystems">
                    Security Systems
                  </Link>
                </li>
              </ul>
            </li>
            <li className="listitem">
              <Link to="/contacts">Contacts</Link>
            </li>
            <li className="listitem">
              <Link to="/faqs">FAQ</Link>
            </li>
          </ul>
          <div className="line">|</div>
          <div className="login_signup">
            <div className="login">
              <Link
                to={"/login"}
                className={"login_client"}
                style={{ width: "100%", textDecoration: "none",height:'auto',padding:'1.5em 0' }}
              >
                <Text height={'100%'}>Login</Text>
              </Link>
            </div>
            <div className="signup">
              <Text>Sign Up </Text>
              <ul className="sign_up_list">
                <Link to="/signup" style={{height:'100%'}}>
                  <li >Client</li>
                </Link>
                <Link   to="/agentsignup" style={{height:'100%'}}>
                  <li >
                    Technician
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
export default NavBar;
