import {Box, Center, Flex, Input, Text,Button} from "@chakra-ui/react";
import NavBar from "../../components/navBar";
import Footer from "../../pages/Footer";
import {ErrorMessage, Field, Formik} from "formik";
import {useState} from "react";
import * as Yup from 'yup'
import {server_URL} from "../../serverURL";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Store} from "react-notifications-component";

const validationSchema = Yup.object().shape({
    fullName:Yup.string().required("full Name Required"),
    email:Yup.string().email().required("Email Required"),
    phone:Yup.string()
        .length(10, "Ensure you have entered a correct SA cell number")
        .required("Cell number Required"),
    password:Yup.string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
        )
        .required("Password Required"),
    confirmPassword:Yup.string()
        .oneOf([Yup.ref("password"), null], "Password do not match")
        .required("Cormfirm your password")
})
function AdminSignUp(){
    const [loading,setloading] = useState(false)
    const navigate= useNavigate()
    const [showEye,setShowEye] =useState(false)
    const onShowPassword =()=>setShowEye(!showEye)
    const sendData =async (values) => {
        setloading(true)
        const config = {
            url: server_URL + '/admin/new',
            method: "POST",
            headers: {
                Accept: "application/json"
            },
            data: values
        }
        await axios(config).then(response => {
            if (response) {
                console.log(response.data.user)
                sessionStorage.setItem("Session-token",response.data.user.token)
                setloading(false);
                navigate("/new/adminverify")
            }
        }).catch(error=>{
            userExitMessage()
        })
    }
    const userExitMessage = () => {
        Store.addNotification({
            title: "Sorry...,This user already  exist !!!",
            message: "Login to continue",
            type: "danger",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
            onRemoval: () => {
                setloading(false);
            },
        });
    };
    return (
        <Box>
            <NavBar/>
            <Center h={'100vh'}>
                <Flex flexDir={'column'} w={'75%'} >
                    <Text fontSize={'1.1em'} bgColor={'blue.800'} color={'white'} w={'100%'} p={5}>Administrator Sign Up</Text>
                    <Box>

                    </Box>
                    <Formik initialValues={
                        {
                            fullName: "",
                            password: "",
                            confirmPassword: "",
                            phone: "",
                            email: ""
                        }
                    } onSubmit={(values)=>{
                        console.log(values)
                        sendData(values)
                    }}
                            validationSchema={validationSchema}
                    >
                        {({values,isSubmitting,errors,touched,handleBlur,handleSubmit})=>(
                            <form onSubmit={handleSubmit} >
                                <Flex flexDir={'column'}  m={'auto'} p={5} w={'auto'}>
                                        <Flex  justifyContent={'center'}  m={2}  w={'100%'}  alignItems={'center'} >
                                            <Box w={'25%'}>
                                                <Text   m={2} textAlign={'start'} >Username :</Text>
                                            </Box>
                                            <Flex flexDir={'column'} alignItems={'flex-start'}>
                                                <Input m={2} as={Field} onBlur={handleBlur}  w={'auto'} m={0} name={'fullName'}/>
                                            </Flex>
                                        </Flex>
                                    <Text fontSize={'sm'} m={0}  color={'red.500'} textOverflow={'wrap'}  display={errors.fullName&&touched.fullName} >{errors.fullName}</Text>
                                    <Flex alignItems={'center'}  m={2}   justifyContent={'center'} w={'100%'} >
                                        <Box  w={'25%'}>
                                            <Text  m={2} textAlign={'start'} >Email:</Text>
                                        </Box>
                                        <Flex flexDir={'column'} alignItems={'flex-start'}>
                                            <Input m={2} onBlur={handleBlur} as={Field} w={'auto'} m={0} name={'email'}/>
                                        </Flex>
                                    </Flex>
                                    <Text fontSize={'sm'} m={0}  color={'red.500'} textOverflow={'wrap'}  display={errors.email?true:false} >{errors.email}</Text>

                                    <Flex m={'auto'} alignItems={'center'}  m={2}   justifyContent={'center'} w={'100%'} >
                                        <Box  w={'25%'}>
                                            <Text  m={2}  textAlign={'start'}w={'25%'} >Phone:</Text>
                                        </Box>
                                        <Flex flexDir={'column'} alignItems={'flex-start'}>
                                            <Input m={2} onBlur={handleBlur} as={Field} w={'auto'} placeholder={'(0__) ___ ____'}  m={0} name={'phone'}/>

                                        </Flex>
                                    </Flex>
                                    <Text fontSize={'sm'} m={0}  color={'red.500'} textOverflow={'wrap'}  display={errors.phone?true:false} >{errors.phone}</Text>

                                    <Flex m={'auto'} alignItems={'center'}  m={2}  justifyContent={'center'} w={'100%'}>

                                        <Box w={'25%'}>
                                            <Text  m={2} textAlign={'start'} >Password  :</Text>
                                        </Box>
                                        <Flex flexDir={'column'} flexDir={'column'} alignItems={'flex-start'}>
                                            <Input mx={2} onBlur={handleBlur} mt={2} mb={0} as={Field} m={0}  w={'75%'}  w={'auto'}  name={'password'}/>

                                        </Flex>
                                        </Flex>
                                    <Text fontSize={'sm'} m={0}  color={'red.500'} textOverflow={'wrap'}  display={errors.password?true:false} >{errors.password}</Text>

                                    <Flex m={'auto'} alignItems={'center'}  m={2}   justifyContent={'center'} w={'100%'}>
                                        <Box w={'25%'}>
                                            <Text  m={2}  textAlign={'start'}>Confirm Password :</Text>
                                        </Box>

                                        <Flex flexDir={'column'} w={'fit-content'} alignItems={'flex-start'}>
                                            <Input m={2} onBlur={handleBlur} as={Field} m={0} w={'auto'}  name={'confirmPassword'}/>
                                        </Flex>
                                    </Flex>
                                    <Text fontSize={'sm'} m={0}  color={'red.500'} display={errors.confirmPassword?true:false} >{errors.confirmPassword}</Text>

                                    <Button  m={'auto'} w={'fit-content'} type={'submit'}>Submit</Button>
                                </Flex>

                            </form>
                        )
                        }

                    </Formik>

                </Flex>

            </Center>

            <Footer/>
        </Box>
    )
}

export default AdminSignUp