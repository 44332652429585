import { createSlice } from "@reduxjs/toolkit";

const ClientTicketSlice = createSlice({
  name: "clientTickets",
  initialState: {
    tickets: [],
    currentTicket:[],
  },
  reducers: {
    addTickets(state, action) {
      const tickets = action.payload;
      state.tickets = tickets
    },
    removeTicket(state, action) {
      state.tickets = [];
    },
    addTicket(state,action){
      state.tickets= [action.payload.ticket,...state.tickets]
    },
    setCurrentTicket(state,action){
      const newTicket = action.payload;
      state.currentTicket=newTicket;
    }
  },
});
export const ClientTicketsActions = ClientTicketSlice.actions;
export default ClientTicketSlice;
