import { createSlice } from "@reduxjs/toolkit";

const AgentSettingsSlice= createSlice({
    name:'agentSettings',
    initialState:{
        settings:[]
    },
    reducers:{
        addSettings(state,action){
            state.settings=action.payload
        },
        removeSettings(state,action){
            state.settings =[]
        }
    }
})

export const AgentSettindsAction= AgentSettingsSlice.actions
export default AgentSettingsSlice