import React, {useEffect, useState} from 'react'
import {Box, Button, Img, Skeleton, Spacer, Text} from "@chakra-ui/react";
import {BackArrow} from "@rsuite/icons/es/icons/legacy";
import {useNavigate, useParams} from "react-router-dom";
import {server_URL} from "../../../../serverURL";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {AdminclientsActions} from "../../../DataStore/AdminStore/Clients";
import moment from "moment";
import {AttachmentIcon} from "@chakra-ui/icons";
import {EyeSlashIcon} from "primereact/icons/eyeslash";
 function AdminViewAgent() {
    const selectedAgent= useSelector(state=>state.adminclients).selectedClient
    const [currentAgent,setCurrentAgent]=useState(selectedAgent)
    const dispatch = useDispatch()
    const [loadingData,setLaodingData]= useState(true)
    const {agentEmail} = useParams()
    const navigate= useNavigate()
    const getAgentDetails = async () => {
        setLaodingData(true)
        const config = {
            url: server_URL + `/admin/agent`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email:agentEmail
            }
        }
        await axios(config).then(response => {
            console.log(response.data)
            dispatch(AdminclientsActions.selectClient(response.data))
            //agentData= response
            setCurrentAgent(response.data)
            setLaodingData(false)
        }).catch(error=>{
            console.log(config)
            console.log(error)
            setLaodingData(true)
        })
    }
    useEffect(()=>{
        getAgentDetails()
        return ()=>{
            dispatch(AdminclientsActions.removeSelectedClient())
        }
    },[])
    console.log(selectedAgent)
  return (
    <Box h={'100%'}  className={'flex flex-col h-full'}>
      <Box className={'flex align-items-start m-2'}>
        <Button size={'sm'} onClick={()=>navigate(-1)} leftIcon={<BackArrow/>}>Back</Button>
      </Box>
      <Box h={'100%'} className={'grid grid-cols-12 h-full flex-grow-1'}>
        <Box className={'flex flex-col w-full col-span-6 ' }>
          <Box h={'fit-content'} className={'flex flex-col flex-grow-1 m-2 '}  boxShadow={'md'} >
              <Box className={'flex p-2 mb-4 w-full'} bgColor={'white'}>
                  <Text className={'text-xl w-full text-center'}>Personal Details</Text>
              </Box>
              <Box className={'grid grid-cols-6  mx-4  py-2'}>
                  <Text className={'m-0 col-span-2 p-0 text-start'}>Full Name:</Text>
                 <Box className={'col-span-4'}>
                     {
                         loadingData?<Skeleton></Skeleton>:<Text className={'border-1 text-start m-0 py-1 px-2 py-0'}>{selectedAgent?.userAgent.fullName}</Text>
                     }
                 </Box>
              </Box>
              <Box className={'grid grid-cols-6  mx-4 py-2'}>
                  <Text className={'m-0 col-span-2 p-0 text-start'}>Email:</Text>
                  <Box className={'col-span-4'}>
                      {
                          loadingData?<Skeleton></Skeleton>:<Text className={'border-1 py-1 text-start  m-0 px-2 py-0'}>{selectedAgent?.userAgent.email}</Text>
                      }
                  </Box>

              </Box>
              <Box className={'grid grid-cols-6  mx-4 py-2'}>
                  <Text className={'m-0 p-0 col-span-2 text-start' }>Address:</Text>
                  {
                      loadingData?<Skeleton></Skeleton>:<Text className={'border-1 py-1 col-span-4 m-0 px-2 py-0 text-start'}>{selectedAgent?.userAgent.address||'No address'}</Text>
                  }
              </Box>
              <Box className={'grid grid-cols-6  mx-4 py-2'}>
                  <Text className={'m-0 p-0 col-span-2 text-start'}>Phone:</Text>
                  {
                      loadingData?<Skeleton></Skeleton>:<Text className={'border-1 py-1 col-span-4 m-0 px-2 py-0 text-start'}>{selectedAgent?.userAgent.phone}</Text>
                  }
              </Box>
              <Box className={'grid grid-cols-6  mx-4 py-2'}>
                  <Text className={'m-0 p-0 col-span-2 text-start'}>Job title:</Text>
                  {
                      loadingData?<Skeleton></Skeleton>:<Text className={'border-1 py-1 col-span-4 m-0 px-2 py-0 text-start'}>{selectedAgent.job_title||'Not specified'}</Text>
                  }
              </Box>
              <Box className={'grid grid-cols-6  mx-4 py-2'}>
                  <Text className={' col-span-2 m-0 p-0 text-start'}>Agent verified:</Text>
                 <Box className={'col-span-4'}>
                     {
                         loadingData?<Skeleton></Skeleton>:<Text className={' border-1 py-1 m-0 px-2 py-0 text-start '}>{selectedAgent.smsVerificationID?'Yes':"No"}</Text>
                     }
                 </Box>
              </Box>
              <Box className={'grid grid-cols-6  mx-4 py-2'}>
                  <Text className={'col-span-2 text-start mx-0 p-0'}>Joined us on:</Text>
                  <Box className={'col-span-4'}>
                      {
                          loadingData?<Skeleton></Skeleton>:<Text className={'text-start m-0 px-2 py-0'}>{moment(selectedAgent.createdAt).fromNow()}</Text>
                      }
                  </Box>
              </Box>
              <Spacer/>
              <Button bgColor={'#033053'} color={'white'} className={'w-3/4 my-3 mx-auto'}>Edit</Button>
          </Box>

        </Box>
        <Box className={' flex flex-col col-span-6 w-full'}>
           <Box className={'flex flex-col  flex-grow-1 m-2 '}  boxShadow={'md'}>
               <Box className={'p-2 '} bgColor={'white'}>
                   <Text className={'text-xl'}>Attachments </Text>
               </Box>
               <Box>
                   {loadingData?<Skeleton></Skeleton>:selectedAgent?.attachments.map((attachment,index)=>(
                       <Box className={'flex flex-row m-2 p-1 shadow-md'} bgColor={'gray.300'} key={index}>
                          <Box className={'w-full p-1 flex-row flex'} >
                             <Box className={'w-fit p-2 my-auto'} >
                                 <AttachmentIcon className={'m-1 w-full text-3xl'}/>
                             </Box>
                              <Box className={'w-full my-auto '} >
                                  <Text className={'text-xl text-start m-0 p-0'}>{attachment.name}</Text>
                                  <Text className={'text-sm text-start  m-0 p-0'}>{moment(attachment.createdAt).fromNow()}</Text>
                              </Box>
                          </Box>
                           <Box className={'flex flex-row'}>
                               <Button  className={'m-1 my-auto'} leftIcon={<EyeSlashIcon/>}>View</Button>
                               {attachment.approved!=="N"?<Text>'Yes'</Text>:<Button className={'m-1 my-auto'}>Approve</Button>}
                           </Box>
                       </Box>
                   )||<Box>
                       <Text>No Attachments </Text>
                   </Box>)}
               </Box>
           </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default AdminViewAgent