import { createSlice } from "@reduxjs/toolkit";

const ClientCommunicationSlice = createSlice({
  name: "chats",
  initialState: {
    chats: [],
  },
  reducers: {
    addChats(state, action) {
      const chats = action.payload;
      Object.keys(chats).forEach((key) => {
        if (chats[key] !== state.chats[key]) {
         
          state.chats = [...state.chats, chats[key]];
        }
      });
    },
    removeChats(state, action) {
      state.chats = [];
    },
    addMessage(state, action) {
       //console.log("Adding chat to redux",action.payload)
      const message = action.payload;
      state.chats = [...state.chats, message];
     
    },
  },
});
export const ClientCommunicationActions = ClientCommunicationSlice.actions;
export default ClientCommunicationSlice;
