import { Box, SkeletonText, Text } from '@chakra-ui/react'
import React, {useState} from 'react'
import { FiDollarSign, FiHome } from 'react-icons/fi'
import { TbTicket } from 'react-icons/tb'
import {Link, useNavigate} from 'react-router-dom'
import './ClientSideBar.scss'
import {IoIosHome} from "react-icons/io";
import {BiMoney} from "react-icons/bi";
function ClientSideBar(props) {
    const navigate = useNavigate()
    const [selected,setSelected] = useState(1)
  return (
    <Box  className={`client_side_bar ${props.showBar?'':'open'}`}>
       <Box className='item_list'>
        <ul>
          <li className="item">
              <Box className={`flex flex-row align-middle justify-start py-4 px-5`} onClick={()=>{props.setShowBar(!props.showBar);navigate('/mydashboard/home')}}>
                  <IoIosHome className={'m-1'} />
                <Text  fontSize={'lg'}>Home</Text>
              </Box>
          </li>
          <li className="item">
          <Box className={`flex flex-row align-middle justify-start py-4  px-5`} onClick={()=>{props.setShowBar(!props.showBar);navigate('/mydashboard/tickets/all')}} >
            <TbTicket className={'m-1'} />
            <Text fontSize={'lg'}>Tickets</Text>
            </Box>
            </li>
          <li className="item">
          <Box className={`flex flex-row align-middle justify-start py-4 px-5`} onClick={()=>{props.setShowBar(!props.showBar);navigate('/mydashboard/billing')}}>
           <BiMoney className={'m-1'} />
            <Text  fontSize={'lg'}>Billing</Text>
            </Box>
            </li>
        </ul>
       </Box>
       <Box bgColor={'blue.800'} p={2} color='white'>TechResQ @{new Date().getFullYear()}</Box>
    </Box>
  )
}


export default ClientSideBar