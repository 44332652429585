import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "user",
  initialState: {
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    emailConfirmed: false,
    job_title: "",
    avatarName: "",
    avatarDestination: "",
    address: "",
    token: "",
    isActive: false,
    phoneConfirmed: false,
    criminalCheckStatus: "",
    idVerified: false,
    criminalCheck: "",
    activeRole:"",
    roles: [],
    payments: [],
    funds:null
  },
  reducers: {
    addUser(state, action) {
      console.log(action)
      const newUser = action.payload;
      state.fullName = newUser.fullName || "";
      state.email = newUser.email || "";
      state.address = newUser.address || "";
      state.phoneNumber = newUser.phoneNumber || "";
      state.phoneConfirmed = newUser.phoneConfirmed;
      state.emailConfirmed = newUser.emailConfirmed;
      state.job_title = newUser.job_title;
      state.avatarName = newUser.avatarName;
      state.password = newUser.password;
      state.token = newUser.token;
      state.roles = newUser.roles;
      state.activeRole= newUser.activeRole;
      state.userWallet =newUser.userWallet;
    },
    updateUser(state, action) {
      console.log("Update redux of the user ", action.payload);
      const user = action.payload;
      state.address = user.address;
      state.job_title = user.job_title;
      state.phoneNumber = user.phoneNumber;
    },
    removeUser(state, action) {
      state.fullName = "";
      state.email = "";
      state.password = "";
      state.phoneNumber = "";
      state.job_title = "";
      state.avatarName = "";
      state.avatarDestination = "";
      state.address = "";
      state.token = "";
      state.emailConfirmed=false;
      state.phoneConfirmed=false;
    },
    addAvatarDestination(state, action) {
      state.avatarDestination = action.payload;
    },
    setActive(state, action) {
      state.isActive = action.payload;
    },
    addOnSignUp(state, action) {
      const newUser = action.payload;
      state.fullName = newUser.fullName;
      state.email = newUser.email;
      state.password = newUser.password;
      state.phone = newUser.phone;
    },
    userVerified(state, action) {
      state.userVerified = action.payload;
    },
    updateNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    verifyEmail(state, action) {
      state.emailConfirmed = action.payload;
    },
    updateRoles(state,action) {
      state.roles = action.payload;
    },
    updateFunds(state,action){
      state.userWallet=action.payload;
    }
  },
});
export const userActions = UserSlice.actions;
export default UserSlice;
