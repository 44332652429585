import { Box,Icon,Avatar, Img, Menu, MenuIcon,MenuButton, MenuItem, MenuList, IconButton, Text, AvatarBadge, Tag, ButtonGroup, Spacer} from '@chakra-ui/react'
import React from 'react'
import { Dropdown,Button, Badge } from 'rsuite'
import logo from '../../../Assets/TechResQ_Logo_fixed.svg'
import PlusIcon from '@rsuite/icons/Plus';
import { BellIcon, DeleteIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { ImProfile } from 'react-icons/im';
import { BsPerson } from 'react-icons/bs';
import { BiLogOut } from 'react-icons/bi';
import Notifications from './Notifications';
import {useSelector} from "react-redux";



function AdminNavBar(props) {
    const admin = useSelector(state=>state.user)
  return (
    <Box className='col-12 h-15%' w={'100%'} m={1}  justifyContent='space-between' boxShadow='sm' display='flex' flexDir={'row'} p={0} >
        <Box p={1} display='flex' flexDir={'row'} alignItems='center'>
            <Img src={logo} w={'150px'} h='auto' alt="logo" />
        </Box>
        <Box display={'flex'} alignItems='center' px={4} py={1} >
        <Dropdown title={'| new '} size='md'  icon={<PlusIcon/>} style={{padding:'0 .6em',borderRadius:'none'}}>
            <Dropdown.Item  icon={<PlusIcon/>} as={Link} to='/admin/ticket/new/'>Ticket </Dropdown.Item>
            <Dropdown.Item  icon={<PlusIcon/>} as={Link} to='/admin/user/new/'>User</Dropdown.Item>
            <Dropdown.Item  icon={<PlusIcon/>} as={Link} to='/admin/company/new/'>Company </Dropdown.Item>
            <Dropdown.Item  icon={<PlusIcon/>} as={Link} to='/admin/orders/quotes/new/'>Quote</Dropdown.Item>
            <Dropdown.Item  icon={<PlusIcon/>} as={Link} to='/admin/orders/invoices/new/'>Invoice </Dropdown.Item>
            <Dropdown.Item  icon={<PlusIcon/>} as={Link} to='/admin/orders/items/new/'>Item </Dropdown.Item>
        </Dropdown>
           <Menu pos='relative' zIndex={10}>
            <MenuButton pos='relative' size='md' mx={5} my={1} as={ButtonGroup} backgroundColor='white' icon={<BellIcon size='lg' />} >
             <IconButton icon={<BellIcon/>}/>
             <Box pos='absolute !important'
             borderRadius={'50%'}
             bgColor='red'
             size={'sm'}
             height='20px'
             width='20px'
            color ='white' zIndex={10}
             top='-1' right='-1'>
                <Text fontSize={'sm'}>{notifications.length}</Text>
             </Box>
            </MenuButton>
              <MenuList m={0} p={0} borderRadius='10px' overflow={'hidden'}>
                <Box bgColor={'blue.800'} color='white' p={2}>
                    <Text fontSize='md'>Notifications </Text>
                </Box>
                <MenuItem m={0} p={0}>
                <Box display={'flex'} m={0} p={0} flexDir='column' w='100%'>
                    {notifications.map((notification, index) => (
                        <Notifications id={notification.id} sender={notification.sender} />
                    ))}
                </Box>
                </MenuItem>
                <Box h='' bgColor={'blue.800'} p={1} display='flex' flexDir={'row'} alignItems='center' justifyContent='flex-end'>
                    <Button size='sm' style={{display:'flex'}}>
                    <DeleteIcon mx={1}/>
                       <Text mx={1}>Clear All</Text>
                    </Button>
                </Box>
              </MenuList>
            </Menu>
            <Menu px={2} >
                <MenuButton size='md' as={IconButton} borderRadius='50%' icon={<Avatar src={admin.avatarDestination} circle><AvatarBadge  boxSize='1.25em' bg={admin.isActive ? 'green.500' : 'red.500'}></AvatarBadge></Avatar>}>
            </MenuButton>
              <MenuList m={0} p={0} >
              <Box bgColor={'gray.100'} p={2}>
                    <Text fontSize='md'>{admin.fullName}</Text>
                    </Box>
                <MenuItem as={Link} to="/admin/profile" textDecor={'none'} p={2} fontSize='sm' _hover={{backgroundColor:'blue.700',color:'white'}}>
                       <BsPerson style={{padding:'.1em'}}/>

                <Text mx={2}>Profile </Text>
                </MenuItem>
                <MenuItem as={Link} textDecoration='none' to='/login' p={2} fontSize='sm' _hover={{backgroundColor:'blue.700',color:'white'}}>
                    <BiLogOut style={{padding:'.1em'}}/>
                    <Text mx={2}>Logout</Text></MenuItem>
              </MenuList>
            </Menu>
        </Box>
    </Box>
  )
}

const notifications =[
    {
        id:1,
        sender:'vuyani@gmail.com',
        text:'hello',
        opened:0,
        type:'ticket',
        status:'unread',
        createdAt:'2021-01-01 00:00'
    },
    {
        id:2,
        sender:'vuyani@gmail.com',
        text:'hello',
        opened:0,
        type:'ticket',
        status:'unread',
        createdAt:'2021-01-01 00:00'
    },
    {
        id:3,
        sender:'vuyani@gmail.com',
        text:'hello',
        opened:0,
        type:'ticket',
        status:'unread',
        createdAt:'2021-01-01 00:00'
    }
]
export default AdminNavBar
