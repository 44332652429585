import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Stat,
  StatGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs, Tag,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { TbCricket } from "react-icons/tb";
import { useSelector } from "react-redux";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import HomeLineChart from "./components/HomeLineChart";
import Dateset from "../../utils/TimeInterval"
import {SelectPicker} from "rsuite";

const StatsCounter = (numerator, denominator) => {
  return (numerator / denominator) * 100;
};
function AdminHome(socket) {
  const location = useLocation();
  const [todayDate,setTodayDate]= useState(new Date())
  const navigate = useNavigate(location.pathname);
  const admintickets = useSelector((state) => state.admintickets);
  const adminclients = useSelector((state) => state.adminclients);
  const getActiveUsers = () => {};
   console.log("admin tickets set:",admintickets.tickets)
  return (
    <Box  h="100%" overflowY={"auto"} overflowX={"hidden"}>
      <Box className={'flex flex-col w-full '}>
        <Box className={'m-1'}><Text className={'text-2xl font-bold text-start'}>Tickets</Text></Box>
        <Flex flexDir={"row"} className={'w-full p-2'}>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Opened </Text>
            <Text className={'my-1  text-xl text-start px-2'}>{
              admintickets.tickets.filter((ticket) => ticket.status === "1")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Closed  </Text>
            <Text className={'my-1 text-xl text-start px-2'}>{
              admintickets.tickets.filter((ticket) => ticket.status === "2")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'red.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Escalated  </Text>
            <Text className={'my-1 text-xl text-start px-2'}> {
              admintickets.tickets.filter((ticket) => ticket.status === "4")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Pending </Text>
            <Text className={'my-1 text-xl text-start px-2'}> {
              admintickets.tickets.filter((ticket) => ticket.status === "3")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Resolved </Text>
            <Text className={'my-1 text-xl text-start px-2'}> {
              admintickets.tickets.filter((ticket) => ticket.status === "5")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}> Total </Text>
            <Text className={'my-1 text-xl text-start px-2'}>  {
              admintickets.tickets.length
            } Tickets </Text>
          </Box>
        </Flex>
      </Box>
      <Box className={'flex flex-col w-full '}>
        <Box className={'m-1'}><Text className={'text-2xl font-bold text-start'}>Invoices</Text></Box>
        <Flex flexDir={"row"} className={'w-full p-2'}>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Pending</Text>
            <Text className={'my-1  text-xl text-start px-2'}>{
              admintickets.tickets.filter((ticket) => ticket.status === "1")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Closed  </Text>
            <Text className={'my-1 text-xl text-start px-2'}>{
              admintickets.tickets.filter((ticket) => ticket.status === "2")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'red.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Escalated  </Text>
            <Text className={'my-1 text-xl text-start px-2'}> {
              admintickets.tickets.filter((ticket) => ticket.status === "4")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Pending </Text>
            <Text className={'my-1 text-xl text-start px-2'}> {
              admintickets.tickets.filter((ticket) => ticket.status === "3")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}>Resolved </Text>
            <Text className={'my-1 text-xl text-start px-2'}> {
              admintickets.tickets.filter((ticket) => ticket.status === "5")
                  .length
            } Tickets </Text>
          </Box>
          <Box className={"my-1 mx-1 py-3 w-full flex flex-col"} boxShadow={'lg'} borderLeftColor={'blue.800'} borderLeftWidth={'2px'} borderLeftStyle={'solid'} bgColor={"gray.100"} >
            <Text className={'text-sm m-0 text-start py-1 px-2'} color={'gray.500'}> Total </Text>
            <Text className={'my-1 text-xl text-start px-2'}>  {
              admintickets.tickets.length
            } Tickets </Text>
          </Box>
        </Flex>
      </Box>
      <Box my={1} mx={"auto"} w={"100%"} justifyContent={"space-around"} className={'grid grid-cols-3'}>
        <Flex bgColor={"whitesmoke"} boxShadow={'lg'} m={1} className={'flex flex-col h-fit'} justifyContent={"space-around"}>
          <Text
            w={"100%"}
            fontSize={"xl"}
            className={'m-0 flex justify-center align-items-center'}
            color={"blue.800"}
          >
            Agents{" "}
          </Text>
          <Box w={"100%"} p={1} className={'grid grid-cols-3'}>
            <Flex
              alignItems={"center"}
              w={"100%"}
              my={0}
              justifyContent={"space-between"}
              className={'m-1 p-1 flex flex-col'}
            >
              <CircularProgress value={59} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                {
                  adminclients.activeUsers.filter(
                      (client) => client.role === "AGENT"
                  ).length
                }
              </Text>
              <Text
                m={1}
                w={"50%"}
                textAlign={"center"}
                className={'text-xs mx-auto'}
              >
               Active
              </Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex
                alignItems={"center"}
                w={"100%"}
                my={0}
                justifyContent={"space-between"}
                className={'m-1 p-1 flex flex-col'}
            >
              <CircularProgress value={
                (adminclients.clients.filter(
                    (client) => client.smsVerificationID !==null&&client.role==="AGENT"
                ).length/adminclients.clients.filter(
                    (client) => client.role==="AGENT"
                ).length)*100
              } size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.clients.filter(
                          (client) => client.smsVerificationID !==null&&client.role==="AGENT"
                      ).length
                    }
                  </Text>
                  <Text  textAlign={"center"} className={'lg:text-md md:text-xs text-xs m-1 p-0'}>Verified</Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex
                alignItems={"center"}
                w={"100%"}
                my={0}
                justifyContent={"space-between"}
                className={'m-1 p-1 flex flex-col'}
            >
              <CircularProgress value={59} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1  w-1/2'} >
                    {
                      adminclients.clients.filter(
                          (client) => client.role === "AGENT"
                      ).length
                    }
                  </Text>
                  <Text m={1} w={"50%"} textAlign={"center"} className={'lg:text-md md:text-sm text-xs  mx-auto'}> Total</Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </Box>
        </Flex>
        <Flex
            bgColor={"whitesmoke"}
            m={1}
            boxShadow={'lg'}
            className={'flex flex-col h-fit'}
            justifyContent={"space-around"}
        >
          <Text
              w={"100%"}
              fontSize={"xl"}

              className={'m-0 flex justify-center align-items-center'}
              color={"blue.800"}
          >
            Clients{" "}
          </Text>
          <Box w={"100%"} p={1} bgShadow={'lg'}  bgColor={'whitesmoke'} className={'flex flex-row'}>
            <Flex
                alignItems={"center"}
                w={"100%"}
                my={0}
                justifyContent={"space-between"}
                className={'m-1 p-1 flex flex-col'}
            >
              <CircularProgress value={59} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.activeUsers.filter(
                          (client) => client.role === "USER"
                      ).length
                    }
                  </Text>
                  <Text m={1} textAlign={"center"} className={'text-xs  mx-auto'}>Active</Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex
                alignItems={"center"}
                w={"100%"}
                my={0}
                justifyContent={"space-between"}
                className={'m-1 p-1 flex flex-col'}
            >
              <CircularProgress value={59} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.clients.filter(
                          (client) => client.smsVerificationID !== null && client.role==="USER"
                      ).length
                    }
                  </Text>
                  <Text m={1} textAlign={"center"} className={'text-xs  mx-auto'}>Verified</Text>
                </CircularProgressLabel>
              </CircularProgress>

            </Flex>
            <Flex
                alignItems={"center"}
                w={"100%"}
                my={0}
                justifyContent={"space-between"}
                className={'m-1 p-1 flex flex-col'}
            >
              <CircularProgress  value={59} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.clients.filter(
                          (client) => client.role==="Client"
                      ).length
                    }
                  </Text>
                  <Text m={1} w={"50%"} textAlign={"center"} className={'text-xs  mx-auto'}>Total</Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </Box>
        </Flex>
        <Flex
            bgColor={"whitesmoke"}
            m={1}
            className={'flex flex-col h-fit'}
            justifyContent={"space-around"}
            boxShadow={'lg'}
        >

          <Text
              w={"100%"}
              fontSize={"xl"}
              className={'m-0 p-0 flex justify-center align-items-center'}
              color={"blue.800"}
          >
            Administrator{" "}
          </Text>
          <Box w={"100%"} p={1}  className={'flex flex-row'} bgColor={'whitesmoke'}>
            <Flex alignItems={"center"} w={"100%"}   my={0} justifyContent={"space-between"}  className={'m-1 p-1 flex flex-col'} >
              <CircularProgress  value={(adminclients.activeUsers.filter(
                    (client) =>client.activeRole === "Admin").length / adminclients.activeUsers.filter(client => client.activeRole === "admin").length)*100} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.activeUsers.filter(
                          (client) => client.activeRole === "Admin"
                      ).length
                    }
                  </Text>
                  <Text m={1} w={"50%"} textAlign={"center"} className={'text-sm  mx-auto'}> Active</Text>
                </CircularProgressLabel>
              </CircularProgress>


            </Flex>
            <Flex alignItems={"center"} w={"100%"} my={0} justifyContent={"space-between"}  className={'m-1 p-1 flex flex-col'} >
              <CircularProgress value={(adminclients.clients.filter(
                  (client) => client.smsVerificationID !== null&&client.role==="ADMIN"
              ).length/ adminclients.clients.filter(
                  (client) => client.role === "ADMIN"
              ).length)*100} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.clients.filter(
                          (client) => client.smsVerificationID !== null&&client.role==="ADMIN"
                      ).length
                    }
                  </Text>
                  <Text m={1} w={"50%"} textAlign={"center"} className={'text-sm  mx-auto'}>  Verified</Text>
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
            <Flex alignItems={"center"} w={"100%"} my={0} justifyContent={"space-between"} className={'m-1 p-1 flex flex-col'} >
              <CircularProgress value={(adminclients.clients.filter(
                  (client) => client.activeRole === "Admin"
              ).length/ adminclients.clients.filter(
                  (client) => client.activeRole === "Admin"
              ).length)*100} size='2.3em' thickness='2px' >
                <CircularProgressLabel className={'w-1/2'}>
                  <Text fontSize={"2xl"} color={'blue.700'} borderBottomStyle={'solid'} borderBottomWidth={'1px'} borderBottomColor={'blue.800'} className={'mx-auto my-1 px-1 w-1/2'} >
                    {
                      adminclients.clients.filter(
                          (client) => client.activeRole === "Admin"
                      ).length
                    }
                  </Text>
                  <Text m={1} w={"50%"} textAlign={"center"} className={'text-sm  mx-auto'}>Total</Text>
                </CircularProgressLabel>
              </CircularProgress>

            </Flex>
          </Box>
        </Flex>

      </Box>
      <Flex flexDir={"row"} w="100%" m={1} h={'100%'} flexDirection={"column"}>

        <Text
          bgColor={"blue.800"}
          w={"100%"}
          m={0}
          top={0}
          fontSize={"xl"}
          color={"white"}
        >
          Ticket Statistics
        </Text>

        <Box className={'grid grid-cols-3 w-full overflow-hidden h-full'} >
          <Box className={'col-span-2'} >
            <HomeLineChart tickets={admintickets.tickets} start={todayDate} labels={['Open', 'Close','Resolved']} status={['1','2','3']} />
          </Box>
          <Flex flexDir={"column"}>
            <Text>Ticket Services</Text>
            <Accordion
              flexDir={"column"}
              h={"100%"}
              w={'100%'}
              color={"white"}
              allowToggle
              overflowY={'hidden'}
            >
              {ticketDataServices.map((services, index) => (
                <AccordionItem  className={'w-full  m-0 p-0'} overflowY={'scroll'}  key={index}>
                  <AccordionButton color={'gray.800'} _hover={{bg:"gray.200"}} _expanded={{ bg: 'gray.200', color: 'gray.800' }} bgColor={'whitesmoke'}  className={'flex justify-between'}>
                    <Box className={'m-0 p-0'} fontFamily={'Roboto'}
                         fontWeight={'bold'}>{services.category}</Box>
                    <AccordionIcon color={'gray.800'} />
                  </AccordionButton>
                  {services.services.map((service, index) => (
                    <AccordionPanel
                                    className={'flex h-fit m-0  p-0 flex-row justify-between w-full'} color={'gray.600'}  key={index} fontSize={"md"}
                                    alignItems={"center"}
                                    bgColor={'gray.100'}

                    >
                    <Box textAlign={"start"} className={'px-1'} >
                    <Text m={0} p={0} fontSize={"sm"} >
                  {service}
                    </Text>
                    </Box>
                    <Flex p={0} className={'flex-row align-items-center'}  h={"100%"} borderLeft={"solid 1px gray"}>
                    <Text className={'text-xl mx-1 my-0'} p={0}>{
                      admintickets.tickets.filter(ticket=>ticket.service===service).length
                    }</Text>
                    <Text className={'mx-1 text-xs p-0 my-0'} > tickets</Text>
                    </Flex>
                    </AccordionPanel>
                  ))}
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
const ticketDataServices = [
  {
    category: "IT Desktop Support",
    services: [
      "Software Installation",
      "Hardware Installation",
      "Software Upgrades",
      "Virus removals",
    ],
  },
  {
    category: "IT Infrastructure Support",
    services: [
      "Network Connectivity installations",
      "Network Connectivity Support",
      "WIFI Troubleshooting",
      "WIFI Performance Improvement",
      "Audio Visual Installations",
      "Audio Visual Support",
      "Video Conferencing system installations",
      "Video Conferencing system Support",
    ],
  },
  {
    category: "IT Hardware and Software Sales",
    services: ["New Laptop", "New Hardware Part", "New Desktop", "Software"],
  },
];
const StatusPicker = ['Open', 'Closed', 'Resolved', 'Escalated'].map(
    item => ({ label: item, value: item })
);
export default AdminHome;
