import React, { useState } from "react";
import {
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Tag,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Icon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { TagInput } from "rsuite";
import { BackArrow, FilePdfO, PlusCircle } from "@rsuite/icons/es/icons/legacy";
import "./adminNewTicket.scss";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { server_URL } from "../../../../serverURL";
import axios from "axios";

const validationSchema = Yup.object().shape({
  sender: Yup.object().required("Required !!"),
  priority: Yup.number().required("Required !!"),
  status: Yup.number().required("Required !!"),
  subject: Yup.string().required("Required !!"),
});

function AdminNewTicket() {
  const navigate = useNavigate();
  const [addCC, setAddCC] = useState(false);
  const adminClients = useSelector((state) => state.adminclients);
  const agents = adminClients.clients.filter(
    (client) => client.role === "AGENT"
  );
  const clients = adminClients.clients.filter(
    (client) => client.role === "USER"
  );
  const [attached, setAttached] = useState(false);
  const [files, setFiles] = useState([]);

  const fileUpload = async (e) => {
    setFiles(e.files.map((file) => file));
    setAttached(true);
    return true;
  };
  const onTemplateRemove = (file, callback) => {
    callback();
  };

  const itemTemplate = (file, props) => {
    return (
      <Box
        className="flex align-items-center overflow-hidden align-items-center m-1 flex-row"
        boxShadow={"md"}
        bgColor={"white"}
      >
        <Box
          className="flex align-items-center flex-row"
          style={{ width: "100%" }}
        >
          <Box className={"m-1"}>
            {" "}
            {file.name.split(".")[1] === "pdf" ? (
              <Icon
                as={FilePdfO}
                color={"orange.500"}
                className={"text-4xl p-1"}
              />
            ) : (
              <img
                alt={file.name}
                role="presentation"
                src={file.objectURL}
                width={50}
              />
            )}
          </Box>
          <Box className="flex flex-column text-left text-sm w-full ">
            <Text className={"mx-1 my-0 w-full p-0"}>
              {file.name.length < 20
                ? `${file.name}`
                : `${file.name.split(".")[0].slice(0, 20)}... .${
                    file.name.split(".")[1]
                  }`}
            </Text>
            <Text className={"mx-1 my-0 p-0"}>
              {new Date().toLocaleDateString()}
            </Text>
            <Tag
              size={"sm"}
              bgColor={attached ? "whatsapp.500" : "orange.300"}
              className={"my-2 w-auto mx-auto"}
            >
              {attached ? "Attached" : "Not Attached"}
            </Tag>
          </Box>
        </Box>
        <Box>
          <CloseButton
            type="button"
            icon="pi pi-times"
            className="p-button-outlined p-button-rounded p-button-danger ml-auto"
            onClick={() => onTemplateRemove(file, props.onRemove)}
          />
        </Box>
      </Box>
    );
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <Box
        className={className}
        style={{
          backgroundColor: "whitesmoke",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        <Box disabled={true}>{uploadButton}</Box>
        {cancelButton}
      </Box>
    );
  };
  const onTemplateClear = () => {
    setAttached(false);
    setFiles([]);
  };
  const RemoveAttachedFile = (e) => {
    setFiles(files.filter((file) => file.name !== e.file.name));
  };
  const createTicket = async (values) => {
    const config = {
      url: server_URL + "ticket",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: values,
    };
    await axios(config)
      .then((response) => console.log(response))
      .catch((err) => console.log(err));
  };
  return (
    <Box className={"h-full m-0 p-0"}>
      <Box className={"flex flex-col h-full"} boxShadow={"md"}>
        <Box className={"w-full p-2 flex flex-row"} bgColor={"white"}>
          <Box className={"flex w-fit align-items-start m-2"}>
            <Button
              size={"sm"}
              onClick={() => navigate(-1)}
              leftIcon={<BackArrow />}
            >
              Back
            </Button>
          </Box>
          <Text className={"my-auto mx-auto p-0 w-full text-center"}>
            {" "}
            New Ticket{" "}
          </Text>
        </Box>
        <Box className={"flex-grow-1 h-full"}>
          <Formik
            initialValues={{
              subject: "",
              sender: "",
              agent: "",
              service: "",
              description: "",
              status: "",
              priority: "",
              cc_emails: [],
              isEscalated: false,
              deleted: false,
              agentName: "",
              companyID: "",
              files: files,
            }}
            onSubmit={(values) => {
              values.files = files;
              createTicket(values);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              isSubmitting,
              errors,
              setValues,
              setFieldValue,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
            }) => (
              <Box
                as={"form"}
                onSubmit={handleSubmit}
                className={
                  "p-0 relative w-full h-full grid grid-cols-12 overflow-y-hidden overflow-x-hidden  mx-auto"
                }
                boxShadow={"md"}
              >
                <Box
                  className={
                    "p-1 relative col-span-9  h-full flex flex-col overflow-y-hidden "
                  }
                >
                  <Box
                    className={"m-1 p-1 flex flex-col  mx-0 w-full h-full"}
                    borderRadius={"5px"}
                    bgColor={"white"}
                  >
                    <Box className={" flex flex-col flex-grow-1"}>
                      <Box className={"flex flex-col w-full justify-start"}>
                        <Text className={"m-1 p-0 text-start w-auto"}>
                          Subject:
                        </Text>
                        <Input
                          onChange={handleChange}
                          value={values.subject}
                          onBlur={handleBlur}
                          name={"subject"}
                          size={"md"}
                          className={"m-0 px-3 w-1/2"}
                        />
                        {errors.subject && touched.subject && (
                          <Text
                            className={"text-sm text-start"}
                            color={"red.500"}
                          >
                            {errors.subject}
                          </Text>
                        )}

                        <Box>
                          {addCC ? (
                            <Box className={"flex flex-col align-items-start"}>
                              <Button
                                leftIcon={<PlusCircle />}
                                onClick={() => setAddCC(false)}
                                className={"align-self-start"}
                                bgColor={"transparent"}
                                color={"gray"}
                                _hover={{
                                  bgColor: "transparent",
                                  color: " blue.400",
                                }}
                              >
                                Add CC
                              </Button>
                            </Box>
                          ) : (
                            <Box className={"flex flex-col align-items-start"}>
                              <Text className={"text-start mx-1 mt-2"}>
                                CC:
                              </Text>
                              <Input
                                as={TagInput}
                                onChange={(e) => setFieldValue("cc_emails", e)}
                                value={values.cc_emails}
                                name={"cc_emails"}
                                trigger={["Enter", "Space", "Comma"]}
                                placeholder="Enter email and press ENTER"
                                className={"p-1 text-start"}
                              ></Input>
                              <Button
                                size={"sm"}
                                className={"align-self-start p-0 mx-1"}
                                onClick={() => setAddCC(true)}
                                bgColor={"transparent"}
                                color={"gray"}
                                _hover={{
                                  bgColor: "transparent",
                                  color: " blue.400",
                                }}
                              >
                                Hide CC:
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box className={"flex flex-row w-full justify-between"}>
                        <FormControl
                          className={"flex my-1 w-full flex-col"}
                          isRequired
                        >
                          <FormLabel className={"m-1 p-0 text-start w-auto"}>
                            {" "}
                            Status:{" "}
                          </FormLabel>
                          <Box className={"flex flex-col"}>
                            <Dropdown
                              display={"chip"}
                              placeholder={"Set Status"}
                              name={"status"}
                              showClear
                              onChange={(e) => setFieldValue("status", e.value)}
                              onBlur={handleBlur}
                              optionLabel={"label"}
                              value={values.status}
                              options={statuses}
                              className={"w-full text-sm"}
                            ></Dropdown>
                            {errors.status && touched.status && (
                              <Text
                                className={"text-sm text-start"}
                                color={"red.500"}
                              >
                                {errors.status}
                              </Text>
                            )}
                          </Box>
                        </FormControl>
                        <FormControl
                          className={"flex  m-1 w-full flex-col"}
                          isRequired
                        >
                          <FormLabel className={"m-1 p-0 text-start w-auto"}>
                            {" "}
                            Priority:{" "}
                          </FormLabel>
                          <Box className={"flex flex-col"}>
                            <Dropdown
                              placeholder={"Set Priority"}
                              name={"priority"}
                              showClear
                              onChange={(e) =>
                                setFieldValue("priority", e.value)
                              }
                              onBlur={handleBlur}
                              optionLabel={"label"}
                              value={values.priority}
                              options={prioprities}
                              className={"w-full text-sm"}
                            ></Dropdown>
                            {errors.priority && touched.priority && (
                              <Text
                                className={"text-sm text-start"}
                                color={"red.500"}
                              >
                                {errors.priority}
                              </Text>
                            )}
                          </Box>
                        </FormControl>
                        <FormControl
                          className={"flex m-1 w-full flex-col"}
                          isRequired
                        >
                          <FormLabel className={"m-1 p-0 text-start w-auto"}>
                            {" "}
                            Client:{" "}
                          </FormLabel>
                          <Dropdown
                            placeholder={"Select a client"}
                            name={"sender"}
                            showClear
                            onChange={(e) => setFieldValue("sender", e.value)}
                            onBlur={handleBlur}
                            value={values.sender}
                            optionLabel={"email"}
                            options={clients}
                            className={"w-full text-sm"}
                          ></Dropdown>
                          {errors.sender && touched.sender && (
                            <Text
                              className={"text-sm text-start"}
                              color={"red.500"}
                            >
                              {errors.sender}
                            </Text>
                          )}
                        </FormControl>
                        <FormControl className={"flex m-1 w-full flex-col"}>
                          <FormLabel className={"m-1 p-0 text-start w-auto"}>
                            {" "}
                            Agent:{" "}
                          </FormLabel>
                          <Dropdown
                            placeholder={"Select an agent "}
                            name={"agent"}
                            showClear
                            onChange={(e) => setFieldValue("agent", e.value)}
                            onBlur={handleBlur}
                            optionLabel={"email"}
                            value={values.agent}
                            options={agents}
                            className={"w-full text-sm"}
                          ></Dropdown>
                          {errors.agent && touched.agent && (
                            <Text className={"text-sm text-start"}>
                              {errors.agent}
                            </Text>
                          )}
                        </FormControl>
                      </Box>
                      <FormControl
                        className={"flex-grow-1 flex flex-col py-2 "}
                        isRequired
                      >
                        <FormLabel className={"m-1 p-0  text-start w-auto"}>
                          {" "}
                          Description:{" "}
                        </FormLabel>
                        <GrammarlyEditorPlugin
                          clientId="client_ULQrsoVcDq4MN25KgrpwHr"
                          className={"w-full h-full flex"}
                        >
                          <Textarea
                            name={"description"}
                            height={"100%"}
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={"flex-grow-1 h-full"}
                          />
                        </GrammarlyEditorPlugin>
                      </FormControl>
                    </Box>
                    <Box className={"m-1 flex flex-row justify-end"}>
                      <Button
                        size={"md"}
                        onClick={() => navigate(-1)}
                        className={"mx-2"}
                      >
                        Close
                      </Button>
                      <Button
                        type={"submit"}
                        size={"md"}
                        bgColor={"blue.800"}
                        color={"white"}
                        className={"mx-2"}
                      >
                        Create
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box
                  className={"mx-1 my-2 col-span-3 h-full w-full"}
                  boxShadow={"md"}
                  borderRadius={"md"}
                  bgColor={"whitesmoke"}
                >
                  <Text className={"p-1"} bgColor={"white"} color={""}>
                    Attachments
                  </Text>
                  <FileUpload
                    onRemove={(e) => RemoveAttachedFile(e)}
                    auto
                    onClear={onTemplateClear}
                    headerTemplate={headerTemplate}
                    contentStyle={{ backgroundColor: "whitesmoke" }}
                    accept="image/*,.pdf"
                    itemTemplate={itemTemplate}
                    customUpload
                    uploadOptions={{ label: "Attach" }}
                    uploadHandler={fileUpload}
                    maxFileSize={2000000}
                    multiple
                    className={"text-sm h-full"}
                    emptyTemplate={
                      <Box className="m-0 p-1 h-1/3">
                        Drag and drop files to here to upload.
                      </Box>
                    }
                  ></FileUpload>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

const files = [
  { filename: "INage.pg", url: "eetwrtwrwter.vom" },
  { filename: "INaplpge.pg", url: "eetwrtwrwter.vom" },
];

const statuses = [
  { label: "Open", value: 1 },
  { label: "Pending", value: 1 },
  { label: "Closed", value: 1 },
  { label: "Resolved", value: 1 },
  { label: "Escalated", value: 1 },
];
const prioprities = [
  { label: "Low", value: 1 },
  { label: "Medium", value: 2 },
  { label: "High", value: 3 },
  { label: "Urgent", value: 4 },
];
export default AdminNewTicket;
