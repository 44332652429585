import {createSlice} from "@reduxjs/toolkit";

const AdminTicketSlice = createSlice({
  name: "adminTickets",
  initialState: {
    tickets: [],
    currentTicket:null,
    ticketCount:0
  },
  reducers: {
    addTickets(state, action) {
      const tickets = action.payload;
      console.log("Adding  admin tickets ", tickets);
      Object.keys(tickets).forEach((key) => {
        if (tickets[key] !== state.tickets[key]) {
          state.tickets = [...state.tickets, tickets[key]];
        }
      });
    },
    removeTickets(state, action) {
      state.tickets = [];
    },
    addTicket(state, action) {
      console.log("Add ticket to redux soter")
      const ticket = action.payload;
      state.tickets = [...state.tickets, ticket];
    },
    addCurrentTicket(state,action){
      console.log(action.payload)
      state.currentTicket = action.payload
    },
    removeCurrentTicket(state,action){
      state.currentTicket =[]
    },
    addMessage(state,action){
      const message= action.payload
      console.log(action.payload)
      state.currentTicket.conversation.messages= [...state.currentTicket.conversation.messages,message]
    },
    updateAgent(state, action) {
      state.currentTicket.agent = action.payload
    },
    updateStatus (state, action) {
      console.log("Update status ")
      state.currentTicket.status = action.payload
    },
    addNote(state, action) {
      console.log("Add Note ")
      const note = action.payload
      state.currentTicket.ticketNotes =[...state.currentTicket.ticketNotes,note]
    },
    updateTicket(state,action){
       const ticket = action.payload
       console.log(ticket)
    },updateConsultationStatus(state, action) {
      const consultation= action.payload
      if(consultation.consultationId===state.currentTicket.consultation.consultationId){
        state.currentTicket.consultation.finishedAt=consultation.finishedAt
      }
    },
  },
});
export const AdminTicketsActions = AdminTicketSlice.actions;
export default AdminTicketSlice;
