import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, useNumberInput, HStack, Box,
} from '@chakra-ui/react'
import {Button, Input} from "rsuite";

export function MobileSpinner() {
    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
        useNumberInput({
            step: 1,
            defaultValue: 1,
            min: 1,
            max: 20,
        })
    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    return (
        <Box className={'flex justify-center align-middle'} maxW='220px'>
            <Button  {...inc}>+</Button>
            <Input className={'w-fit'} {...input} />
            <Button {...dec}>-</Button>
        </Box>
    );
};
