import {
  Box,
  Button,
  Flex,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { BiPlus } from "react-icons/bi";
import { Table } from "rsuite";

function AgentHome() {
  const { Column, HeaderCell, Cell } = Table;
  return (
    <Box height={"100%"}>
      <Box className={"grid grid-cols-4"}>
        <Stat bgColor={"white"} boxShadow={"md"} m={1} p={2} height={"auto"}>
          <StatNumber>3</StatNumber>
          <StatLabel className={"text-xs"}>Open Tickets</StatLabel>
        </Stat>
        <Stat boxShadow={"md"} bgColor={"white"} m={1} p={2} height={"auto"}>
          <StatNumber>3</StatNumber>
          <StatLabel className={"text-xs"}>Closed Tickets</StatLabel>
        </Stat>
        <Stat bgColor={"white"}  boxShadow={"md"} m={1} p={2} height={"auto"}>
          <StatNumber>3</StatNumber>
          <StatLabel className={"text-xs"}>Pending Tickets</StatLabel>
        </Stat>
        <Stat bgColor={"white"} boxShadow={"md"} m={1} p={2} height={"auto"}>
          <StatNumber>3</StatNumber>
          <StatLabel> Finished Assessments </StatLabel>
        </Stat>
      </Box>
      <Flex justifyContent={"flex-start"} m={2}>
        <Button leftIcon={<BiPlus />} m={3} size={"sm"}>
          New Ticket{" "}
        </Button>
        <Button leftIcon={<BiPlus />} m={3} size={"sm"}>
          New Contact{" "}
        </Button>
        <Button leftIcon={<BiPlus />} m={3} size={"sm"}>
          New Company{" "}
        </Button>
      </Flex>
      <Box>
        <Table
          height={400}
          onRowClick={(rowData) => {
            console.log(rowData);
          }}
        >
          <Column width={100} align="center" fixed>
            <HeaderCell>Ticket Id </HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column width={200}>
            <HeaderCell>Full Name</HeaderCell>
            <Cell dataKey="firstName" />
          </Column>

          <Column width={150}>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="lastName" />
          </Column>

          <Column width={200}>
            <HeaderCell>Priority</HeaderCell>
            <Cell dataKey="gender" />
          </Column>

          <Column width={150}>
            <HeaderCell>Service Name</HeaderCell>
            <Cell dataKey="age" />
          </Column>

          <Column width={150}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="postcode" />
          </Column>
          <Column width={150} fixed="right">
            <HeaderCell>...</HeaderCell>

            <Cell>
              {(rowData) => (
                <Box>
                  <Text onClick={() => alert(`id:${rowData.id}`)}> Edit </Text>
                </Box>
              )}
            </Cell>
          </Column>
        </Table>
      </Box>
    </Box>
  );
}

export default AgentHome;
