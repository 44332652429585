import {Avatar, Box, Button, Center, Flex, PinInput, PinInputField, Spinner, Text} from "@chakra-ui/react";
import NavBar from "../../components/navBar";
import Footer from "../../pages/Footer";
import React, {useState} from "react";
import {server_URL} from "../../serverURL";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {ErrorMessage, Form, Formik} from "formik";
import {useSelector} from 'react-redux'
import * as Yup from 'yup'
import {Store} from "react-notifications-component";

const validationSchema =Yup.object().shape({
    otp:Yup.string().min(6,"Check OTP").required("Required")
})
function AgentSMSopt(){
    const navigate= useNavigate()
    const userData = useSelector(state=>state.user)
    const [loading,setLoading]= useState(false)
    const [otp,setOTP] =useState('')
    const verifySMS =async (values) => {
        setLoading(true)
        const data = {
            otp: values.otp,
            email: userData.email
        }
        const config = {
            method: 'POST',
            url: server_URL + '/agents/agentsms',
            headers: {
                Accept: 'application/json'
            },
            data:data
        }
        await axios(config).then(response=>{
            if(response){
                navigate('/agent_email_v')
              }
        }).catch(error=>{
            console.log(error.response.data.data)
            OTPIncorrect(error.response.data.data)
        })
    }
    const OTPIncorrect = (value) => {
        Store.addNotification({
            title: "",
            message: value,
            type: "danger",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
            onRemoval: () => {
                setLoading(false);
            },
        });
    };
    return (
        <Box>
            <NavBar/>
            <Box h={'100vh'} className={'p-5'}>
                <Center borderBottomWidth={'1px'} className={'text-white p-2 mx-auto mt-3 w-50'} h={'fit-content'}><Text className={'text-2xl'} color={'blue.800'}>SMS Verification </Text></Center>
                <Center  className={'row no-gutters h-full  m-0 p-5'}>
                    <Center className={'col-lg-6 col-md-6 col-sm-12'}  justifyContent={'center'} alignItems={'center'}>
                        <Flex flexDir={'column'} m={4} p={3} >
                            <Flex alignItems={'center'}>
                                <Avatar bg={'blue.800'} size={'md'} name='1' m={1}/>
                                <Text m={1}>Complete the registration form </Text>
                            </Flex>
                            <Flex alignItems={'center'} m={1}>
                                <Avatar bg={'green.400'} size={'md'} name='2'/>
                                <Text m={2} textAlign={'start'}>You will receive a One Time Pin to your cellphone. Enter the PIN to confirm your registration </Text>
                            </Flex>
                            <Flex alignItems={'center'} m={1}>
                                <Avatar bg={'blue.800'} size={'md'} name='3'/>
                                <Text m={2}>Confirm your email address </Text>
                            </Flex>
                        </Flex>
                    </Center>
                    <Center  className={'col-lg-6 col-md-6 col-sm-12'}>
                        <Box flexDir={'column'} boxShadow={'md'} className={'w-75'} justifyContent={'center'} alignItems={'center'}>
                            <Formik initialValues={{otp: ''}} onSubmit={values => verifySMS(values)} validationSchema={validationSchema}>
                                {({values,isSubmitting,setFieldValue,errors})=>(
                                    <Form>
        <Box mx={2} my={5}>
            <Text p={5} >Enter the OTP</Text>
            <PinInput onChange={(e)=>setFieldValue("otp",e)}>
                <PinInputField m={.5} color={'blackAlpha.700'}/>
                <PinInputField m={.5} color={'blackAlpha.700'}/>
                <PinInputField m={.5} color={'blackAlpha.700'}/>
                <PinInputField m={.5} color={'blackAlpha.700'}/>
                <PinInputField m={.5} color={'blackAlpha.700'}/>
                <PinInputField m={.5} color={'blackAlpha.700'}/>
            </PinInput>
            {errors.otp?<Text color={'red.500'}  fontStyle={'italic'} m={2}>{errors.otp}</Text>:null}
        </Box>
         <Button m={3} disabled={errors.otp?true:false} disabled={loading||isSubmitting} type={'submit'}>{loading||isSubmitting?<Spinner/>:<Text>Verify</Text>} </Button>
    </Form>
    )}
</Formik>

                        </Box>


                    </Center>
                </Center>
            </Box>

            <Footer/>
        </Box>
    )
}

export default AgentSMSopt