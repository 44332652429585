import { createSlice } from "@reduxjs/toolkit";

const UserWalletSlice = createSlice({
    name: "wallet",
    initialState: {
       transactions:[],
    },
    reducers: {
        addTransactions(state, action) {
            state.transactions= action.payload;
        },
        addTransaction(state,action){
            const transaction = action.payload
            console.log(transaction)
            console.log(state.transactions)
            state.transactions= [...state.transactions,transaction];
            console.log(state.transactions)
        },
        removeTransactions(state, action) {
           state.transactions=[]
        },
    }
});
export const userWalletActions= UserWalletSlice.actions;
export default UserWalletSlice;
