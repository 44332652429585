import React, { useEffect } from "react";
import NavBar from "../components/navBar.js";
import Footer from "./Footer.js";
import ndalaict from "../Assets/ndalaict.png";
import support from "../Assets/support.jpg";
import { FiChevronRight } from "react-icons/fi";
export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("Scroll");
  }, []);
  return (
    <div className="enduser_page">
      <NavBar />
      <div className="body">
        <div className="heading">
          <div className="">
            <h3 style={{ fontFamily: "RobotoCondensedRegular" }}>
              END-USER SUPPORT
            </h3>
            <img src={support} alt="" />
          </div>
          <hr />
          <div className="list">
            <div className="Card">
              <FiChevronRight fontSize={"2rem"} />
              <h5>SOFTWARE AND HARDWARE INSTALLATIONS</h5>
            </div>
            <div className="Card">
              <FiChevronRight fontSize={"2rem"} />
              <h5>SOFTWARE UPGRADES</h5>
            </div>
            <div className="Card">
              <FiChevronRight fontSize={"2rem"} />
              <h5>VIRUS REMOVALS</h5>
            </div>
            <div className="Card">
              <FiChevronRight fontSize={"2rem"} />
              <h5>... AND ALL OTHER IT RELATED ISSUES</h5>
            </div>
          </div>
          <div className="_power">
            <div className="text">
              <p>
                To learn more about our solutions visit our company website{" "}
              </p>
            </div>
            <div className="ndalaict">
              <div className="logo">
                <img src={ndalaict} alt="" />
              </div>
              <div className="link">
                <div className="site">
                  <a
                    target="_blank"
                    href="https://www.ndalaict.com"
                    rel="noreferrer"
                    style={{ color: "silver" }}
                  >
                    NdalaICT Solutions{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
