import { createSlice } from "@reduxjs/toolkit";

const SupplierDataSlice = createSlice({
    name: "Suppliers",
    initialState: {
        suppliers: [],
        currentSupplier:[],
    },
    reducers: {
        addSuppliers(state, action) {
            const suppliers = action.payload;
            state.suppliers = suppliers
        },
        removeSuppliers(state, action) {
            state.supplieirs= [];
        },
        addSupplier(state,action){
            state.suppliers= [action.payload,...state.suppliers]
        },
        setCurrentSupplier(state,action){
            const newSupplier = action.payload;
            state.currentSupplier=newSupplier;
        }
    },
});
export const SuppliersActions =SupplierDataSlice.actions;
export default SupplierDataSlice;
