
import React, {ReactNode, useEffect, useState} from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import{
    Stack,
    useColorMode,
    Center,
    StatGroup,
    Stat,
    StatLabel,
    StatNumber,
    Spacer,
    AvatarBadge
} from "@chakra-ui/react";
import {
  MoonIcon,
  NotAllowedIcon,
  SettingsIcon,
  SunIcon,
} from "@chakra-ui/icons";
import {
  BiBell,
  BiLogOut,
  BiMoney,
  BiNotification,
  BiPlus,
  BiPulse,
  BiUser,
} from "react-icons/bi";
import {useDispatch, useSelector} from "react-redux";
import AgentHome from "./AgentHome";
import {Link, Outlet, useNavigate} from "react-router-dom";
import { io } from "socket.io-client";
import {server_URL} from "../../serverURL";
import {Store} from "react-notifications-component";
import {userActions} from "../DataStore/UserData";

const LinkItems = [
  { name: 'Home', icon: FiHome ,path:'/agent/home'},
  { name: 'Tickets', icon: FiTrendingUp,path:'/agent/tickets/all' },
  { name: 'Settings', icon: FiCompass,path:'/agent/settings' },
];
export default function AgentInterface({children}) {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [activeTab,setActiveTab]= useState('Home')
  const userData = useSelector((state) => state.user);
  const [agentSocket, setagentSocket] = useState(() =>
      io(server_URL, {
        query: {
          email: userData.email,
          role: "AGENT",
        },
        extraHeaders: {
          "Access-Control-Allow-Origin": "*",
        },
      })
  );

  const Message = ({ message, status }) => {
    Store.addNotification({
      message: message,
      type: status,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
      onRemoval: () => {
        // setloading(false);
        // navigate("/login")
      },
    });
  };
 useEffect(()=>{
   agentSocket.on("connect", () => {
       dispatch(userActions.setActive(true));
       agentSocket.emit("agent:");
       Message({ message: "User connected", status: "success" });
   });
   agentSocket.on("$techresq:user_profile",(data)=>{
     console.log("Agent Profile",data)
   })
   agentSocket.on("disconnect", (reason, details) => {
     Message({ message: "User disconnected", status: "danger" }); // '{"code":1,"message":"Session ID unknown"}'
   });
   return ()=>{
       agentSocket.disconnect()
   }
 },[])
  //console.log(userData.isActive)
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
      <Box minH="100vh" fontFamily='Roboto Condensed' bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} user={userData} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Outlet/>
        </Box>
      </Box>
  );
}
const SidebarContent = ({ onClose, ...rest }) => {
  return (
      <Box
          transition="3s ease"
          bg={useColorModeValue('white', 'gray.900')}
          borderRight="1px"
          borderRightColor={useColorModeValue('gray.200', 'gray.700')}
          w={{ base: 'full', md: 60 }}
          pos="fixed"
          h="full"
          {...rest}>
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Text fontSize="2xl" fontFamily='Roboto Condensed' fontWeight="bold">
            TechResQ Agent
          </Text>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {LinkItems.map((link) => (
            <NavItem key={link.name} path={link.path} icon={link.icon} >
              {link.name}
            </NavItem>
        ))}
      </Box>
  );
};

const NavItem = ({ icon, path, children, ...rest }) => {
  return (
      <Link to={path} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
            align="center"
            p="2"
            mx="4"
            borderRadius="sm"
            role="group"
            cursor="pointer"
            _hover={{
              bg: 'blue.700',
              color: 'white',
            }}
            {...rest}>
          {icon && (
              <Icon
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: 'white',
                  }}
                  as={icon}
              />
          )}
          {children}
        </Flex>
      </Link>
  );
};
const MobileNav = ({ onOpen,user, ...rest }) => {

    const navigate= useNavigate();
  return (
      <Flex
          ml={{ base: 0, md: 60 }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          bg={useColorModeValue('white', 'gray.900')}
          borderBottomWidth="1px"
          borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
          justifyContent={{ base: 'space-between', md: 'flex-end' }}
          {...rest}>
        <IconButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu />}
        />

        <Text
            display={{ base: 'flex', md: 'none' }}
            fontSize="2xl"
            fontFamily='Roboto Condensed'
            fontWeight="bold">
          TechResQ Agent
        </Text>
        <HStack spacing={{ base: '0', md: '6' }}>
          <IconButton
              size="lg"
              variant="ghost"
              aria-label="open menu"
              icon={<FiBell />}
          />
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none'}}>
                <HStack>
                  <Avatar size={'sm'} src={user.avatarDestination}/>
                  <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                    <Text fontSize="sm">{user.fullName}</Text>
                    <Text fontSize="xs" color="gray.600">
                        {user.email}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                  bg={useColorModeValue('white', 'gray.900')}
                  borderColor={useColorModeValue('gray.200', 'gray.700')}>
                <MenuItem onClick={()=>navigate('/agent/me')}>Profile</MenuItem>
                <MenuItem onClick={()=>navigate('/agent/settings')}>Settings</MenuItem>
                <MenuDivider />
                <MenuItem onClick={()=>navigate('/login')}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
  );
};