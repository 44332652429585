import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  IconButton, Img,
  Input, InputGroup, InputLeftElement, InputRightElement, PinInput,
  PinInputField, Spinner,
  Text, Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState, useRef } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import { server_URL } from "../serverURL";
import otp from "../Assets/otp.gif"
import {CheckIcon, EditIcon} from "@chakra-ui/icons";
import {userActions} from "../Portals/DataStore/UserData";
import {useFormik} from "formik";
import * as Yup from 'yup'

const START_HOURS = 0;
const START_MINUTES = 1;
const START_SECOND = 0;
const START_DERATION = 60 * 1;
function VerificationPage() {
  const userData = useSelector((state) => state.user);
  const [currentMinutes, setMinutes] = useState(START_MINUTES);
  const [currentSeconds, setSeconds] = useState(START_SECOND);
  const [isStop, setIsStop] = useState(false);
  const [duration, setDuration] = useState(START_DERATION);
  const [otp_value, setotp_value] = useState("");
  const [otp_state, setotp_state] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState(userData.phone);
  const [resend, setResend] = useState(false);
  const countdownRef = useRef(null);
  const [verified, setverified] = useState(false);
  const dispatch = useDispatch();
  const [changingPhone,setChangingPhone]= useState(false)

  const navigate = useNavigate();
  //   useEffect(() => {
  //       if(userData.email===''){
  //         navigate('/signup')
  //   }
  //   }, [userData])
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const verify = async () => {
    setisLoading(true);
    const email = sessionStorage.getItem("Session-email");
    const phone = sessionStorage.getItem("Session-phone");
    console.log("USER EMAIL ", email);
    const data = {
      otp: otp_value,
      email: email,
      phoneNumber: phone,
    };

    const config = {
      method: "post",
      url: server_URL + "/users/verification",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      data: data,
    };

    console.log("User Data", data);
    await axios(config)
      .then((response) => {
        console.log(response);
        if (response.status.toString() === "200") {
          setverified(true);
          setisLoading(false);
          sendingMessage("User verified");
          navigate("/verified");
        } else {
          sendingErrorMessage("We could send an email to verify account");
          setverified(true);
        }
      })
      .catch((err) => {
        console.log(err.response.data.title);
        sendingErrorMessage(err.response.data.title);
      });
  };
  const changeNumber = async (values) => {
    const data = {
      email: userData.email,
      phone: values.phone,
    };
    console.log("Changing :", data);
    const config = {
      method: "POST",
      url: server_URL + "/users/change",
      headers: {
        Accept: "application/json",
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        if ((response.status = 200)) {
          sendingMessage(response.data.message);
        } else {
          sendingMessage(response.data.message);
        }
      })
      .catch((error) => {
        sendingErrorMessage(error.message);
      });
  };

  const resendOTP = async () => {
    setisLoading(true);
    const email = userData.email;
    console.log("USER EMAIL ", email);
    const data = {
      email: email,
    };
    const config = {
      method: "post",
      url: server_URL + "/users/resendotp",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${tokenData.token}`,
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.user.email !== "") {
          sendingMessage("SMS sent....");
          setResend(false);
          setotp_state(false);
          setisLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        sendingErrorMessage("We could not resend the OTP");

        seterror(true);
      });
  };

  const sendingMessage = (data) => {
    Store.addNotification({
      title: data,
      type: "success",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },onRemoval: () => {
        setisLoading(false);
        setChangingPhone(false)
      },
    });
  };
  const sendingErrorMessage = (message) => {
    Store.addNotification({
      title: "",
      message: message,
      type: "warning",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: () => {
        setisLoading(false);
        setChangingPhone(false)
      },
    });
  };
  const smsForm=useFormik({
    initialValues:{
      phone:userData.phone
    },
    onSubmit:(values)=>{
      console.log(values)
      setShow(false)
      setResend(true)
      setChangingPhone(true)
      changeNumber(values)
    },
    validationSchema:Yup.object({
      phone: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .length(9, "Ensure you have entered a correct SA cell number")
          .required("Required")
    })
  })
  console.log("Show",show)
  return (
    <div className="verification_page">
      <NavBar />
      <div className="body">
        {!verified ? (
          <div className="otp">
            <div className="">
              <Img m={'auto'} p={0} w={'100px'} src={otp}/>
              <Text m={0} p={0} fontSize={"1.3em"}>We have sent an SMS to: </Text>
              {/*<Box as={"form"} onSubmit={smsForm.handleSubmit} className={'flex flex-row w-3/4  justify-center m-auto p-4 '} alignItems={'center'} >*/}
              {/*  <Box>*/}
              {/*    <Input type={'number'}  color={'black'} size={'md'} placeholder={smsForm.values.phone} name={'phone'} disabled={show?false:true} className={'m-0 p-2  text-center'} onChange={smsForm.handleChange} value={smsForm.values.phone} m={0} p={0}/>*/}
              {/*  </Box>*/}
              {/*  <Tooltip label={!show?"Click to edit number":"Click to save number "}>*/}
              {/*    /!*{show?<Button isDisabled={changingPhone?true:false} leftIcon={changingPhone?<Spinner/>:<CheckIcon/>} boxShadow={'lg'}  className={'mx-2'} size='sm'*!/*/}
              {/*    /!* onClick={()=>setShow(false)}*!/*/}
              {/*    /!*>Edit Number </Button>: <Button isDisabled={changingPhone?true:false} leftIcon={changingPhone?<Spinner/>:<EditIcon/>} boxShadow={'lg'}  type={'none'} className={'mx-2'} size='sm' onClick={()=> {*!/*/}
              {/*    /!*  setShow(true)*!/*/}
              {/*    /!*  setotp_state(true)*!/*/}
              {/*    /!*  console.log("Clicked edit ")*!/*/}
              {/*    /!*}}>Save Number</Button>}*!/*/}
              {/*  </Tooltip>*/}
              {/*</Box>*/}
              <Text fontSize={"1.3em"} m={0} p={0}>{userData.phone}</Text>
              {smsForm.errors.phone?<Text>{smsForm.errors.phone}</Text>:""}
               <Box className={''}>
                  <PinInput otp isDisabled={otp_state} value={otp_value} m={3} size='lg' p={0} variant="flushed" onChange={(e)=>setotp_value(e)}>
                    <PinInputField p={3} m={4} />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />

                    <PinInputField />
                    <PinInputField />
                  </PinInput>
              </Box>
              {resend?
                  <Button bgColor={'#003053'} color={'white'} _hover={{color:'white',bgColor:'blue.700'}} disabled={show?true:false}  onClick={()  => resendOTP()}>Resend{" "}</Button>:
                  <Button disabled={isLoading || otp_value.length !== 6 ? true : false} onClick={() => verify()}> {isLoading?<Spinner/>:<Text>Verify{" "}</Text>}</Button>
              }
            </div>
          </div>
        ) : (
          <Box w={"50%"} display="flex" flexDir={"column"} height="100%">
            <Alert
              margin={"auto"}
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="60%"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="1em">
                Verification successful!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                <Button
                  m={"1em"}
                  onClick={() => navigate("/login")}
                  fontSize="1em"
                  bgColor="blue.900"
                  color="white"
                  borderWidth={".1em"}
                >
                  Login
                </Button>
              </AlertDescription>
            </Alert>
          </Box>
        )}
      </div>
      <Footer />
    </div>
  );
}
export default VerificationPage;
