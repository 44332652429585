import {Box, IconButton, Input, Text, Tooltip} from "@chakra-ui/react";
import {AttachmentIcon} from "@chakra-ui/icons";
import {FiSend} from "react-icons/fi";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import Message from "./Message";
import {useOutletContext} from "react-router-dom";

export default function Conversations({conversation,ticketID,socket,requester}){
    const[newMessage,setnewMessage]= useState('')
    const userData = useSelector(state=>state.user)
    const messageElement= useRef(null)

    const sendNewMessage = () => {
        //send to socket io
        //send to lis
        console.log("Sending Message",newMessage)
        if (newMessage !== "") {
            const message = {
                text: newMessage,
                createdAt: new Date().toJSON().slice(0, 19).replace("T", " "),
                sender: requester,
                attachment_id: "",
                type: "message",
            };
            socket.emit("$techresq:message:add", {
                ticketId: `${ticketID}`,
                useremail: `${requester}`,
                responder:userData.email,
                message,
            });

            setnewMessage("");
        }
    };
    useEffect(() => {
        if (messageElement) {
            messageElement.current.addEventListener("DOMNodeInserted", (event) => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: "smooth" });
            });
        }
    }, []);
    return (
        <Box h={'100%'} className={'grid grid-rows-6 rounded-md w-full'} bgColor={'gray.300'}>
            <Box className={'m-0 p-1 h-fit  flex align-items-center text-lg justify-content-center'} bgColor={'gray.400'}>Conversations</Box>
            <Box ref={messageElement} className={'row-span-6 overflow-y-auto m-0 '} overflowY={'auto'} >
                {conversation.messages.map((message,index)=> {
                    return <Message  message={message}  me={message.sender === userData.email}/>
                })}
            </Box>
            <Box className={'flex h-fit flex-row mx-2 my-2 px-2 py-1 rounded-lg'} bgColor={'white'} >
                <Input value={newMessage} onChange={(e)=>setnewMessage(e.target.value)}  onKeyPress={(e) => {
                    if (e.key === "Enter") sendNewMessage();
                }} border={'1px solid'} borderColor={'gray.200'} placeholder={'Type message'} size={'sm'} className={'m-1 rounded-full'} bgColor={'white'}/>
                <IconButton onClick={()=>sendNewMessage()}  size={'sm'} icon={<FiSend/>} className={'m-1 rounded-full'} bgColor={'gray.300'}  aria-label={'Send Message'}/>
            </Box>
        </Box>
    )
}