import {
  Box,
  Button,
  Center,
  CloseButton,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  SkeletonText,
  Spacer,
  StatHelpText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Textarea,
  textDecoration,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { AddIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ImTicket } from "react-icons/im";
import moment from "moment";
import ticketDataServices from "../../../utils/serviceList";
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";
import { SelectPicker, TagInput, Timeline } from "rsuite";
import { ClientTicketsActions } from "../../DataStore/Client/ClientTickets";
const time = new Date();

const statusDescrptions = {
  1: "Open",
  2: "Pending",
  3: "Resolved",
  4: "Closed",
};
const priorityIdentifier = { 1: "Low", 2: "Medium", 3: "High", 4: "Urgent" };
const priorityIdentifierColor = {
  1: "green.300",
  2: "yellow.300",
  3: "orange.300",
  4: "red.300",
};
const filterData = ["Open", "Closed", "Pending", "Escalated"].map((item) => ({
  label: item,
  value: item,
}));

export default function ClientTicketLists() {
  const [showTicket, setshowTicket] = useState(false);
  const [ticketSearchItem, setticketSearchItem] = useState("");
  const [statusFilterBy, setstatusFilterBy] = useState("All");
  const tickets = useSelector((state) => state.clientTickets).tickets;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ticketChoice, setticketChoice] = useState("");
  const [urgency, seturgency] = useState(1);
  const [description, setDescription] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setsubjectError] = useState("");
  const [priority, setPriority] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [cc_email, setCC_Email] = useState([]);
  const [currentTicket, setcurrentTicket] = useState(0);
  const [fileToUpload, setfileToUpload] = useState([]);
  const [isbillingCardset, setisbillingCardset] = useState(false);
  const [cc_employees, setcc_employees] = useState([]);
  const [ticketChoiceError, setticketChoiceError] = useState("");
  const [ticket_details, setticket_details] = useState([]);
  const [loadingTicket, setloadingTicket] = useState(false);
  const userData = useSelector((state) => state.user);
  const banking = useSelector((state) => state.banking);
  const dispatch = useDispatch();
  const bankDetials = useSelector((state) => state.banking);
  const submitTicket = () => {
    const newTicketData = {
      description: description,
      subject: subject,
      requester: userData.email,
      priority: urgency,
      status: 1,
      fwd_cc: cc_employees,
      createdAt: new Date(),
      service_name: ticketChoice,
    };
    //ticketSubmitted();
    onClose();

  };
  console.log(tickets)
  return (
    <Box h="100%" className="row m-0 no-gutters">
      {showTicket ? null : (
        <Flex
          px={2}
          alignItems={"center"}
          m={2}
          justifyContent={"space-between"}
        >
          <Button
            onClick={() => onOpen()}
            leftIcon={<AddIcon fontSize=".5em" />}
            p={".3em .6em"}
            size="1em"
            borderRadius={"md"}
            variant="solid"
            style={{ backgroundColor: "#033053", color: "white" }}
          >
            <Text fontSize={".9em"}>Create a Ticket</Text>
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent
              p={1}
              fontFamily={"RobotoCondensedRegular"}
              bgColor={"gray.200"}
            >
              <ModalHeader>
                <Box>
                  <Text textAlign={"center"} fontFamily="RobotoCondensedBold">
                    New Ticket
                  </Text>
                </Box>
                <Divider />
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody w={"100%"}>
                <Wrap mx={2}>
                  <Text fontSize={"md"} m={1} size={"sm"} f>
                    From: <strong>{userData.fullName}</strong>
                  </Text>
                </Wrap>
                <Flex
                  display={"flex"}
                  alignItems="center"
                  flexDir={"row"}
                  justifyContent={"space-between"}
                  mx={2}
                  my={3}
                >
                  <Text fontSize={"md"} my={1} w="25%">
                    Subject:
                  </Text>
                  <Input
                    value={subject}
                    fontFamily={"RobotoCondensedRegular"}
                    fontSize={"sm"}
                    variant="unstyled"
                    m={1}
                    p={2}
                    w={"75%"}
                    borderBottom={"solid .1px silver"}
                    color={"black"}
                    bgColor={"white"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                    placeholder={"Enter subject"}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Flex>
                <Flex
                  display={"flex"}
                  alignItems="center"
                  flexDir={"row"}
                  justifyContent={"space-between"}
                  mx={2}
                  my={3}
                >
                  <Text fontSize={"md"} my={1} w="25%">
                    CC:
                  </Text>
                  <Input
                    as={TagInput}
                    trigger={["Enter", "Space", "Comma"]}
                    value={cc_employees}
                    onChange={(e) => setcc_employees(e)}
                    placeholder="e.g john@gmail.com, lerato@gmail.com"
                    h={"auto"}
                    mx={2}
                    p={0}
                    bgColor={"gray"}
                    style={{
                      width: "75%",
                      borderBottom: "solid .1px silver",
                      fontFamily: "RobotoCondensedBold",
                      fontSize: ".7em",
                      background: "white",
                    }}
                  />
                </Flex>

                <Flex flexDir={"row"} w="100%" justifyContent={"flex-start"}>
                  <Text
                    fontSize={"sm"}
                    p={".6em"}
                    textAlign={"start"}
                    my={1}
                    width="25%"
                  >
                    Service Type:
                  </Text>
                  <Menu w="auto">
                    <MenuButton
                      as={Button}
                      leftIcon={<ChevronDownIcon />}
                      bgColor={"white"}
                      borderColor="gray.200"
                      borderBottomWidth={".1em"}
                      mx={2}
                    >
                      <Text fontSize={"sm"}>{ticketChoice}</Text>
                    </MenuButton>
                    <MenuList h={200} w={"100%"} overflow={"auto"}>
                      {ticketDataServices
                        ? ticketDataServices.map((service) => (
                            <>
                              <MenuGroup key={service} title={service.category}>
                                {service.services.map((item) => (
                                  <MenuItem
                                    key={item}
                                    fontSize={"1rem"}
                                    onClick={() => setticketChoice(item)}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                ))}
                              </MenuGroup>
                              <MenuDivider />
                            </>
                          ))
                        : "No tickets"}
                    </MenuList>
                  </Menu>
                </Flex>

                <Flex
                  as={Flex}
                  flexDir={"row"}
                  w="100%"
                  justifyContent={"flex-start"}
                >
                  <Text
                    fontSize={"sm"}
                    p={".6em"}
                    textAlign={"start"}
                    my={1}
                    width="25%"
                  >
                    Description:{" "}
                  </Text>
                  <Textarea
                    variant="unstyled"
                    p={2}
                    mx={2}
                    w={"75%"}
                    color={"gray.500"}
                    backgroundColor="white"
                    borderColor={descriptionError !== " " ? "red" : "gray.100"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></Textarea>
                </Flex>
                <Text mt={1} fontSize={"sm"} fontStyle="italic" color={"gray"}>
                  You can attach your files after creating a ticket
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button colorScheme="blue" onClick={() => submitTicket()}>
                  Submit Ticket
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <InputGroup w="50%">
            <InputLeftElement
              m={3}
              pointerEvents="none"
              height={"2em"}
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              htmlSize={".9em"}
              m={2}
              backgroundColor={"white"}
              fontSize={"md"}
              value={ticketSearchItem}
              onChange={(e) => setticketSearchItem(e.target.value)}
              type="text"
              placeholder="Search ticket by Subject"
            />
            <InputRightElement
              m={2}
              disabled={true}
              onClick={() => setticketSearchItem("")}
              children={ticketSearchItem ? <CloseButton /> : null}
            />
          </InputGroup>
          <SelectPicker
            onClean={() => setstatusFilterBy("All")}
            onSelect={(e) => setstatusFilterBy(e)}
            data={filterData}
            searchable={false}
            placement="auto"
            placeholder="filter By Status:"
            size="md"
            fontSize={".9em"}
            bgColor="red"
            style={{ fontSize: ".9em", zIndex: 0, marginInline: "2em" }}
          />
        </Flex>
      )}
      <Divider m={0} />
      <Box
        className="col-lg-8 col-md-8 col-sm-12 pt-0"
        h="90%"
        overflow={"auto"}
      >
        <Box boxShadow="md" pt={1} bgColor="gray.100">
          {
            <List m={0} p={0}>
              {tickets!==null&&tickets.length!==0?tickets?.tickets
                .filter((item) => {
                  return (
                    item.subject
                      .toUpperCase()
                      .indexOf(ticketSearchItem.toUpperCase()) > -1 &&
                    (statusDescrptions[item.status] ===
                      statusFilterBy.toString() ||
                      statusFilterBy === "All")
                  );
                })
                .map((item) => (
                  <SkeletonText isLoaded={true}>
                    <ListItem key={item.ticketId} m={0} p={0}>
                      <Flex
                        _hover={{
                          backgroundColor: "gray.100",
                          color: "blue.800",
                        }}
                        bgColor={"white"}
                        p={0}
                        borderWidth={"1px"}
                        borderColor={"black.400"}
                        borderRadius={"md"}
                        boxShadow={"md"}
                        my={1}
                      >
                        <Center p={0} m={3}>
                          <Icon
                            fontSize={"2em"}
                            width={"1.3em"}
                            height="1.3em"
                            as={ImTicket}
                            p={2}
                            borderRadius="50%"
                            boxShadow={"md"}
                          />

                        </Center>
                        <Box
                          p={1}
                          w="60%"
                          textAlign="start"
                          _hover={{ color: "white", textDecoration: "none" }}
                          as={Link}
                          to={`/mydashboard/tickets/${item.id}`}
                        >
                          <Text
                            fontSize={"lg"}
                            color="gray.800"
                            fontFamily="RobotoCondensedBold"
                          >
                            Ticket ID:#{item.id}
                          </Text>
                          <Text color="gray" m={0} fontSize={"md"}>
                            {item.subject}
                          </Text>
                          <Text color="gray.700" fontSize="sm" m={0}>
                            Created {moment(`${item.createdAt}`).fromNow()}
                          </Text>
                        </Box>

                        <Flex
                          display={"flex"}
                          justifySelf={"center"}
                          p={2}
                          ml={1}
                          mr={3}
                          flexDir="column"
                          alignItems={"flex-start"}
                        >
                          <Text fontSize={"sm"}>
                            <span>Status:</span>{" "}
                            {statusDescrptions[item.status]}
                          </Text>
                          <Text fontSize={"sm"}>
                            Priority: {priorityIdentifier[item.priority]}{" "}
                          </Text>
                          <Spacer />
                          <Box fontSize={"sm"}>
                            Assigned To:{" "}
                            <Tag>
                              {item.agent ? item.agent : "Not Assigned"}
                            </Tag>
                          </Box>
                        </Flex>
                      </Flex>
                    </ListItem>
                  </SkeletonText>
                )):''}
            </List>
          }
        </Box>
      </Box>
      <Box className="col-lg-4 col-md-4 col-sm-0 h-100">
        <Box boxShadow={"md"} h="90%" overflow={"hidden"} className="pb-12">
          <Text fontFamily={"RobotoCondensedBold"} fontSize={"xl"} m={3}>
            Recent Activities{" "}
          </Text>
          <Divider mx={1} my={0} />
          <Box p={4} w="100%" h={"100%"} overflow={"auto"} textAlign="start">
            <Timeline>
              {tickets!==null&&tickets.length!==0?tickets?.tickets
                .filter(
                  (ticket) =>
                    moment(ticket.updateAt) >= moment(time).subtract(1, "days")
                )
                .map((ticket) => {
                  return (
                    <Timeline.Item>
                      <Text fontSize={"md"}>
                        Ticket #{ticket.id} was logged{" "}
                        {new moment(ticket.createdAt).fromNow()}
                      </Text>{" "}
                    </Timeline.Item>
                  );
                }):''}
            </Timeline>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
