import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { Store } from "react-notifications-component";
import ReCAPTCHA from "react-google-recaptcha";
import { userActions } from "../Portals/DataStore/UserData";
import { server_URL } from "../serverURL";
import { ClientTicketsActions } from "../Portals/DataStore/Client/ClientTickets";
import {ApiRoutes} from "../utils/BackEndApiRputes/ApiRoutes";

const SITE_KEY = "6LcKWmIhAAAAAL_tk2CX7SLsx1LJAieKXKyVBj9K";

function LoginComponent({ showLink, closeModal }) {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [loading, setloading] = useState(false);
  const [isVerified] = useState(false);
  const [clientSocket, setClientSocket] = useState(null);
  const [clientActive, setClientActive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const checkLogin = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    const config = {
      method: "post",
      url:server_URL+"/users/login",
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          const {
            fullName,
            password,
            address,
            email,
            phoneNumber,
            job_title,
            emailConfirmed,
            phoneConfirmed,
            avatarName,
            avatarDestination,
            roles,
            tickets,
            activeRole,
          } = response.data;
          const { token } = response.data;
          const url = avatarDestination;
          dispatch(
            userActions.addUser({
              fullName,
              password,
              address,
              avatarName,
              email,
              activeRole,
              phoneNumber,
              phoneConfirmed,
              emailConfirmed,
              job_title,
              roles,
              tickets,
            })
          );
          dispatch(userActions.addAvatarDestination(url));
          dispatch(ClientTicketsActions.addTickets(tickets));
          sessionStorage.setItem("Session-token", token);
          sessionStorage.setItem("Session-email", email);
          sessionStorage.setItem("Session-phone", phoneNumber);
          setloading(false);
          closeModal();
          //console.log("user role", response);
          setClientActive(true);
          console.log(roles);
          const role = activeRole.toUpperCase();
          console.log(role);
          if (role === "Client".toUpperCase()) {
            navigate("/mydashboard/home", { replace: true });
          } else if (role === "admin".toUpperCase()) {
            navigate("/admin/analytics");
          } else if (role === "agent".toUpperCase()) {
            navigate(`/agent/home`);
          }
        } else if (response.status === 201) {
          console.log(response);
          const { fullName, email, phoneNumber, roles } = response.data;
          console.log({ fullName, email, phoneNumber });
          userDetailsErrorMessage("User has not been  validated ");
          setClientActive(false);
          dispatch(
            userActions.addUser({
              fullName: fullName,
              email: email,
              phoneNumber: phoneNumber,
              roles: roles,
            })
          );
            sessionStorage.setItem("Session-email", email);
            sessionStorage.setItem("Session-phone", phoneNumber);
          navigate("/verifyaccount");
        } else {
          userExitMessage(response.status);
          setClientActive(false);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.status === 401) {
          dispatch(
            userActions.addUser({
              fullName: e.response.data[0].fullName,
              email: e.response.data[0].email,
              phone: e.response.data[0].phone,
              role: e.response.data[0].role,
            })
          );
          sessionStorage.setItem("Session-email", e.response.data);
          userDetailsErrorMessage(e.response.data);
          navigate("/sms");
        }
        else if(e.response?.status === 400){
          userDetailsErrorMessage(e.response.data);
        }else{
          userDetailsErrorMessage(e.message);

        }
      });
  };
  const userExitMessage = (data) => {
    Store.addNotification({
      title: "Sorry...,This user doesnt exist !!!",
      message: data,
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
      onRemoval: () => {
        setloading(false);
      },
    });
  };
  const userDetailsErrorMessage = (data) => {
    Store.addNotification({
      message: data,
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: () => {
        setloading(false);
      },
    });
  };
  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
      recaptchaValue: "",
    },
    onSubmit: (values) => {
      setloading(true);
      checkLogin(values);
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "Required";
      }
      if (!values.password) {
        errors.password = "Required";
      }
      // if (!values.recaptchaValue) {
      //   errors.recaptchaValue = "Required";
      // }
      return errors;
    },
  });
  const handleClickEye = () => {
    setShow(!show);
  };
  // const ConnectSocket = (client_Socket) => {
  //   client_Socket = io(server_URL, {
  //     query: {
  //       email: "vuyanidwt@gmail.com",
  //       role: "USER",
  //     },
  //     extraHeaders: {
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //   });
  //   client_Socket.on("connect", () => {});
  //   client_Socket.on("disconnect", () => {});
  //   setClientSocket(client_Socket);
  //   setClientActive(true);
  // };
  // const DisconnectSocket = (client_Socket) => {
  //   if (client_Socket) {
  //     client_Socket.disconnect();
  //
  //   } else {
  //     console.log("it is not connected");
  //   }
  // };
  return (
    <div className="body">
      <div className="container">
        <Text fontFamily={"RobotoCondensedBold"} fontSize={"1.5em"}>
          Login
        </Text>
        <hr />
        <div className="inner_cards">
          <form onSubmit={loginForm.handleSubmit}>
            <div style={{ width: "100%" }} size="lg" className="">
              <FormLabel fontSize={"md"} htmlFor="email">
                Username :
              </FormLabel>
              <Box w={"100%"}>
                <InputGroup>
                  <Input
                    autoComplete="email"
                    type="email"
                    bgColor={"white"}
                    onBlur={loginForm.handleBlur}
                    onChange={loginForm.handleChange}
                    value={loginForm.values.email}
                    id="email"
                    name="email"
                  />
                </InputGroup>
              </Box>
              {loginForm.touched.email && loginForm.errors.email ? (
                <Text fontSize={"sm"} color="red.500">
                  {loginForm.errors.email}
                </Text>
              ) : null}
            </div>
            <Box style={{ width: "100%" }} className="">
              <FormLabel fontSize="md" htmlFor="password">
                Password :
              </FormLabel>
              <Box w={"100%"}>
                <InputGroup>
                  <Input
                    bgColor={"white"}
                    autoComplete="current-password"
                    onBlur={loginForm.handleBlur}
                    type={show ? "text" : "password"}
                    id="password"
                    onChange={loginForm.handleChange}
                    value={loginForm.values.password}
                    name="password"
                  />
                  <InputRightElement height="100%">
                    <Button m={1} size="sm" onClick={loginForm.handleChange}>
                      {show ? (
                        <ViewOffIcon onClick={handleClickEye} />
                      ) : (
                        <ViewIcon onClick={handleClickEye} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
              {loginForm.touched.password && loginForm.errors.password ? (
                <Text fontSize={"sm"} c color={"red.500"}>
                  {loginForm.errors.password}
                </Text>
              ) : null}
            </Box>
            <Wrap className="forgot" mx={0} my={5}>
              <Flex w={"100%"} flexDir={"column"}>
                <ReCAPTCHA
                  className="recaptcha"
                  name="recaptchaValue"
                  sitekey={SITE_KEY}
                  onChange={(event) =>
                    loginForm.setFieldValue("recaptchaValue", event)
                  }
                  onBlur={loginForm.handleBlur}
                  ref={ref}
                />
                {loginForm.touched.recaptchaValue &&
                loginForm.errors.recaptchaValue &&
                !isVerified ? (
                  <Text fontSize={"sm"} color={"red.500"}>
                    {loginForm.errors.recaptchaValue}
                  </Text>
                ) : null}
              </Flex>
              <Flex
                justifyContent={"flex-start"}
                textAlign={"center"}
                w={"100%"}
              >
                <Link to={"/forgotpassword"}>
                  <Text fontSize={"md"}>Forgot Password?</Text>
                </Link>
              </Flex>
            </Wrap>
            <button
              disabled={loading}
              value="Submit"
              type="submit"
              style={{ margin: ".4em" }}
            >
              {loading ? <Spinner /> : <Text>Submit </Text>}
            </button>
            {showLink ? (
              <Link to="/signup" style={{ margin: "0" }}>
                <Text fontSize={"md"}>I do not have an account</Text>
              </Link>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
}
export default LoginComponent;
