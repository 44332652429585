import React from 'react'
import { Outlet } from 'react-router-dom'
import {Box} from "@chakra-ui/react";

function AgentsInterface() {
  return (
    <Box className={'h-full'}>
        <Outlet/>
    </Box>
  )
}

export default AgentsInterface