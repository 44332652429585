import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FiChevronDown } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function ClientProfileBar() {
  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.notifications);
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuButton transition="all 0.3s" _focus={{ boxShadow: "none" }}>
        <HStack>
          <Avatar size={"sm"} src={user.avatarDestination}>
            <AvatarBadge
              borderColor="papayawhip"
              bg={user.isActive ? "green" : "tomato"}
              boxSize="1.25em"
            />
          </Avatar>
          <VStack
            display={{ base: "none", md: "flex" }}
            alignItems="flex-start"
            spacing="2px"
          >
            <Flex>
              <Text fontSize="sm" mx={2}>
                {user.fullName}
              </Text>
            </Flex>
            <Box>
              <Text fontSize="xs" color="gray.600" mx={2}>
                {user.email}
              </Text>
            </Box>
          </VStack>
          <Box display={{ base: "none", md: "flex" }}>
            <FiChevronDown />
          </Box>
        </HStack>
      </MenuButton>
      <MenuList
        zIndex={2}
        bg={useColorModeValue("white", "gray.900")}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <MenuItem as={Link} to="/mydashboard/profile">
          Profile
        </MenuItem>
        <MenuItem as={Link} to="/mydashboard/settings">
          Settings
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={() => {
            sessionStorage.setItem("Session-token", "");
            sessionStorage.setItem("Session-email", "");
            navigate("/");
          }}
        >
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default ClientProfileBar;
