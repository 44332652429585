import { EmailIcon } from "@chakra-ui/icons";
import {Box, Text, Tag, Icon, Flex, Spacer, Button} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { BsFillEnvelopeAtFill, BsPerson } from "react-icons/bs";
import { TbTicket } from "react-icons/tb";
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import { useSelector } from "react-redux";
import {BiSupport} from "react-icons/bi";
import PlusIcon from "@rsuite/icons/Plus";

function TicketsList(props) {
  const tickets = useSelector((state) => state.admintickets).tickets;
  console.log(tickets)
  const navigate = useNavigate()
  return (
    <Box
      className="p-0 m-0"
      h={"85vh"}
      overflowY="auto"
      overflowX="hidden"
      boxShadow={"lg"}
      bgColor="gray.100"
      borderRadius={"5px"}

    >
      {tickets?tickets
        .filter(
          (ticket) =>
            ticket.subject
              .toUpperCase()
              .indexOf(props.ticketSearch.toUpperCase()) > -1 &&
            (ticket?.status=== props.statusFilter?.toString() ||
              props.statusFilter === null) &&
            (ticket.agent?.toString() === props.agentFilter?.toString() ||
              props.agentFilter === null) &&
            (ticket.priority?.toString() === props.priorityFilter?.key.toString() ||
              props.priorityFilter === null)
        )
        .map((ticket) => {
          console.log("Current Ticket",ticket)
         return  <Box
            key={ticket.id}
            _hover={{ textDecoration: "none", color: "white",backgroundColor: "blue.900" }}
            as={Link}
            to={`/admin/ticket/${ticket.id}`}
            className={'mx-1 my-2'}
            display="flex"
            w="auto"
            flexDir={"row"}
            alignItems="center"
            bgColor="white"
            borderRadius={"3px"}
            color={'blue.800'}
          >
            <Box
              w={"100%"}
              className={'w-full text-start p-2 flex h-full  flex-row justify-between'}
              bgColor={"white"}
              _hover={{ backgroundColor: "#033053" }}
            >
             <Box className={'flex flex-row'}>
               <Box className={"flex m-1 flex-row align-items-center"} color={'gray.500'}>
                 <BsFillEnvelopeAtFill size={60} className={"m-1 rounded"}/>
               </Box>
              <Box className={"m-1"}>
                <Text outline={"none"} m={0} className={'px-0 m-0 text-xl font-bold py-0'}>
                  {ticket.subject}
                </Text>
                <Text fontFamily={"RobotoCondensedRegular"} className={'text-base'} mx={1}>
                  {ticket.sender} Sender
                </Text>
                <Box className={"flex flex-col"}>
                <Text  bgColor={'red.100'} color={'red'} className={'text-sm w-fit px-1 py-0 rounded-sm'} fontFamily='RobotoCondensedLight'>Overdue</Text>
              </Box>

               </Box>

             </Box>
              <Box className={'flex flex-col align-items-center'}>
                <Tag className={'text-xs m-1 px-1 w-fit h-fit '}>Status : Open </Tag>
                <Tag className={'text-xs m-1 px-1 w-fit h-fit '}>Priority: Low</Tag>
              </Box>
              <Box className={'flex flex-col align-bottom h-full bg-red-500'}>
                <Box className={'flex flex-col h-full'}>
                  <Box className={'flex flex-row align-middle justify-center'}>
                    <Box className={'my-1'}>
                      <BiSupport className={"text-3xl "}/>
                    </Box>
                    <Text className={'text-sm m-1 p-0 flex'}>{ticket.agent}</Text>
                  </Box>
                  <Text className={'text-sm m-0 p-0'}>Created: {moment(ticket.createdAt).fromNow().toString()}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        }):<Box className={"flex flex-col h-full w-full justify-center align-middle gap-5"}><Text className={"text-lg"}>No Tickets to Display </Text><Button className={"w-fit mx-auto"} color={"white"} onClick={()=>navigate("/admin/ticket/new/")} bgColor={"#033053"} leftIcon={<PlusIcon/>}>Create New Ticket</Button></Box>}
    </Box>
  );
}

export default TicketsList;
