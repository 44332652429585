import {
    Box, Button, Center, Flex,
    FormControl, FormErrorMessage, FormHelperText, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import {InputGroup} from "rsuite";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {bankingActions} from "../../../DataStore/bankingData";
import {server_URL} from "../../../../serverURL";
import axios from "axios";
import {useDispatch} from "react-redux";
import {Store} from "react-notifications-component";
import message from "../Message";
import {userWalletActions} from "../../../DataStore/userWallet";

let yoco ;
function AddFunds({bankisOpen,bankonClose,user:userData,clientSocket,checkoutId,url,showPopup}){
    const dispatch = useDispatch()
    const [loading,setLoading] =useState(false)
    const checkFunds= async ()=>{
        const config = {
            url:server_URL+'/users/checkfunds',
            method:'POST',
            headers:{
                "Content-Type":'application/json'
            },
            data:{
                checkoutId:checkoutId,
                email: userData.email,
            }
        }
        await axios(config).then(response=>{
            if(response){
                console.log(response)
                if(response.data.status==='completed'){
                    dispatch(userWalletActions.addTransaction(response.data))
                    bankonClose()
                    cardMessage('Funds added','success')
                }else {
                    bankonClose()
                    cardMessage('transaction process was not completed','danger')
                }
            }else{
                bankonClose()
                cardMessage('No response','danger')
            }
        }).catch(error=>{
            bankonClose()
            cardMessage(error.response.data.message,'danger')
        })
    }
    const cardMessage = (message,status) => {
        Store.addNotification({
            message: message,
            type: status,
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
            onRemoval: () => {
            },
        });
    };
    return (
        <Modal isCentered size={'full'}  closeOnOverlayClick={false} isOpen={bankisOpen} onClose={bankonClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <Text  textalign='center' m={'auto'} fontFamily={'Roboto Condensed'}>Add Funds </Text>
                    </Center>
                </ModalHeader>
                <ModalCloseButton bgColor={'red'} />
                <ModalBody>
                    <Box width={'100%'} height={'80vh'} class={'w-full h-full'} >
                        <iframe width={'100%'} height={'100%'} allowFullScreen={true}	 src={url}></iframe>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button color={'white'} size={'lg'} bgColor={'#033053'} onClick={()=>checkFunds()}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
export default AddFunds