import {createSlice} from "@reduxjs/toolkit";

const AgentTicketsSlice=createSlice({
   name:'agentTickets',
    initialState:{
        tickets:[],
        currentTicket:[]
    },
    reducers: {
        addTickets(state, action) {
            state.tickets = action.payload
        },
        removeTickets(state,action){
            state.tickets =[]
        },
        addTicket(state,action){
            const ticket = action.payload
            state.tickets= [...state.tickets,ticket]
        },
        AddCurrentTicket(state,action){
            state.currentTicket= action.payload
        },
        UpdateCurrentTicketStatus(state,action){
            state.currentTicket.status= action.payload
        },
        UpdateCurrentTicketPriority(state,action){
            state.currentTicket.priority= action.payload
        },
        AddAttachmentToCurrentTicket(state,action){
            state.currentTicket.attachments=[...state.currentTicket.attachments,action.payload]
        },
        addTicketMessage(state,payload){
            //
        }
    }

})
export const agentTicketsActions= AgentTicketsSlice.actions;
export default AgentTicketsSlice