import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import { server_URL } from "../serverURL";
import {Store} from "react-notifications-component";
function AccountVerificationQ() {
  const tokenData = useSelector((state) => state.token);
  const userData = useSelector((state) => state.user);
  const [verifyNow, setverifyNow] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    // if (userData.email === "") {
    //   navigate("/signup");
    // }
  }, [tokenData, navigate]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sendVerificationCode = async () => {
    const receiver=sessionStorage.getItem("Session-phone");
    const email=sessionStorage.getItem("Session-email");
    const data = {
      receiver,
      email
    }
    console.log(data)
    const config = {
      method: "post",
      url: server_URL + "/users/resendotp",
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
      },
      data: data,
    };
    await axios(config)
      .then((response) => {
        console.log(response);
        setisLoading(false);
        navigate("/verify");
      })
      .catch((e) => {
        setisLoading(false);
        console.log(e);
      });
  };
  const statusMessage = (data) => {
    Store.addNotification({
      title: "Sorry...,This user doesnt exist !!!",
      message: data,
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  return (
    <div className="accountverificationQuestion">
      <NavBar />
      <div className="body">
        <Center h={"60vh"} flexDir="column">
          <Heading fontSize={"20px"}>
            Your account was registered successfully!!!
          </Heading>
          <Button
            bgColor={"green.300"}
            m={"2em"}
            onClick={() => sendVerificationCode()}
          >
            Verify account now
          </Button>

        </Center>
      </div>
      <Footer />
    </div>
  );
}

export default AccountVerificationQ;
