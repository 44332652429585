import NavBar from "../../components/navBar";
import {Footer} from "rsuite";
import {Box, Center, Flex,Text} from "@chakra-ui/react";
import fingerprint from '../../Assets/fingerprint.json'
import  wait from '../../Assets/wait.svg'
import Lottie from 'lottie-react'
import { useLottie } from "lottie-react";
import './AgentAplicationStatus.css'
import { IoIosCheckmarkCircle} from "react-icons/io";
import {Icon} from "@iconify/react";
import {useSelector} from "react-redux";
function AgentApplicationStatus(){
    const userData=useSelector(state=>state.user)
    const params='Get dat afrom the url'
    const options = {
        animationData: fingerprint,
        loop: true,
        style:{
            height:200,
            border:'solid 1px #033053',
            width:'fit-content'
        }
    };
    const optionsWait = {
        animationData: wait,
        loop: true,
        style:{
            height:50,
            border:'solid 1px #033053',
            width:'fit-content'

        }
    };
    const { View } = useLottie(options);

    return (
        <div>
            <NavBar/>
            <Center h={'80vh'} flexDir={ 'column'}>
                <Text fontSize={'1.5em'} fontWeight={'bold'}> We are now processing your application.
            </Text>
               <Flex w={'75%'}>
                   {<Center m={4} p={5} w={'45%'} borderRight={'solid 1px #033053'}>
                       {View}
                   </Center>}
                   <Box m={'auto'}>
                       <Flex m={3} alignItems={'center'}>
                           <IoIosCheckmarkCircle size={50} style={{color:'green'}} />
                           <Text>
                               Your application has been submitted successfully
                           </Text>
                       </Flex>



                       <Flex m={4}>
                           {userData.idVerified? <IoIosCheckmarkCircle size={50} style={{color:'green'}} />:<svg width="40" height="40" viewBox="0 0 300 300" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                               <g id="wait">
                                   <g id="Ellipse1">
                                       <mask id="path-1-inside-1_4_5" fill="white">
                                           <path
                                               d="M290 150C295.523 150 300.035 145.517 299.667 140.007C297.376 105.673 283.331 73.0532 259.733 47.7325C233.855 19.9656 198.413 3.0415 160.551 0.371568C122.689 -2.29836 85.2231 9.48448 55.7047 33.3449C26.1864 57.2054 6.81106 91.3691 1.48443 128.949C-3.8422 166.53 5.27597 204.732 27.0009 235.856C48.7258 266.979 81.442 288.71 118.555 296.667C155.667 304.624 194.416 298.216 226.991 278.734C256.696 260.968 279.252 233.536 290.995 201.192C292.879 196 289.791 190.44 284.486 188.906L281.774 188.121C276.469 186.586 270.961 189.662 269.015 194.83C258.92 221.633 240.024 244.347 215.277 259.147C187.658 275.665 154.805 281.098 123.339 274.352C91.873 267.606 64.1345 249.181 45.715 222.793C27.2955 196.405 19.5646 164.015 24.0808 132.152C28.597 100.29 45.0244 71.3239 70.0516 51.0938C95.0788 30.8637 126.845 20.8736 158.946 23.1373C191.047 25.401 221.097 39.7501 243.037 63.2923C262.697 84.3871 274.536 111.457 276.785 140.009C277.219 145.515 281.655 150 287.178 150H290Z"/>
                                       </mask>
                                       <path
                                           d="M290 150C295.523 150 300.035 145.517 299.667 140.007C297.376 105.673 283.331 73.0532 259.733 47.7325C233.855 19.9656 198.413 3.0415 160.551 0.371568C122.689 -2.29836 85.2231 9.48448 55.7047 33.3449C26.1864 57.2054 6.81106 91.3691 1.48443 128.949C-3.8422 166.53 5.27597 204.732 27.0009 235.856C48.7258 266.979 81.442 288.71 118.555 296.667C155.667 304.624 194.416 298.216 226.991 278.734C256.696 260.968 279.252 233.536 290.995 201.192C292.879 196 289.791 190.44 284.486 188.906L281.774 188.121C276.469 186.586 270.961 189.662 269.015 194.83C258.92 221.633 240.024 244.347 215.277 259.147C187.658 275.665 154.805 281.098 123.339 274.352C91.873 267.606 64.1345 249.181 45.715 222.793C27.2955 196.405 19.5646 164.015 24.0808 132.152C28.597 100.29 45.0244 71.3239 70.0516 51.0938C95.0788 30.8637 126.845 20.8736 158.946 23.1373C191.047 25.401 221.097 39.7501 243.037 63.2923C262.697 84.3871 274.536 111.457 276.785 140.009C277.219 145.515 281.655 150 287.178 150H290Z"
                                           stroke="url(#paint0_linear_4_5)" stroke-width="40"
                                           mask="url(#path-1-inside-1_4_5)"/>
                                   </g>
                                   <g id="Ellipse2">
                                       <mask id="path-2-inside-2_4_5" fill="white">
                                           <path
                                               d="M240 150C245.523 150 250.052 145.511 249.501 140.016C247.346 118.537 238.281 98.2292 223.529 82.2248C206.508 63.7585 183.161 52.3729 158.13 50.3311C133.099 48.2892 108.216 55.7405 88.4273 71.204C68.6383 86.6675 55.3923 109.011 51.3221 133.793C47.2518 158.575 52.6554 183.982 66.4588 204.962C80.2622 225.943 101.455 240.962 125.824 247.034C150.193 253.105 175.955 249.785 197.988 237.733C217.084 227.288 232.168 210.947 241.08 191.285C243.36 186.255 240.505 180.553 235.272 178.787L234.828 178.637C229.595 176.87 223.977 179.718 221.568 184.688C214.39 199.498 202.749 211.799 188.166 219.775C170.642 229.36 150.154 232.001 130.772 227.172C111.391 222.343 94.5367 210.398 83.5588 193.712C72.5808 177.026 68.2832 156.82 71.5203 137.11C74.7574 117.401 85.2921 99.631 101.031 87.3327C116.769 75.0344 136.559 69.1082 156.466 70.7322C176.374 72.3561 194.941 81.4112 208.478 96.0976C219.744 108.32 226.84 123.697 228.903 140.026C229.596 145.505 234.008 150 239.531 150H240Z"/>
                                       </mask>
                                       <path
                                           d="M240 150C245.523 150 250.052 145.511 249.501 140.016C247.346 118.537 238.281 98.2292 223.529 82.2248C206.508 63.7585 183.161 52.3729 158.13 50.3311C133.099 48.2892 108.216 55.7405 88.4273 71.204C68.6383 86.6675 55.3923 109.011 51.3221 133.793C47.2518 158.575 52.6554 183.982 66.4588 204.962C80.2622 225.943 101.455 240.962 125.824 247.034C150.193 253.105 175.955 249.785 197.988 237.733C217.084 227.288 232.168 210.947 241.08 191.285C243.36 186.255 240.505 180.553 235.272 178.787L234.828 178.637C229.595 176.87 223.977 179.718 221.568 184.688C214.39 199.498 202.749 211.799 188.166 219.775C170.642 229.36 150.154 232.001 130.772 227.172C111.391 222.343 94.5367 210.398 83.5588 193.712C72.5808 177.026 68.2832 156.82 71.5203 137.11C74.7574 117.401 85.2921 99.631 101.031 87.3327C116.769 75.0344 136.559 69.1082 156.466 70.7322C176.374 72.3561 194.941 81.4112 208.478 96.0976C219.744 108.32 226.84 123.697 228.903 140.026C229.596 145.505 234.008 150 239.531 150H240Z"
                                           stroke="url(#paint1_linear_4_5)" stroke-width="44"
                                           mask="url(#path-2-inside-2_4_5)"/>
                                   </g>
                                   <g id="Ellipse3">
                                       <mask id="path-3-inside-3_4_5" fill="white">
                                           <path
                                               d="M190.92 150C195.935 150 200.084 145.902 199.178 140.97C197.473 131.684 193.16 123.005 186.655 115.994C178.077 106.747 166.321 101.085 153.743 100.14C141.166 99.196 128.696 103.04 118.834 110.902C108.971 118.764 102.444 130.063 100.561 142.534C98.6772 155.006 101.577 167.728 108.678 178.152C115.78 188.575 126.558 195.93 138.854 198.742C151.149 201.553 164.053 199.615 174.979 193.313C183.264 188.535 189.948 181.517 194.318 173.149C196.64 168.704 193.887 163.564 189.095 162.083V162.083C184.304 160.602 179.316 163.399 176.547 167.58C173.845 171.66 170.216 175.097 165.907 177.582C158.949 181.595 150.732 182.83 142.902 181.039C135.072 179.249 128.208 174.565 123.686 167.927C119.164 161.289 117.317 153.188 118.517 145.246C119.716 137.304 123.872 130.109 130.153 125.102C136.434 120.096 144.374 117.648 152.384 118.249C160.393 118.85 167.879 122.456 173.342 128.345C176.725 131.992 179.178 136.347 180.554 141.043C181.965 145.855 185.906 150 190.92 150V150Z"/>
                                       </mask>
                                       <path
                                           d="M190.92 150C195.935 150 200.084 145.902 199.178 140.97C197.473 131.684 193.16 123.005 186.655 115.994C178.077 106.747 166.321 101.085 153.743 100.14C141.166 99.196 128.696 103.04 118.834 110.902C108.971 118.764 102.444 130.063 100.561 142.534C98.6772 155.006 101.577 167.728 108.678 178.152C115.78 188.575 126.558 195.93 138.854 198.742C151.149 201.553 164.053 199.615 174.979 193.313C183.264 188.535 189.948 181.517 194.318 173.149C196.64 168.704 193.887 163.564 189.095 162.083V162.083C184.304 160.602 179.316 163.399 176.547 167.58C173.845 171.66 170.216 175.097 165.907 177.582C158.949 181.595 150.732 182.83 142.902 181.039C135.072 179.249 128.208 174.565 123.686 167.927C119.164 161.289 117.317 153.188 118.517 145.246C119.716 137.304 123.872 130.109 130.153 125.102C136.434 120.096 144.374 117.648 152.384 118.249C160.393 118.85 167.879 122.456 173.342 128.345C176.725 131.992 179.178 136.347 180.554 141.043C181.965 145.855 185.906 150 190.92 150V150Z"
                                           stroke="url(#paint2_linear_4_5)" stroke-width="44" stroke-linejoin="round"
                                           mask="url(#path-3-inside-3_4_5)"/>
                                   </g>
                               </g>
                               <defs>
                                   <linearGradient id="paint0_linear_4_5" x1="150" y1="0" x2="150" y2="300"
                                                   gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#529de2"/>
                                       <stop offset="2"/>
                                   </linearGradient>
                                   <linearGradient id="paint1_linear_4_5" x1="150" y1="50" x2="150" y2="250"
                                                   gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#529de2"/>
                                       <stop offset="1"/>
                                   </linearGradient>
                                   <linearGradient id="paint2_linear_4_5" x1="150" y1="100" x2="150" y2="200"
                                                   gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#529de2"/>
                                       <stop offset="1" stop-color="#000407"/>
                                   </linearGradient>
                               </defs>
                           </svg>
                           }
                           <Text mx={2} p={0} color={'whatsapp.500'}>ID/Passport Verification...</Text>
                       </Flex>
                       <Flex m={4}>
                           {userData.idVerified? <IoIosCheckmarkCircle size={50} style={{color:'green'}} />:<svg width="40" height="40" viewBox="0 0 300 300" fill="none"
                                                                                                                 xmlns="http://www.w3.org/2000/svg">
                               <g id="wait">
                                   <g id="Ellipse1">
                                       <mask id="path-1-inside-1_4_5" fill="white">
                                           <path
                                               d="M290 150C295.523 150 300.035 145.517 299.667 140.007C297.376 105.673 283.331 73.0532 259.733 47.7325C233.855 19.9656 198.413 3.0415 160.551 0.371568C122.689 -2.29836 85.2231 9.48448 55.7047 33.3449C26.1864 57.2054 6.81106 91.3691 1.48443 128.949C-3.8422 166.53 5.27597 204.732 27.0009 235.856C48.7258 266.979 81.442 288.71 118.555 296.667C155.667 304.624 194.416 298.216 226.991 278.734C256.696 260.968 279.252 233.536 290.995 201.192C292.879 196 289.791 190.44 284.486 188.906L281.774 188.121C276.469 186.586 270.961 189.662 269.015 194.83C258.92 221.633 240.024 244.347 215.277 259.147C187.658 275.665 154.805 281.098 123.339 274.352C91.873 267.606 64.1345 249.181 45.715 222.793C27.2955 196.405 19.5646 164.015 24.0808 132.152C28.597 100.29 45.0244 71.3239 70.0516 51.0938C95.0788 30.8637 126.845 20.8736 158.946 23.1373C191.047 25.401 221.097 39.7501 243.037 63.2923C262.697 84.3871 274.536 111.457 276.785 140.009C277.219 145.515 281.655 150 287.178 150H290Z"/>
                                       </mask>
                                       <path
                                           d="M290 150C295.523 150 300.035 145.517 299.667 140.007C297.376 105.673 283.331 73.0532 259.733 47.7325C233.855 19.9656 198.413 3.0415 160.551 0.371568C122.689 -2.29836 85.2231 9.48448 55.7047 33.3449C26.1864 57.2054 6.81106 91.3691 1.48443 128.949C-3.8422 166.53 5.27597 204.732 27.0009 235.856C48.7258 266.979 81.442 288.71 118.555 296.667C155.667 304.624 194.416 298.216 226.991 278.734C256.696 260.968 279.252 233.536 290.995 201.192C292.879 196 289.791 190.44 284.486 188.906L281.774 188.121C276.469 186.586 270.961 189.662 269.015 194.83C258.92 221.633 240.024 244.347 215.277 259.147C187.658 275.665 154.805 281.098 123.339 274.352C91.873 267.606 64.1345 249.181 45.715 222.793C27.2955 196.405 19.5646 164.015 24.0808 132.152C28.597 100.29 45.0244 71.3239 70.0516 51.0938C95.0788 30.8637 126.845 20.8736 158.946 23.1373C191.047 25.401 221.097 39.7501 243.037 63.2923C262.697 84.3871 274.536 111.457 276.785 140.009C277.219 145.515 281.655 150 287.178 150H290Z"
                                           stroke="url(#paint0_linear_4_5)" stroke-width="40"
                                           mask="url(#path-1-inside-1_4_5)"/>
                                   </g>
                                   <g id="Ellipse2">
                                       <mask id="path-2-inside-2_4_5" fill="white">
                                           <path
                                               d="M240 150C245.523 150 250.052 145.511 249.501 140.016C247.346 118.537 238.281 98.2292 223.529 82.2248C206.508 63.7585 183.161 52.3729 158.13 50.3311C133.099 48.2892 108.216 55.7405 88.4273 71.204C68.6383 86.6675 55.3923 109.011 51.3221 133.793C47.2518 158.575 52.6554 183.982 66.4588 204.962C80.2622 225.943 101.455 240.962 125.824 247.034C150.193 253.105 175.955 249.785 197.988 237.733C217.084 227.288 232.168 210.947 241.08 191.285C243.36 186.255 240.505 180.553 235.272 178.787L234.828 178.637C229.595 176.87 223.977 179.718 221.568 184.688C214.39 199.498 202.749 211.799 188.166 219.775C170.642 229.36 150.154 232.001 130.772 227.172C111.391 222.343 94.5367 210.398 83.5588 193.712C72.5808 177.026 68.2832 156.82 71.5203 137.11C74.7574 117.401 85.2921 99.631 101.031 87.3327C116.769 75.0344 136.559 69.1082 156.466 70.7322C176.374 72.3561 194.941 81.4112 208.478 96.0976C219.744 108.32 226.84 123.697 228.903 140.026C229.596 145.505 234.008 150 239.531 150H240Z"/>
                                       </mask>
                                       <path
                                           d="M240 150C245.523 150 250.052 145.511 249.501 140.016C247.346 118.537 238.281 98.2292 223.529 82.2248C206.508 63.7585 183.161 52.3729 158.13 50.3311C133.099 48.2892 108.216 55.7405 88.4273 71.204C68.6383 86.6675 55.3923 109.011 51.3221 133.793C47.2518 158.575 52.6554 183.982 66.4588 204.962C80.2622 225.943 101.455 240.962 125.824 247.034C150.193 253.105 175.955 249.785 197.988 237.733C217.084 227.288 232.168 210.947 241.08 191.285C243.36 186.255 240.505 180.553 235.272 178.787L234.828 178.637C229.595 176.87 223.977 179.718 221.568 184.688C214.39 199.498 202.749 211.799 188.166 219.775C170.642 229.36 150.154 232.001 130.772 227.172C111.391 222.343 94.5367 210.398 83.5588 193.712C72.5808 177.026 68.2832 156.82 71.5203 137.11C74.7574 117.401 85.2921 99.631 101.031 87.3327C116.769 75.0344 136.559 69.1082 156.466 70.7322C176.374 72.3561 194.941 81.4112 208.478 96.0976C219.744 108.32 226.84 123.697 228.903 140.026C229.596 145.505 234.008 150 239.531 150H240Z"
                                           stroke="url(#paint1_linear_4_5)" stroke-width="44"
                                           mask="url(#path-2-inside-2_4_5)"/>
                                   </g>
                                   <g id="Ellipse3">
                                       <mask id="path-3-inside-3_4_5" fill="white">
                                           <path
                                               d="M190.92 150C195.935 150 200.084 145.902 199.178 140.97C197.473 131.684 193.16 123.005 186.655 115.994C178.077 106.747 166.321 101.085 153.743 100.14C141.166 99.196 128.696 103.04 118.834 110.902C108.971 118.764 102.444 130.063 100.561 142.534C98.6772 155.006 101.577 167.728 108.678 178.152C115.78 188.575 126.558 195.93 138.854 198.742C151.149 201.553 164.053 199.615 174.979 193.313C183.264 188.535 189.948 181.517 194.318 173.149C196.64 168.704 193.887 163.564 189.095 162.083V162.083C184.304 160.602 179.316 163.399 176.547 167.58C173.845 171.66 170.216 175.097 165.907 177.582C158.949 181.595 150.732 182.83 142.902 181.039C135.072 179.249 128.208 174.565 123.686 167.927C119.164 161.289 117.317 153.188 118.517 145.246C119.716 137.304 123.872 130.109 130.153 125.102C136.434 120.096 144.374 117.648 152.384 118.249C160.393 118.85 167.879 122.456 173.342 128.345C176.725 131.992 179.178 136.347 180.554 141.043C181.965 145.855 185.906 150 190.92 150V150Z"/>
                                       </mask>
                                       <path
                                           d="M190.92 150C195.935 150 200.084 145.902 199.178 140.97C197.473 131.684 193.16 123.005 186.655 115.994C178.077 106.747 166.321 101.085 153.743 100.14C141.166 99.196 128.696 103.04 118.834 110.902C108.971 118.764 102.444 130.063 100.561 142.534C98.6772 155.006 101.577 167.728 108.678 178.152C115.78 188.575 126.558 195.93 138.854 198.742C151.149 201.553 164.053 199.615 174.979 193.313C183.264 188.535 189.948 181.517 194.318 173.149C196.64 168.704 193.887 163.564 189.095 162.083V162.083C184.304 160.602 179.316 163.399 176.547 167.58C173.845 171.66 170.216 175.097 165.907 177.582C158.949 181.595 150.732 182.83 142.902 181.039C135.072 179.249 128.208 174.565 123.686 167.927C119.164 161.289 117.317 153.188 118.517 145.246C119.716 137.304 123.872 130.109 130.153 125.102C136.434 120.096 144.374 117.648 152.384 118.249C160.393 118.85 167.879 122.456 173.342 128.345C176.725 131.992 179.178 136.347 180.554 141.043C181.965 145.855 185.906 150 190.92 150V150Z"
                                           stroke="url(#paint2_linear_4_5)" stroke-width="44" stroke-linejoin="round"
                                           mask="url(#path-3-inside-3_4_5)"/>
                                   </g>
                               </g>
                               <defs>
                                   <linearGradient id="paint0_linear_4_5" x1="150" y1="0" x2="150" y2="300"
                                                   gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#529de2"/>
                                       <stop offset="2"/>
                                   </linearGradient>
                                   <linearGradient id="paint1_linear_4_5" x1="150" y1="50" x2="150" y2="250"
                                                   gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#529de2"/>
                                       <stop offset="1"/>
                                   </linearGradient>
                                   <linearGradient id="paint2_linear_4_5" x1="150" y1="100" x2="150" y2="200"
                                                   gradientUnits="userSpaceOnUse">
                                       <stop stop-color="#529de2"/>
                                       <stop offset="1" stop-color="#000407"/>
                                   </linearGradient>
                               </defs>
                           </svg>
                           }
                           <Text mx={2} p={0} color={'whatsapp.500'}>Criminal Check...</Text>
                       </Flex>
                   </Box>
               </Flex>
                <Text color={'red'} fontStyle={'italic'} m={4} fontFamily={'RobotoCondensedLight'} fontWeight={'thin'}>
                    Do note that the process can take up to 2 days, due to intense background check.
                </Text>
            </Center>
            <Footer/>
        </div>
    )
}

export default AgentApplicationStatus