import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";

function AdminNewCompany() {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const submitForm = (values) => {
    console.log("Submitting Form ", values);
    setSubmitted(true);
  };
  console.log(submitted);
  useEffect(() => {
    return () => {
      setSubmitted(false);
    };
  }, []);
  return (
    <Box className={"h-full m-0 p-0"}>
      <Box
        className={"flex flex-col h-full overflow-y-auto"}
        boxShadow={"none"}
      >
        <Box className={"w-full p-2"} bgColor={"white"}>
          <Text> New Company </Text>
        </Box>
        {!submitted ? (
          <Box className={"flex-grow-1 h-full"}>
            <Formik
              initialValues={{
                registrationNumber: "",
                companyName: "",
                companyEmail: "",
                industry: "",
                password: "",
                confirmPassword: "",
                domain: "",
                description: "",
              }}
              onSubmit={(values) => {
                submitForm(values);
              }}
              validationSchema={Yup.object({
                registrationNumber: Yup.string().required("Required"),
                companyName: Yup.string().required("Required"),
                companyEmail: Yup.string().email().required("Email Required"),
                domain: Yup.string().required("Required"),
                password: Yup.string()
                  .matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
                  )
                  .required("Required"),
                confirmPassword: Yup.string()
                  .oneOf([Yup.ref("password"), null], "Password do not match")
                  .required("Required"),
              })}
            >
              {({
                values,
                handleSubmit,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                errors,
              }) => (
                <Box
                  as={"form"}
                  onSubmit={handleSubmit}
                  className={
                    "p-0 relative w-full h-full flex flex-row overflow-y-hidden  mx-auto"
                  }
                >
                  <Box
                    className={
                      "p-2 my-2 relative w-9/12 m-auto h-full flex flex-col overflow-y-hidden "
                    }
                    boxShadow={"lg"}
                    bgColor={"white"}
                  >
                    <Box
                      className={"m-1 p-1 flex flex-col  mx-0 w-full h-full"}
                      borderRadius={"5px"}
                    >
                      <Box className={" flex flex-col flex-grow-1"}>
                        <Box className="flex flex-row">
                          <Box className="flex flex-row w-full my-auto mx-1 justify-between">
                            <FormControl
                              className={"flex flex-col w-full justify-start"}
                              isRequired
                            >
                              <FormLabel
                                className={"m-1 p-0 text-start w-auto"}
                              >
                                Company Name
                              </FormLabel>
                              <Input
                                isRequired
                                isInvalid={
                                  errors.companyName && touched.companyName
                                }
                                errorBorderColor="red.300"
                                name={"companyName"}
                                value={values.companyName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={"md"}
                                className={"m-0 p-2 w-1/2"}
                              />
                              {errors.companyName && touched.companyName && (
                                <Text
                                  className={"text-sm text-start"}
                                  color={"red.300"}
                                >
                                  {errors.companyName}
                                </Text>
                              )}
                            </FormControl>
                          </Box>
                          <Box
                            className={
                              "flex flex-row w-full my-auto mx-1 justify-between"
                            }
                          >
                            <FormControl
                              className={"flex my-1 w-full flex-col"}
                              isRequired
                            >
                              <FormLabel
                                className={"m-1 p-0 text-start w-auto"}
                              >
                                {" "}
                                Registration Number{" "}
                              </FormLabel>
                              <Input
                                isRequired
                                errorBorderColor="red.300"
                                isInvalid={
                                  errors.registrationNumber &&
                                  touched.registrationNumber
                                }
                                name={"registrationNumber"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={"md"}
                                className={"m-0 p-2 w-1/2"}
                              ></Input>
                              {errors.registrationNumber &&
                                touched.registrationNumber && (
                                  <Text
                                    className={"text-sm text-start"}
                                    color={"red.300"}
                                  >
                                    {errors.registrationNumber}
                                  </Text>
                                )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box className="flex flex-row">
                          <Box
                            className={
                              "flex flex-row w-full m-1 justify-between"
                            }
                          >
                            <FormControl
                              className={"flex my-1 w-full flex-col"}
                              isRequired
                            >
                              <FormLabel
                                className={"m-1 p-0 text-start w-auto"}
                              >
                                {" "}
                                Domain{" "}
                              </FormLabel>
                              <Input
                                isRequired
                                errorBorderColor="red.300"
                                isInvalid={errors.domain && touched.domain}
                                name={"domain"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={"md"}
                                className={"m-0 p-2 w-1/2"}
                              ></Input>
                              {errors.domain && touched.domain && (
                                <Text
                                  className={"text-sm text-start"}
                                  color={"red.300"}
                                >
                                  {errors.domain}
                                </Text>
                              )}
                            </FormControl>
                          </Box>

                          <Box
                            className={
                              "flex flex-row w-full m-1 justify-between"
                            }
                          >
                            <FormControl
                              className={"flex my-1 w-full flex-col"}
                              isRequired
                            >
                              <FormLabel
                                className={"m-1 p-0 text-start w-auto"}
                              >
                                {" "}
                                Company Email{" "}
                              </FormLabel>
                              <Input
                                isRequired
                                errorBorderColor="red.300"
                                isInvalid={
                                  errors.companyEmail && touched.companyEmail
                                }
                                name={"companyEmail"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={"md"}
                                className={"m-0 p-2 w-1/2"}
                              ></Input>
                              {errors.companyEmail && touched.companyEmail && (
                                <Text
                                  className={"text-sm text-start"}
                                  color={"red.300"}
                                >
                                  {errors.companyEmail}
                                </Text>
                              )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box className="flex flex-row">
                          <Box
                            className={
                              "flex flex-row w-full m-1 justify-between"
                            }
                          >
                            <FormControl
                              className={"flex my-1 w-full flex-col"}
                              isRequired
                            >
                              <FormLabel
                                className={"m-1 p-0 text-start w-auto"}
                              >
                                {" "}
                                Password{" "}
                              </FormLabel>
                              <Input
                                isRequired
                                errorBorderColor="red.300"
                                isInvalid={errors.password && touched.password}
                                name={"password"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={"md"}
                                className={"m-0 p-2 w-1/2"}
                              ></Input>
                              {errors.password && touched.password && (
                                <Text
                                  className={"text-sm text-start"}
                                  color={"red.300"}
                                >
                                  {errors.password}
                                </Text>
                              )}
                            </FormControl>
                          </Box>

                          <Box
                            className={
                              "flex flex-row w-full m-1 justify-between"
                            }
                          >
                            <FormControl
                              className={"flex my-1 w-full flex-col"}
                              isRequired
                            >
                              <FormLabel
                                className={"m-1 p-0 text-start w-auto"}
                              >
                                {" "}
                                Confirm Password{" "}
                              </FormLabel>
                              <Input
                                isRequired
                                errorBorderColor="red.300"
                                isInvalid={
                                  errors.confirmPassword &&
                                  touched.confirmPassword
                                }
                                name={"confirmPassword"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size={"md"}
                                className={"m-0 p-2 w-1/2"}
                              ></Input>
                              {errors.confirmPassword &&
                                touched.confirmPassword && (
                                  <Text
                                    className={"text-sm text-start"}
                                    color={"red.300"}
                                  >
                                    {errors.confirmPassword}
                                  </Text>
                                )}
                            </FormControl>
                          </Box>
                        </Box>
                        <Box className={" h-1/3 flex flex-col py-2 "}>
                          <Text className={"m-1 p-0 text-start w-auto"}>
                            Company Description:{" "}
                          </Text>
                          <Textarea
                            name={"description"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={"flex-grow-1"}
                          />
                          {errors.description && touched.description && (
                            <Text
                              className={"text-sm text-start"}
                              color={"red.300"}
                            >
                              {errors.description}
                            </Text>
                          )}
                        </Box>
                      </Box>
                      <Box className={"m-1 flex flex-row justify-end"}>
                        <Button
                          size={"md"}
                          onClick={() => navigate(-1)}
                          className={"mx-2"}
                        >
                          Close
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          size={"md"}
                          type={"submit"}
                          bgColor={"blue.800"}
                          color={"white"}
                          className={"mx-2"}
                        >
                          Create
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
        ) : (
          <Center className={"flex-grow-1 h-full"}>
            <Center bgColor={"white"} className={"h-1/2 w-1/2 flex-col"}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <CheckCircleIcon
                  size={"lg"}
                  className={"text-8xl"}
                  color={"whatsapp.500"}
                />
              </motion.div>

              <Text>Company creates !!! </Text>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                className={"m-3"}
              >
                Back
              </Button>
            </Center>
          </Center>
        )}
      </Box>
    </Box>
  );
}
const files = [
  { filename: "INage.pg", url: "eetwrtwrwter.vom" },
  { filename: "INaplpge.pg", url: "eetwrtwrwter.vom" },
];

export default AdminNewCompany;
