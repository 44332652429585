import {Tabs, TabList, TabPanels, Tab, TabPanel, Box, Text} from '@chakra-ui/react'
import { Menubar } from 'primereact/menubar';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./allOrders.scss"


function AllOrders() {
    return (
        <Box>All Orders</Box>
    )
}



export default AllOrders

