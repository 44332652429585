import React from 'react'

function NewAdmin() {
  return (
    <div>NewAdmin</div>
  )
}



export default NewAdmin