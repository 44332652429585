import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import {
  Box,
  Button,
  Menu,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { IoChevronDownOutline } from "react-icons/io5";

import { generateItems, generateUsers } from "../../FakeData/Items";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function ListofItems() {
  const dispatch = useDispatch();
  console.log(generateUsers(5));
  const gridRef = useRef();
  const navigate = useNavigate();
  const ListColumnDefs = [
    { field: "_id" },
    { field: "category" },
    { field: "balance" },
    { field: "contactNumber" },
    { field: "email" },
    { field: "fullName" },
  ];
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      autoHeight: true,
      rowDrag: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
    }),
    []
  );

  const cellClickedListener = useCallback((event) => {
    console.log(event.data);
  }, []);

  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);
  return (
    <Box className={"h-full mb-5 w-full"}>
      <Box bgColor="#033053">
        <Text className="text-xl p-4" color="white">
          List of Items
        </Text>
      </Box>
      <Box className={"flex flex-row m-1 p-1 justify-between"}>
        <Box>
          <Menu>
            <MenuButton as={Button} rightIcon={<IoChevronDownOutline />}>
              Reports
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => onBtnExport()}>Download CSV</MenuItem>
              <MenuItem onClick={() => console.log("Download PDF")}>
                Download PDF
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Box className="w-full flex-grow  h-full">
        <Box
          style={{ width: "100%", height: "60%" }}
          className={"ag-theme-alpine h-full flex flex-col m-0 px-2"}
        >
          <AgGridReact
            class={"w-full"}
            defaultColDef={defaultColDef}
            rowSelection={"multiple"}
            rowData={generateUsers(50)}
            columnDefs={ListColumnDefs}
            rowStyle={{ textAlign: "start" }}
            animateRows={true}
            onCellClicked={cellClickedListener}
            ref={gridRef}
            paginationAutoPageSize={true}
            pagination={true}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ListofItems;
