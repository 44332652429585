import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  Input,
  InputLeftElement,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup, Image,
} from "@chakra-ui/react";
import {
  IoCard,
  IoCash,
  IoChevronForwardSharp,
  IoLogoUsd,
  IoWifi,
} from "react-icons/io5";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ArrowForwardIcon, EditIcon, Icon } from "@chakra-ui/icons";
import AddFunds from "./AddFunds";
import ScanToPay from "./ScanToPay";
import { server_URL } from "../../../../serverURL";
import { Store } from "react-notifications-component";
import axios from "axios";
import { Plus } from "@rsuite/icons";
import userWallet, { userWalletActions } from "../../../DataStore/userWallet";
import cardSVG from "../../../../Assets/undraw_credit_card_re_blml.svg";

function CardComponent() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [amountValue, setAmountValue] = useState(0);
  let yoco;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const bank = useSelector((state) => state.banking);
  const [checkoutId,setcheckoutId]=useState('')
  const [amount, setAmount] = useState(0.0);
  const [paymentURL,setPaymentURL]= useState('')
  const [paymentInProgress,setpaymentInProgress]= useState(false)
  const format = (val) => val.toString();
  const parse = (val) => parseFloat(val, 2);
  const {
    isOpen: bankisOpen,
    onOpen: bankonOpen,
    onClose: bankonClose,
  } = useDisclosure();
  const {
    isOpen: scanisOpen,
    onOpen: scanonOpen,
    onClose: scanonClose,
  } = useDisclosure();
  const {
    isOpen: topUpisOpen,
    onOpen: topUponOpen,
    onClose: topUponClose,
  } = useDisclosure();
  const yocoRef = useRef(null);
  const cardMessage = ( message, type ) => {
    Store.addNotification({
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
      onRemoval: () => {},
    });
  };

  const AddFundsHandler = () => {
    console.log("Adding Funds", window.navigator.onLine);
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: server_URL + "/addfunds",
      headers: {
        "Content-Type": "Application/json",
        "X-Auth-Secret-Key": "sk_test_96e68c09eBJKlvk091b4421affb3",
      },
      data: {
        userId: userData.email,
        amount: amount,
      },
    };
    axios(config)
      .then((response) => {
        if (response) {
          console.log("YOCO RESPONSE", response);
         setPaymentURL(response.data.redirectUrl)
          setcheckoutId(response.data.id)
          bankonOpen()
          //cardMessage({ message: "Funds Added", status: "success" });
        } else {
          cardMessage("no response ",  "danger" );
        }
      })
      .catch((error) => {
        console.log(error);
        cardMessage(error.message,  "danger" );
      });
    // socket.emit("$techresq:user:addFunds", {
    //   email: userData.email,
    //   amount: amount,
    //   currency: "ZAR",
    //   idempotencyKey: "fwrf54fw5445g345g5g244fqD^&7&^f779",
    // });

  };
  // console.log(paymentInProgress)
  const yocoSDK = async ()=>{

  }
  useEffect(()=>{
    yoco=new window.YocoSDK({
      publicKey:'pk_test_edea93c3GN5kJmG19364'
    })
    return ()=>{

    }
  })

  const FundPopUp = ()=>{
    bankonOpen()
  }
  const showPopUp = ()=>{
    if(yoco){
      yoco.showPopup({
        "amountInCents":amount*100,
        "currency": "ZAR",
        name:"TechResQ Add Funds ",
        description:'Topping up your funds',
        callback:response=>{
          console.log(response)
          if(response.error){
             cardMessage('We could not proccess you funds ','success')
          }else {
            console.log('We arent golden',response)
            cardMessage('We could not proccess you funds ','danger')
          }
        }
      })
    }else {
      console.log("No yoco",yoco)
    }
  }
  const handlePaymentPopupClosed= ()=>{
    console.log("Payment Pop has been closed ")
    setpaymentInProgress(false)
  }
  const handlePaymentPopUpOpen = ()=>{
    console.log('Payment Pop has has been open ')
  }
  const checkingFundsProcess = ()=>{
  }
  const handleAmountChange = (amountValue) => {
    if (amountValue > 100) {
      onToggle();
    }
    setAmountValue(amountValue);
  };
  return (
    <Flex
      flexDir={"column"}
      className={
        "border-1 h-fit my-1 p-0 md:p-0 border-gray-900 w-full md:w-fit m-0 p-0  md:p-2 rounded-md"
      }
    >
      <Text
        className={"mx-2 my-2 text-xl text-bold text-start"}
        fontFamily={"Roboto"}

      >
        Bank Card
      </Text>
      <Box
        className={
          "h-fit w-fit grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 "
        }
      >
        {/*<Box className={"py-2 sm:pr-0 md:pr-0 lg:pr-4 m-auto w-full"}>*/}
        {/*  <Box*/}
        {/*    color={"gray.300"}*/}
        {/*    className={"w-full h-fit rounded-md p-2"}*/}
        {/*    background={*/}
        {/*      "radial-gradient(circle, rgba(3,48,83,1) 0%, rgba(1,51,91,1) 100%)"*/}
        {/*    }*/}
        {/*  >*/}
        {/*    /!*Card*!/*/}

        {/*    <Flex alignItems={"center"}>*/}
        {/*      <Flex*/}
        {/*        alignItems={"center"}*/}
        {/*        justifyContent={"space-between"}*/}
        {/*        className={"w-full px-2 py-2"}*/}
        {/*      >*/}
        {/*        <Text className={"m-0 p-0"}>VISA</Text>|*/}
        {/*        <Text className={"m-0 p-0 text-light"}>{bank.bankName}</Text>*/}
        {/*      </Flex>*/}
        {/*      <IoWifi size={"7%"} />*/}
        {/*    </Flex>*/}
        {/*    /!*cardbody *!/*/}
        {/*    <Box className={" p-2 h-auto"}>*/}
        {/*      <Text className={"my-3 text-start text-lg"} fontWeight={"bold"}>*/}
        {/*        {bank.accountNumber}*/}
        {/*      </Text>*/}
        {/*      <Flex*/}
        {/*        justifyContent={"space-between"}*/}
        {/*        className={"w-full align-middle"}*/}
        {/*        fontFamily={"Roboto Condensed"}*/}
        {/*        fontWeight={"light"}*/}
        {/*      >*/}
        {/*        <Text className={"m-0 p-0"}>{bank.holder}</Text>*/}
        {/*        <Text className={"m-0 p-0"}>{bank.expiry}</Text>*/}
        {/*      </Flex>*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*  <Box className={"w-full flex justify-start m-2 "}>*/}
        {/*    <Button*/}
        {/*      size={"sm"}*/}
        {/*      boxShadow={"md"}*/}
        {/*      bgColor={"gray.400"}*/}
        {/*      borderRadius={"0"}*/}
        {/*      onClick={() => bankonOpen()}*/}
        {/*      rightIcon={<EditIcon />}*/}
        {/*    >*/}
        {/*      Edit Card{" "}*/}
        {/*    </Button>*/}
        {/*  </Box>*/}
        {/*</Box>*/}

        <Box
          className={
            "ml-auto flex flex-column justify-center align-items-center w-fit md:w-full"
          }
        >
          <Box
            className={"mx-2 my-1 text-md  w-fit md:w-full"}
            boxShadow={"md"}
            color={"gray.500"}
            borderRadius={"lg"}
            bgColor={"whatsapp.50"}
          >
            <Text className={" p-3 text-start font-medium text-sm"}>
              We recommend to keep your account above R500.00 to easily make
              calls without having to updates fund frequently
            </Text>
          </Box>
          <Box
            boxShadow={"md"}
            bgColor={"white"}
            width={"100%"}
            className={
              " grid grid-cols-1 p-2 md:grid-cols-1 lg:grid-cols-1 w-full md:w-full lg:w-fit"
            }
          >
            <Box
              className={"flex flex-row  w-full p-0 align-items-center h-full"}
              bgColor={"white"}
            >
              <Text className={"mx-1 text-lg"}>R</Text>
              <Popover
                returnFocusOnClose={false}
                isOpen={isOpen}
                onClose={onClose}
                closeOnBlur={false}
                outline={"none"}
                _focusVisible={{ outline: "none" }}
                placement="bottom"
                borderRadius={"md"}
                className={"m-0 p-0"}
              >
                <PopoverTrigger>
                  <NumberInput
                    onKeyDown={(e) =>
                      e.key === "Enter" && amount < 100 ? onToggle() : ""
                    }
                    precision={2}
                    size="md"
                    className={"w-full"}
                    _focus={{ border: "none", outline: "none" }}
                    m={0}
                    onChange={(valueString) =>
                      setAmount(parseFloat(valueString))
                    }
                    min={10.0}
                  >
                    <NumberInputField
                      bgColor={"white"}
                      _focusVisible={{ boxShadow: "none" }}
                      borderRadius={0}
                      placeholder={"0.00"}
                      focusBorderColor="red"
                    />
                  </NumberInput>
                </PopoverTrigger>
                <PopoverContent
                  outline={"none"}
                  _focusVisible={{ outline: "none" }}
                >
                  <PopoverHeader
                    borderTopLeftRadius={"md"}
                    borderTopRightRadius={"md"}
                    color={"blackAlpha.800"}
                    bgColor={"whitesmoke"}
                    fontWeight="semibold"
                  >
                    Please accept our minimum amount{" "}
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Box className={"p-10"}>
                      <Text>You must enter a minimum of R100.00</Text>
                    </Box>
                  </PopoverBody>
                </PopoverContent>

              </Popover>
              <Button
                justifyContent={"space-between text-center"}
                className={"m-0 p-3 w-fit"}
                id={"#checkout-button"}
                borderRadius={0}
                isDisabled={amount < 100 || isNaN(amount)}
                onClick={() =>AddFundsHandler()}
                bgColor={"blue.800"}
                color={"white"}
              >
                <Plus />
                Top Up
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={'w-full flex p-3'}>
          <Image src={cardSVG} className={'w-1/2 m-auto'}/>
        </Box>
      </Box>
      <AddFunds
        bank={bank}
        user={userData}
        url={paymentURL}
        checkoutId={checkoutId}
        bankonClose={bankonClose}
        bankisOpen={bankisOpen}
        bankonOpen={bankonOpen}
        showpopup={showPopUp}
      />
      {/*<ScanToPay scanonOpen={scanonOpen} scanonClose={scanonClose}  scanisOpen={scanisOpen}/>*/}
    </Flex>
  );
}

export default CardComponent;
