import {Box, CloseButton, Flex, Icon, IconButton, Tag, Text} from "@chakra-ui/react";
import {MdClose, MdUpload} from "react-icons/md";
import React, {useState} from "react";
import {Button} from "rsuite";
import {AddIcon} from "@chakra-ui/icons";
import {BiTrash, BiX} from "react-icons/bi"
import { motion } from 'framer-motion'



function CertificateInputs (props){
    const [file,setFile] = useState()
    const handleChanges =(e)=>{
            // console.log(e.target.files[0])
            //props.setFieldValue(e.target.files[0])
        changeCertificate(e.target.files[0])
        setFile(e.target.files[0])
        }
    function humanfileSize(size) {
        const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    const resetFile =(certificate)=>{
        const newCertificates= props.certificates.filter((certificate,index)=>((index+4)!==props.id))
        // console.log(newCertificates)
        props.setFieldValue("certificatesFiles",newCertificates)

    }
    const changeCertificate=(certificate)=>{


        console.log(props.certificates)
        const newCertificates=props.certificates.map((file,index)=>{
            // console.log(file,index)
            if((index+4)===props.id){
                return certificate
            }else {
                return file
            }
        })
        props.setFieldValue("certificatesFiles",newCertificates)
        //  console.log(certificate.name)
        // console.log(newCertificates)

    }
    const add =(certificate)=> {
        const temporaryCets = props.certificates
        // temporaryCets[index]
        props.setFieldValue("certificatesFiles", [...props.certificates, certificate])
    }
    return(
        <Flex as={motion.div}  data-aos="fade-right" w={'100%'} alignItems={'center'} >
            <Box pos={'relative'} w='100%'  m={1} borderRadius={'5px'} bgColor='gray.200' className="file-upload">
                <Box p={1}>
                    <input onChange={(e)=>handleChanges(e)} accept={'.pdf,.jpeg,.png'} style={{display:'none'}} type="file" name="file-upload-input" id={`file-upload-input-${props.id}`} />
                    {props.file.name===""||props.file.name===undefined?<label style={{backgroundColor:'',width:'100%'}} className="file-select-name" htmlFor={`file-upload-input-${props.id}`}>
                        <Flex flexDir={'row'} justifyContent='center' alignItems={'center'} w='100%' p={1}>
                            <Icon as={MdUpload}/>
                            <Text fontSize={'.9em'} color='blue.800' fontFamily={'RobotoCondensedRegular'}>Upload Certificate</Text>
                        </Flex>
                    </label>:<>
                        <Flex flexDir={'row'} justifyContent='space-between' alignItems={'center'}>
                            <Flex flexDir={'row'}  w='100%' justifyContent='space-between'  alignItems={'center'}>
                                <Text m={1} fontSize='.8em'>{props.file.name?.length<30?props.file.name?.slice(0,20):(props.file.name?.slice(0,30)+"...")}</Text>
                                <Tag  color={props.file.size<=2097152?"green":"red"} m={1} fontSize='.8em'>{humanfileSize(props.file.size)}</Tag>
                            </Flex>
                            <IconButton icon={<MdClose/>} onClick={() => resetFile()}  aria-label={'icon button'}/>
                        </Flex>
                    </>}
                </Box>
                {/*{file.size>=2097152?<Text bgColor={'red.300'} borderBottomEndRadius={'5px'} borderBottomLeftRadius={'5px'} fontSize={'.7em'} p={1}>File too Big!!! (max: 2MB)</Text>:null}*/}
            </Box>
        </Flex>
    )
}
export default CertificateInputs