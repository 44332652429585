import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import NavBar from "../components/navBar";
import Footer from "./Footer";
import dataforgot from "../Assets/data-forgot.jpg";
import { useState } from "react";
import axios from "axios";
import { Store } from "react-notifications-component";
import validator from "validator";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { server_URL } from "../serverURL";
function ForgotPassword() {
  const [emailSent, setemailSent] = useState(false);
  const [count, setCount] = useState(0);
  const [email, setEmail] = useState("");
  const [gettingEmail, setgettingEmail] = useState(false);
  const [email_error, setemail_error] = useState("");
  const sendingMessage = (email) => {
    Store.addNotification({
      title: `Email Sent to ${email} `,
      message: "Please check your emails",
      type: "success",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: (id) => {
        setgettingEmail(false);
      },
    });
  };
  const sendingMessageError = (email) => {
    Store.addNotification({
      title: ``,
      message: "This email does not exist on our system",
      type: "warning",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: (id) => {
        setgettingEmail(false);
      },
    });
  };
  const sendLink = async () => {
    setgettingEmail(true);
    if (email === "") {
      setemail_error("Invalid Email");
    }
    if (email_error === "" && email !== "") {
      await axios
        .post(`${server_URL}/users/forgotPassword`, { email })
        .then((response) => {
          console.log(response);
          if (response.data.length > 0) {
            sendingMessage(email);
            setemailSent(true);
          } else {
            sendingMessageError();
          }
        })
        .catch((e) => {
          sendingMessageError();
        });
    }
  };

  const validate = (e) => {
    if (validator.isEmail(e) && e !== "") {
      setEmail(e);
      setemail_error("");
    } else {
      setEmail(e);
      setemail_error("Invalid Email");
    }
  };

  return (
    <div>
      <NavBar />
      <div className="body" style={{ height: "100vh" }}>
        <Center
          filter="auto"
          brightness="100%"
          backdropFilter={"blur(5px)"}
          backgroundSize={"100%"}
          backgroundImage={dataforgot}
          height={"10rem"}
          width={"100%"}
        >
          <Heading color={"white"} fontFamily={"RobotoCondensedRegular"}>
            Forgot Password
          </Heading>
        </Center>
        <Box
          bgColor={"white"}
          m={3}
          display="flex"
          flexDir={"column"}
          pb={"10rem"}
          margin={"auto"}
          pt="1rem"
        >
          {emailSent ? (
            <div className="alldone" style={{ padding: "5.5rem" }}>
              <Box>
                <CheckCircleIcon color={"green"} fontSize={"md"} />
                <Text fontSize={"lg"} fontFamily={"RobotoCondensedBold"}>
                  Email sent successfully!!!
                </Text>
                <Text m={5}>
                  To complete the process, use the link sent to you.
                </Text>
                <Link style={{ margin: "3rem" }} to="/login">
                  <u>Back to Login</u>
                </Link>
              </Box>
            </div>
          ) : (
            <div
              className=""
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "6rem",
              }}
            >
              <Box pt={1} pb={1}>
                <Text textAlign={"start"} fontSize={"lg"}>
                  Enter your email address
                </Text>
              </Box>
              <Flex
                pb={5}
                justifyContent={"center"}
                width={"100%"}
                marginLeft={"auto"}
                marginRight={"auto"}
                flexDir={"column"}
              >
                <Input
                  type="text"
                  value={email}
                  onChange={(e) => validate(e.target.value)}
                />
                <Text
                  textAlign={"start"}
                  fontFamily={"RobotoCondensedRegular"}
                  fontSize={".7em"}
                  color="rgb(196, 68, 68)"
                  mt={2}
                >
                  {email_error}
                </Text>
              </Flex>

              <Center p={4}>
                <Button
                  width={"13em"}
                  isDisabled={gettingEmail}
                  onClick={() => sendLink()}
                >
                  {gettingEmail ? <Spinner /> : <Text>Next</Text>}
                </Button>
              </Center>

              <Flex></Flex>
            </div>
          )}
        </Box>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
