import { BellIcon } from '@chakra-ui/icons'
import { Box ,Icon, Button, Flex, Text, IconButton} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function Notifications({id,sender,text,opened,type,ticketID,createdAt}) {
  return (
    <Box as={Link} m={0} p={2} _hover={{backgroundColor:'gray.300',color:'white'}} to={`/admin/ticket/${id}`} style={{textDecoration:'none',display:'flex',flexDirection:'row'}}>
        <Box>
            <BellIcon m={2} fontSize='md' bgColor={'gray.400'} />
        </Box>
        
        <Box >
            <Text fontSize='md' m={0} p={0}>Message</Text>
            <Text m={0} p={0} fontSize={'.4em'}>2 hours ago </Text>
        </Box>
    </Box>
  )
}

export default Notifications