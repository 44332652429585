import React from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {Box} from "@chakra-ui/react";
import {Menubar} from "primereact/menubar";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from '@chakra-ui/react'
import {ChevronRightIcon} from "@chakra-ui/icons";

function SupplierInterface() {
     const navigate = useNavigate();
    const items = [
        {
            label: "All clients",
            icon: "pi pi-fw pi-pencil",
            items: [
                {
                    label: "New Client",
                    icon: "pi pi-plus pi-align-left",
                    command: () => {
                        navigate("/admin/orders/items/new");
                    },
                },
                {
                    label: "List of clients ",
                    icon: "pi pi-fw pi-users",
                    command: () => {
                        navigate("/admin/orders/items/all");
                    },
                },
                {
                    label: "Item Categories",
                    icon: "pi pi-fw pi-list",
                    command: () => {
                        navigate("/admin/orders/items/all");
                    },
                },

            ],
        },
        {
            label: "Vendors ",
            icon: "pi pi-fw pi-user",
            items: [
                {
                    label: "New Vendor",
                    icon: "pi pi-fw pi-user-plus",
                },
                {
                    label: "List of vendors ",
                    icon: "pi pi-fw pi-bars",
                },
                {
                    label: "Vendor Categories",
                    icon: "pi pi-fw pi-users",
                    items: [
                        {
                            label: "Filter",
                            icon: "pi pi-fw pi-filter",
                            items: [
                                {
                                    label: "Print",
                                    icon: "pi pi-fw pi-print",
                                },
                            ],
                        },
                        {
                            icon: "pi pi-fw pi-bars",
                            label: "List",
                        },
                    ],
                },
            ],
        },
        {
            label: "Suppliers",
            icon: "pi pi-fw pi-user-plus",
            items: [
                {
                    label: "New Supplier",
                    icon: "pi pi-fw pi-pencil",

                },
                {
                    label: "List of Suppliers",
                    icon: "pi pi-fw pi-bars",
                },
                {
                    label: "Supplier Categories ",
                    icon: "pi pi-fw pi-users",
                },
            ],
        },
    ];
    return (
        <Box className={'flex flex-col m-0 p-0 h-full relative'} >
            <div>
                <Breadcrumb spacing='2px' separator={<ChevronRightIcon color='gray.500' />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href='#/admin/supplier/all'>Clients</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href='#'></BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href='#'>Contact</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                <Menubar
                    pt={{
                        action: ({ props, state, context }) => ({}),
                    }}
                    onChange={(e) => console.log(e)}
                    model={items}
                    fontFamily={"RobotoCondensedRegular"}
                    className={"w-full h-fit  rounded-md"}
                />
            </div>
            <Outlet/>
        </Box>
    )
}


export default SupplierInterface
