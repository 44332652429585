import {Line} from 'react-chartjs-2'
import {Box} from "@chakra-ui/react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {TimeInterval} from "../../../utils/TimeInterval";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend

);
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Recent Tickets ',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

function HomeLineChart({tickets, start, labels}){
    const time_Interval= new TimeInterval()
    const data ={
        labels: time_Interval.getTimeInterval(start,7),
        datasets: [
            time_Interval.DateSet(tickets,start,7,'Open',"1",'1','#772b2b','#772b2b',0.5,true),
            time_Interval.DateSet(tickets,start,7,'Closed',"2",'1','#6cf15d','#30772b',0.5,true),
            time_Interval.DateSet(tickets,start,7,'Resolved',"3",'1','#215794','#4062d3',0.5,true),
            // {label: 'Closed', data: [2, 9, 30, 15, 2, 3], borderWidth: 1,borderColor:'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)',tension:0.3,fill:true },
        ]
    }

    return (<Line height={200} data={data} width={400} options={options}/>)
}


export default HomeLineChart