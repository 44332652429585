import React, { useState } from "react";
import {
  Box,
  Input,
  InputGroup,
  Text,
  InputRightElement,
  Button,
  InputLeftElement,
  Flex,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {userActions} from "../Portals/DataStore/UserData";
import * as Yup from "yup";
import { server_URL } from "../serverURL";
import axios from "axios";
import { useDispatch } from "react-redux";
import {Store} from "react-notifications-component"

interface User {
  fullName:string,
  phone:string ,
  email:string,
  password:string,
}
function SignupAsAnIndividual({ setsignUpas }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    fullName: "",
    phone: "",
    email: "",
    confirmEmail: "",
    Password: "",
    confirmPassword: "",
    toggled: false,
    checked: false,
  };
  const onSubmit = async (values) => {
    setIsLoading(true);
    const user:User ={
      fullName: values.fullName,
      phone: "0"+values.phone.toString(),
      email: values.email,
      password: values.password,
      address: ""
    };
    const config = {
      url: server_URL + "/users",
      method: "POST",
      headers:{
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: user,

    };
    await axios(config)
      .then((response) => {
        if (response) {
          setIsLoading(false)
          dispatch(userActions.addUser(response.data))
          navigate("/verify")
        }
      })
      .catch((e)=>{
        console.log(e)
        if(e.response?.status===401){
          MessageNotification({message:e.response.data,type:"Danger"})
        }else {
          MessageNotification({message:e.message,type:"Danger"})
        }

      });
  };
  const MessageNotification = (data)=>{
    Store.addNotification({
    message: data.message,
    type:data.type,
    insert: "bottom",
    container: "Bottom-center",
    animation :["animate_animated","animate_fadeIn"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
    onRemoval: () => {
      setIsLoading(false);
    },
    })
  }
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .length(9, "Ensure you have entered a correct SA cell number")
      .required("Required"),
    email: Yup.string().email().required("Email is required"),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email"), null], "Email do not match")
      .required("Required"),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
      )

      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password do not match")
      .required("Required"),
    checked: Yup.boolean()
      .oneOf([true], "Field must be checked")
      .required("Required"),
  });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, onChange, onBlur, setFieldValue }) => (
        <Form className={"p-4 w-full  shadow-d bg-gray-200 rounded-md"}>
          <Box className="flex m-3 align-middle flex-row">
            <Button
              className={"w-fit shadow-md"}
              onClick={() => setsignUpas(0)}
              leftIcon={<IoIosArrowBack />}
            >Back
            </Button>
            <Text
              className={"text-2xl w-full text-center block"}
              color={"#033053"}
              fontFamily={"RobotoCondensedBold"}
            >
              {" "}
              Signing Up as an Individual{" "}
            </Text>
          </Box>
          <Box className={""}>
            <Text
              htmlFor="fullName"
              as={"label"}
              className={"text-start w-full text-sm"}
            >
              Full Name{" "}
            </Text>
            <InputGroup m={0} className={"flex flex-col"}>
              <Input
                as={Field}
                type="text"
                id="fullName"
                name="fullName"
                bgColor={"white"}
                color={"gray.600"}
                _focusVisible={{ borderColor: "gray.300" }}
              />
              <ErrorMessage name="fullName"
                component={"div"}
                className={"w-full text-start text-sm text-red-600 m-1"}
              />
            </InputGroup>
          </Box>
          <Box className={"mt-2"}>
            <Text
              as={"label"}
              htmlFor="contact_Number"
              className={"text-start w-full text-sm"}
            >
              Contact Number
            </Text>
            <InputGroup m={0} className={"flex flex-col"}>
              <InputLeftElement pointerEvents={"none"} m={0} children="+27" />
              <Input
                as={Field}
                type="number"
                id="contact_Number"
                name="phone"
                // borderColor={SignUpForm.errors.Phone ? "red" : "white"}
                bgColor={"white"}
                color={"gray.600"}
                onBlur={onBlur}
                _focusVisible={{ borderColor: "gray.300" }}
              />
              <ErrorMessage
                name="phone"
                component="div"
                className={"w-full text-start text-sm text-red-600 m-1"}
              />
            </InputGroup>
          </Box>
          <Box className={"mt-2"}>
            <Text
              as={"label"}
              className={"text-start w-full text-sm"}
              htmlFor="email_address"
            >  Email Address
            </Text>
            <InputGroup m={0} className={"flex flex-col"}>
              <Input
                as={Field}
                type="email"
                id="email_address"
                name="email"
                bgColor={"white"}
                color={"gray.600"}
                _focusVisible={{ borderColor: "gray.300" }}
              />
              <ErrorMessage
                name="email"
                component="div"
                className={"w-full text-start text-sm text-red-600 m-1"}
              />
            </InputGroup>
          </Box>
          <Box className={"pt-2"}>
            <Text
              as={"label"}
              className={"text-start w-full text-sm"}
              htmlFor="confirm_Email"
            >
              Confirm Email Address:
            </Text>
            <InputGroup m={0} className={"flex flex-col"}>
              <Input
                as={Field}
                type="text"
                id="confirm_Email"
                name="confirmEmail"
                bgColor={"white"}
                color={"gray.600"}
                _focusVisible={{ borderColor: "gray.300" }}
              />

              <ErrorMessage
                name="confirmEmail"
                component="div"
                className={"w-full text-start text-sm text-red-600 m-1"}
              />
            </InputGroup>
          </Box>
          <Box className={"mt-2"}>
            <Text
              as={"label"}
              className={"text-start w-full text-sm "}
              htmlFor="password"
            >
              Password
            </Text>
            <InputGroup m={0} className={"flex flex-col"}>
              <Input
                as={Field}
                type={show ? "text" : "password"}
                id="password"
                name="password"
                bgColor={"white"}
                color={"gray.600"}
                _focusVisible={{ borderColor: "gray.300" }}
              />
              <InputRightElement>
                <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                  {show ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
              <ErrorMessage
                name="password"
                component={"div"}
                className={"w-full text-start text-sm text-red-600 m-1"}
              />
            </InputGroup>
          </Box>
          <Box>
            <Text
              as={"label"}
              className={"text-start w-full text-sm "}
              htmlFor="confirmPassword"
            >
              Confirm Password
            </Text>
            <InputGroup m={0} className={"flex flex-col"}>
              <Input
                as={Field}
                type={show ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                bgColor={"white"}
                color={"gray.600"}
                _focusVisible={{ borderColor: "gray.300" }}
              />
              <InputRightElement>
                <Button h="1.75rem" size="sm" onClick={() => setShow(!show)}>
                  {show ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
              <ErrorMessage
                name="confirmPassword"
                component={"dvi"}
                className={"w-full text-start text-sm text-red-600 m-1"}
              />
            </InputGroup>
          </Box>
          <Flex display={"flex"} w={"100%"} px={2} flexDirection={"column"}>
            <Flex className={"flex flex-row justify-start align-middle"}>
              <Checkbox
                type={"checkbox"}
                textAlign={"start"}
                name="checked"
                onChange={(e) => setFieldValue("checked", e.target.checked)}
                size="lg"
                className={"mx-1 my-auto p-0 h-fit w-fit"}
                spacing="1rem"
                colorScheme="blue"
                bgColor={"whitesmoke"}
              ></Checkbox>
              <Box
                className={
                  "block my-auto justify-start align-items-start text-start"
                }
              >
                <Text
                  className={"text-black text-start inline  text-sm m-0 p-0"}
                >
                  {" "}
                  Check this box if you have read and agree to our{" "}
                </Text>
                <Link to="/privacy" className={"mx-1 inline-block"}>
                  <Text
                    className={
                      "text-sm text-bold m-0 p-0 underline  text-gray-500"
                    }
                  >
                    Agreement Terms
                  </Text>
                </Link>
              </Box>
            </Flex>
            <ErrorMessage
              name="checked"
              component={"div"}
              className={"w-full text-start text-sm text-red-600 m-1"}
            />

          </Flex>
          <Button isDisabled={isLoading ? true : false} type="submit">
            {isLoading ? <Spinner /> : <Text>Submit</Text>}
          </Button>
          <Link to="/login">
            <Text className={"text-md"}>I have an account.</Text>
          </Link>
          {/*{JSON.stringify(errors)}*/}
        </Form>
      )}
    </Formik>
  );
}

export default SignupAsAnIndividual;
//
// FullName:"",
//     Phone:"",
//     EmailAddress:"",
//     businessName:"",
//     registrationNumber:"",
//     ConfirmEmail:"",
//     Password:"",
//     confirmPassword:"",
//     company:"",
//     toggled:false,
// checked:false,
