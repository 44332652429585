import { CheckIcon, SearchIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormLabel, Input, InputGroup, InputRightElement, Text, useDisclosure } from '@chakra-ui/react'
import React,{useState} from 'react'
import { BiUpArrowCircle } from 'react-icons/bi'
import {Outlet, useLocation, useOutletContext} from 'react-router-dom'
import { CheckPicker, Col, FlexboxGrid, SelectPicker } from 'rsuite'
import TicketsList from './TicketsList'
import TicketViewer from './TicketViewer'

function AdminTickets() {
    return (
    <Box h={'100%'} className={'flex flex-col m-0 p-0 h-full relative'} >
      <Outlet />
    </Box>
  )
}
export default AdminTickets
