import NavBar from '../../components/navBar';
import Footer from '../../pages/Footer';
import {Box, Heading, Text, Button, Center, Avatar, Flex,Input, Spinner} from "@chakra-ui/react";
import {Formik,Form,ErrorMessage,Field} from 'formik'
import * as Yup from 'yup'
import React, {useEffect, useState} from "react";
import {useDispatch} from 'react-redux'
import {AgentSignUpDataActions} from "../DataStore/AgentStore/AgentSignUpData";
import {useNavigate} from "react-router-dom";
import {server_URL} from "../../serverURL";
import axios from "axios";
import {Store} from "react-notifications-component";
import {userActions} from "../DataStore/UserData";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountrySelect from "react-phone-number-input";
import './AgentSignigUp.scss'



const validationSchema = Yup.object().shape({
    fullName:Yup.string().required("Required*"),
    email:Yup.string().email('Invalid email').required('Required*'),
    password:Yup.string()
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
        ).required('Required'),
    confirmPassword:Yup.string().required("Required").oneOf([Yup.ref('password'),null], "Passwords must match"),
    phone:Yup.string().required('Required*')
})
function AgentSigningUp () {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading,setLoading]= useState(false)
    const [country,setCountry]=useState('ZA')
    const userExitMessage = () => {
        Store.addNotification({
            title: "Sorry...,This user exist!!!",
            message: "",
            type: "danger",
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
            onRemoval: () => {
                setLoading(false);
            },
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const submitRegistration=async (values) => {
        setLoading(true)
        const config = {
            url: server_URL + '/agents/new',
            method: 'POST',
            headers: {
                Accept: "application/json",
            },
            data: {
                values,
            }
        }
        await axios(config).then(response=>{
            console.log("sign Up response",response)
            if(response){
                dispatch(userActions.addUser(values))
                setLoading(false)

                navigate('/agentsmsverify')
            }
            else {
                userExitMessage()
            }
        }).catch( error=>{
              console.log("Sign Up error", error )
                userExitMessage()
        }

        )
    }




 return (
    <Box >
        <NavBar/>
        <Box h={'100%'} className={'grid grid-cols-1 md:grid-cols-2 p-10 md:py-28 md:px-20 w-100'}>
            <Center className={'w-full md:w-100 lg:w-50'} >
                <Box>
                    <Text as={'span'} className={'text-3xl md:text-xl'}
                          bgGradient="linear(to-r, blue.900,blue.200)"
                          fontFamily={'RobotoCondensedBold'}
                          bgClip="text" fontSize={"1.5em"} py={'3em'}>A pay as you use IT support service.</Text>
                    <Text className={'text-lg'} fontFamily={'RobotoCondensedBold'} fontWeight={'800'} color={'gray.900'} py={'.5em'} lineHeight='1em' >
                        We’re looking for amazing technicians and Engineers  just like you! <br></br>Become a part
                        of our rockstar technical team and skyrocket your career!
                    </Text>
                    <Flex flexDir={'column'} m={4} p={3}>
                        <Flex alignItems={'center'}>
                            <Avatar bg={'green.500'} size={'md'} name='1' m={1}/>
                            <Text m={1}>Complete the registration form </Text>
                        </Flex>
                        <Flex alignItems={'center'} m={1}>
                            <Avatar bg={'blue.800'} size={'md'} name='2'/>
                            <Text m={2} textAlign={'start'}>You will receive a One Time Pin on your cellphone. </Text>
                        </Flex>
                        <Flex alignItems={'center'} m={1}>
                            <Avatar bg={'blue.800'} size={'md'} name='3'/>
                            <Text m={2}>Confirm your email address </Text>
                        </Flex>
                    </Flex>
                </Box>

            </Center>
           <Center className={'w-full md:w-100 lg:w-50'}>
               <Box h={'auto'}  bg={'gray.50'}
                    rounded={'xl'}
                   className={'p-2 m-2'}
                    boxShadow='lg'
                    height={'auto'}
                    m='0em'
                    w={'100%'}
                    maxW={'100%'}>
                   <Heading
                       color={'gray.800'}
                       lineHeight={1.1}
                       fontFamily='RobotoCondensedBold'
                       fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
                       Join our team
                       <Text
                           as={'span'}
                           bgGradient="linear(to-r, blue.900,blue.400)"
                           bgClip="text">
                           !
                       </Text>
                   </Heading>
                   <Box as={Formik}
                        width={'100%'}
                       initialValues={{ email: '', password: '',phone:'',fullName:'',confirmPassword:'' }}
                       validationSchema={validationSchema}
                       onSubmit={(values, { setSubmitting }) => {
                           dispatch(AgentSignUpDataActions.addNewAgent(values))
                           submitRegistration(values)

                       }}
                   >
                       {({ setFieldValue,handleBlur,handleFocus,
                             isSubmitting,
                             /* and other goodies */
                         }) => (
                           <Box as={Form} className={'p-4 w-full'}>

                               <Box as={Field} className={'p-2 my-2 rounded hover:border-red-50'} _active={{color:'gray.200'}} _hover={{outline:'none'}} type={'text'} w='100%' name={'fullName'} placeholder={'full Name'}/>
                               <Box as={ErrorMessage} className={'mx-1 text-xs text-left text-red-600'} name={'fullName'} component={'div'}/>

                               <Box as={Field} className={'p-2 my-2  rounded hover:border-red-50'} _active={{color:'gray.200'}} _hover={{outline:'none'}} type={'text'} w='100%' name={'email'} placeholder={'Email'}/>
                               <Box as={ErrorMessage} className={'mx-1 text-xs text-left text-red-600'} name={'email'} component={'div'}/>
                               {/*<Box as={Field} className={'m-1 p-2 rounded hover:border-red-50'} _active={{color:'gray.200'}} _hover={{outline:'none'}} type={'text'} w='100%' name={'phone'} placeholder={'Cell Number '}/>*/}
                               {/*<Box as={ErrorMessage} className={'mx-1 text-xs text-left text-red-600'} name={'phone'} component={'div'}/>*/}
                               <Box as={PhoneInputWithCountrySelect}
                                    placeholder="Phone number"
                                    bg={'white'}
                                    border={0}
                                    smartCaret={true}
                                    name='phone'
                                    fontSize='1em'
                                    className={'px-2 my-2'}
                                    onChange={(value)=>setFieldValue('phone',value?.toString()||'')}
                                    defaultCountry="ZA"
                                    onCountryChange={(e)=>setCountry(e)}
                               />
                               <Box as={ErrorMessage} className={' text-xs text-left text-red-600'} name={'phone'} component={'div'}/>

                               <Box as={Field} className={'p-2 my-2 w-full rounded hover:border-red-50'} _active={{color:'gray.200'}} outline='none' type={'text'} w='100%' name={'password'} placeholder={'Password'}/>
                               <Box as={ErrorMessage} className={'mx-1 text-xs text-left text-red-600'} name={'password'} component={'div'}/>

                               <Box as={Field} className={'p-2 my-2 rounded hover:border-red-50'} _active={{color:'gray.200'}} outline='none' type={'text'} w='100%' name={'confirmPassword'} placeholder={'ConfirmPassword'}/>
                               <Box as={ErrorMessage} className={'mx-1 text-xs text-left text-red-600'} name={'confirmPassword'} component={'div'}/>
                               <Button className={'m-2'} type={'submit'} >
                                   {loading?<Spinner/>:<Text>Submit</Text>}
                               </Button>
                           </Box>
                       )}
                   </Box>
               </Box>
           </Center>
           </Box>
        <Footer/>
    </Box>
 )
}

export default AgentSigningUp