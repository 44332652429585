import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";
import "rsuite/dist/rsuite.min.css";
import "primereact/resources/primereact.min.css";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import UserSignUp from "./pages/UserSignUp";
import store from "./Portals/DataStore/mainStore";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";
import Aboutus from "./pages/Aboutus";
import EnduserServices from "./pages/EnduserServices";
import FAQs from "./pages/FAQs";
import Contactus from "./pages/Contactus";
import Sales from "./pages/Sales";
import Infrastructure from "./pages/InfrastructureServices";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import AccountVerificationQ from "./pages/AccountVerificationQ";
import VerificationPage from "./pages/VerificationPage";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import Error404 from "./pages/Error404";
import HomePage from "./pages/HomePage";
import Downloads from "./pages/Downloads";
import AgreementTerms from "./pages/AgreementTerms.js";
import { ReactNotifications } from "react-notifications-component";
import PrivateRoutes from "./utils/ProtectedRoutes";
import AgentSigningUp from "./Portals/Agent/AgentSigningUp";
import AdminInterface from "./Portals/Admin/AdminInterface";
import ClientInterface from "./Portals/Client/ClientInterface";
import AdminHome from "./Portals/Admin/AdminHome";
import AdminTickets from "./Portals/Admin/components/Tickets/AdminTickets";
import AdminAllTickets from "./Portals/Admin/components/Tickets/AdminAllTickets";
import TicketViewer from "./Portals/Admin/components/Tickets/TicketViewer";
import AdminNewTicket from "./Portals/Admin/components/Tickets/AdminNewTicket";
import AdminProfile from "./Portals/Admin/AdminProfile";
import NewAgent from "./Portals/Admin/components/Agents/NewAgent";
import AllAgents from "./Portals/Admin/components/Agents/AllAgents";
import AgentsInterface from "./Portals/Admin/components/Agents/AgentsInterface";
import AdminViewAgent from "./Portals/Admin/components/Agents/AdminViewAgent";
import NewClient from "./Portals/Admin/components/AdminClients/AdminNewClient";
import ViewClient from "./Portals/Admin/components/AdminClients/AdminViewClient";
import AllClients from "./Portals/Admin/components/AdminClients/AdminAllClients";
import AdminClientInterface from "./Portals/Admin/components/AdminClients/AdminClientInterface";
import AdminNewClient from "./Portals/Admin/components/AdminClients/AdminNewClient";
import AdminViewClient from "./Portals/Admin/components/AdminClients/AdminViewClient";
import AdminAllClients from "./Portals/Admin/components/AdminClients/AdminAllClients";
import AllAdministrators from "./Portals/Admin/components/Admins/AllAdministrators";
import NewAdmin from "./Portals/Admin/components/Admins/NewAdmin";
import ViewAdministrator from "./Portals/Admin/components/Admins/ViewAdministrator";
import AdminCompanyInterface from "./Portals/Admin/components/Company/AdminCompanyInterface";
import AdminNewCompany from "./Portals/Admin/components/Company/AdminNewCompany";
import ClientHome from "./Portals/Client/ClientHome";
import ClientTickets from "./Portals/Client/ClientTickets";
import ClientBilling from "./Portals/Client/ClientBilling";
import ClientTicketsList from "./Portals/Client/components/ClientTicketsList";
import TicketView from "./Portals/Client/components/TicketView";
import ClientProfile from "./Portals/Client/ClientProfile";
import ClientSettings from "./Portals/Client/ClientSettings";
import AgentOnboarding from "./Portals/Agent/AgentOnboarding";
import AgentSMSopt from "./Portals/Agent/AgentSMSopt";
import AgentEmailverification from "./Portals/Agent/AgentEmailverification";
import AgentApplicationStatus from "./Portals/Agent/AgentApplicationStatus";
import AdminSignUp from "./Portals/Admin/AdminSignUp";
import AdminSMSotp from "./Portals/Admin/AdminSMSotp";
import AdmniEmailVerification from "./Portals/Admin/AdmniEmailVerification";
import AgentEmailVerified from "./Portals/Agent/AgentEmailVerified";
import SmsVerify from "./pages/SmsVerify";
import VerifyEmail from "./pages/VerifyEmail";
import SLA from "./Portals/Admin/components/AdminSettings/SLA";
import AdminSettingsInterface from "./Portals/Admin/components/AdminSettings/AdminSettingsInterface";
import AdminEmailNotifications from "./Portals/Admin/components/AdminSettings/AdminEmailNotifications";
import AdminSettings from "./Portals/Admin/components/AdminSettings/AdminSettings";
import AccessControl from "./Portals/Admin/components/AdminSettings/AccessControl";
import SecuritySystems from "./pages/SecuritySystems";
import AgentInterface from "./Portals/Agent/AgentInterface";
import AgentHome from "./Portals/Agent/AgentHome";
import AgentProfile from "./Portals/Agent/AgentProfile";
import AgentSettings from "./Portals/Agent/AgentSettings";
import AgentTicketsInterface from "./Portals/Agent/components/AgentTicketsInterface";
import AllAgentTickets from "./Portals/Agent/components/AllAgentTickets";
import AgentTicketViewer from "./Portals/Agent/components/AgentTicketViewer";
import EmailVerification from "./pages/EmailVerification";
import SignUpv2 from "./pages/SignUpv2";
import AdminOrdersInterface from "./Portals/Admin/components/Orders/AdminOrdersInterface";
import AllOrders from "./Portals/Admin/components/Orders/AllOrders";
import NewOrder from "./Portals/Admin/components/Orders/NewOrder";
import NewItem from "./Portals/Admin/components/Orders/Items/NewItem";
import ViewItem from "./Portals/Admin/components/Orders/Items/ViewItem";
import ListofItems from "./Portals/Admin/components/Orders/Items/ListofItems";
import EditItem from "./Portals/Admin/components/Orders/Items/EditItem";
import {ItemCategoryList} from "./Portals/Admin/components/Orders/Items/Categories/ItemCategoryList";
import {ItemCategory} from "./Portals/Admin/components/Orders/Items/Categories/ItemCategory";
import SupplierInterface from "./Portals/Admin/components/Suppliers/SupplierInterface";
import {NewSupplier} from "./Portals/Admin/components/Suppliers/NewSupplier";
import {ViewSupplier} from "./Portals/Admin/components/Suppliers/ViewSupplier";
import {AllSuppliers} from "./Portals/Admin/components/Suppliers/AllSuppliers";
import {NewInvoice} from "./Portals/Admin/components/Orders/Invoices/NewInvoice";
import {ViewInvoice} from "./Portals/Admin/components/Orders/Invoices/ViewInvoice";
import {AllInvoices} from "./Portals/Admin/components/Orders/Invoices/AllInvoices";
import {NewQoute} from "./Portals/Admin/components/Orders/Qoutes/NewQoute";
import {ViewQoute} from "./Portals/Admin/components/Orders/Qoutes/ViewQoute";
import {QoutesList} from "./Portals/Admin/components/Orders/Qoutes/QoutesList";
import VendorInterface from "./Portals/Admin/components/Vendor/VendorInterface";
import NewVendor from "./Portals/Admin/components/Vendor/NewVendor";
import ViewVendor from "./Portals/Admin/components/Vendor/ViewVendor";
import AllVendors from "./Portals/Admin/components/Vendor/AllVendors";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.addEventListener("Error", () => {
      console.log("Error occured");
    });
    return () => {};
  }, []);
  return (
    <Provider store={store}>
      <ChakraProvider>
        <ReactNotifications />
        <div className="App">
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route exact path="/contacts" element={<Contactus />} />
            <Route exact path="/downloads" element={<Downloads />} />
            <Route path="/enduserservices" element={<EnduserServices />} />
            <Route
              path="/infrastructureservices"
              element={<Infrastructure />}
            />
            <Route path="/securitysystems" element={<SecuritySystems />} />
            <Route path={`/newAdmin`} exact element={<AdminSignUp />} />
            <Route element={<PrivateRoutes />}>
              <Route
                path={`/new/adminverify`}
                exact
                element={<AdminSMSotp />}
              />
              <Route
                path={`/new/adminemailverify`}
                exact
                element={<AdmniEmailVerification />}
              />
              {/* <Route path={'/admindashboard'} element={<Admin/>}/> */}
              {/* <Route path={'/agentdashboard'} element={<AgentInterface/>}/> */}
              {/* Admin */}
              <Route path="/admin" exact element={<AdminInterface />}>
                <Route path="analytics" element={<AdminHome />} />

                {/* Admin tickets  */}
                <Route path="ticket" element={<AdminTickets />}>
                  <Route path="all" element={<AdminAllTickets />} />
                  <Route path=":ticketId" element={<TicketViewer />} />
                  <Route path="new" element={<AdminNewTicket />} />
                </Route>

                {/* Admin profile */}
                <Route path="profile" element={<AdminProfile />} />
                {/* Admin Agents */}
                <Route path="agent" element={<AgentsInterface />}>
                  <Route path="new" element={<NewAgent />} />
                  <Route
                    path=":agentEmail"
                    exact
                    element={<AdminViewAgent />}
                  />
                  <Route path="all" element={<AllAgents />} />
                </Route>
                {/* Admin Users  */}
                <Route path="user" element={<AdminClientInterface />}>
                  <Route path="new" element={<AdminNewClient />} />
                  <Route path=":clientId" exact element={<AdminViewClient />} />
                  <Route path="all" element={<AdminAllClients />} />
                </Route>
                <Route path="supplier" element={<SupplierInterface />}>
                  <Route path="new" element={<NewSupplier />} />
                  <Route path=":supplierId" exact element={<ViewSupplier />} />
                  <Route path="all" element={<AllSuppliers />} />
                </Route>
                <Route path="vendor" element={<VendorInterface />}>
                  <Route path="new" element={<NewVendor />} />
                  <Route path=":supplierId" exact element={<ViewVendor/>} />
                  <Route path="all" element={<AllVendors />} />
                </Route>
                <Route path="items" element={<AdminClientInterface />}>
                  <Route path="new" element={<AdminNewClient />} />
                  <Route path=":itemId" exact element={<AdminViewClient />} />
                  <Route path="all" element={<AdminAllClients />} />
                </Route>
                <Route path="usergroup" element={<AdminClientInterface />}>
                  <Route path="new" element={<AdminNewClient />} />
                  <Route path=":groupId" exact element={<AdminViewClient />} />
                  <Route path="all" element={<AdminAllClients />} />
                </Route>
                {/* Admins  */}
                <Route path="admin" element={<AdminClientInterface />}>
                  <Route path="new" element={<NewAdmin />} />
                  <Route
                    path=":adminId"
                    exact
                    element={<ViewAdministrator />}
                  />
                  <Route path="all" element={<AllAdministrators />} />
                </Route>

                {/* Admin Company  */}
                <Route path="company" element={<AdminCompanyInterface />}>
                  <Route path="new" element={<AdminNewCompany />} />
                </Route>

                {/* Admin Orders  */}
                <Route path="orders" element={<AdminOrdersInterface />}>
                  <Route path="" element={<AllOrders />} />
                  <Route path="new" element={<NewOrder />} />
                  <Route path="items/new" element={<NewItem />} />
                  <Route path="items/:id" element={<ViewItem />} />
                  <Route path="items/all" element={<ListofItems />} />
                  <Route path="items/{id}" element={<EditItem />} />
                  <Route
                    path="items/category/"
                    element={<ItemCategoryList />}
                  />
                  <Route
                    path="items/category/{id}"
                    element={<ItemCategory />}
                  />

                  <Route path="invoices/new" element={<NewInvoice />} />
                  <Route path="invoices/:id" element={<ViewInvoice />} />
                  <Route path="invoices/all" element={<AllInvoices />} />

                  <Route path="quotes/new" element={<NewQoute />} />
                  <Route path="quotes/:id" element={<ViewQoute />} />
                  <Route path="quotes/all" element={<QoutesList />} />
                </Route>
                {/* Admin Settings*/}
                <Route path={"settings"} element={<AdminSettingsInterface />}>
                  <Route path={""} element={<AdminSettings />} />
                  <Route path={"sla"} element={<SLA />} />
                  <Route
                    path={"email_notification"}
                    element={<AdminEmailNotifications />}
                  />
                  <Route path={"accesscontrol"} element={<AccessControl />} />
                </Route>
              </Route>
              <Route path="mydashboard/" exact element={<ClientInterface />}>
                {/* <Route path="/mydashboard" element={<Clientdashboard/>}/> */}
                <Route path="home" exact element={<ClientHome />} />
                <Route path="tickets" exact element={<ClientTickets />}>
                  <Route path="all" exact element={<ClientTicketsList />} />
                  <Route path=":id" exact element={<TicketView />} />
                </Route>
                <Route path="billing" exact element={<ClientBilling />} />
                <Route path="profile" exact element={<ClientProfile />} />
                <Route path="settings" exact element={<ClientSettings />} />
              </Route>
              <Route path="/agent" exact element={<AgentInterface />}>
                <Route path={"home"} exact element={<AgentHome />} />
                <Route path={"me"} exact element={<AgentProfile />} />
                <Route path={"settings"} exact element={<AgentSettings />} />
                <Route
                  path={"tickets"}
                  exact
                  element={<AgentTicketsInterface />}
                >
                  <Route path={"all"} exact element={<AllAgentTickets />} />
                  <Route
                    path={":ticketID"}
                    exact
                    element={<AgentTicketViewer />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="/salesservices" exact element={<Sales />} />
            <Route path="/faqs" exact element={<FAQs />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="agentsignup" exact element={<AgentSigningUp />} />
            <Route path={"agentsmsverify"} exact element={<AgentSMSopt />} />
            <Route
              path={"agent_email_v"}
              exact
              element={<AgentEmailverification />}
            />
            <Route
              path={"agentmailverified"}
              element={<AgentEmailVerified />}
            />
            <Route path="/onboarding" exact element={<AgentOnboarding />} />
            <Route
              path={"applicationstatus"}
              exact
              element={<AgentApplicationStatus />}
            />
            <Route path="/signup" exact element={<SignUpv2 />}></Route>
            <Route path="/signup_v2" exact element={<UserSignUp />}></Route>
            <Route
              path="/verifyaccount"
              exact
              element={<AccountVerificationQ />}
            />
            <Route path="/verify" exact element={<VerificationPage />} />
            <Route path="/verified" exact element={<VerifyEmail />} />
            <Route path="/verifyEmail" exact element={<EmailVerification />} />
            <Route path="/privacy" exact element={<AgreementTerms />} />
            <Route path="/forgotpassword" exact element={<ForgotPassword />} />
            <Route path="/password" exact element={<NewPassword />} />
            <Route path="/sms" exact element={<SmsVerify />} />
            {/* Dashboard Routes */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </ChakraProvider>
    </Provider>
  );
}
export default App;
