import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Text,
  ModalOverlay,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Tag,
  Tooltip,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Avatar,
  MenuOptionGroup,
  MenuItemOption,
  Select,
  IconButton,
  Input,
  Textarea,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter, Checkbox, Icon, Stack, Center,
} from "@chakra-ui/react";
import {ArrowLeft, Plus} from "@rsuite/icons";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import { Store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import "primereact/resources/primereact.min.css";

import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { IoArrowUpCircleOutline, IoFlag, IoPlayCircle } from "react-icons/io5";
import { ImUser, ImUserPlus } from "react-icons/im";
import { IoIosPrint, IoMdSquare } from "react-icons/io";
import { SelectPicker } from "rsuite";
import {
  AttachmentIcon,
  ChevronDownIcon,
  DownloadIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import AdminTicketSlice, {
  AdminTicketsActions,
} from "../../../DataStore/AdminStore/Tickets";
import axios from "axios";
import { server_URL } from "../../../../serverURL";
import { AdminclientsActions } from "../../../DataStore/AdminStore/Clients";
import "../selectorTheme";
import Conversations from "./Conversations";
import {BiArrowBack, BiFile} from "react-icons/bi";
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { Dropdown } from "primereact/dropdown";
import "./ticketView.scss";
import { useFormik } from "formik";
import { FileUpload } from "primereact/fileupload";
import moment from "moment";
import "primereact/resources/primereact.min.css";
import {ListBox} from "primereact/listbox";
import CountdownTimer, {useCountdown} from "./CountDown";
import {FilePdfO} from "@rsuite/icons/es/icons/legacy";
import PlusIcon from "@rsuite/icons/Plus";

function TicketViewer() {
  const params = useParams();
  const userData = useSelector((state) => state.user);
  const [adminSocket] = useOutletContext();
  const [loadingData, setLoadingData] = useState(true);
  const [inputMessage, setInputMessage] = useState("");
  const [description, setNote] = useState("");
  const [files, setFiles] = useState(null);
  const [hasConversation, setHasConversation] = useState(false);
  const [newMessage, setnewMessage] = useState("");
  const [reAssignMessage, setReAssignMessage] = useState("");
  const [escalating, setEscalating] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileUploaderRef= useRef(null)
  const assignAgentRef= useRef(null)
  const {
    isOpen: assignAgentisOpen,
    onOpen: assignAgentonOpen,
    onClose: assignAgentClose,
  } = useDisclosure();
  const {
    isOpen: statusisOpen,
    onOpen: statusonOpen,
    onClose: statusonClose,
  } = useDisclosure();
  const {
    isOpen: priorityisOpen,
    onOpen: priorityonOpen,
    onClose: priorityonClose,
  } = useDisclosure();
  const {
    isOpen: escalateisOpen,
    onOpen: escalateonOpen,
    onClose: escalateonClose,
  } = useDisclosure();
  const {
    isOpen: resolutionisOpen,
    onOpen: resolutiononOpen,
    onClose: resolutiononClose,
  } = useDisclosure();
  const {
    isOpen: attachmentsisOpen,
    onOpen: attachmentsonOpen,
    onClose: attachmentsonClose,
  } = useDisclosure();
  const adminClients = useSelector((state) => state.adminclients);
  const agents = adminClients.clients.filter(
    (client) => client.role === "AGENT"
  );
  const statuses = [
    { label: "Open", value: 1 },
    { label: "Closed", value: 3 },
    { label: "Pending", value: 2 },
    { label: "Resolved", value: 4 },
  ];

  const statusDescriptions = {
    1: "Open",
    2: "Pending",
    3: "Closed",
    4: "Resolved",
  };
  let currentTicket = useSelector((state) => state.admintickets).currentTicket;
  const [ticketAgent, setTicketAgent] = useState({
    email: currentTicket?.agent | null,
  });
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [reAsssigning, setreAssigning] = useState(currentTicket?.status | null);
  const [status, setStatus] = useState( null);
  const [closeAfterUpload , setCloseAfterUpload] = useState(false);
  const [assignedAgent , setAssignedAgent] = useState(null);
  const ticket = useSelector((state) => state.admintickets).tickets.filter(
    (ticket) => ticket.id.toString() === params.ticketId
  )[0];
  const today = new Date()
  const [consultationTime, setConsultationTime]= useState(currentTicket?.consultation?currentTicket.consultation.createdAt:null)
  const data = adminClients.clients
    .filter((client) => client.role === "AGENT")
    .map((client) => client.fullName)
    .map((item) => ({ label: item, value: item }));
  const statusMessage = (message, status) => {
    Store.addNotification({
      message: `System Message:${message}`,
      type: status,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  const sendEvent = async () => {
    await adminSocket.emit("joinRoom", {
      room: currentTicket.conversation.conversationId,
    });
  };
  const getticket = async () => {
    setLoadingData(true);
    const config = {
      method: "get",
      url: server_URL + `/admin/ticket/${ticket.id}/${ticket.sender}`,
      headers: {
        "Content-Type": "Application/json",
      },
      data: {
        email: ticket.sender,
        id: ticket.id,
      },
    };
    await axios(config)
      .then((response) => {
        if (response) {

          currentTicket = response.data;
          dispatch(AdminTicketsActions.addCurrentTicket(response.data));
          sendEvent();
          statusMessage("Ticket fetched", "success");
          setLoadingData(false);
        } else {
          statusMessage("No response ", "danger");
          setLoadingData(false);
        }
      })
      .catch((error) => {
        statusMessage("Ticket fetched", "danger");
        setLoadingData(false);
      });
  };
  useLayoutEffect(() => {
    getticket();
    return () => {
      //dispatch(AdminTicketsActions.removeCurrentTicket());
    };
  }, []);
  useEffect(() => {}, [newMessage]);
  useEffect(() => {
    assignAgent();
  }, [ticketAgent]);
  useEffect(() => {
    if (status) {
      statusonOpen();
    }
  }, [status]);
  useEffect(() => {
    if(assignedAgent!==null) {
      assignAgentonOpen()
    }
  }, [assignedAgent]);
  const sendNewMessage = () => {
    //send to socket io
    //send to lis
    if (inputMessage !== "") {
      const message = {
        text: inputMessage,
        createdAt: new Date().toJSON().slice(0, 19).replace("T", " "),
        sender: currentTicket.sender,
        attachment_id: "",
        type: "message",
      };
      adminSocket.emit("$techresq:message:add", {
        ticketId: `${currentTicket.id}`,
        useremail: `${currentTicket.sender}`,
        message,
      });
      setInputMessage("");
    }
  };
  const assignAgent = () => {
    assignAgentClose()
    if (
        data &&selectedAgent&&
      selectedAgent?.email !== currentTicket?.agent
    ) {
      adminSocket.emit("$techresq:ticket:agent:assign", {
        agent: selectedAgent.email,
        sender: currentTicket.sender,
        message: `Assign ticket to ${selectedAgent.fullName}`
      });
    }
  };
  const changeStatus = (noteData) => {
    if (status && currentTicket?.status && status !== currentTicket?.status) {
      if (
        currentTicket.id.toString() !== "0" &&
        currentTicket.id.toString() !== 0
      ) {
        adminSocket.emit("$techresq:tickets:status:update", {
          status: status,
          ticketId: currentTicket.id,
          noteData,
        });
      }
    }
  };
  const changingStatusForm = useFormik({
    initialValues: {
      description: "",
      type: "status",
      creator: userData.email,
    },
    onSubmit: (values) => {
      const _status = statusDescriptions[status];
      values.subject = `Changed Status :${_status}`;
      changeStatus(values);
      statusonClose();
    },
    validate: (values) => {
      let errors = {};
      if (!values.description) {
        errors.description = "Required";
      }
      return errors;
    },
  });
  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <Box
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "space-between",
          width:'100%'
        }}
      >
        <Box className={'w-auto'}>{chooseButton}</Box>
        <Box className={'w-auto'}>{uploadButton}</Box>
        <Box className={'w-auto'}>{cancelButton}</Box>
      </Box>
    );
  };
  const ticketFileUploadHandler = ()=>{
    if(closeAfterUpload){
      attachmentsonClose()
    }
  }
  const onSelectTemplate= (e) => {
    const files = e.files
  }
  const beginConsultation = ()=>{
    console.log('consultation beginning....')
    setConsultationTime(today)
    adminSocket.emit('$techresq:consultation:add',{sender:currentTicket.sender,agent:userData.email})
  }
  const finishConsultation = ()=>{
    adminSocket.emit('$techresq:consultation:update',{consultationId:currentTicket.consultation.consultationId,ticketId:currentTicket.id,agent:currentTicket.agent})
  }
  const removeAttachment =()=>{

  }
  const itemTemplate = (file, props) => {
    return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
            <span className="flex flex-column text-left ml-3">
                        {file.name}
              <small>{new Date().toLocaleDateString()}</small>
                    </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeAttachment()} />
        </div>
    );
  };
  return (
    <Box className={"grid grid-cols-6 overflow-x-hidden overflow-y-auto"}  h={"100%"}>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={resolutionisOpen}
        onClose={resolutiononClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resolution</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={resolutiononClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={statusisOpen}
        onClose={statusonClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"4px"}>
          <ModalHeader bgColor={"blue.800"}>
            <Text
              className={"text-center"}
              fontFamily={"Roboto"}
              color={"white"}
            >
              Status Change
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={changingStatusForm.handleSubmit}>
              <Box>
                <Text name={"description"}>
                  Changing status to :{statusDescriptions[status]}
                </Text>
                <Text>Note: </Text>
                <Textarea
                  name={"description"}
                  isInvalid={!!changingStatusForm.errors.description}
                  value={changingStatusForm.values.description}
                  onBlur={changingStatusForm.handleBlur}
                  onChange={changingStatusForm.handleChange}
                  size="sm"
                  placeholder={"Type your description here ..."}
                  resize={"none"}
                  h={"10em"}
                  className={"w-full"}
                />
                {changingStatusForm.errors.description ? (
                  <Text className={"text-sm"} color={"red.400"}>
                    {changingStatusForm.errors.description}
                  </Text>
                ) : (
                  ""
                )}
                {statusDescriptions[status]==='Resolved'?<Checkbox>Bill ticket</Checkbox>:''}

                <Box
                  className={
                    "flex flex-row my-2 p-0 mx-0 w-full justify-between"
                  }
                >
                  <Button
                    className={"m-0"}
                    variant="solid"
                    type={"submit"}
                    color={"whitesmoke"}
                    bgColor={"whatsapp.600"}
                  >
                    Change Status
                  </Button>
                  <Button
                    className={"m-0"}
                    colorScheme="gray"
                    mr={3}
                    onClick={statusonClose}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={priorityisOpen}
        onClose={priorityonClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Priority</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={priorityonClose}>
              Close
            </Button>
            <Button variant="ghost">Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={escalateisOpen}
        onClose={escalateonClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Escalated</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={escalateonClose}>
              Close
            </Button>
            <Button variant="ghost">Escalate</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={attachmentsisOpen}
        onClose={attachmentsonClose}
      >
        <ModalOverlay />
        <ModalContent bgColor={'white'} fontFamily={'Roboto Condensed'}>
          <ModalHeader className={"fle"} bgColor={"blue.800"} color={"white"}>
           Add Attachment(s)
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className={"m-0 p-1"}>
            <Box className={'p-2'} bgColor={'whitesmoke'} >
              <FileUpload
                name="demo[]"
                url={"/api/upload"}
                multiple
                accept="image/*"
                maxFileSize={2000000}
                ref={fileUploaderRef}
                chooseOptions={{ label: "Add File",className:'addFileButton' }}
                cancelOptions={{ label: "Clear",className:'clearButton' }}
                uploadOptions={{label:'Upload',className:'uploadButton'}}
                customUpload={true}
                onSelect={onSelectTemplate}
                uploadHandler={()=>ticketFileUploadHandler()}
                headerTemplate={headerTemplate}
                emptyTemplate={
                  <Box className={'p-2'}>
                    <Box className="m-2 text-center w-full">Drag and drop files to here to Add.</Box>
                  </Box>
                }
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Box className={'flex flex-row w-full justify-between'}>
              <Button color={'white'} onClick={attachmentsonClose} bgColor="blue.800" mr={3} className={'w-full'} >
                Close
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
          closeOnOverlayClick={false}
          isCentered
          isOpen={assignAgentisOpen}
          onClose={assignAgentClose}
      >
        <ModalOverlay />
        <ModalContent bgColor={'white'} fontFamily={'Roboto Condensed'}>
          <ModalHeader className={"fle"} bgColor={"blue.800"} color={"white"}>
            Assign Agent
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className={"m-0 p-1"}>
            <Box className={"p-3  w-full"}>
              <ListBox value={selectedAgent} onChange={e=>setSelectedAgent(e.value)} className="w-full md:w-14rem" listStyle={{ height: '250px' }} virtualScrollerOptions={{ itemSize: 38 }} filter optionLabel="fullName" options={agents}></ListBox>
              <Box className={'flex flex-row align-middle text-lg mx-3 my-4'}>
                <Text>Change Agent:</Text>
                <Tag size={'lg'} className={'text-lg px-2 mx-1'}>{selectedAgent?.fullName} </Tag>
              </Box>
            </Box>

          </ModalBody>

          <ModalFooter>
            <Box className={'flex flex-row w-full justify-between'}>
              <Button color={'white'} bgColor="gray.300" mr={3} onClick={ assignAgentClose}>
                Close
              </Button>
              <Button color={'white'} bgColor="blue.800" mr={3} onClick={()=>assignAgent(selectedAgent.email)}>
                Assign Agent
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box
        className={"col-span-4 p-2 flex flex-col overflow-x-hidden overflow-y-auto"}
        h={"100%"}
      >
        <Box className={"flex justify-start"}>
          <Button
            leftIcon={<BiArrowBack />}
            onClick={() => navigate("/admin/ticket/all")}
            size={"sm"}
          >
            Back
          </Button>
        </Box>
          <Box className={"flex flex-row my-1 "}>
            <Text className={"m-1 p-0 "} fontSize={"md"}>
              Subject:
            </Text>
            {!loadingData ? (
            <Text className={"m-1 p-0"} fontSize={"md"}>
              {currentTicket.subject}
            </Text>
            ) : (
                <Skeleton height={'2rem'} className="mb-2 w-full"></Skeleton>
            )}
          </Box>


          <Box
            className={"flex flex-col w-full  justify-start align-items-start"}
            borderBottom={"1px solid gray"}
          >
            <Text className={" m-1 p-0"} fontSize={"md"}>
              Issue:{" "}
            </Text>
            {!loadingData ? (
            <Text
              fontSize={"md"}
              fontStyle={"italic"}
              color={"gray.500"}
              className={"text-start my-0 px-1 pb-7 w-full"}
            >
              {" "}
              {currentTicket.description}
            </Text>
            ) : (
                  <Skeleton  height={'5rem'} className={'w-full m-1 p-1'} startColor='gray.600' endColor='whitesmoke' ></Skeleton>
            )}
          </Box>


        {loadingData ? (
          <Skeleton height={'30em'} className={'m-1 p-1'} startColor='#033053' endColor='blue.800'></Skeleton>
        ) : (
          <Box className={"py-2 flex overflow-hidden flex-grow"}>
            <Conversations
              requester={ticket.sender}
              socket={adminSocket}
              conversation={currentTicket.conversation}
              ticketID={currentTicket.id}
            />
          </Box>
        )}
        <Box className={"w-full m-0 justify-start flex-1"}>
          {loadingData ? (
              <Skeleton height={'10em'} className={'m-1 p-1'}></Skeleton>
          ) : (
              <Box  className={'py-2 h-full'}>
         <Box className={'w-full h-full p-1 flex-grow '} boxShadow={'lg'} bgColor={'white'}>
          <Box className={'flex'}>
            <Button rightIcon={<PlusIcon size={'sm'}/>} onClick={() => attachmentsonOpen()} size="sm" border={'1px solid'} borderColor={'#033053'} color={'#033053'} className={'m-2 flex align-middle px-1'}>
              <Text className={'m-auto p-0 text-xs'}>Add Attachment(s){""}</Text>
            </Button>

          </Box>
          <Box minH={'8em'} overflowY={'auto'} className={'w-full p-1 h-fit'}>
            {currentTicket.attachments!==[]?currentTicket?.attachments.map(attach=>
                <Box bgColor={"whitesmoke"} boxShadow={'lg'} className={'w-full my-0.5 p-0.5 rounded-md flex flex-row align-middle justify-between'}>
                  <Box className={'flex flex-row align-middle'}>
                    <Box className={'m-auto rounded-full p-1 w-1/2'} boxShadow={'lg'} >
                      <Icon as={AttachmentIcon} className={'text-lg'}  />
                    </Box>
                    <Box className={'flex flex-col w-full justify-start p-1'} >
                      <Text className={'mx-1 p-0 text-start whitespace-nowrap'}>{attach.name}</Text>
                      <Text className={'mx-1 my-0 p-0 text-xs whitespace-nowrap'}>Created At:{moment(attach.createdAt).format('HH:MM DD/MM/YYYY')}</Text>
                    </Box>
                  </Box>
                  <Box height={'auto'} color={'white '}  className={'flex p-1 align-middle justify-center'}>
                    <Button
                        leftIcon={<DownloadIcon/>}
                        size={"sm"}
                        className="m-auto py-0"
                        boxShadow={'lg'}
                        bgColor={'gray.400'}
                    >Download{" "}</Button>
                  </Box>
                </Box>
            ):<Box><Text>No Attachments...</Text></Box>}
          </Box>
         </Box>
         </Box>)}
        </Box>
      </Box>
      <Box
        className={"col-span-2 p-1 flex flex-col"}
        borderLeft={"1px solid"}
        borderColor={'gray.200'}
        fontSize={"md"}
        bgColor={'whitesmoke'}

      >
        <Box className={"flex flex-row m-1"}>
          <Text className={"m-0 p-1"}>Sender:</Text>
          {loadingData ? (
            <Skeleton height='20px' className={'m-1 p-1 w-full'}></Skeleton>
          ) : (
            <Tag bgColor={"white"} className={"m-0 p-1"}>
              {currentTicket.sender}
            </Tag>
          )}
        </Box>

          <Box className={"flex flex-row m-1"}>
            <Text className={"m-0 p-1"}>Assigned:</Text>
            {!loadingData ? (
            <Text className={"m-0 p-1"}>
              {currentTicket?.agent ? currentTicket.agent : "None"}
            </Text>
            ) : (
                <Skeleton height={'20px'} className={'m-1 p-1 w-full'}></Skeleton>
            )}
          </Box>

        {!loadingData ? (
          <Box className={"flex flex-row m-1"}>
            <Button
              placeholder={currentTicket.agent ? currentTicket.agent : "Assign"}
              focusInputRef={assignAgentRef}
              value={ticketAgent}
              optionLabel="email"
              onClick={() =>
                  setAssignedAgent({email:'',fullName:'Not selected'})
              }
              options={agents}
            >Assign/Re-Assign</Button>
          </Box>
        ) : (
          <Skeleton height='20px' className={'m-1 p-1 w-full'}></Skeleton>
        )}


          <Box className={"flex flex-row m-1"}>
            <Text className={"m-0 p-1"}>Status:</Text>
            {!loadingData ? (
            <Text className={"m-0 p-1"}>
              {currentTicket?.status
                ? statusDescriptions[currentTicket.status]
                : "None"}
            </Text>
            ) : (
                <Skeleton height='20px' className={'m-1 p-1 w-full'}></Skeleton>
            )}
          </Box>

        {!loadingData ? (
          <Box className={"flex flex-row m-1"}>
            <Dropdown
              placeholder={
                status ? `Change status to :${statusDescriptions[status]}` : "Change Status..."
              }
              filter
              value={status}
              optionLabel="label"
              onChange={(e) =>
                setStatus(e.value)
              }
              options={statuses}
            ></Dropdown>
          </Box>
        ) : (
              <Skeleton height='20px' className={'m-1 p-1'} />
        )}
        <Box
          className={"flex relative  mt-10 mx-4 rounded-md"}
        >
          <Box
            className={"absolute -translate-y-1/2 mx-auto left-0 right-0  p-1 w-3/4"}
            zIndex={2}
            border={"1px solid"}
            borderColor={'gray.200'}
            borderRadius={"5px"}
            bgColor={"white"}
          >
            <Text className={"text-md"}>Notes</Text>
          </Box>
          <Box
            h={"26em"}
            className={"relative overflow-y-auto py-4 w-full px-1 rounded-md"}
            bgColor={'white'}
            boxShadow={'lg'}
            borderColor={'gray.200'}
          >
            {/*{loadingData?<Skeleton></Skeleton>:<Timeline  className={'bg-blue-600'} value={currentTicket.ticketNotes} align="left" className="customized-timeline" content={NoteDisplayTemplate} />*/}
            {/*}*/}
            {loadingData ? (
              <Skeleton height={'30em'} className={'m-1 p-1 '}></Skeleton>
            ) : (
              <Box  className={'flex flex-col w-full justify-center align-middle'}>
                {currentTicket.ticketNotes.map((item, index) => (
                  <Box key={index} className={"w-3/4 mx-auto p-1 my-2"}>
                    <Popover>
                      <PopoverTrigger>
                        <Box className={'flex flex-row relative w-full align-middle justify-start'} >
                         <Box className={'w-fit absolute'} zIndex={'1'} >
                           <Text bgColor={'gray.100'} boxShadow={'md'} className={"text-sm -translate-y-1/2 m-0 px-3 py-1 rounded-md  whitespace-nowrap  h-fit text-gray"}>
                             {moment(item.createdAt).format('HH:MM DD/MM/YYYY').toString()}
                           </Text>
                         </Box>
                          <Text bgColor={'whitesmoke'}  className={"py-3 p-1 relative m-0 my-1 w-full  text-start"} boxShadow={'md'} >{item.subject}</Text>
                        </Box>
                      </PopoverTrigger>
                      <PopoverContent className={'flex flex-col'}>
                        <PopoverArrow className={'m-auto'} />
                        <PopoverCloseButton />
                        <PopoverHeader bgColor={"blue.700"} color={'white'} className={'m-1 text-sm text-start rounded-md'}>
                          Note by: {item.creator}
                        </PopoverHeader>
                        <PopoverBody>{item.description}</PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>

            <Box className={'m-3 p-2 h-fit'} bgColor={'white'}>
              <Text className={'text-lg text-start'} fontFamily={'RobotoCondensedBold'}>Service Rendering: </Text>
              {loadingData?<Skeleton height={'15em'} className={'m-1 p-1'}></Skeleton>:(
              <Box className={'flex flex-row p-2'} boxShadow={'md'}>
                    <Box className={'flex flex-row align-bottom mx-2'}>
                      <CountdownTimer data={currentTicket.consultation?currentTicket.consultation:null} finishMethod={finishConsultation} startMethod={beginConsultation} finished={currentTicket.consultation?currentTicket.consultation.finishedAt:null} targetDate={currentTicket.consultation?currentTicket.consultation.createdAt: new Date()}/>
                    </Box>
              </Box>
              )}
            </Box>
         <Box className={"flex relative  mt-10 mx-4"}>
           <Button>Add Payment</Button>
         </Box>
        <Box
            className={"flex relative  mt-3 mx-4 rounded-md"}
        >
          <Box
              className={"absolute -translate-y-1/2 mx-auto left-0 right-0  p-1 w-3/4"}
              zIndex={2}
              border={"1px solid"}
              borderColor={'gray.200'}
              borderRadius={"5px"}
              bgColor={"white"}

          >
            <Text className={"text-md"}>Transactions</Text>
          </Box>
          <Box
              h={"26em"}
              className={"relative overflow-y-auto py-4 w-full px-1 rounded-md"}
              bgColor={'white'}
              boxShadow={'lg'}
              borderColor={'gray.200'}
          >

            {loadingData ? (
                <Skeleton height={'30em'} className={'m-1 p-1 '}></Skeleton>
            ) : (
                <Box  className={'flex flex-col w-full justify-center align-middle'}>
                  {currentTicket.transactions.map((item, index) => (
                      <Box key={index} boxShadow={'lg'} className={"w-3/4 flex flex-row justify-between mx-auto p-1 my-2"}>
                        <Box className={'flex flex-col'}>
                         <Box className={'flex'}>
                           <Text className={'m-1 p-0'}>Amount:</Text>
                           <Text className={'m-1 p-0'}>{Math.abs(parseFloat(item.amount))}</Text>
                         </Box>
                          <Box className={'flex'}>
                            <Text className={'m-1 p-0'}>Date:</Text>
                            <Text className={'m-1 p-0'}>{moment(item.createdAt).fromNow().toString()}</Text>
                          </Box>
                        </Box>
                        <Box className={'flex flex-col h-auto'}>
                          <Box className={'flex  h-fit my-auto mx-2 '} >
                            <Tag className={'px-3'} bgColor={'#033053'} color={'white'}>{item.status}</Tag>
                          </Box>
                        </Box>
                      </Box>
                  ))}
                </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default TicketViewer;
