import {Box, Center, FormControl, FormLabel, Input, Text, FormHelperText, FormErrorMessage} from "@chakra-ui/react";
import axios from "axios";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {Store} from "react-notifications-component";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Button} from "rsuite";
import * as Yup from "yup";
import NavBar from "../components/navBar";
import {userActions} from "../Portals/DataStore/UserData";
import {server_URL} from "../serverURL";
import Footer from "./Footer";



function UserSignUp(){
	const navigate = useNavigate();
	const [value, setValue] = useState("false");
	const [show, setShow] = useState(false);
	const [status, setstatus] = useState("");
	const [recatchavalue, setrecatchavalue] = useState("");
	const [isLoading, setisLoading] = useState(false);
	//User data
	const dispatch = useDispatch();
	let verificationCode;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const createUserOnly = async (data) => {
		//const stringifiedData= data
		await axios
			.post(server_URL + "/users", { data })
			.then((response) => {
				console.log("Response Error ",response)
				setisLoading(false);
				console.log("Adding User", response);
				const { fullName, emailAddress, phone } = response.data;
				if (response.data.length !== 0) {
					setstatus(false);
					sessionStorage.setItem("Session-email", emailAddress);
					sessionStorage.setItem("Session-phone", phone);
					dispatch(userActions.addUser(response.data.user));
					navigate("/verify");
				} else {
					console.log(response.data.message);
					setisLoading(false);
					sendingMessage("No user ");
				}
			})
			.catch((error) => {

				console.log(error.response);
				if(error.status=== 401){
					sendingMessage(error.message)
				}else {
					sendingMessage(error.response.data.message);
				}
				console.log("Error");
				setisLoading(false);
			});
	};
	const createCompanyAndUser = async (data) => {
		await axios
			.post(server_URL + "/company/new", data)
			.then((response) => {
				if (response.data !== []) {
					const company_id = response.data.id;
					const userdata = { ...data, company_id };
					createUserOnly(userdata);
				} else {
					sendingCompanyAddMessage();
				}
			})
			.catch((error) => console.log(error));
	};
	const sendingMessage = (data) => {
		Store.addNotification({
			title: data,
			type: "danger",
			insert: "bottom",
			container: "bottom-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
			},
			onRemoval: () => {
				setisLoading(false);
			},
		});
	};
	const sendingCompanyAddMessage = () => {
		Store.addNotification({
			title: "Sorry...,This company already in our system!!!",
			message:
				"Please check your company Name, Registration and Company Domain ",
			type: "danger",
			insert: "bottom",
			container: "bottom-center",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 6000,
				onScreen: true,
			},
		});
	};

	const SignUpForm = useFormik({
		initialValues: {
			fullName: "",
			phone: "",
			emailAddress: "",
			businessName: "",
			registrationNumber: "",
			companyDomain: "",
			confirmEmail: "",
			password: "",
			confirmPassword: "",
			repatchaValue: "",
			industry: "",
			companyDescription: "",
			toggled: false,
			checked: false,
		},
		onSubmit: (values) => {
			// sendDataToBack({values,value})
			console.log(values)
			// setisLoading(true);
			// if (values.toggled === "true") {
			// 	createCompanyAndUser(values);
			// } else {
			// 	createUserOnly(values);
			// }
		},
		validationSchema: Yup.object({
			fullName: Yup.string().required("Required"),
			phone: Yup.string()
				.matches(/^[0-9]+$/, "Must be only digits (exclude country code) ")
				.length(10, "Ensure you have entered a correct SA cell number")
				.required("Required"),
			emailAddress: Yup.string().required("Required"),
			confirmEmail: Yup.string()
				.oneOf([Yup.ref("emailAddress"), null], "Email do not match")
				.required("Required"),
			password: Yup.string()
				.matches(
					/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
					'Password must be 8 characters including 1 Lowercase,1 Uppercase,1 Number and 1 special character "@,#,%,!" '
				)
				.required("Required"),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref("password"), null], "Password do not match")
				.required("Required"),
			toggled: Yup.string().oneOf(["true", "false"], "Required"),
			industry: Yup.string().when("toggled", {
				is: "true",
				then: Yup.string().required("Required"),
			}),
			companyDescription: Yup.string().when("toggled", {
				is: "true",
				then: Yup.string().required("Required"),
			}),
			businessName: Yup.string().when("toggled", {
				is: "true",
				then: Yup.string().required("Required"),
			}),
			registrationNumber: Yup.string().when("toggled", {
				is: "true",
				then: Yup.string().required("Required"),
			}),
			companyDomain: Yup.string().when("toggled", {
				is: "true",
				then: Yup.string().required("Required"),
			}),
			checked: Yup.boolean()
				.oneOf([true], "Field must be checked")
				.required("Required"),
		}),
	});
	function onChangeRecaptcha(value) {
		setrecatchavalue(value);
	}
    console.log(SignUpForm.errors)
	return (
		<Box>
			<NavBar/>
			<Box className={'pt-10'}>
				<Box className={'m-2 p-2'}>
					<Text className={'text-2xl'}>Sign Up </Text>
				</Box>
				<Center  className={'w-full md:w-1/2  p-2 m-auto'}>
					<Box as={'form'} className={'w-full'}  onSubmit={SignUpForm.handleSubmit}>
						<Box as={FormControl} isRequired isInvalid={SignUpForm.errors.fullName} className={'w-full p-2 bg-gray-100 flex justify-center align-items-center flex-row'}>
							<FormLabel className={'w-1/2 flex m-auto text-start px-2'}>Full Name : </FormLabel>
							<Input type={'text'} className={'w-full'} name={'fullName'} onChange={SignUpForm.handleChange} onBlur={SignUpForm.handleBlur}>
							</Input>
							{SignUpForm.errors.fullName?<FormErrorMessage className={"w-full absolute bottom-0 left-0 px-3"}>{SignUpForm.errors.fullName}</FormErrorMessage>:''}

						</Box>
						<Box as={FormControl} isRequired isInvalid={SignUpForm.errors.phone} className={'w-full  px-2 py-4 bg-gray-100 flex justify-center align-items-center flex-row md:flex-column'}>
							<FormLabel className={'w-1/2 flex m-auto text-start px-2'}>Contact Number : </FormLabel>
							<Input type={'tel'} className={'w-full'} name={'phone'} onChange={SignUpForm.handleChange}>

							</Input>
							{SignUpForm.errors.phone?<FormErrorMessage className={"w-full absolute bottom-0 left-0 px-3"}>{SignUpForm.errors.phone}</FormErrorMessage>:''}

						</Box>
						<Box as={FormControl} isRequired isInvalid={SignUpForm.errors.emailAddress} className={'w-full px-2 m-0 py-4 bg-gray-100 flex justify-center align-items-center flex-row'}>
							<FormLabel className={'w-1/2 flex m-auto text-start px-2'}>Email Address : </FormLabel>
							<Input type={'email'} className={'w-full'} name={'emailAddress'} onChange={SignUpForm.handleChange}>
							</Input>
							{SignUpForm.errors.emailAddress?<FormErrorMessage className={"w-full absolute bottom-0 left-0 px-3"}>{SignUpForm.errors.emailAddress}</FormErrorMessage>:''}
						</Box>
						<Box as={FormControl} isRequired isInvalid={SignUpForm.errors.confirmEmail} className={'w-full p-2 bg-gray-100 flex justify-center align-items-center flex-row'}>
							<FormLabel className={'w-1/2 flex m-auto text-start px-2'}>Confirm Email Address : </FormLabel>
							<Input type={'email'} className={'w-full'} name={'confirmEmail'} onChange={SignUpForm.handleChange}>
							</Input>
							{SignUpForm.errors.confirmEmail?<FormErrorMessage className={"w-full absolute bottom-0 left-0 px-3"}>{SignUpForm.errors.confirmEmail}</FormErrorMessage>:''}
						</Box>
						<Box as={FormControl} isRequired isInvalid={SignUpForm.errors.password} className={'w-full p-2 bg-gray-100 flex justify-center align-items-center flex-row'}>
							<FormLabel className={'w-1/2 flex m-auto text-start px-2'}>Password  : </FormLabel>
							<Input type={'password'} className={'w-full'} name={'password'} onChange={SignUpForm.handleChange}>
							</Input>
							{SignUpForm.errors.password?<FormErrorMessage className={"w-full absolute bottom-0 left-0 px-3"}>{SignUpForm.errors.password}</FormErrorMessage>:''}
						</Box>
						<Box as={FormControl} isRequired isInvalid={SignUpForm.errors.confirmPassword} className={'w-full p-2 bg-gray-100 flex justify-center align-items-center flex-row'}>
							<FormLabel className={'w-1/2 flex m-auto text-start px-2'}>Confirm Password  : </FormLabel>
							<Input type={'password'} className={'w-full'} name={'confirmPassword'} onChange={SignUpForm.handleChange}>
							</Input>
							{SignUpForm.errors.password?<FormErrorMessage className={"w-full absolute bottom-0 left-0 px-3"}>{SignUpForm.errors.confirmPassword}</FormErrorMessage>:''}

						</Box>


						<Button type={'submit'}>Submit</Button>

					</Box>
				</Center>

			</Box>
			<Footer/>
		</Box>
	)
}


export default  UserSignUp
