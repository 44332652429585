import {createSlice} from "@reduxjs/toolkit";

const SLASlice= createSlice({
    name:'SLASlice',
    initialState:{
        slaPolicy:[]
    },
    reducers:{
        addSLAPolicies(state,action){
            state.slaPolicy= action.payload
        },
        removeSLAPolicies(state,action){
            state.slaPolicy=[]
        },
        addPolicy(state,action){
            const policy=action.payload
            state.slaPolicy= [...state.slaPolicy,policy]
        }
    }
})
export const SLAPolicyActions= SLASlice.actions;
export default SLASlice;