import { AttachmentIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FiFile, FiSend } from "react-icons/fi";
import { Input, InputGroup } from "rsuite";
import Message from "./Message";
import { useSelector } from "react-redux";

export default function Conversation(props) {
  const userData = useSelector((state) => state.user);
  let messages = useSelector((state) => state.chats).chats;
  const [newMessage, setnewMessage] = useState("");
  const messageEl = useRef(null);
  const sendNewMessage = () => {
    //send to socket io
    //send to lis
    if (newMessage !== "") {
      const message = {
        text: newMessage,
        createdAt: new Date().toJSON().slice(0, 19).replace("T", " "),
        sender: userData.email,
        attachment_id: "",
        type: "message",
      };
     // props.socket.emit("$techresq:message:add", {
     //    ticketId: `${props.selectedTicket}`,
     //    useremail: `${userData.email}`,
     //    message,
     //  });

      setnewMessage("");
    }
  };
  useEffect(() => {
      messageEl.current.scrollTo(0,messageEl.current.scrollHeight);
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);
  return (
    <Box
      className={"flex h-full"}
      w={"w-full"}
      position="relative"
      justifyContent={"center"}
      alignItems="center"
    >
      <Box
        position={"relative"}
        className="flex m-0 pb-12 w-full flex-column h-full justify-content-between overflow-hidden"
      >
        <Box height={"3em"} bgColor="blue.900"></Box>
        <Box
          px={2}
          py={3}
          className="h-full"
          borderRadius="md"
          bgColor="gray.100"
          overflowY={"auto"}
          overflowX="hidden"
          ref={messageEl}
        >

          {messages.map((message) => {
            // if (message.type === "file") {
            //   return (
            //     <Message
            //       message={message.message}
            //       avatarURL={props.avatarURL}
            //       time={message.time}
            //       tags={message.tags}
            //       me={message.sender !== "" ? true : false}
            //       type={message.type}
            //       file={message.file}
            //       files={message.files}
            //     />
            //   );
            // }
            // console.log('ME',message.sender===userData.email)
            // console.log('user ',userData.email)
            // console.log('Message sender',message.sender)
            return (
              <Message
                message={message.message}
                avatarURL={props.avatarURL}
                time={message.time}
                tags={message.tags}
                me={message.sender===userData.email}
              />
            );
          })}
        </Box>

        <Box position={""} bottom={1} p={1} right={0}>
          <InputGroup
            style={{
              backgroundColor: "gray.200",
              boxShadow: "0px 0px 5px 1px gray",
            }}
          >
            <InputGroup.Button>
              <AttachmentIcon />
            </InputGroup.Button>
            <Input
              value={newMessage}
              size="lg"
              onChange={(e) => setnewMessage(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter") sendNewMessage();
              }}
            />
            <InputGroup.Button
              onClick={() => {
                sendNewMessage();
              }}
            >
              <FiSend />
            </InputGroup.Button>
          </InputGroup>
        </Box>
      </Box>
    </Box>
  );
}
let message_s = [
  {
    messageId: 1,
    message: "Hi There pkp",
    time: "Thu Nov 24 2022 03:20:23 GMT+0200 (South Africa Standard Time)",
    sender: "me ",
    tags: ["&#128077", "&#128206"],
    type: "message",
  },
  {
    messageId: 2,
    message: "Hello &#128077 ",
    time: "Thu Nov 24 2022 03:20:23 GMT+0200 (South Africa Standard Time)",
    sender: "",
    tags: ["&#128077", "&#128206"],
    type: "message",
  },
  {
    messageId: 3,
    message: "How are you today ",
    time: "Thu Nov 24 2022 03:20:23 GMT+0200 (South Africa Standard Time)",
    sender: "",
    tags: ["&#128077", "&#128206"],
    type: "message",
  },
  {
    messageId: 4,
    message: "Am good thanks how are you",
    time: "Thu Nov 24 2022 03:20:23 GMT+0200 (South Africa Standard Time)",
    sender: "me",
    file: ["file 1", "file 2"],
    type: "file",
  },
];
