import { createSlice } from "@reduxjs/toolkit";

const VendorsDataSlice = createSlice({
    name: "vendors",
    initialState: {
        vendors: [],
        currentVendor:[],
    },
    reducers: {
        addVendors(state, action) {
            const vendors = action.payload;
            state.vendors= vendors
        },
        removeVendors(state, action) {
            state.vendors = [];
        },
        addVendor(state,action){
            state.vendors= [action.payload,...state.vendors]
        },
        setCurrentVendor(state,action){
            const newVendor = action.payload;
            state.currentVendor=newVendor;
        }
    },
});
export const VendorsActions = VendorsDataSlice.actions;
export default VendorsDataSlice;
