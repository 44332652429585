import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Img, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList,
  Modal,
  ModalBody, ModalCloseButton,
  ModalContent,
  ModalFooter, ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text, Textarea,
  useDisclosure, Wrap,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./ClientInterface.scss";
import ClientMainContentPage from "./ClientMainContentPage";
import ClientNavigationBar from "./components/ClientNavigationBar";
import ClientSideBar from "./components/ClientSideBar";
import { server_URL } from "../../serverURL";
import axios from "axios";
import { io, Socket } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { bankingActions } from "../DataStore/bankingData";
import { ClientCommunicationActions } from "../DataStore/Client/ClientCommunication";
import { NotificationActions } from "../DataStore/Client/notifications";
import { userActions } from "../DataStore/UserData";
import { ClientTicketsActions } from "../DataStore/Client/ClientTickets";
import { Store } from "react-notifications-component";
import { Link, Outlet } from "react-router-dom";
import { useLayoutEffect } from "react";
import { Howl, Howler } from "howler";
import creditcard from "../../Assets/creditcard.gif";
import * as SignalR from "@microsoft/signalr";
import { TagInput } from "rsuite";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ticketDataServices from "../../utils/serviceList";

const statusDescrptions = {
  1: "Open",
  2: "Pending",
  3: "Resolved",
  4: "Closed",
};
const priorityIdentifier = { 1: "Low", 2: "Medium", 3: "High", 4: "Urgent" };
const priorityIdentifierColor = {
  1: "green.300",
  2: "yellow.300",
  3: "orange.300",
  4: "red.300",
};
const filterData = ["Open", "Closed", "Pending", "Escalated"].map((item) => ({
  label: item,
  value: item,
}));
const ClientInterface = () => {
  const [showBar, setShowBar] = useState(true);
  const [urgency, seturgency] = useState(1);
  const [initialLoading, setinitialLoading] = useState(true);
  const userData = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const [descriptionError, setdescriptionError] = useState("");
  const [ticketChoice, setticketChoice] = useState("");
  const [cc_employees, setcc_employees] = useState([]);
  const [description, setDescription] = useState("");
  const bankDetails = useSelector((state) => state.banking);
  const {
    isOpen: ticketisOpen,
    onOpen: ticketonOpen,
    onClose: ticketonClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = sessionStorage.getItem("Session-token");
  const email = sessionStorage.getItem("Session-email");
  const [clientSocket, setclientSocket] = useState(null);
  const submitTicket = () => {
    const newTicketData = {
      description: description,
      subject: subject,
      requester: userData.email,
      priority: urgency,
      status: 1,
      fwd_cc: cc_employees,
      createdAt: new Date(),
      service_name: ticketChoice,
    };
    //clientSocket.emit("$techresq:ticket:new", newTicketData);
    //ticketSubmitted();
    onClose();
  };
  useEffect(() => {
    let connection = new SignalR.HubConnectionBuilder()
      .withUrl(`${server_URL}/techChat`, {
        transport: SignalR.HttpTransportType.LongPolling,
      })
      .build();
    connection.start().then(() => {
      console.log("SignalR connected");
      dispatch(userActions.setActive(true));
    });

    connection.on("SendMessage", (message) => {
      console.log("Received message:", message);
    });

    return () => {
      connection.onclose((message) => {
        console.log("CLose signalR");
      });
    };
  }, []);
  const serverErrors = (error, error_type) => {
    Store.addNotification({
      message: error,
      type: error_type ? "danger" : "success",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
      onRemoval: () => {},
    });
  };
  if (!initialLoading) {
    return (
      <Flex justifyContent={"center"} alignItems="center" h="100%">
        <Spinner />
      </Flex>
    );
  }

  const Message = ({ message, status }) => {
    Store.addNotification({
      message: message,
      type: status,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
      onRemoval: () => {
        // setloading(false);
        // navigate("/login")
      },
    });
  };
  return (
    <Box
      w={"100%"}
      fontSize="md"
      className="client_interface"
      bgColor={"white"}
    >
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Container
              pos={"absolute"}
              top="0"
              bottom={"0"}
              width="100%"
              height={"fit-content"}
              bgColor="white"
              zIndex={"100"}
              right="0"
              left="0"
            >
              <Box>
                <Flex
                  flexDir={"column"}
                  fontFamily={"RobotoCondensedRegular"}
                  alignItems="center"
                >
                  <Text m={2} fontFamily={"RobotoCondensedBold"}>
                    Welcome !!!{" "}
                  </Text>
                  <Divider mb={1} />
                  <Text m={1}>To continue with our services</Text>
                  <Text>Enter a minimum of R100</Text>

                  <Img src={creditcard} size={"sm"} width="5em" />

                  <Link to={"/mydashboard/billing"}>
                    <Button
                      m={2}
                      size="sm"
                      // onClick={() => {
                      //   props.settab("Settings");
                      // }}
                    >
                      Add payment now
                    </Button>
                  </Link>
                </Flex>
              </Box>
            </Container>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ClientNavigationBar
        isLoaded={initialLoading}
        setShowBar={setShowBar}
        showBar={showBar}
      />
      <Flex flexDir={"row"} flexGrow={1} h="80vh" pos={"relative"}>
        <ClientSideBar
          isLoaded={initialLoading}
          setShowBar={setShowBar}
          showBar={showBar}
        />
        <Box overflowY={"auto"} overflowX="hidden" w="100%" h="100%">
          <Outlet />
        </Box>
      </Flex>
    </Box>
  );
};

export default ClientInterface;
