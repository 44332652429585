import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import ndalaict from "../Assets/ndalaict.png";
import servers from "../Assets/server_room.jpg";
import { FiChevronRight } from "react-icons/fi";
import { TbNetwork } from "react-icons/tb";
import { Text } from "@chakra-ui/react";

function InfrastructureServices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="InfrastructureServices_page">
      <NavBar />
      <div className="body">
        <div className="heading">
          <div className="">
            <h3>IT INFRASTRUCTURE SUPPORT</h3>
            <img src={servers} alt="" />
          </div>
          <hr />
          <div className="list">
            <div className="Card">
              <div>
                <FiChevronRight fontSize={"2rem"} />
              </div>
              <Text w={"100%"} textAlign={"start"}>
                NETWORK CONNECTIVITY INSTALLATIONS AND SUPPORT
              </Text>
            </div>
            <div className="Card">
              <div>
                <FiChevronRight fontSize={"2rem"} />
              </div>
              <Text w={"100%"} textAlign={"start"}>
                WIFI TROUBLESHOOTING AND PERFORMANCE IMPROVEMENTS
              </Text>
            </div>
            <div className="Card">
              <div>
                <FiChevronRight fontSize={"2rem"} />
              </div>
              <Text w={"100%"} textAlign={"start"}>
                AUDIO VISUAL INSTALLATIONS AND SUPPORT
              </Text>
            </div>
            <div className="Card">
              <div>
                <FiChevronRight fontSize={"2rem"} />
              </div>
              <Text w={"100%"} textAlign={"start"}>
                VIDEO CONFERENCING SYSTEM INSTALLATIONS AND SUPPORT
              </Text>
            </div>
          </div>
          <div className="_power">
            <div className="text">
              <p>
                To learn more about our solutions visit our company website{" "}
              </p>
            </div>

            <div className="ndalaict">
              <div className="logo">
                <img src={ndalaict} alt="" />
              </div>
              <div className="link">
                <div className="site">
                  <a
                    target="_blank"
                    href="https://www.ndalaict.com"
                    rel="noreferrer"
                    style={{ color: "silver" }}
                  >
                    NdalaICT Solutions{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default InfrastructureServices;
