import {Avatar, Box, Flex, IconButton, Tag, Text} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { useSelector } from "react-redux";
import gsap from "gsap";
import { AttachmentIcon } from "@chakra-ui/icons";
function Message({ message, me, type }) {
  // const messageRef = useRef(null);
  // const userData = useSelector((state) => state.user);
  // useEffect(() => {
  //     gsap.from(messageRef.current, {
  //         width: "200%",
  //     });
  // }, []);
  return (
    <Box w={"fit"} transition={{ ease: "easeOut", duration: 1 }}>
      <Flex
        w={"100%"}
        p={1}
        my={5}
        // ref={messageRef}
        as="div"
        flexDir={me ? "row-reverse" : "row"}
        justifyContent={me ? "flex-start" : "flex-start"}
        alignItems="center"
      >
        {/*<Avatar  m={2} boxShadow="lg" size={"sm"} />*/}
        <Flex
          p={2}
          my={2}
          alignItems="center"
          boxShadow="md"
          position={"relative"}
          w="50%"
          right={0}
          bgColor={me ? "blue.800" : "whitesmoke"}
          borderRadius="5px"
        >
          <Box
            flexDir={"column"}
            height="fit-content"
            width="100%"
            mx={1}
            my={0}
            alignItems="flex-start"
            color={me ? "white" : "black"}
          >
            <Text
              fontSize={"md"}
              fontFamily="RobotoCondensedLight"
              flexWrap="wrap"
              m={0}
              textAlign="start"
            >
              {message.message}
            </Text>
          </Box>
          <Tag
            bottom={"-.1em"}
            textAlign={"start"}
            color={"gray.700"}
            borderRadius={"5px"}
            left={ "auto"}
            right={ 0}
            bgColor={"#0000000"}
            fontSize=".7em"
            fontFamily={"RobotoCondensedRegular"}
            className={'translate-y-1/2 absolute'}
            boxShadow={'md'}
            borderColor={'gray.100'}
            border={'.1px solid'}
          >
            {moment(message.createdAt).fromNow().toString()}{" "}
          </Tag>
          <Text
            pos="absolute"
            left={me ? "auto" : 0}
            right={me ? 0 : "auto"}
            top={"-2em"}
            fontSize={"sm"}
          >
            {me ? "You" : " Vuyani Daweti"}
          </Text>
          {me ? (
            <Text
              w={"10em"}
              textAlign="start"
              bottom={"-1.5em"}
              color={"gray.600"}
              borderRadius={"5px"}
              left={"0"}
              bgColor={"#0000000"}
              position="absolute"
              fontSize=".7em"
              fontFamily={"RobotoCondensedRegular"}
            >
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Message;
