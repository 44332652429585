import {Avatar, Box, Button, Center, Flex, PinInput, PinInputField, Text} from "@chakra-ui/react";
import NavBar from "../../components/navBar";
import Footer from "../../pages/Footer";
import React from "react";
import Lottie from 'react-lottie'
import Email from '../../Assets/Email 2.json'
import {ArrowRight} from "@rsuite/icons";
import {useNavigate} from "react-router-dom";
function AgentEmailverification(){
    const navigate = useNavigate()
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Email,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return(
        <Box>
            <NavBar/>
           <Box  h={'100vh'}  className={'row no-gutters  m-0 '}>
               <Center className={'col-lg-6 col-md-6 col-sm-12'}  justifyContent={'center'} alignItems={'center'}>
                   <Flex flexDir={'column'} m={4} p={3} >
                       <Flex alignItems={'center'}>
                           <Avatar bg={'blue.800'} size={'md'} name='1' m={1}/>
                           <Text m={1}>Complete the registration form </Text>
                       </Flex>
                       <Flex alignItems={'center'} m={1}>
                           <Avatar bg={'blue.800'} size={'md'} name='2'/>
                           <Text m={2} textAlign={'start'}>You will receive a One Time Pin to your cellphone. Enter the PIN to confirm your registration </Text>
                       </Flex>
                       <Flex alignItems={'center'} m={1}>
                           <Avatar bg={'green.400'} size={'md'} name='3'/>
                           <Text m={2}>Confirm your email address </Text>
                       </Flex>
                   </Flex>
               </Center>
               <Center flexDir={'column'} className={'col-lg-6 col-md-6 col-sm-12'}>
                   <Box flexDir={'column'} boxShadow={'md'} className={'w-75'} justifyContent={'center'} alignItems={'center'}>
                       <Box bgColor={'blue.800'} className={'text-white'}><Text>Email Verification </Text></Box>
                       <Box mx={2} my={5}>
                           <Lottie  options={defaultOptions}
                                    height={200}
                                    width={200}/>
                       </Box>
                       <Text m={4}>Check your mailbox and follow the instructions </Text>
                   </Box>
                   <Button m={3} rightIcon={<ArrowRight/>} onClick={()=>navigate('/onboarding')}>Skip </Button>

               </Center>
           </Box>
            <Footer/>
        </Box>
    )

}
export default AgentEmailverification