import { Box, Center, Text, Spinner, Button } from "@chakra-ui/react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";

import React, { useState, useEffect } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import { server_URL } from "../serverURL";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { userActions } from "../Portals/DataStore/UserData";
import {Store} from "react-notifications-component";

function EmailVerification() {
  const [email_verified, setemail_verified] = useState(false);
  const [verifyingEmail, setverifyingEmail] = useState(false);
  const [verifyingEmailSent, setverifyingEmailSent] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [emailError, setemailError] = useState(false);
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verifyEmail = async () => {
    const config = {
      method: "post",
      url: server_URL + "/users/verifyemail",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        token: token,
      },
    };
    await axios(config)
      .then((response) => {
        if (response.status === 200) {
          //MessageNotification('User verified','success')
          setverifyingEmailSent(true);
          setemailError(false);
          dispatch(userActions.verifyEmail(true));
        }
      })
      .catch((error) => {
        setverifyingEmailSent(true);
        setemail_verified(false);
        //MessageNotification('The token has expired!!1','danger')
        setemailError(true);
      });
  };
  useEffect(() => {
    verifyEmail();
  }, []);
  const MessageNotification = (data,type) => {
    Store.addNotification({
      message:data,
      type: type,
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
      onRemoval: () => {

      },
    });
  };
  console.log(email_verified)
  return (
    <Box className="w-full" bgColor={"whitesmoke"}>
      <NavBar />
      <Center className="w-full py-44 relative h-full">
        <Box boxShadow={"lg"} bgColor="white" className="w-1/2">
          {!setverifyingEmailSent? (
            <Box className="m-0 p-10">
              <Spinner
                size="xl"
                color="blue.800"
                thickness="1px"
                speed="1s"
                emptyColor="blue.200"
              />
              <Text className="p-1">Verifying email ...</Text>
            </Box>
          ) : (
            <Box
              bgColor={!emailError?"whatsapp.100":"red.100"}
              className="m-0 p-8 flex flex-col justify-content-center align-items-center"
            >
              {!emailError ? (
                <CheckCircleIcon
                  className="m-4"
                  fontSize={"6xl"}
                  color="whatsapp.500"
                />
              ) : (
                <CloseIcon />
              )}
              {emailError ? (
                <Text
                  fontStyle={"bold"}
                  fontFamily={"Roboto"}
                  className="text-xl m-3"
                >
                  Sorry we could not verify your email at the moment.
                  <br/>
                  Your link has expired !!!
                </Text>
              ) : (
                <Text
                  fontStyle={"bold"}
                  fontFamily={"Roboto"}
                  className="text-xl m-3"
                >
                  Email Verification Successful !!!
                </Text>
              )}
              <Button
                size="sm"
                bgColor="blue.800"
                className="w-1/3"
                color={"white"}
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Box>
          )}
        </Box>
      </Center>
      <Footer />
    </Box>
  );
}

export default EmailVerification;
