import React, { useEffect } from "react";
import NavBar from "../components/navBar";
import LandingPage from "./LandingPage";
import computer from "../Assets/computer.jpg";
import pexels_field_engineer from "../Assets/pexels_field_engineer.jpg";
import {
  Center,
  Text,
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
  Heading,
  Divider,
  Box,
  Img,
  Flex,
  CircularProgress,
  CircularProgressLabel,
  Tooltip,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import { EffectFade, Autoplay, Pagination } from "swiper";
import tod_logo from "../Assets/tod_logo.png";
import mtn from "../Assets/mtn.png";
import Polycom from "../Assets/Polycom.png";
import LiquidTelecom from "../Assets/LiquidTelecom.jpg";
import Fortinet from "../Assets/Fortinet.png";
import cisco from "../Assets/cisco.png";
import kathea from "../Assets/kathea.png";
import huawei from "../Assets/huawei.png";
import Footer from "./Footer";
import ndalaict from "../Assets/ndalaict.png";
import { Link, useNavigate } from "react-router-dom";
import { Parallax } from "react-parallax";
import techresqportalticket from "../Assets/techresqportalticket.jpg";
import techresqportalticketlap from "../Assets/techresqportalticket.png";

import { Timeline, Whisper } from "rsuite";
import MemberIcon from "@rsuite/icons/Member";
import CountUp from "react-countup";
import { CheckCircleIcon, CheckIcon, Icon } from "@chakra-ui/icons";
import { BsArrowUp } from "react-icons/bs";

function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="HomePage">
      <NavBar />
      <div className="div">
        <LandingPage />
        <div className="section_1 row m-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Box
              boxShadow={"md"}
              bgGradient="linear(to-r, blue.900, whiteAlpha.500)"
              opacity=".6"
              border={"1px solid gray"}
              py="2em"
              mx={4}
              my={5}
            >
              <Text
                as="h3"
                fontSize={"1.2em"}
                style={{ lineHeight: "2em", padding: "1rem" }}
                color="white"
                fontFamily="RobotoCondensedBold"
              >
                Our clients are small to medium enterprises and individuals who
                do not need permanent IT personnel or IT support contracts, but
                a Pay-as-you-use service. You only pay for time spent to support
                your requirements through our fixed remote and onsite hourly
                rates.
              </Text>
            </Box>
          </div>
          <Box
            as="div"
            flexDir={{ base: "column-reverse", md: "row" }}
            className="col-12 row m-0 "
          >
            <div className="col-lg-6 col-md-6 col-sm-12 p-3">
              <Box p="1em" m="1em">
                <Text fontSize={"2xl"}>Easy to use helpdesk solution </Text>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>Register</Text>
                </Flex>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>Login</Text>
                </Flex>

                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>
                    Raise a ticket
                  </Text>
                </Flex>
              </Box>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <img alt="" src={techresqportalticket} />
            </div>
          </Box>
          <div className="col-lg-12 col-md-12 col-sm-12 row m-0">
            <div className="col-lg-6 col-md-6 col-sm-12 m-auto">
              <img alt="" src={techresqportalticketlap} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Box p="1em" m="1em">
                <Text fontSize={"2xl"}>Features</Text>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>
                    Web based Ticketing System{" "}
                  </Text>
                </Flex>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>
                    User Dashboard
                  </Text>
                </Flex>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>
                    Ticket Tracker{" "}
                  </Text>
                </Flex>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>
                    SMS & Email Notifications
                  </Text>
                </Flex>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>Invoicing </Text>
                </Flex>
                <Flex
                  m={"1em"}
                  p="1em"
                  bgColor="whiteAlpha.400"
                  _hover={{ bgColor: "whiteAlpha.700" }}
                >
                  <CheckCircleIcon color={"green.500"} size="lg" m={1} />
                  <Text fontFamily={"RobotoCondensedLight"}>
                    Online Payment (support all SA banks)
                  </Text>
                </Flex>
              </Box>
            </div>
          </div>
        </div>
        <div className="section_2">
          <div className="picture p-5">
            <img src={computer} />
          </div>
          <div data-aos="fade-right" className="words">
            <Center className="word">
              <Text
                as="p"
                color={"white"}
                fontSize={"1em"}
                fontFamily="RobotoCondensedLight"
                style={{ lineHeight: "2em", padding: ".5rem" }}
              >
                We bridge the gap between clients and IT professionals, by
                providing top accredited professionals who have undergone
                rigorous assessments, we ensure high quality specialized
                services. TechResQ connects you to an engineer for your specific
                needs that your business remains productive and efficient our
                ecosystem is one built to advance successful returns for our
                clients
              </Text>
            </Center>
          </div>
        </div>
        <div className="section_3">
          <div className="m_block">
            <Text
              as="h3"
              fontSize={"1em"}
              style={{ lineHeight: "2em", padding: "1rem", fontWeight: "700" }}
            >
              Our Services{" "}
            </Text>

            <div className="tabs">
              <Tabs isFitted variant="soft-rounded" colorScheme="white">
                <TabList>
                  <Tab
                    fontSize={"1em"}
                    className="tab"
                    data-aos="flip-left"
                    _selected={{ color: "white", bg: "#033053" }}
                  >
                    End-user Support
                  </Tab>
                  <Tab
                    fontSize={"1em"}
                    className="tab"
                    data-aos="flip-left"
                    _selected={{ color: "white", bg: "#033053" }}
                  >
                    IT Infrastructure Support
                  </Tab>
                  <Tab
                    fontSize={"1em"}
                    className="tab"
                    data-aos="flip-right"
                    _selected={{ color: "white", bg: "#033053" }}
                  >
                    IT Hardware & Software Sales{" "}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <div className="tab_inside">
                      <Heading as={"h3"} size={"1em"}>
                        END-USER SUPPORT{" "}
                      </Heading>
                      <Divider />
                      <Text fontSize={"1em"}>Software Installations</Text>
                      <Text fontSize={"md"}>Hardware Installations</Text>
                      <Text fontSize={"md"}>Software Upgrades</Text>
                      <Text fontSize={"md"}>Virus Removal </Text>
                      <Text fontSize={"md"}>
                        ...and more IT Related Issues{" "}
                      </Text>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab_inside">
                      <Heading as={"h3"} fontSize={"1em"}>
                        IT INFRASTRUCTURE SUPPORT
                      </Heading>
                      <Divider />
                      <Text fontSize={"1em"}>
                        {" "}
                        Network connectivity Installations and Support{" "}
                      </Text>

                      <Text fontSize={"1em"}>
                        WIFI Troubleshooting and Performance Improvement
                      </Text>
                      <Text fontSize={"1em"}>
                        Audio and Visual Installations and Support{" "}
                      </Text>
                      <Text fontSize={"1em"}>
                        Video Conferencing System Installation and Support{" "}
                      </Text>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab_inside">
                      <Heading as="h3" p={0}>
                        IT HARDWARE & SOFTWARE SALES
                      </Heading>
                      <Divider />
                      <Text fontSize={"md"}>
                        Laptop/Desktop/Mobile Devices{" "}
                      </Text>
                      <Text fontSize={"md"}>Networking Equipment </Text>
                      <Text fontSize={"md"}>Audio and Visuals</Text>
                      <Text fontSize={"md"}>Software Products</Text>
                    </div>
                  </TabPanel>
                </TabPanels>
                <div className="_power">
                  <div className="text">
                    <Text fontSize={"1em"}>
                      To learn more about our solutions visit our company
                      website{" "}
                    </Text>
                  </div>
                  <div className="ndalaict">
                    <div className="logo">
                      <img src={ndalaict} alt="" />
                    </div>
                    <div className="link">
                      <div className="site">
                        <a
                          target="_blank"
                          href="https://www.ndalaict.com"
                          rel="noreferrer"
                          style={{ color: "silver" }}
                        >
                          NdalaICT Solutions{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="section_4">
          <div className="m_block"></div>
        </div>
        <div className="section_5">
          <div data-aos="zoom-in" className="m_block one">
            {/* <div className="m_block">
              <Link to="/contacts">
                {" "}
                <Text
                  fontSize={".8em"}
                  m={1}
                >{`>> Find out more about our pricing list << `}</Text>
              </Link>
            </div> */}
            <img src={pexels_field_engineer} alt="" />
          </div>
          <div className="m_block_2 m_block">
            <div className="text w-100 ">
              <Text fontSize={"1em"} color="white">
                We recruit experienced IT technicians across the country.
                <br />
                All IT Technicians undergo a comprehensive background screening{" "}
                <br /> and vetting process, including technical skills
                assessments.
              </Text>
              <Box p={0}>
                <Link to="/agentsignup">
                  <Button fontSize={"1em"} w={"auto"} p={0} borderRadius="sm">
                    JOIN US
                  </Button>
                </Link>
              </Box>
            </div>
          </div>
        </div>
        <div className="section_6">
          <div className="heading">
            <Text
              as="h3"
              fontSize={"1.4em"}
              style={{ lineHeight: "2em", padding: "1em", fontWeight: "700" }}
            >
              Our Partners{" "}
            </Text>
            <hr />
          </div>
          <div className="m_block" style={{ marginBottom: "3rem" }}>
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              autoHeight={false}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              className="mySwiper"
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[EffectFade, Pagination, Autoplay]}
            >
              <SwiperSlide style={{ width: "100%" }}>
                <Image src={mtn} alt="" style={{ width: "100%" }} />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={kathea} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={Fortinet} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={huawei} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={LiquidTelecom} />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={cisco} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={Polycom} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <Image src={tod_logo} alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
          <Box className="contact_us">
            <Box className="contact_us_inner">
              <div className="words">
                <Text fontSize={"1em"}>
                  Become industrial competitive by taking advantage of our
                  technical Solutions{" "}
                </Text>
              </div>
              <Button fontSize={"1em"} onClick={() => navigate("/signup")}>
                Register with Us
              </Button>
            </Box>
          </Box>
        </div>

        <Footer />
      </div>

      <Box
        position={"fixed"}
        mixBlendMode={"difference"}
        onClick={() => window.scrollTo(0, 0)}
        _hover={{ mixBlendMode: "normal" }}
        bottom={0}
        right={0}
        zIndex={10}
        boxShadow="lg"
        bgColor={"blue.900"}
        p={3}
        m={4}
      >
        <Icon as={BsArrowUp} color="white" size="lg" />
      </Box>
    </div>
  );
}

export default HomePage;
