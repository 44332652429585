import {Box, Button, Spinner, Text} from "@chakra-ui/react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {server_URL} from "../serverURL";
import {useSelector} from "react-redux";
import axios from "axios";
import {Store} from "react-notifications-component";


function SmsVerify(){

    const userData = useSelector(state => state.user)
    const navigate=useNavigate();
    const [email,setEmail]=useState('')
    const [isLoading,setIsLoading]=useState(false)
    const [name,setName]=useState()
    const verifySms=async () => {
        setIsLoading(true)
        const config = {
            url: server_URL + "/users/resendotp",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: {email}
        }
        await axios(config).then(response => {
            if(response){
                setIsLoading(false)
                navigate("/verify")
            }
        }).catch(error=>{
            console.log(error)

            sendingMessage("error.message",'danger',5000)
            setIsLoading(false)
        })
    }
    useEffect(()=>{
        console.log(sessionStorage.getItem('Session-email'))
        if(!sessionStorage.getItem('Session-email')){
            navigate("/login")
        }
        setEmail(sessionStorage.getItem('Session-email'))
    },[])
    const sendingMessage = ({data,type,time}) => {
        Store.addNotification({
            title: data,
            type,
            insert: "bottom",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: time,
                onScreen: true,
            },onRemoval: () => {
                setIsLoading(false);

            },
        });
    };

    return (
        <Box>
            <NavBar/>
            <Box className={'p-14 md:py-40 lg:py-48 md:px-0'}>
                <Box className={'w-full md:w-1/2 mx-auto p-14 md:p-28'} bgColor={'gray.200'}>
                    <Text>Verify your account to continue </Text>
                    <Button bgColor={'#033053'} onClick={()=>verifySms()} boxShadow={'lg'} _hover={{bgColor:'white',color:'black'}} color={'white'} className={'w-full m-10'}>{!isLoading?<Text>Verify Account</Text>:<Spinner/>} </Button>
                </Box>
            </Box>
            <Footer/>
        </Box>
    )
}

export default SmsVerify