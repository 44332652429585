import React from 'react'
import {Tabs, TabList, TabPanels, Tab, TabPanel, Text} from '@chakra-ui/react'
import {Menubar} from "primereact/menubar";
import {useNavigate} from "react-router-dom";
function AdminAllClients() {

  return (
    <div>
     <div>
       <Text className={'text-xl m-2'} fontFamily={'RobotoCondensedBold'}>All Clients </Text>
     </div>
      <div>

      </div>
    </div>
  )

}

export default AdminAllClients
