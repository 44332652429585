import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Wrap,
} from "@chakra-ui/react";
import React, { useState } from "react";
import NavBar from "../components/navBar";
import Footer from "./Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import { CheckCircleIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Store } from "react-notifications-component";
import { server_URL } from "../serverURL";

function NewPassword() {
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const [tokenExpired, settokenExpired] = useState(false);
  const [passwordChanged, setpasswordChanged] = useState(false);
  const [emailSent, setemailSent] = useState(false);
  const navigate = useNavigate();

  const verifyToken = async (values) => {
    if (values && searchParams.get("id") && searchParams.get("token")) {
      const data = {
        token: searchParams.get("token"),
        id: searchParams.get("id"),
        password: values.password,
      };
      console.log(searchParams.get("token"));
      const config = {
        method: "post",
        //url: "https://d24susgzy6zbdw.cloudfront.net/verify",
        url: server_URL + "/users/password",
        headers: {
          Accept: "application/json",
        },
        data: data,
      };
      await axios(config)
        .then((response) => {
          if (response.data === "Token expired") {
            console.log("This token has expired .....");
            settokenExpired(true);
          } else {
            setpasswordChanged(true);
          }
        })
        .catch((err) => console.log("Could not verify the token", err));
    } else {
      navigate("/not_found");
    }
    //   const token =searchParams.get("token")
    //   const id=searchParams.get("id")
    //   const {password} =values
  };
  const sendLink = async () => {
    if (searchParams.get("id")) {
      console.log("Sending");
      const email = searchParams.get("id");
      console.log(email);
      await axios
        .post(`${server_URL}/user/forgot/`, { email })
        .then((response) => {
          console.log(response);
          if (response.data.length > 0) {
            sendingMessage(email);
            setemailSent(true);
            console.log("Sent");
          } else {
            ErrorMessage();
          }
        })
        .catch((e) => console.log());
    }
  };
  const sendingMessage = (email) => {
    Store.addNotification({
      title: `Email resent to ${email} `,
      message: "Please check your emails",
      type: "success",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  const handleClick = () => setShow(!show);
  const passwordForm = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      verifyToken(values);
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords dont match")
        .required("Required"),
    }),
  });
  const ErrorMessage = () => {
    Store.addNotification({
      title: "Expired Token",
      message: "",
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  return (
    <div className="new_password">
      <NavBar />
      <div className="body">
        {passwordChanged ? (
          <Flex
            flexDir={"column"}
            alignItems={"center"}
            bgColor={"#d9d9d9"}
            justifyContent={"center"}
            h={"90vh"}
          >
            <CheckCircleIcon fontSize={"2em"} color={"green"} />
            <Text m={3}>Your password has been changed successfully!!!</Text>
            <Link to={"/login"} w={"100%"} style={{ textDecoration: "none" }}>
              <Button
                color={"white"}
                w={"10rem"}
                _hover={{ backgroundColor: "white", color: "black" }}
                bgColor={"blue.600"}
                onClick={() => verifyToken()}
              >
                Login
              </Button>
            </Link>
          </Flex>
        ) : (
          <form onSubmit={passwordForm.handleSubmit}>
            <Center
              flexDir={"column"}
              style={{ backgroundColor: "#d9d9d9", height: "90vh" }}
            >
              <Heading fontSize={"lg"}>Reset Password </Heading>
              <Flex
                w={"50%"}
                m={4}
                display="flex"
                flexDir={"column"}
                alignItems={"flex-start"}
              >
                <InputGroup size="md">
                  <Input
                    disabled={tokenExpired ? true : false}
                    bgColor={"white"}
                    name="password"
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter new password"
                    onChange={passwordForm.handleChange}
                    value={passwordForm.values.password}
                    onBlur={passwordForm.handleBlur}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {passwordForm.touched.password &&
                passwordForm.errors.password ? (
                  <div className="error" style={{ color: "rgb(196, 68, 68)" }}>
                    {passwordForm.errors.password}!!!
                  </div>
                ) : null}
              </Flex>
              <Flex
                w={"50%"}
                display="flex"
                flexDir={"column"}
                alignItems={"flex-start"}
              >
                <InputGroup size="md">
                  <Input
                    disabled={tokenExpired ? true : false}
                    bgColor={"white"}
                    name="confirmPassword"
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Confirm new password"
                    onChange={passwordForm.handleChange}
                    onBlur={passwordForm.handleBlur}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {passwordForm.touched.confirmPassword &&
                passwordForm.errors.confirmPassword ? (
                  <div className="error" style={{ color: "rgb(196, 68, 68)" }}>
                    {passwordForm.errors.confirmPassword}!!!
                  </div>
                ) : null}
              </Flex>
              <Wrap m={5}>
                <Button
                  value="submit"
                  type="submit"
                  _hover={{ color: "silver" }}
                  color={"white"}
                  backgroundColor={"#033053"}
                >
                  Change Password
                </Button>
              </Wrap>
              {tokenExpired ? (
                <Box>
                  <Text my={3} color={"red"}>
                    This token has expired
                  </Text>
                  <Button color={"white"} onClick={() => sendLink()}>
                    Resend Link
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Center>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default NewPassword;
