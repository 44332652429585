import { configureStore } from "@reduxjs/toolkit";
// import ActiveUsersSlice from "./DataStore/Admin/ActiveUsers";
// import technicians from "./DataStore/Admin/technicianList";
// import ticketsListSlice from "./DataStore/Admin/ticketsList";
//  import UsersSlice from "./DataStore/Admin/Users";
import BankingSlice from "./bankingData";
import ClientCommunicationSlice from "./Client/ClientCommunication";
import ClientTicketSlice from "./Client/ClientTickets.js";
import NotificationSlice from "./Client/notifications";
import AdminTicketSlice from "./AdminStore/Tickets";
import ItemSlice from "./AdminStore/Items";
import AdminUClientsSlice from "./AdminStore/Clients";
import UserWallet from './userWallet'
import SLAStore from "./AdminStore/SLAStore"
import SuppliersDataSlice from "./Supplier/SuppliersData"
import VendorsDataSlice from "./Vendors/VendorData"
import AgentTicketsSlice from './AgentStore/agentTickets'
import AgentSettingsSlice from './AgentStore/AgentSettings'
// import TokenSlice from "./DataStore/token";
import UserSlice from "./UserData";
import AdminClientSlice from "./AdminStore/Clients";
import AgentSignUpDataSlice from "./AgentStore/AgentSignUpData";
import userWallet from "./userWallet";
import AdminQuotationsSlice from "./AdminStore/Quotations";
const store = configureStore({
  reducer: {
    clientTickets: ClientTicketSlice.reducer,
    newAgent: AgentSignUpDataSlice.reducer,
    // tickets:ticketsListSlice.reducer,
    wallet:userWallet.reducer,
    user: UserSlice.reducer,
    // token:TokenSlice.reducer,
    banking: BankingSlice.reducer,
    chats: ClientCommunicationSlice.reducer,
    // activeusers:ActiveUsersSlice
    notification: NotificationSlice.reducer,
    admintickets: AdminTicketSlice.reducer,
    adminclients: AdminClientSlice.reducer,
    adminquotations:AdminQuotationsSlice.reducer,
    admininvoices:AdminQuotationsSlice.reducer,
    //Agent reducers
    agenttickets:AgentTicketsSlice.reducer,
    agentSettings:AgentSettingsSlice.reducer,
    SLAPolicies: SLAStore.reducer,
    ItemSlice:ItemSlice.reducer,
    Suppliers:SuppliersDataSlice.reducer,
    Vendors: VendorsDataSlice.reducer
  },
});
export default store;
