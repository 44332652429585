import React from 'react'
import {useNavigate} from "react-router-dom";
import {Box, Button, Input, Text, Textarea} from "@chakra-ui/react";
import {Formik} from "formik";
import {Dropdown} from "primereact/dropdown";
import {FileUpload} from "primereact/fileupload";

function NewAgent() {
  const navigate= useNavigate()
  return (
      <Box  className={'h-full m-0 p-0'}>
        <Box className={'flex flex-col h-full'} boxShadow={'md'}>
          <Box className={'w-full p-2'} bgColor={'white'}>
            <Text> New Agent  </Text>
          </Box>
          <Box className={'flex-grow-1 h-full'}>
            <Formik initialValues={{
              subject:'',
              sender:'',
              agent:"",
              service:'',
              description:'',
              status:'',
              priority:'',
              cc_emails:[],
              isEscalated:false,
              deleted:false,
              agentName:'',
              companyID:'',
            }
            } onSubmit={(values)=>{
            }
            }>
              {({values,isSubmitting,errors})=>(
                  <Box as={"form"} className={'p-0 relative w-full h-full flex flex-row overflow-y-hidden  mx-auto'} boxShadow={'md'} >
                    <Box className={'p-1 relative w-9/12 h-full flex flex-col overflow-y-hidden '}>
                      <Box className={'m-1 p-1 flex flex-col  mx-0 w-full h-full'} borderRadius={'5px'} bgColor={'white'}>
                        <Box className={' flex flex-col flex-grow-1'} >
                          <Box className={'flex flex-col w-full justify-start'} >
                            <Text className={'m-1 p-0 text-start w-auto'}>Subject:</Text>
                            <Input size={'md'} className={'m-0 p-2 w-1/2'}/>
                          </Box>
                          <Box className={'flex flex-row w-full justify-between'}>
                            <Box className={'flex my-1 w-full flex-col'}>
                              <Text  className={'m-1 p-0 text-start w-auto'}> Status: </Text>
                              <Dropdown></Dropdown>
                            </Box>
                            <Box className={'flex  m-1 w-full flex-col'}>
                              <Text  className={'m-1 p-0 text-start w-auto'}> Priority: </Text>
                              <Dropdown></Dropdown>
                            </Box>
                            <Box className={'flex m-1 w-full flex-col'}>
                              <Text className={'m-1 p-0 text-start w-auto'}> Status: </Text>
                              <Dropdown className={'w-full'}></Dropdown>
                            </Box>
                            <Box className={'flex m-1 w-full flex-col'}>
                              <Text className={'m-1 p-0 text-start w-auto'}> Agent: </Text>
                              <Dropdown></Dropdown>
                            </Box>
                          </Box>
                          <Box className={'flex-grow-1 flex flex-col py-2 '} >
                            <Text  className={'m-1 p-0 text-start w-auto'}> Description: </Text>
                            <Textarea className={'flex-grow-1'}/>
                          </Box>
                        </Box>
                        <Box className={'m-1 flex flex-row justify-end'}>
                          <Button size={'md'} className={'mx-2'}>Close</Button>
                          <Button size={'md'} bgColor={'blue.800'} color={'white'} className={'mx-2'}>Create</Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={'mx-1 my-2 h-full'} boxShadow={'md'} borderRadius={'md'} >
                      <Text className={'p-1'} bgColor={'white'}  color={''} classNa>Attachments</Text>
                      <Box>{files.map(file=>(
                          <Box className={'flex m-1 p-1 flex-row justify-between'}>
                            <Text>{file.filename}</Text>
                            <Button size={'sm'}>Download</Button>
                          </Box>
                      ))}</Box>
                      <FileUpload maxFileSize={2000000} multiple className={'text-sm h-full'} emptyTemplate={<Box className="m-0 p-1 h-1/3">Drag and drop files to here to upload.</Box>} ></FileUpload>
                    </Box>
                  </Box>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
  )
}

const files = [
  {filename:'INage.pg',url:'eetwrtwrwter.vom'},
  {filename:'INaplpge.pg',url:'eetwrtwrwter.vom'},
]
export default NewAgent