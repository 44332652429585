import { createSlice } from "@reduxjs/toolkit";

const AgentSignUpDataSlice = createSlice({
  name: "chats",
  initialState: {
    fullName:'',
    email:'',
    phone:'',
    password:'',
    attachments:[]
  },
  reducers: {
    addNewAgent(state, action) {
      const agent =action.payload;
      state.fullName = agent.fullName;
      state.email = agent.email;
      state.phone = agent.phone;
      state.password = agent.password;
    },
    addNeAgentAttachments(state,action){
        const attachments = action.payload;
        state.attachments =attachments;
    },
    addNewAttachement(state,action){
        const attachment = action.payload;
        state.attachments.push(attachment);
    },
  },
});
export const AgentSignUpDataActions = AgentSignUpDataSlice.actions;
export default AgentSignUpDataSlice;
